import { OpportunityAdoptionMetric } from '../components/opportunity/OpportunityAdoptionChart'

const copy = {
  dashboard: {
    levelsFull: {
      title: 'Levels',
      description:
        'The count of members who have moved into each level from a higher or lower level',
    },
    levelMovement: {
      title: 'Level Movement',
      description: 'View the movement of members between levels over time',
    },
    membersPerLevel: {
      title: 'Members per Level',
      description:
        'The count of members in each level ranked with our propietary Member Product Participation (MPP™) score.',
    },
  },
  explore: {
    presetHeader: 'Select a Goal',
    presets: {
      growth: {
        title: 'Grow a Product/Service',
        description:
          'Find members who have high propensity to adopt a specific product or service ',
      },
      churn: {
        title: 'Mitigate churn',
        description:
          'Find members who have a high propensity to churn from a specific product or service',
      },
      lowHangingFruit: {
        title: 'Low hanging fruit',
        description:
          'Find products or services with the highest propensity for grow member participation',
      },
      levelGrowth: {
        description:
          'Find products or services that can help move members up from a specific level',
      },
      memberExplore: {
        title: 'Explore Members',
        description: 'Explore your membership without a specific goal in mind',
      },
    },
    recommendationsHeader: {
      title: 'Recommended Product Categories',
      description:
        'Product categories are given a rating (0-5) based on the number and distribution of propensities for members to adopt/churn',
    },
    filter: {
      invertDescription: 'Invert the filter to include only members who do not meet the criteria',
    },
    memberExplore: {
      title: 'Explore members',
      description: 'Explore your membership without a target product in mind',
    },
  },
  opportunity: {
    table: {
      headers: {
        targetedMembers: 'The number of members targeted as part of the opportunity',
        predictedGrowth:
          "The estimated value of growth/churn based on the product's value and propensity of the selected members",
        impactedMembers:
          'The number of targeted members who have adopted a product or have been retained as part of the opportunity',
        timeline: 'The dates that the opportunity will run (and results will be measured for',
        avgPropensity:
          'The average propensity of the targeted members for the selected target product category',
        totalAssetValue:
          'The total value of all assets held by the targeted members for the selected target product category',
        aiScore:
          'The score given to the opportunity by our AI based on propensity, size, feature density, and other factors',
      },
    },
    modal: {
      steps: {
        targetedMembers: {
          title: 'Targeted Members',
          description:
            'View the selected filters and basic information about your selected targeted members',
        },
        marketingPlan: {
          title: 'Marketing Plan',
          description:
            'Outline a marketing plan yourself or have AI generate it for you, catered specifically to your target market',
        },
        marketingDetails: {
          title: 'Marketing methods',
          description:
            'Select the marketing methods you plan to use to reach your target members for this opportunity. Plan the content of these methods yourself or have AI generate it for you, catered specifically to the target market and method',
        },
      },
    },
    ai: {
      button: {
        title: 'AI Opportunities',
        description:
          'Our AI has identified high propensity opportunities that you may be interested in',
      },
      modal: {
        title: 'AI Opportunities',
        description:
          'Our AI has identified opportunities for you to explore. You accept these opportunities to add them to your list of opportunities, or you can delete them',
        empty: {
          title: 'No new AI Opportunities',
          subtitle: 'Check back later for new AI opportunities',
        },
      },
    },
    view: {
      results: {
        title: 'Results',
        description: 'View information about how your opportunity performed',
        aiSummary: {
          title: 'AI Summary',
          description: 'Quickly check out a brief summary of the results of your opportunity',
        },
        extendedObservationPeriod: {
          title: 'Extended Observation Period',
          description: 'Results will be measured until this date',
        },
        adoption: {
          metrics: {
            [OpportunityAdoptionMetric.ACTUAL]: {
              title: 'Actual',
              description: 'The actual adoption of the target product',
            },
            [OpportunityAdoptionMetric.ONE_MONTH_HISTORICAL_AVG]: {
              title: '1 Month Historical Average',
              description:
                'Calculated from the average adoption rate of the target product over the last month',
            },
            [OpportunityAdoptionMetric.THREE_MONTH_HISTORICAL_AVG]: {
              title: '3 Month Historical Average',
              description:
                'Calculated from the average adoption rate of the target product over the last three months',
            },
            [OpportunityAdoptionMetric.MATCHING_PERIOD_ONE_YEAR]: {
              title: 'Matching Period One Year Ago',
              description:
                'Calculated from the adoption of the target product over the same period one year ago',
            },
            [OpportunityAdoptionMetric.APPLIED]: {
              title: 'Applied',
              description:
                'Members who have applied for the target product (Note: This metric may be 0 for products that do not require an application)',
            },
          },
        },
        segmentation: {
          title: 'Attribute Performance',
          description: 'View the performance of individual attributes of your members',
        },
        location: {
          title: 'Location Performance',
          description:
            'View the performance of the opportunity across geographic regions (limited to the top 20 cities, states, or zips)',
        },
        stats: {
          targetedMembers: {
            title: 'Targeted Members',
            description: 'The number of members who were targeted as part of the opportunity',
          },
          churnRate: {
            title: 'Churn Rate',
            description:
              'The percentage of targeted members who churned the product during the campaign',
          },
          conversionRate: {
            title: 'Conversion Rate',
            description:
              'The percentage of targeted members who opened the product during the campaign',
          },
          uniqueMembersOpened: {
            title: 'Members Opened',
            description:
              'The number of targeted members who opened the product during the campaign',
          },
          uniqueMembersChurned: {
            title: 'Members Churned',
            description:
              'The number of targeted members who churned the product during the campaign',
          },
          productsOpened: {
            title: 'Products Opened',
            description:
              'The total number of products that where opened by members who were targeted as part of the opportunity',
          },
          productsChurned: {
            title: 'Products Churned',
            description:
              'The total number of products that where churned by members who were targeted as part of the opportunity',
          },
          avgBalanceAdded: {
            title: 'Average Balance Added',
            description:
              'The sum of all balances of products opened by targeted members divided by the number of products opened',
          },
          avgBalanceLost: {
            title: 'Average Balance Lost',
            description:
              'The sum of all balances of products churned by targeted members divided by the number of products churned',
          },
          totalBalanceAdded: {
            title: 'Total Balance Added',
            description: 'The sum of all balances of products opened by targeted members',
          },
          totalBalanceLost: {
            title: 'Total Balance Lost',
            description: 'The sum of all balances of products churned by targeted members',
          },
          applicationsCount: {
            title: 'Applications Opened',
            description: 'The number of applications opened by targeted members',
          },
          pctDiffLastYear: {
            title: '% Difference from Last Year',
            description: 'The difference in performance from the same period last year',
          },
          pctDiffThreeMonthAvg: {
            title: '% Difference from 3 Month Average',
            description: 'The difference in performance from the 3 month average',
          },
          pctDiffNonTargeted: {
            title: '% Difference from Non-Targeted',
            description: 'The difference in performance from non-targeted members',
          },
        },
        attributes: {
          table: {
            metricCol: {
              title: 'Metric',
              description: 'A feature relating to demographics, transactions, or other trends',
            },
            attributeCol: {
              title: 'Attribute',
              description: 'A specific value of the metric',
            },
            opensCol: {
              title: 'Opens',
              description:
                'The number of targeted members with this attribute who opened the target product',
            },
            percentOpensCol: {
              title: '% of Opens',
              description:
                'The percentage of targeted members who opened the target product with this attribute',
            },
            targetedCol: {
              title: 'Targeted',
              description: 'The number of targeted members with this attribute',
            },
            percentTargetedCol: {
              title: '% of Targeted',
              description: 'The percentage of targeted members with this attribute',
            },
            percentPerformanceCol: {
              title: '% Performance',
              description: 'The performance percentage (% Opens - % Targeted)',
            },
          },
          title: 'Member Attributes',
          description: 'View, filter, and sort all attributes to find what really matters',
        },
      },
      publish: {
        warning: {
          title: 'Publish?',
          description:
            'You are about to publish this opportunity. Once it is published, the targeted member group and dates can no longer be changed. The member list will also be sent to any integrations that have been added to your organization',
        },
      },
    },
    upload: {
      title: 'Upload Target List',
      description:
        'Upload a list of members to target for this opportunity. This list will be used to measure the results of the opportunity',
      file: {
        description: 'A CSV file containing one column (no header) and rows of target member ids',
      },
    },
  },
  admin: {
    integrations: {
      title: 'Integrations',
      description: 'Opportunity member lists will be sent to these third party integrations.',
      none: 'No integrations have been added to your organization... yet. Contact us to add one',
    },
  },
}

export default copy
