import { Icon } from '@iconify/react'
import { Stack, Typography } from '@mui/material'
import icons from '../../config/icons.config'

export interface MetricErrorViewProps {
  metricID: string
}

export default function MetricErrorView({ metricID }: MetricErrorViewProps) {
  return (
    <Stack
      sx={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        p: 2,
      }}
    >
      <Icon icon={icons.error} width={40} />
      <Typography variant='subtitle1'>
        Sorry! Something went wrong fetching {metricID} data
      </Typography>
    </Stack>
  )
}
