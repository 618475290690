import { Card, SxProps } from '@mui/material'

export interface StandardCardProps {
  children: React.ReactNode
  variant?: 'outlined'
  sx?: SxProps
  padding?: number
}

export default function StandardCard({ children, variant, sx, padding }: StandardCardProps) {
  return (
    <Card
      sx={{
        padding: padding ?? 3,
        boxShadow: variant === 'outlined' ? 'none' : undefined,
        borderColor: 'grey.300',
        ...sx,
      }}
      variant={variant}
    >
      {children}
    </Card>
  )
}
