export interface LevelArgument {
  levelID: string
  name: string
  scoreStart: number
  scoreEnd: number
}

export default class Level {
  readonly levelID: string
  readonly name: string
  readonly scoreStart: number
  readonly scoreEnd: number

  constructor(arg: LevelArgument) {
    this.levelID = arg.name
    this.name = arg.name
    this.scoreStart = arg.scoreStart
    this.scoreEnd = arg.scoreEnd
  }
}
