import { Stack } from '@mui/material'
import MetricsConfig from '../../model/metric/MetricsConfig'
import SelectableButton from '../util/SelectableButton'

export interface MetricConfigButtonProps {
  config: MetricsConfig
  isSelected: boolean
  onSelect: (config: MetricsConfig) => void
}

export default function MetricConfigButton({
  config,
  isSelected,
  onSelect,
}: MetricConfigButtonProps) {
  return (
    <SelectableButton
      key={config.metricsConfigID}
      isSelected={isSelected}
      onClick={() => onSelect(config)}
      data-testid={'metric-config-btn-' + config.name}
    >
      <Stack direction='row' alignItems='center' gap={2}>
        {config.name}
      </Stack>
    </SelectableButton>
  )
}
