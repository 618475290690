import { Box, Dialog } from '@mui/material'
import Metric from '../../model/admin/Metric'
import ChartType from '../../model/chart/ChartType'
import MetricData from '../../model/metric/MetricData'
import MetricChartDisplay from '../chart/MetricChartDisplay'
import ModalCloseButton from '../modal/ModalCloseButton'
import ModalContent from '../modal/ModalContent'
import ModalHeader from '../modal/ModalHeader'

export interface FullMetricModalProps {
  chartType: ChartType
  metric: Metric
  metricData: MetricData
  selectedValues: string[] | undefined
  onClose: () => void
}

export default function FullMetricModal({
  chartType,
  metric,
  metricData,
  selectedValues,
  onClose,
}: FullMetricModalProps) {
  return (
    <Dialog open onClose={onClose} maxWidth='lg' fullWidth>
      <ModalCloseButton onClose={onClose} />
      <ModalHeader title={metric.name} description={metric.description} center />
      <ModalContent>
        <Box height={400} p={5}>
          <MetricChartDisplay
            metricID={metric.metricID}
            type={chartType}
            data={metricData.chartData}
            selectedValues={selectedValues}
          />
        </Box>
      </ModalContent>
    </Dialog>
  )
}
