import { ImmutableArray, useHookstate } from '@hookstate/core'
import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useModal } from '../../../contexts/ModalContext'
import { adminManager, exploreManager } from '../../../managers/_manager.config'
import Client, { ClientArgument } from '../../../model/admin/Client'
import Metric from '../../../model/admin/Metric'
import globalState from '../../../service/external/GlobalState'
import { ExportMembersModalContent } from '../../opportunity/ExportMembersModal'

export default function ExportSettingsTab() {
  // -- Navigation
  const { openModal, closeModal, showSnackbar } = useModal()

  // -- Form
  const { handleSubmit, reset } = useForm<ClientArgument>()

  // -- Global state
  const exploreMetrics = useHookstate(globalState.exploreMetrics).get().data
  const currentClient = useHookstate(globalState.currentClient).get()
  const clients = useHookstate(globalState.clients).get().data

  // -- Local state
  // const [client, setClient] = useState(currentClient!)
  const [originalClient, setOriginalClient] = useState(currentClient!)
  const [selectedMetrics, setSelectedMetrics] = useState<Metric[]>([])

  // Effect
  useEffect(() => {
    exploreManager.fetchMetrics().then(() => {
      if (currentClient && clients) {
        adminManager.selectClient(currentClient.clientID)
        updateSelectedMetrics(currentClient.exportFormat)
      }
    })
  }, [currentClient, clients, adminManager])

  // -- Actions
  const handleReset = (client: Client) => {
    if (!client.exportFormat || !exploreMetrics) return
    updateSelectedMetrics(client.exportFormat)
    reset({
      exportFormat: client.exportFormat as string[] | undefined,
    })
  }

  const handleSave: SubmitHandler<ClientArgument> = async (data) => {
    const updatedClient = new Client({
      ...(originalClient as Client),
      exportFormat: selectedMetrics.map((metric) => metric.metricID),
    })
    await adminManager.saveClient(updatedClient)
    setOriginalClient(updatedClient)
    // handleReset(updatedClient)
    showSnackbar('export-format-updated', 'Changes saved')
  }

  const updateSelectedMetrics = (metricIDs?: ImmutableArray<string>) => {
    if (exploreMetrics && metricIDs) {
      const temp = (exploreMetrics as Metric[]).filter((metric) =>
        metricIDs.includes(metric.metricID),
      ) as Metric[]
      // temp.sort((a, b) => a.name.localeCompare(b.name))
      setSelectedMetrics(temp)
    }
  }

  return (
    <Card variant='outlined' sx={{ width: '100%' }}>
      <CardContent>
        <Typography variant='h5' component='h1' fontSize='lg'>
          Default Export Format
        </Typography>
        <Typography fontSize='sm' mb={1} color='text.secondary'>
          Define which metrics to include by default when exporting data
        </Typography>
        <Box mt={4}>
          <form onSubmit={handleSubmit(handleSave)}>
            <Stack spacing={2} m={3}>
              <ExportMembersModalContent
                openModal={openModal}
                closeModal={closeModal}
                exploreMetrics={exploreMetrics as Metric[]}
                selectedMetrics={selectedMetrics}
                setSelectedMetrics={setSelectedMetrics}
              />
            </Stack>
            <CardActions>
              <Stack direction='row' gap={1} mt={2}>
                <Button type='submit' variant='contained' color='primary'>
                  Save
                </Button>
                <Button onClick={() => handleReset(originalClient as Client)}>Undo</Button>
              </Stack>
            </CardActions>
          </form>
        </Box>
      </CardContent>
    </Card>
  )
}
