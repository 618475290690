import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { List, ListItem, ListSubheader, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import icons from '../../config/icons.config'
import Role from '../../model/admin/Role'
import globalState from '../../service/external/GlobalState'
import PermissionView from '../util/PermissionView'
import SelectableButton from '../util/SelectableButton'

export interface AdminSection {
  label: string
  pages: AdminPage[]
  permissionID: string
}

export interface AdminPage {
  label: string
  icon: string
}

const sections: AdminSection[] = [
  {
    label: 'Global Admin',
    pages: [
      {
        label: 'Clients',
        icon: icons.bank,
      },
      {
        label: 'Users',
        icon: icons.person,
      },
      {
        label: 'Default Methods',
        icon: icons.megaphone,
      },
      {
        label: 'Permissions',
        icon: icons.key,
      },
      {
        label: 'Roles',
        icon: icons.role,
      },
      {
        label: 'Announcements',
        icon: icons.announcement,
      },
    ],
    permissionID: 'global-admin',
  },
  {
    label: 'Organization Admin',
    pages: [
      {
        label: 'Organization',
        icon: icons.people,
      },

      {
        label: 'Users',
        icon: icons.person,
      },
      {
        label: 'Methods',
        icon: icons.megaphone,
      },
      {
        label: 'Integrations',
        icon: icons.integration,
      },
      {
        label: 'Default Export',
        icon: icons.export,
      },
    ],
    permissionID: 'client-admin',
  },
]

export default function SideBar({ onSelectPage }: { onSelectPage: (pageID: string) => void }) {
  // -- Global state
  const user = useHookstate(globalState.user).get().data
  const roles = useHookstate(globalState.roles).get().data

  // -- Local state
  const [selectedPage, setSelectedPage] = useState(getDefaultPageID())

  // -- Lifecycle
  // Set default page
  useEffect(() => {
    const defaultPageID = getDefaultPageID()
    if (defaultPageID) onSelectPage(defaultPageID)
  }, [])

  // -- Logic
  function getDefaultPageID() {
    if (!user) return null
    const section = sections.find((section) =>
      user.hasPermission(section.permissionID, roles as Role[]),
    )
    if (!section) return null
    const page = section.pages[0]
    return getPageID(section, page)
  }

  function getPageID(section: AdminSection, page: AdminPage) {
    return `${section.label}-${page.label}`
  }

  // -- Actions
  function onPageButtonClick(section: AdminSection, page: AdminPage) {
    const pageID = getPageID(section, page)
    setSelectedPage(pageID)
    onSelectPage(pageID)
  }

  return (
    <List
      sx={{
        minWidth: 250,
        borderRadius: 1.5,
        marginLeft: 2,
        marginTop: 2,
        height: '100%',
        boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2),0 12px 24px -4px rgba(145, 158, 171, 0.12)',
      }}
      component={Paper}
    >
      {sections.map((section, index) => (
        <PermissionView requiredPermissions={[section.permissionID]} key={index}>
          <List subheader={<ListSubheader>{section.label}</ListSubheader>}>
            {section.pages.map((page, index) => (
              <ListItem
                key={index}
                sx={{
                  paddingY: 0.5,
                }}
              >
                <SelectableButton
                  sx={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    px: 2,
                    fontWeight: 'regular',
                  }}
                  isSelected={selectedPage === getPageID(section, page)}
                  onClick={() => onPageButtonClick(section, page)}
                >
                  <Icon
                    icon={page.icon}
                    style={{
                      marginRight: '0.5rem',
                      marginBottom: '2px',
                    }}
                  />
                  {page.label}
                </SelectableButton>
              </ListItem>
            ))}
          </List>
        </PermissionView>
      ))}
    </List>
  )
}
