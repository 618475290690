export enum ProductCategoryType {
  loan = 'loan',
  share = 'share',
  service = 'service',
}

export interface ProductCategoryArgument {
  productCategoryID: string
  name: string
  type: ProductCategoryType
  value: number
}

export default class ProductCategory {
  readonly productCategoryID: string
  readonly name: string
  readonly type: ProductCategoryType
  readonly value: number

  constructor(arg: ProductCategoryArgument) {
    this.productCategoryID = arg.productCategoryID
    this.name = arg.name
    this.type = arg.type
    this.value = arg.value
  }
}
