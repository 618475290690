import { useHookstate } from '@hookstate/core'
import { useEffect } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import { adminManager } from '../../../managers/_manager.config'
import Role from '../../../model/admin/Role'
import globalState from '../../../service/external/GlobalState'
import StatusView from '../../util/StatusView'
import AdminTabView, { AdminTab } from '../AdminTabView'
import DeleteModal from '../modals/DeleteModal'
import RolesModal from '../modals/RolesModal'

export default function RolesTabView() {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()

  // Global state
  const rolesState = useHookstate(globalState.roles).get()
  const roles = rolesState.data

  // Effect
  useEffect(() => {
    adminManager.fetchRoles()
  }, [])

  // -- Actions
  const handleCreate = () => {
    const role = Role.create()
    openModal(
      'create-role',
      <RolesModal
        role={role}
        roles={roles as Role[]}
        isCreating
        onClose={() => closeModal('create-role')}
        onSubmit={async (updatedRole) => {
          await adminManager.saveRole(updatedRole)
          closeModal('create-role')
          showSnackbar('role-created', 'Role created')
        }}
      />,
    )
  }

  const handleEdit = (roleID: string) => {
    const role = roles?.find((role) => role.roleID === roleID)
    if (!role) return
    openModal(
      roleID,
      <RolesModal
        roles={roles as Role[]}
        role={role as Role}
        onClose={() => closeModal(role.roleID)}
        onSubmit={async (updatedRole) => {
          await adminManager.saveRole(updatedRole)
          closeModal(role.roleID)
          showSnackbar('role-updated', 'Role updated')
        }}
      />,
    )
  }

  const handleDuplicate = (roleID: string) => {
    const role = roles?.find((role) => role.roleID === roleID)
    if (!role) return
    openModal(
      roleID,
      <RolesModal
        roles={roles as Role[]}
        isCreating
        role={role.duplicate()}
        onClose={() => closeModal(role.roleID)}
        onSubmit={async (updatedRole) => {
          await adminManager.saveRole(updatedRole)
          closeModal(role.roleID)
          showSnackbar('role-created', 'Role created')
        }}
      />,
    )
  }

  const handleDelete = (roleID: string) => {
    openModal(
      roleID,
      <DeleteModal
        onClose={() => closeModal(roleID)}
        onConfirm={() => {
          adminManager.deleteRole(roleID)
          showSnackbar('role-deleted', 'Role deleted')
        }}
        label='role'
      />,
    )
  }

  const tab: AdminTab<Role> = {
    label: 'Role',
    pluralLabel: 'Roles',
    columns: [
      { name: 'ID', valueGetter: (r) => r.roleID, width: 300 },
      { name: 'Name', valueGetter: (r) => r.name },
      { name: 'Description', valueGetter: (r) => r.description },
    ],
    searchFilter: (searchText, record) =>
      record.name.toLowerCase().includes(searchText.toLowerCase()),
    rowKey: (record) => record.roleID,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDuplicate: handleDuplicate,
    onDelete: handleDelete,
  }

  return (
    <StatusView
      state={rolesState}
      render={(roles) => <AdminTabView data={roles as Role[]} tab={tab} />}
    />
  )
}
