import { Stack, Typography } from '@mui/material'
import Metric from '../../model/admin/Metric'

export interface DashboardMetricButtonProps {
  metric: Metric
  onClick: () => void
}

export default function MetricButton({ metric, onClick }: DashboardMetricButtonProps) {
  return (
    <Stack
      border={1}
      borderRadius={1}
      p={1.5}
      sx={{
        cursor: 'pointer',
        borderColor: 'grey.300',
        '&:hover': {
          backgroundColor: 'grey.200',
        },
      }}
      onClick={onClick}
    >
      <Typography fontWeight='bold'>{metric.name}</Typography>
      <Typography color='text.secondary'>{metric.description}</Typography>
    </Stack>
  )
}
