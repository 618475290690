import { MetricOptions } from '../admin/Metric'
import ContinuousFilter, {
  ContinuousFilterArgument,
  ContinuousFilterValues,
} from './ContinuousFilter'
import DiscreteFilter, { DiscreteFilterArgument, DiscreteFilterValues } from './DiscreteFilter'

export enum FilterType {
  discrete = 'discrete',
  continuous = 'continuous',
}

export type FilterValues = DiscreteFilterValues | ContinuousFilterValues

export type FilterArgument = {
  type: FilterType
} & (ContinuousFilterArgument | DiscreteFilterArgument)

export class FilterFactory {
  static create(arg: FilterArgument) {
    switch (arg.type) {
      case FilterType.discrete:
        return new DiscreteFilter(arg as DiscreteFilterArgument)
      case FilterType.continuous:
        return new ContinuousFilter(arg as ContinuousFilterArgument)
    }
  }

  // Merge all filters with the same metric id
  static merge(filters: Filter[]) {
    const mergedFilters: Filter[] = []
    for (const filter of filters) {
      const existingFilter = mergedFilters.find((f) => f.metricID === filter.metricID)
      if (existingFilter) {
        mergedFilters[mergedFilters.indexOf(existingFilter)] = existingFilter.merge(filter)
      } else {
        mergedFilters.push(filter)
      }
    }

    return mergedFilters
  }
}

export abstract class AFilter {
  abstract getLabel(): string
  abstract getNameLabel(): string
  abstract getValueLabel(): string
  abstract getSelectedKeys(keys: string[], options?: MetricOptions): string[]
  abstract equals(filter?: Filter): boolean
  abstract isEmpty(): boolean
  abstract setValues(values: FilterValues): Filter
  abstract setInvert(invert: boolean): Filter
  abstract toggleValues(values: FilterValues): Filter
  abstract merge(filter: Filter): Filter
}

type Filter = AFilter & (DiscreteFilter | ContinuousFilter)
export default Filter
