import { v4 as uuid } from 'uuid'
import ChartType from '../chart/ChartType'
import ContinuousFilterOption from '../filter/ContinuousFilterOption'
import DiscreteFilterOption from '../filter/DiscreteFilterOption'
import FilterOption from '../filter/FilterOption'

export enum MetricLocation {
  dashboard = 'dashboard',
  productExplore = 'productExplore',
  memberExplore = 'memberExplore',
}

export enum MetricSortBy {
  key = 'key',
  value = 'value',
}

export enum MetricCategory {
  all = 'All',
  financialBehaviors = 'Financial Behaviors',
  demographics = 'Demographics',
  location = 'Location',
  memberParticipation = 'Member Participation',
  surveys = 'Surveys',
  // transactions = 'Transactions',
  productBalances = 'Product Balances',
}

export interface DiscreteMetricOption {
  label: string
  key: string
}

export interface ContinuousMetricOption {
  label: string
  min: number
  max?: number
}

export type MetricOptions = DiscreteMetricOption[] | ContinuousMetricOption[]

export interface MetricArgument {
  metricID: string
  name: string
  description: string
  defaultChart: ChartType
  availableCharts: ChartType[]
  defaultGridWidth: number
  defaultGridHeight: number
  minGridWidth: number
  minGridHeight: number
  locations: MetricLocation[]
  isActive: boolean
  hasHeader?: boolean
  isRequired?: boolean
  isResizable?: boolean
  isArray?: boolean
  isFilter?: boolean
  isMultiSelect?: boolean
  isContinuous?: boolean
  isDate?: boolean
  sortBy?: MetricSortBy
  memberKey?: string
  category?: MetricCategory
  featureID?: string
  limit?: number
  options?: MetricOptions
}

export default class Metric {
  readonly metricID: string
  readonly name: string
  readonly description: string
  readonly defaultChart: ChartType
  readonly availableCharts: ChartType[]
  readonly defaultGridWidth: number
  readonly defaultGridHeight: number
  readonly minGridWidth: number
  readonly minGridHeight: number
  readonly locations: MetricLocation[]
  readonly isActive: boolean
  readonly hasHeader?: boolean
  readonly isRequired?: boolean
  readonly isResizable?: boolean
  readonly isArray?: boolean
  readonly isFilter?: boolean
  readonly isMultiSelect?: boolean
  readonly isContinuous?: boolean
  readonly isDate?: boolean
  readonly sortBy?: MetricSortBy
  readonly memberKey?: string
  readonly category?: MetricCategory
  readonly featureID?: string
  readonly limit?: number
  readonly options?: MetricOptions

  constructor(arg: MetricArgument) {
    this.metricID = arg.metricID
    this.name = arg.name
    this.description = arg.description
    this.defaultChart = arg.defaultChart
    this.availableCharts = arg.availableCharts
    this.defaultGridWidth = arg.defaultGridWidth
    this.defaultGridHeight = arg.defaultGridHeight
    this.minGridWidth = arg.minGridWidth
    this.minGridHeight = arg.minGridHeight
    this.hasHeader = arg.hasHeader
    this.locations = arg.locations
    this.isActive = arg.isActive
    this.isRequired = arg.isRequired
    this.isResizable = arg.isResizable
    this.isArray = arg.isArray
    this.isFilter = arg.isFilter
    this.isMultiSelect = arg.isMultiSelect
    this.isContinuous = arg.isContinuous
    this.isDate = arg.isDate
    this.sortBy = arg.sortBy
    this.memberKey = arg.memberKey
    this.category = arg.category
    this.featureID = arg.featureID
    this.limit = arg.limit
    this.options = arg.options
  }

  static create() {
    return new Metric({
      metricID: uuid(),
      name: '',
      description: '',
      defaultChart: ChartType.bar,
      availableCharts: [ChartType.bar],
      defaultGridWidth: 4,
      defaultGridHeight: 4,
      minGridWidth: 1,
      minGridHeight: 1,
      locations: [MetricLocation.dashboard],
      isActive: true,
    })
  }

  duplicate() {
    return new Metric({
      ...this,
      metricID: '',
      name: `${this.name} (copy)`,
    })
  }

  getFilterOption(keys?: string[]): FilterOption | undefined {
    if (!this.isFilter) return undefined
    if (this.isContinuous) {
      return new ContinuousFilterOption({
        metricID: this.metricID,
        valueType: this.isDate ? 'date' : 'number',
        name: this.name,
      })
    } else {
      // Create a discrete filter option, using the keys as options if available
      const options =
        (this.options as DiscreteMetricOption[]) ??
        keys?.map((key) => ({
          key: key,
          label: key,
        })) ??
        []
      return new DiscreteFilterOption({
        metricID: this.metricID,
        name: this.name,
        options: options.map((option) => option.key),
        isMultiSelect: this.isMultiSelect || false,
      })
    }
  }

  isAvailable(location: MetricLocation) {
    return this.locations.includes(location)
  }
}
