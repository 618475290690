import { useHookstate } from '@hookstate/core'
import { Stack } from '@mui/material'
import { useEffect } from 'react'
import ExploreConfigToolbar from '../components/explore/ExploreConfigToolbar'
import ExploreMetricsView from '../components/explore/ExploreMetricsView'
import MemberExploreHeader from '../components/explore/MemberExploreHeader'
import StatusView from '../components/util/StatusView'
import { exploreManager } from '../managers/_manager.config'
import { MetricLocation } from '../model/admin/Metric'
import ExploreView from '../model/explore/ExploreView'
import MetricsConfig from '../model/metric/MetricsConfig'
import globalState from '../service/external/GlobalState'

export default function MemberExploreScreen() {
  // Global state
  const configsState = useHookstate(globalState.exploreConfigs).get()

  // -- Lifecycle
  useEffect(() => {
    fetch()
  }, [])

  async function fetch() {
    await exploreManager.fetchConfigs(MetricLocation.memberExplore)
    await exploreManager.fetchMetrics()
    exploreManager.fetchMetricsData()
    exploreManager.fetchProductCategories()
  }

  // -- UI
  return (
    <Stack>
      <MemberExploreHeader />
      <StatusView
        defaultMargin
        state={configsState}
        render={(configs) => {
          return <Stack p={2} gap={2}>
            <ExploreConfigToolbar
              location={MetricLocation.memberExplore}
              configs={
                configs.filter((config) =>
                  config.isAvailable(ExploreView.memberExplore as any),
                ) as MetricsConfig[]
              }
            />
            <ExploreMetricsView />
          </Stack>
        }}
      />
    </Stack>
  )
}
