// Import necessary dependencies and interfaces
import { useHookstate } from '@hookstate/core'
import { useEffect } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import { adminManager } from '../../../managers/_manager.config'
import Permission from '../../../model/admin/Permission'
import globalState from '../../../service/external/GlobalState'
import StatusView from '../../util/StatusView'
import AdminTabView, { AdminTab } from '../AdminTabView'
import DeleteModal from '../modals/DeleteModal'
import PermissionsModal from '../modals/PermissionsModal'

export default function PermissionsTabView() {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()

  // Global state
  const permissionsState = useHookstate(globalState.permissions).get()
  const permissions = permissionsState.data

  // Effect
  useEffect(() => {
    adminManager.fetchPermissions()
  }, [])

  // -- Actions

  const handleCreate = () => {
    const permission = Permission.create()
    openModal(
      'create-permission',
      <PermissionsModal
        permission={permission}
        permissions={permissions as Permission[]}
        isCreating
        onClose={() => closeModal('create-permission')}
        onSubmit={async (updatedPermission) => {
          await adminManager.savePermission(updatedPermission)
          closeModal('create-permission')
          showSnackbar('permission-created', 'Permission created')
        }}
      />,
    )
  }

  const handleEdit = (permissionID: string) => {
    const permission = permissions?.find((permission) => permission.permissionID === permissionID)
    if (!permission) return
    openModal(
      permissionID,
      <PermissionsModal
        permissions={permissions as Permission[]}
        permission={permission as Permission}
        onClose={() => closeModal(permission.permissionID)}
        onSubmit={async (updatedPermission) => {
          await adminManager.savePermission(updatedPermission)
          closeModal(permission.permissionID)
          showSnackbar('permission-updated', 'Permission updated')
        }}
      />,
    )
  }

  const handleDuplicate = (permissionID: string) => {
    const permission = permissions?.find((permission) => permission.permissionID === permissionID)
    if (!permission) return
    openModal(
      permissionID,
      <PermissionsModal
        permissions={permissions as Permission[]}
        isCreating
        permission={permission.duplicate()}
        onClose={() => closeModal(permissionID)}
        onSubmit={async (updatedPermission) => {
          await adminManager.savePermission(updatedPermission)
          closeModal(permissionID)
          showSnackbar('permission-created', 'Permission created')
        }}
      />,
    )
  }

  const handleDelete = (permissionID: string) => {
    openModal(
      permissionID,
      <DeleteModal
        onClose={() => closeModal(permissionID)}
        onConfirm={() => {
          adminManager.deletePermission(permissionID)
          showSnackbar('permission-deleted', 'Permission deleted')
        }}
        label='permission'
      />,
    )
  }

  const PermissionsTab: AdminTab<Permission> = {
    label: 'Permission',
    pluralLabel: 'Permissions',
    columns: [
      { name: 'ID', valueGetter: (p) => p.permissionID, width: 300 },
      { name: 'Name', valueGetter: (p) => p.name },
      { name: 'Description', valueGetter: (p) => p.description },
      { name: 'Active', valueGetter: (p) => (p.isActive ? 'Yes' : 'No'), width: 100 },
    ],
    searchFilter: (searchText, record) =>
      record.name.toLowerCase().includes(searchText.toLowerCase()),
    rowKey: (record) => record.permissionID,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDuplicate: handleDuplicate,
    onDelete: handleDelete,
  }

  return (
    <StatusView
      state={permissionsState}
      render={(permissions) => (
        <AdminTabView data={permissions as Permission[]} tab={PermissionsTab} />
      )}
    />
  )
}
