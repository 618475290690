import { Box, Button, Paper, Stack } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { exploreManager } from '../../managers/_manager.config'
import BackButton from '../util/BackButton'
import ExportMembersButton from '../util/ExportMembersButton'
import ExploreHeaderStats from './ExploreHeaderStats'
import ExploreFilterView from './filter/ExploreFilterView'
import ProductCategoriesDisplay from './ProductCategoriesDisplay'

export interface MemberExploreHeaderProps {}

export default function MemberExploreHeader({}: MemberExploreHeaderProps) {
  // Navigation
  const navigate = useNavigate()

  // -- Local state
  const [showProducts, setShowRecommendations] = useState(false)

  // -- Actions
  function onToggleRecommendations() {
    setShowRecommendations(!showProducts)
  }

  function onBack() {
    navigate(-1)
  }

  // -- UI
  return (
    <Paper sx={{ border: 'none', borderRadius: 0, padding: 2 }}>
      <Stack>
        <Stack direction='row' gap={2} alignItems='flex-start'>
          <BackButton onClick={onBack} />
          <Box width={170}>
            <ExploreHeaderStats metricIDs={['targetedMembers']} />
          </Box>
          <ExploreFilterView
            flex={1}
            onApplyFilters={() => exploreManager.fetchProductCategories()}
          />
          <ExportMembersButton
            exportFunction={(metrics) => exploreManager.exportMemberList(metrics)}
          />
          <Button
            sx={{ width: 250 }}
            variant={showProducts ? 'text' : 'contained'}
            onClick={onToggleRecommendations}
            data-testid='explore-member-explore-header-select-product-button'
          >
            {showProducts ? 'Cancel' : 'Select product category'}
          </Button>
        </Stack>
        <Stack gap={2}>
          {showProducts && (
            <Box px={5} py={2}>
              <ProductCategoriesDisplay />
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}
