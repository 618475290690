import AdminAnnouncement from '../model/admin/AdminAnnouncement'
import Client from '../model/admin/Client'
import Method from '../model/admin/Method'
import Permission from '../model/admin/Permission'
import Role from '../model/admin/Role'
import User from '../model/admin/User'
import DataSource from '../service/domain/DataSource'
import StateService from '../service/domain/StateService'
import { initialState } from '../service/external/GlobalState'
import { deleteOneInList, load, saveOneInList } from '../util/LoadableHookstateHelpers'
import LoadableState from '../util/LoadableState'

export default class AdminManager {
  readonly dataSource: DataSource
  readonly state: StateService

  constructor(dataSource: DataSource, globalState: StateService) {
    this.dataSource = dataSource
    this.state = globalState
  }

  // Cache
  clearCache(clientID: string) {
    this.dataSource.clearCache(clientID)
  }
  generateCache(clientID: string) {
    this.dataSource.generateCache(clientID)
  }
  async getCacheKeys(clientID: string) {
    return this.dataSource.getCacheKeys(clientID)
  }

  // Push
  async pushToProd(clientID: string) {
    await this.dataSource.pushToProd(clientID)
  }

  // Client
  async fetchClientFromLocalStorage() {
    const clientJSON = localStorage.getItem('client')
    if (!clientJSON) return
    const storedClient = new Client(JSON.parse(clientJSON))
    const client = await this.dataSource.fetchClient(storedClient.clientID)
    this.state.currentClient.set(client)
  }

  async fetchClients() {
    await load(this.state.clients, () => this.dataSource.fetchClients())
  }
  async saveClient(client: Client) {
    if (client.logo) {
      if (client.logoURL) {
        const fileType = client.logoURL.split('.').pop()
        if (fileType) {
          await this.dataSource.deleteClientLogo(client.clientID, fileType)
        }
      }
      await this.dataSource.saveClientLogo(client.clientID, client.logo)
      client = new Client({
        ...client,
        logo: undefined,
        logoURL: `${process.env.REACT_APP_CLOUDFRONT_URL}/logos/${client.clientID}-logo.${
          client.logo.type.split('/')[1]
        }`,
      })
    }
    const matchFunction = (item: Client) => item.clientID === client.clientID
    const saveFunction = () => this.dataSource.saveClient(client)
    await saveOneInList(this.state.clients, client, saveFunction, matchFunction)
    if (client.clientID === this.state.currentClient.value?.clientID) {
      this.selectClient(client.clientID)
    }
  }
  async createClient(client: Client) {
    if (client.logo) {
      await this.dataSource.saveClientLogo(client.clientID, client.logo)
      client = new Client({
        ...client,
        logo: undefined,
        logoURL: `${process.env.REACT_APP_CLOUDFRONT_URL}/logos/${client.clientID}-logo.${
          client.logo.type.split('/')[1]
        }`,
      })
    }
    const matchFunction = (item: Client) => item.clientID === client.clientID
    const saveFunction = () => this.dataSource.createClient(client)
    await saveOneInList(this.state.clients, client, saveFunction, matchFunction)
    if (client.clientID === this.state.currentClient.value?.clientID) {
      this.selectClient(client.clientID)
    }
  }
  async deleteClient(clientID: string) {
    const fileType = this.state.clients.data
      .get()
      ?.find((client) => client.clientID === clientID)
      ?.logoURL?.split('.')
      .pop()
    if (fileType) {
      await this.dataSource.deleteClientLogo(clientID, fileType)
    }
    const matchFunction = (item: Client) => item.clientID === clientID
    const deleteFunction = () => this.dataSource.deleteClient(clientID)
    await deleteOneInList(this.state.clients, matchFunction, deleteFunction)
    if (clientID === this.state.currentClient.value?.clientID) {
      this.clearClient()
    }
  }
  selectClient(clientID: string) {
    const client = this.state.clients.data.get()?.find((client) => client.clientID === clientID)
    if (!client) throw new Error(`No client found for clientID ${clientID}`)

    // Clear client-specific state
    this.state.users.set(initialState.users)
    this.state.methods.set(initialState.methods)
    this.state.roles.set(initialState.roles)
    this.state.permissions.set(initialState.permissions)
    this.state.levels.set(initialState.levels)
    this.state.opportunities.set(initialState.opportunities)
    this.state.productCategories.set(initialState.productCategories)
    this.state.integrations.set(initialState.integrations)
    this.state.dashboardConfigs.set(initialState.dashboardConfigs)
    this.state.currentDashboardConfig.set(initialState.currentDashboardConfig)
    this.state.exploreConfigs.set(initialState.exploreConfigs)
    this.state.currentExploreConfig.set(initialState.currentExploreConfig)
    this.state.productCategoryRatings.set(initialState.productCategoryRatings)
    this.state.opportunities.set(initialState.opportunities)
    this.state.currentOpportunityResults.set(initialState.currentOpportunityResults)
    this.state.userAnnouncementsData.set(initialState.userAnnouncementsData)

    // Set client
    this.state.currentClient.set(client)
    localStorage.setItem('client', JSON.stringify(client))
  }
  clearClient() {
    this.state.currentClient.set(undefined)
    localStorage.removeItem('client')
  }

  // User
  async fetchUsers() {
    await load(this.state.users, () => this.dataSource.fetchUsers())
  }
  async fetchClientUsers(includeSuperAdmins?: boolean) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    await load(this.state.users, () =>
      this.dataSource.fetchClientUsers(client.clientID, includeSuperAdmins ?? false),
    )
  }
  async fetchClientUsersFromClientID(
    clientID: string,
    includeSuperAdmins: boolean,
  ): Promise<User[]> {
    return await this.dataSource.fetchClientUsers(clientID, includeSuperAdmins)
  }
  async saveUser(user: User) {
    const matchFunction = (item: User) => item.userID === user.userID
    await saveOneInList(this.state.users, user, () => this.dataSource.saveUser(user), matchFunction)
    if (user.userID === this.state.user.data.get()?.userID) {
      this.state.user.set(LoadableState.set(user))
    }
  }
  async deleteUser(userID: string) {
    const matchFunction = (item: User) => item.userID === userID
    const deleteFunction = () => this.dataSource.deleteUser(userID)
    await deleteOneInList(this.state.users, matchFunction, deleteFunction)
  }

  // Global Admin
  async fetchDefaultMethods() {
    const fetchFunction = () => this.dataSource.fetchDefaultMethods()
    await load(this.state.defaultMethods, fetchFunction)
  }
  async saveDefaultMethod(method: Method) {
    const saveFunction = (method: Method) => this.dataSource.saveDefaultMethod(method)
    const matchFunction = (item: Method) => item.methodID === method.methodID
    await saveOneInList(this.state.defaultMethods, method, saveFunction, matchFunction)
  }
  async deleteDefaultMethod(methodID: string) {
    const matchFunction = (item: Method) => item.methodID === methodID
    const deleteFunction = () => this.dataSource.deleteDefaultMethod(methodID)
    await deleteOneInList(this.state.defaultMethods, matchFunction, deleteFunction)
  }

  async fetchPermissions() {
    const fetchFunction = () => this.dataSource.fetchPermissions()
    await load(this.state.permissions, fetchFunction)
  }

  async savePermission(permission: Permission) {
    const saveFunction = (permission: Permission) => this.dataSource.savePermission(permission)
    const matchFunction = (item: Permission) => item.permissionID === permission.permissionID
    await saveOneInList(this.state.permissions, permission, saveFunction, matchFunction)
  }
  async deletePermission(permissionID: string) {
    const matchFunction = (permission: Permission) => permission.permissionID === permissionID
    const deleteFunction = () => this.dataSource.deletePermission(permissionID)
    await deleteOneInList(this.state.permissions, matchFunction, deleteFunction)
  }
  getPermission(permissionID: string) {
    const permissions = this.state.permissions.data.get()
    if (!permissions) throw new Error('No permissions')
    return permissions.find((permission) => permission.permissionID === permissionID)
  }

  async fetchRoles() {
    const fetchFunction = () => this.dataSource.fetchRoles()
    await load(this.state.roles, fetchFunction)
  }

  async saveRole(role: Role) {
    const saveFunction = (role: Role) => this.dataSource.saveRole(role)
    const matchFunction = (item: Role) => item.roleID === role.roleID
    await saveOneInList(this.state.roles, role, saveFunction, matchFunction)
  }
  async deleteRole(roleID: string) {
    const matchFunction = (role: Role) => role.roleID === roleID
    const deleteFunction = () => this.dataSource.deleteRole(roleID)
    await deleteOneInList(this.state.roles, matchFunction, deleteFunction)
  }
  getRole(roleID: string) {
    const roles = this.state.roles.data.get()
    if (!roles) throw new Error('No roles')
    return roles.find((role) => role.roleID === roleID)
  }

  async fetchAdminAnnouncements() {
    await load(this.state.adminAnnouncements, () => this.dataSource.fetchAdminAnnouncements())
  }
  async saveAdminAnnouncement(adminAnnouncement: AdminAnnouncement) {
    const saveFunction = (adminAnnouncement: AdminAnnouncement) =>
      this.dataSource.saveAdminAnnouncement(adminAnnouncement)
    const matchFunction = (item: AdminAnnouncement) =>
      item.announcementID === adminAnnouncement.announcementID
    await saveOneInList(
      this.state.adminAnnouncements,
      adminAnnouncement,
      saveFunction,
      matchFunction,
    )
  }
  async deleteAdminAnnouncement(announcementID: string) {
    const matchFunction = (announcement: AdminAnnouncement) =>
      announcement.announcementID === announcementID
    const deleteFunction = () => this.dataSource.deleteAdminAnnouncement(announcementID)
    await deleteOneInList(this.state.adminAnnouncements, matchFunction, deleteFunction)
  }
  getAdminAnnouncement(announcementID: string) {
    const adminAnnouncements = this.state.adminAnnouncements.data.get()
    if (!adminAnnouncements) throw new Error('No admin announcements')
    return adminAnnouncements.find((announcement) => announcement.announcementID === announcementID)
  }

  // Client Admin
  async fetchMethods() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')
    const fetchFunction = () => this.dataSource.fetchMethods(client.clientID)
    await load(this.state.methods, fetchFunction)
  }
  async saveMethod(method: Method) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    const saveFunction = (method: Method) => this.dataSource.saveMethod(client.clientID, method)
    const matchFunction = (item: Method) => item.methodID === method.methodID
    await saveOneInList(this.state.methods, method, saveFunction, matchFunction)
  }
  async deleteMethod(methodID: string) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    const matchFunction = (method: Method) => method.methodID === methodID
    const deleteFunction = () => this.dataSource.deleteMethod(client.clientID, methodID)
    await deleteOneInList(this.state.methods, matchFunction, deleteFunction)
  }

  async fetchIntegrations() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')
    const fetchFunction = () => this.dataSource.fetchIntegrations(client.clientID)
    await load(this.state.integrations, fetchFunction)
  }

  // Levels
  async fetchLevels() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')
    await load(this.state.levels, () => this.dataSource.fetchLevels(client.clientID))
  }
}
