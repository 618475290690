// PasswordInput.tsx
import { Icon } from '@iconify/react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { Control } from 'react-hook-form'
import icons from '../../config/icons.config'

interface PasswordInputProps {
  control: Control<any>
  name: string
  placeholder?: string
  rules?: any
}

const PasswordInput: FunctionComponent<PasswordInputProps> = ({
  control,
  name,
  placeholder,
  rules,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleVisible = () => {
    setShowPassword(!showPassword)
  }

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      placeholder={placeholder}
      fullWidth
      variant='outlined'
      InputProps={{
        startAdornment: <Icon icon={icons.lock} width={20} style={{ marginRight: 5 }} />,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton aria-label='toggle password visibility' onClick={toggleVisible} edge='end'>
              {showPassword ? <Icon icon={icons.eye} /> : <Icon icon={icons.eyeOff} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...control.register(name, rules)}
    />
  )
}

export default PasswordInput
