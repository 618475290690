import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { opportunityManager } from '../../managers/_manager.config'
import Opportunity from '../../model/opportunity/Opportunity'
import { ScreenPath } from '../../navigation'
import globalState from '../../service/external/GlobalState'
import CustomTextFieldController from '../modal/CustomTextFieldController'
import ModalHeader from '../modal/ModalHeader'
import StatusView from '../util/StatusView'

export interface MergeOpportunitiesModalForm {
  name: string
}

export interface MergeOpportunitiesModalProps {
  opportunities: Opportunity[]
  onClose: () => void
}

export default function MergeOpportunitiesModal({
  opportunities,
  onClose,
}: MergeOpportunitiesModalProps) {
  // Navigation
  const navigate = useNavigate()
  const { showSnackbar } = useModal()

  // Global state
  const opportunitiesState = useHookstate(globalState.opportunities).get()

  // -- Local state
  const [isLoading, setIsLoading] = useState(false)

  // Form
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<MergeOpportunitiesModalForm>()

  // -- Lifecycle
  useEffect(() => {
    opportunityManager.fetchOpportunities()
  }, [])

  // -- Actions
  async function onSubmit({ name }: MergeOpportunitiesModalForm) {
    const allOpportunities = opportunitiesState.data
    if (!allOpportunities) return

    // Check if opportunity name is unique
    const error = Opportunity.validateName(name, allOpportunities as Opportunity[])
    if (error) {
      setError('name', { type: 'manual', message: error })
      return
    }

    try {
      setIsLoading(true)
      const merged = await opportunityManager.mergeOpportunities(opportunities, name)
      navigate(`${ScreenPath.opportunities}/${merged.opportunityID}`)
      setIsLoading(false)
    } catch (error: any) {
      const message =
        error?.response?.data?.details ?? 'An error occurred while merging opportunities'
      showSnackbar('opportunity-merge-error', message, 'error')
      setIsLoading(false)
    }

    onClose()
  }

  // -- UI
  return (
    <Dialog open onClose={onClose} maxWidth='sm' fullWidth>
      <Stack gap={2} px={5}>
        <ModalHeader
          title={`Merge Opportunities`}
          center
          description={`Merging opportunities will create a new opportunity with the unique combined members of the opportunities. This will not delete the original opportunities`}
        />
      </Stack>

      <DialogContent>
        <Stack gap={2} p={2} textAlign='center'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <CustomTextFieldController
                name='name'
                control={control}
                label='Merged Opportunity Name'
                rules={{ required: 'Name is required' }}
                error={errors.name?.message}
                autoFocus
              />
              <StatusView
                state={opportunitiesState}
                render={() => (
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isLoading}
                    startIcon={<Icon icon={icons.merge} width={20} />}
                  >
                    Merge {opportunities.length} Opportunities
                  </LoadingButton>
                )}
              />
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
