import { Icon } from '@iconify/react'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import icons from '../../config/icons.config'

export interface AutocompleteSearchBarProps {
  onSelect: (value: string) => void
  placeholder?: string
  options: {
    label: string
    value: string
  }[]
}

export default function AutocompleteSearchBar({
  onSelect,
  placeholder,
  options,
}: AutocompleteSearchBarProps) {
  return (
    <Autocomplete
      onChange={(_, newValue) => onSelect(newValue?.value || '')}
      options={options}
      fullWidth
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <Icon icon={icons.search} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
