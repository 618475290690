import ChartData from './ChartData'
import ChartDataType from './ChartDataType'
import { DensityData } from './DensityMapData'

export interface GroupedDensityMapDataArgument {
  points: GroupedDensityMapDataPoint[]
}

export default class GroupedDensityMapData extends ChartData {
  readonly points: GroupedDensityMapDataPoint[]

  constructor(arg: GroupedDensityMapDataArgument) {
    super({ type: ChartDataType.groupedDensityMap })
    this.points = arg.points
  }

  isEmpty() {
    return this.points.length === 0
  }
}

export interface GroupedDensityMapDataPoint extends DensityData {
  group: string
}
