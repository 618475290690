import { v4 as uuid } from 'uuid'
import { MethodForm } from '../../components/admin/modals/MethodModal'

export interface MethodArgument {
  methodID: string
  name: string
  description: string
}

export default class Method {
  readonly methodID: string
  readonly name: string
  readonly description: string

  constructor(arg: MethodArgument) {
    this.methodID = arg.methodID
    this.name = arg.name
    this.description = arg.description
  }

  static create() {
    return new Method({
      methodID: '',
      name: '',
      description: '',
    })
  }

  duplicate() {
    return new Method({
      ...this,
      methodID: uuid(),
      name: `${this.name} (copy)`,
    })
  }

  formUpdate(form: MethodForm) {
    return new Method({
      ...this,
      ...form,
    })
  }
}
