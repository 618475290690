import { Icon } from '@iconify/react'
import { Dialog, DialogContent, Input, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import icons from '../../config/icons.config'
import Metric, { MetricCategory } from '../../model/admin/Metric'
import ModalHeader from '../modal/ModalHeader'
import MetricButton from './MetricButton'
import MetricCategoryChip from './MetricCategoryChip'

export interface SelectMetricModalProps {
  availableMetrics: Metric[]
  onMetricClick: (metric: Metric) => void
  onClose: () => void

  // Optional parameters
  title?: string
  description?: string
}

export default function SelectMetricModal({
  availableMetrics,
  onClose,
  onMetricClick,

  // Optional parameters
  title = 'Add Metric',
  description = 'Select a metric to add to your explore view',
}: SelectMetricModalProps) {
  // -- Local state
  const [search, setSearch] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(MetricCategory.all)

  // Functions
  function getFilteredMetrics(metrics: Metric[]) {
    return metrics
      .filter((metric) => {
        const categoryMatch =
          selectedCategory === MetricCategory.all || metric.category === selectedCategory
        return (
          metric.isActive &&
          categoryMatch &&
          metric.name.toLowerCase().includes(search.toLowerCase())
        )
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  // -- UI
  function MetricCategoriesView({
    onSelectCategory,
  }: {
    onSelectCategory: (category: MetricCategory) => void
  }) {
    const categories = Object.values(MetricCategory)
    return (
      <Stack direction='column' spacing={1}>
        {categories.map((category) => (
          <MetricCategoryChip
            key={category}
            category={category}
            onSelectCategory={onSelectCategory}
            selectedCategory={selectedCategory}
          />
        ))}
      </Stack>
    )
  }

  // -- Actions
  function onMetricButtonClick(metric: Metric) {
    onMetricClick(metric)
    onClose()
  }

  // -- UI
  return (
    <Dialog open onClose={onClose} maxWidth='md' fullWidth>
      <ModalHeader
        center
        icon={<Icon icon={icons.charts.bar} width={50} color='primary' />}
        title={title}
        description={description}
      />
      <Input
        placeholder='Search metrics'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{ mx: 6, mb: 0, mt: 4 }}
        startAdornment={<Icon icon={icons.search} color='grey.300' style={{ marginRight: 10 }} />}
        autoFocus
      />

      <DialogContent sx={{ height: 750, width: '100%' }}>
        <Stack direction='row'>
          <Stack gap={1} px={3} pb={5} pt={0} width='75%'>
            {getFilteredMetrics(availableMetrics).length > 0 ? (
              getFilteredMetrics(availableMetrics).map((metric) => (
                <MetricButton
                  key={metric.metricID}
                  metric={metric}
                  onClick={() => onMetricButtonClick(metric)}
                />
              ))
            ) : (
              <Typography color='text.secondary' textAlign='center'>
                No more metrics to add
              </Typography>
            )}
          </Stack>

          <Stack position='sticky' top={0} height='100%'>
            <MetricCategoriesView
              onSelectCategory={(category: MetricCategory) => setSelectedCategory(category)}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
