import ChartData from './ChartData'
import ChartDataType from './ChartDataType'

export enum Timeline {
  '1m' = '1m',
  '3m' = '3m',
  '6m' = '6m',
  '12m' = '12m',
  'ytd' = 'ytd',
}

export interface TimeframeStatisticData {
  timeline: Timeline
  value: number
}

export interface TimeframeChartDataArguments {
  values: TimeframeStatisticData[]
  title: string
  precision: number
  valuePrefix?: string
  valueSuffix?: string
  description?: string
  size?: 'sm' | 'lg'
}

export default class TimeframeChartData extends ChartData {
  readonly values: TimeframeStatisticData[]
  readonly title: string
  readonly precision: number
  readonly valuePrefix?: string
  readonly valueSuffix?: string
  readonly description?: string
  readonly size?: 'sm' | 'lg'

  constructor(arg: TimeframeChartDataArguments) {
    super({ type: ChartDataType.timeframe })
    this.values = arg.values
    this.size = arg.size
    this.title = arg.title
    this.valuePrefix = arg.valuePrefix
    this.valueSuffix = arg.valueSuffix
    this.precision = arg.precision
    this.description = arg.description
  }

  isEmpty() {
    return this.values.length === 0
  }
}
