import DiscreteFilter, { DiscreteFilterValues } from './DiscreteFilter'
import { FilterType } from './Filter'
import FilterOption from './FilterOption'

export interface DiscreteFilterOptionArgument {
  metricID: string
  name: string
  options: DiscreteFilterValues
  isMultiSelect: boolean
}

export default class DiscreteFilterOption implements FilterOption {
  readonly metricID: string
  readonly type = FilterType.discrete
  readonly name: string
  readonly options: DiscreteFilterValues
  readonly isMultiSelect: boolean

  constructor(arg: DiscreteFilterOptionArgument) {
    this.metricID = arg.metricID
    this.name = arg.name
    this.options = arg.options
    this.isMultiSelect = arg.isMultiSelect
  }

  getDefaultFilter(values?: DiscreteFilterValues) {
    return new DiscreteFilter({
      metricID: this.metricID,
      name: this.name,
      values: values ? values : this.options.length > 0 ? [this.options[0]] : [],
    })
  }

  isFull(values: DiscreteFilterValues) {
    return this.options.every((option) => values.includes(option))
  }
}
