import { useHookstate } from '@hookstate/core'
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import DashboardConfigToolbar from '../components/dashboard/DashboardConfigToolbar'
import DashboardMetricsView from '../components/dashboard/DashboardMetricsView'
import StatusView from '../components/util/StatusView'
import { dashboardManager } from '../managers/_manager.config'
import MetricsConfig from '../model/metric/MetricsConfig'
import globalState from '../service/external/GlobalState'

export default function DashboardScreen() {
  // Global state
  const configsState = useHookstate(globalState.dashboardConfigs).get()

  // -- Local state
  const [ready, setReady] = useState(false)

  // Effect
  useEffect(() => {
    fetch()
    setReady(true)
  }, [])

  // Functions
  async function fetch() {
    dashboardManager.fetchMetrics()
    await dashboardManager.fetchConfigs()
    dashboardManager.fetchMetricsData()
  }

  return (
    <StatusView
      defaultMargin
      state={configsState}
      loading={!ready}
      render={(configs) => (
        <Stack gap={1} p={2}>
          <DashboardConfigToolbar configs={configs as MetricsConfig[]} />
          <DashboardMetricsView />
        </Stack>
      )}
    />
  )
}
