import DateUtil from '../../util/DateUtil'

export type FilterRangeValueType = 'number' | 'date'

export interface FilterRangeArgument {
  min: number
  max?: number
  valueType?: FilterRangeValueType
  label?: string
}

export default class FilterRange {
  readonly min: number
  readonly max?: number
  readonly valueType: FilterRangeValueType
  readonly label?: string

  constructor(arg: FilterRangeArgument) {
    this.min = arg.min
    this.max = arg.max
    this.valueType = arg.valueType ?? 'number'
    this.label = arg.label
  }

  getLabel(): string {
    if (this.label) return this.label
    switch (this.valueType) {
      case 'number':
        if (this.max === undefined) return `${this.min}+`
        return `${this.min}-${this.max}`
      case 'date':
        if (this.max === undefined) return `${DateUtil.getDateString(new Date(this.min), true)}+`
        return DateUtil.getDateRangeString(new Date(this.min), new Date(this.max), true)
    }
  }

  equals(range: FilterRange): boolean {
    return this.min === range.min && this.max === range.max
  }

  isWithin(range: FilterRange): boolean {
    // If 'range.max' is undefined, it is an open-ended range starting from 'range.min'
    if (range.max === undefined) {
      // 'this' range is within 'range' if 'this.min' starts at or after 'range.min' starts
      return this.min >= range.min
    }

    // If 'this.max' is undefined, it cannot be fully within a closed range 'range'
    if (this.max === undefined) {
      return false
    }

    // Both ranges are closed, 'this' is within 'range' if 'this' starts after 'range' starts
    // and 'this' ends before 'range' ends
    return this.min >= range.min && this.max <= range.max
  }

  overlaps(range: FilterRange): boolean {
    // Case: min and max are the same
    if (this.min === this.max && range.min === range.max) {
      return this.min === range.min && this.max === range.max
    }

    // Other cases
    const sortedRanges = [this, range].sort((a, b) => a.min - b.min)
    const overlap = (sortedRanges[0].max ?? Infinity) > sortedRanges[1].min
    return overlap
  }
}
