import { Stack } from '@mui/material'
import MetricAttributeResult from '../../model/opportunity/MetricAttributeResult'
import AttributeResultsTable from './AttributeResultsTable'

export interface AttributeResultsPanelProps {
  attributes: MetricAttributeResult[]
  hideMetricCol?: boolean
  onSelectMetric?: (metricID: string) => void
  showPagination?: boolean
  enableColumnActions?: boolean
}

export default function AttributeResultsPanel({
  attributes,
  hideMetricCol = false,
  onSelectMetric,
  showPagination = false,
  enableColumnActions = false,
}: AttributeResultsPanelProps) {
  // -- Actions
  function onRowClick(attribute: MetricAttributeResult) {
    onSelectMetric?.(attribute.metricID)
  }

  // -- UI
  return (
    // <StandardCard sx={{ flex: 1 }} variant='outlined'>
    <Stack flex={1}>
      {/* <Stack direction='row' justifyContent='center'>
        <Typography variant='h6'>{title}</Typography>
        <InfoTag description={description} />
      </Stack> */}

      <AttributeResultsTable
        attributes={attributes}
        hideMetricCol={hideMetricCol}
        onMetricNameClick={onRowClick}
        showPagination={showPagination}
        enableColumnActions={enableColumnActions}
      />
    </Stack>
    // </StandardCard>
  )
}
