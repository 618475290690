import React from 'react'
import ConfirmModal from '../../util/ConfirmModal'

interface DeleteModalProps {
  label: string
  onClose?: () => void
  onConfirm?: () => void
}

const DeleteModal: React.FC<DeleteModalProps> = ({ onClose, onConfirm, label }) => {
  return (
    <ConfirmModal
      title={`Delete ${label}`}
      message={`Are you sure you want to delete this ${label}?`}
      confirmation='Delete'
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}

export default DeleteModal
