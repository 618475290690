import { Icon } from '@iconify/react'
import { IconButton, SxProps } from '@mui/material'
import icons from '../../config/icons.config'

export interface BackButtonProps {
  onClick: () => void
  sx?: SxProps
}

export default function BackButton({ onClick, sx }: BackButtonProps) {
  return (
    <IconButton onClick={onClick} sx={sx}>
      <Icon icon={icons.back} />
    </IconButton>
  )
}
