import PropensityType from '../propensity/PropensityType'
import ProductCategory from './ProductCategory'

export enum ProductCategoryRatingBasicGoalType {
  growth = 'growth',
  churn = 'churn',
  lowHanging = 'low_hanging'
}

export type ProductCategoryRatingGoals = ProductCategoryRatingBasicGoalType | string

export interface ProductCategoryRatingArgument {
  productCategory: ProductCategory
  propensityType: PropensityType
  rating: number
}

export default class ProductCategoryRating {
  readonly productCategory: ProductCategory
  readonly propensityType: PropensityType
  readonly rating: number

  constructor(arg: ProductCategoryRatingArgument) {
    this.productCategory = arg.productCategory
    this.propensityType = arg.propensityType
    this.rating = arg.rating
  }
}
