import { Icon } from '@iconify/react'
import { Stack, Typography } from '@mui/material'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import DateUtil from '../../util/DateUtil'
import InfoTag from '../metric/InfoTag'

export interface ExtendedObservationPeriodProps {
  endDate: Date
  resultsEndDate: Date
}

export default function ExtendedObservationPeriod({
  endDate,
  resultsEndDate,
}: ExtendedObservationPeriodProps) {
  return (
    <Stack direction='row' gap={1} alignItems='center' color='text.secondary'>
      <Icon icon={icons.eye} />
      <Typography>
        {copy.opportunity.view.results.extendedObservationPeriod.title}:{' '}
        {DateUtil.getDateString(resultsEndDate)} (+
        {DateUtil.getDifferenceInDays(endDate, resultsEndDate)} days)
      </Typography>
      <InfoTag description={copy.opportunity.view.results.extendedObservationPeriod.description} />
    </Stack>
  )
}
