import { Icon } from '@iconify/react'
import { Input, InputAdornment } from '@mui/material'
import icons from '../../config/icons.config'

export interface SearchBarProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string,
  testId?: string
}

export default function SearchBar({ value, onChange, placeholder, testId = undefined }: SearchBarProps) {
  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      sx={{
        fontSize: '1rem',
      }}
      startAdornment={
        <InputAdornment position='start'>
          <Icon icon={icons.search} />
        </InputAdornment>
      }
      placeholder={placeholder}
      data-testid={testId}
    />
  )
}
