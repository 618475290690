import { Icon } from '@iconify/react'
import { alpha, Box, Collapse, Fab, Slide, Typography, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import icons from '../../../config/icons.config'
import User from '../../../model/admin/User'
import Opportunity from '../../../model/opportunity/Opportunity'
import OpportunityLog from '../../../model/opportunity/OpportunityLog'
import { ChatView } from './ChatView'

type OpportunityDiscussionProps = {
  user: User | null
  opportunity: Opportunity
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  discussionLogs: OpportunityLog[]
  setDiscussionLogs: React.Dispatch<React.SetStateAction<OpportunityLog[]>>
}

type FloatingActionBtnProps = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

function FloatingActionBtn(props: FloatingActionBtnProps) {
  const [hover, setHover] = useState(false)

  return (
    <Slide direction='up' in={true} mountOnEnter unmountOnExit>
      <Fab
        color='primary'
        variant='extended'
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={() => props.setShow(!props.show)}
        size='large'
        sx={{
          pointerEvents: 'auto',
          paddingLeft: 1.5,
          paddingRight: 1.5,
          zIndex: 50,
          svg: {
            marginRight: 0,
          },
        }}
      >
        <Icon width={20} height={20} icon={icons.discussion} />
        <Collapse in={hover} orientation='horizontal' collapsedSize={0}>
          <Typography variant='button' ml={1}>
            Discussion
          </Typography>
        </Collapse>
      </Fab>
    </Slide>
  )
}

export function OpportunityDiscussion(props: OpportunityDiscussionProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [props.show])

  return (
    <Box
      position='fixed'
      top={0}
      left={0}
      bgcolor={alpha(theme.palette.grey[900], props.show ? 0.5 : 0)}
      sx={{ transition: 'background-color 0.3s', pointerEvents: props.show ? 'auto' : 'none' }}
      width='100%'
      height='100%'
      zIndex={99}
      onClick={() => props.setShow(false)}
    >
      <Box
        ref={containerRef}
        position='fixed'
        bottom={28}
        right={28}
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        alignItems='end'
        onClick={(e) => e.stopPropagation()}
      >
        <FloatingActionBtn show={props.show} setShow={props.setShow} />
        <ChatView
          user={props.user}
          opportunity={props.opportunity}
          show={props.show}
          setShow={props.setShow}
          discussionLogs={props.discussionLogs}
          setDiscussionLogs={props.setDiscussionLogs}
        />
      </Box>
    </Box>
  )
}
