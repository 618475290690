import { Box, DialogContent } from '@mui/material'

export interface ModalContentProps {
  children: React.ReactNode | React.ReactNode[]
}

export default function ModalContent({ children }: ModalContentProps) {
  return (
    <DialogContent>
      <Box>{children}</Box>
    </DialogContent>
  )
}
