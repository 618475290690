import { Button, Stack } from '@mui/material'
import { useState } from 'react'
import ContinuousFilter, { ContinuousFilterValues } from '../../../model/filter/ContinuousFilter'
import ContinuousFilterOption from '../../../model/filter/ContinuousFilterOption'
import FilterRange from '../../../model/filter/FilterRange'
import DateRangePicker from '../../util/DateRangePicker'

export interface DateFilterValueMenuProps {
  filter?: ContinuousFilter
  filterOption: ContinuousFilterOption
  onSubmit: (values: ContinuousFilterValues) => void
  onClose?: () => void
}

export default function DateFilterValueMenu({
  filter,
  filterOption,
  onSubmit,
  onClose,
}: DateFilterValueMenuProps) {
  // -- Local state
  const [ranges, setRanges] = useState(
    filter?.ranges ?? [
      new FilterRange({ min: new Date().getTime(), max: new Date().getTime(), valueType: 'date' }),
    ],
  )

  // Functions
  function getDateRanges() {
    return ranges.map((range) => ({
      startDate: new Date(range.min),
      endDate: range.max ? new Date(range.max) : new Date(),
    }))
  }

  // -- Actions
  function onDoneButtonClick() {
    onSubmit(ranges)
    onClose?.()
  }

  function onStartDateChange(date: Date, index: number) {
    const newRanges = [...ranges]
    newRanges[index] = new FilterRange({
      ...newRanges[index],
      min: date.getTime(),
      valueType: 'date',
    })
    setRanges(newRanges)
  }

  function onEndDateChange(date: Date, index: number) {
    const newRanges = [...ranges]
    newRanges[index] = new FilterRange({
      ...newRanges[index],
      max: date.getTime(),
      valueType: 'date',
    })
    setRanges(newRanges)
  }

  return (
    <Stack p={2} gap={1}>
      {getDateRanges().map((range, index) => (
        <DateRangePicker
          key={index}
          startDate={range.startDate}
          endDate={range.endDate}
          onStartDateChange={(date) => date && onStartDateChange(date, index)}
          onEndDateChange={(date) => date && onEndDateChange(date, index)}
        />
      ))}
      <Button variant='contained' sx={{ marginTop: 1 }} onClick={onDoneButtonClick}>
        Done
      </Button>
    </Stack>
  )
}
