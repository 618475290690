export default class ListUtil {
  // If there is a match function, replace the item in the list, otherwise add it to the end
  static put<T>(list: T[], newItem: T, matchFunction?: (item: T) => boolean) {
    const index = matchFunction ? list.findIndex(matchFunction) : -1
    if (index >= 0) {
      return [...list.slice(0, index), newItem, ...list.slice(index + 1)]
    }
    return [...list, newItem]
  }

  // Remove an item from the list
  static remove<T>(list: T[], matchFunction: (item: T) => boolean) {
    return list.filter((item) => !matchFunction(item))
  }
}
