import Metric, {
  ContinuousMetricOption,
  DiscreteMetricOption,
  MetricLocation,
} from '../model/admin/Metric'
import ChartType from '../model/chart/ChartType'
import ExploreOpportunityStatus from '../model/explore/ExploreOpportunityStatus'
import ProductCategory from '../model/explore/ProductCategory'
import { ProductCategoryRatingGoals } from '../model/explore/ProductCategoryRating'
import PropensityTarget, { EngagementType } from '../model/explore/PropensityTarget'
import ContinuousFilter, { ContinuousFilterValues } from '../model/filter/ContinuousFilter'
import DiscreteFilter, { DiscreteFilterValues } from '../model/filter/DiscreteFilter'
import Filter, { FilterValues } from '../model/filter/Filter'
import FilterOption from '../model/filter/FilterOption'
import FilterRange from '../model/filter/FilterRange'
import { DynamicGridItem } from '../model/metric/GridItem'
import MetricData from '../model/metric/MetricData'
import MetricsConfig from '../model/metric/MetricsConfig'
import Opportunity from '../model/opportunity/Opportunity'
import PropensityType from '../model/propensity/PropensityType'
import DataSource from '../service/domain/DataSource'
import StateService from '../service/domain/StateService'
import ListUtil from '../util/ListUtil'
import {
  deleteOneInList,
  load,
  saveOneInList,
  saveOneInListLocal,
} from '../util/LoadableHookstateHelpers'
import LoadableState, { LoadableArray } from '../util/LoadableState'
import { opportunityManager } from './_manager.config'

export default class ExploreManager {
  readonly dataSource: DataSource
  readonly state: StateService

  constructor(dataSource: DataSource, state: StateService) {
    this.dataSource = dataSource
    this.state = state
  }

  // Config
  async fetchConfigs(location: MetricLocation) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)

    const fetchFunction = () => this.dataSource.fetchExploreConfigs(client.clientID, location)
    await load(this.state.exploreConfigs, fetchFunction)

    this.selectDefaultConfig(location)
  }
  async saveConfig(config: MetricsConfig) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const saveFunction = () => this.dataSource.saveExploreConfig(client.clientID, config)
    const matchFunction = (c: MetricsConfig) => c.metricsConfigID === config.metricsConfigID
    saveOneInList(this.state.exploreConfigs, config, saveFunction, matchFunction)
    this.state.currentExploreConfig.set(config)
  }
  async deleteConfig(configID: string) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const matchFunction = (item: MetricsConfig) => item.metricsConfigID === configID
    const deleteFunction = () => this.dataSource.deleteExploreConfig(client.clientID, configID)
    deleteOneInList(this.state.exploreConfigs, matchFunction, deleteFunction)

    // Set the view back to the first config if possible
    const configs = this.state.exploreConfigs.get()?.data
    if (configs && configs.length > 0) {
      this.state.currentExploreConfig.set(configs[0])
      this.fetchMetricsData()
    } else {
      this.state.currentExploreConfig.set(undefined)
    }

    this.state.isEditingExplore.set(false)
  }
  selectConfig(config: MetricsConfig) {
    this.state.currentExploreConfig.set(config)
    this.state.isEditingExplore.set(false)
    this.fetchMetricsData()
  }
  updateConfigLayout(items: DynamicGridItem[]) {
    const config = this.state.currentExploreConfig.get()
    if (!config) throw new Error(`No current explore configuration`)
    this.updateConfigLocal(config.updateLayout(items))
  }
  private updateConfigLocal(config: MetricsConfig) {
    this.state.currentExploreConfig.set(config)
    saveOneInListLocal(
      this.state.exploreConfigs,
      config,
      (c) => c.metricsConfigID === config.metricsConfigID,
    )
  }
  selectDefaultConfig(location: MetricLocation) {
    const configs = this.state.exploreConfigs.get()?.data
    if (!configs) return
    const availableConfigs = configs.filter((config) => config.isAvailable(location))
    if (availableConfigs.length === 0) throw new Error(`No available configs for ${location}`)
    this.state.currentExploreConfig.set(availableConfigs[0])
  }
  updateMetricConfigState(metricID: string, state: any) {
    const config = this.state.currentExploreConfig.get()
    if (!config) throw new Error(`No current explore configuration`)
    const updatedConfig = config.updateMetricState(metricID, state)
    this.updateConfigLocal(updatedConfig)
  }

  // Metrics
  async fetchMetrics() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)

    // Fetch metrics
    const metrics = await this.dataSource.fetchExploreMetrics(client.clientID)
    this.state.exploreMetrics.set(LoadableState.set(metrics))

    // Populate filter options
    const filterOptions = metrics
      .map((metric) => metric.getFilterOption())
      .filter((f) => f) as FilterOption[]
    this.state.exploreFilterOptions.set(filterOptions)
  }

  async fetchMetricsData() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const config = this.state.currentExploreConfig.get()
    if (!config) throw new Error(`No explore configuration selected`)
    const metrics = this.state.exploreMetrics.data.get()
    if (!metrics) throw new Error('No metrics found')
    const filters = this.state.exploreFilters.get() as Filter[]
    const propensityType = this.state.explorePropensityType.get() as PropensityType
    const engagementType = this.state.exploreEngagementType.get() as EngagementType
    const productCategoryID = this.state.exploreProductCategoryID.get() as string | undefined
    const productCategories = this.state.productCategories.get().data as
      | ProductCategory[]
      | undefined
    const productCategory = productCategories?.find(
      (pc) => pc.productCategoryID === productCategoryID,
    )

    const metricIDs = config.metrics.map((metric) => metric.metricID)

    // If we have a selected product category, include the propensity metric and add the propensity target
    if (productCategory) {
      metricIDs.unshift('propensity')
      metricIDs.unshift('targetedMembers')
      metricIDs.unshift('expectedValueGrowth')
      metricIDs.unshift('avgPropensity')
      metricIDs.unshift('totalPotential')
    } else {
      metricIDs.unshift('targetedMembers')
    }
    const target = productCategory
      ? new PropensityTarget({ productCategory, propensityType, engagementType })
      : undefined

    // Reset metrics data
    this.state.exploreMetricsData.set(LoadableArray.create<MetricData>(metricIDs).loadAll())

    // Fetch data for each metric
    for (const metricID of metricIDs) {
      const metric = metrics.find((metric) => metric.metricID === metricID) as Metric | undefined
      if (!metric) throw new Error(`Can't find metric with id: ${metricID}`)

      this.fetchMetricData(client.clientID, metric, filters, target)
    }
  }

  async fetchMetricData(
    clientID: string,
    metric: Metric,
    filters?: Filter[],
    propensityTarget?: PropensityTarget,
  ) {
    try {
      try {
        const data = await this.dataSource.fetchExploreMetricData(
          clientID,
          metric,
          filters,
          propensityTarget,
        )

        // Set the data for the metric
        this.state.exploreMetricsData.set(
          this.state.exploreMetricsData.get().setItem(data, metric.metricID),
        )

        // Update filter options based on the new data
        const filterOption = data.getFilterOption(metric)
        if (filterOption) {
          this.state.exploreFilterOptions.set(
            ListUtil.put(
              this.state.exploreFilterOptions.get() as FilterOption[],
              filterOption,
              (f) => f.metricID === metric.metricID,
            ),
          )
        }
      } catch (error) {
        // Set the error for the metric
        this.state.exploreMetricsData.set(
          this.state.exploreMetricsData
            .get()
            .errorItem(
              metric.metricID,
              new Error(`Error fetching data for metric: ${metric.metricID}`),
            ),
        )
      }
    } catch (error) {}
  }

  updateMetricChart(metricID: string, chart: ChartType) {
    const config = this.state.currentExploreConfig.get()
    if (!config) throw new Error(`No current explore configuration`)
    const updatedConfig = config.updateMetricChart(metricID, chart)
    this.updateConfigLocal(updatedConfig)
  }
  async addMetric(metric: Metric) {
    const config = this.state.currentExploreConfig.get()
    if (!config) throw new Error(`No current explore configuration`)
    const updatedConfig = config.add(metric)
    this.updateConfigLocal(updatedConfig)
    this.saveConfig(updatedConfig)
    await this.fetchMetricsData()
  }
  removeMetric(metricID: string) {
    const config = this.state.currentExploreConfig.get()
    if (!config) throw new Error(`No current explore configuration`)
    const updatedConfig = config.remove(metricID)
    this.updateConfigLocal(updatedConfig)
    this.saveConfig(updatedConfig)
  }

  // Ratings
  async fetchProductCategoryRatings(goalType: ProductCategoryRatingGoals) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)

    const fetchFunction = () =>
      this.dataSource.fetchProductCategoryRatings(client.clientID, goalType)
    load(this.state.productCategoryRatings, fetchFunction)
  }

  // Preset
  setPropensityPreset(propensityType: PropensityType) {
    this.state.explorePropensityType.set(propensityType)
  }
  setEngagementPreset(engagementType: EngagementType) {
    this.state.exploreEngagementType.set(engagementType)
  }

  // Product category header
  setPropensityType(propensityType: PropensityType) {
    this.state.explorePropensityType.set(propensityType)
    this.fetchMetricsData()
  }
  setEngagementType(engagementType: EngagementType) {
    this.state.exploreEngagementType.set(engagementType)
    this.fetchMetricsData()
  }

  // Editing
  toggleEditing() {
    const isEditing = this.state.isEditingExplore.get()
    this.state.isEditingExplore.set(!isEditing)

    if (isEditing) {
      const config = this.state.currentExploreConfig.get()
      if (!config) throw new Error(`No current explore configuration`)
      this.saveConfig(config as MetricsConfig)
      return true
    }
    return false
  }

  // Filter
  setFilters(filters: Filter[]) {
    this.state.exploreFilters.set(filters)
  }
  addFilter(metricID: string, values?: FilterValues, invert?: boolean) {
    const filterOptions = this.state.exploreFilterOptions.get() as FilterOption[]
    const filterOption = filterOptions.find((option) => option.metricID === metricID)
    if (!filterOption) throw new Error(`Can't find filter option with id: ${metricID}`)

    let filter = filterOption.getDefaultFilter()
    if (values) filter = filter.setValues(values)
    if (invert) filter = filter.setInvert(invert)
    const filters = this.state.exploreFilters.get() as Filter[]
    const updatedFilters = [...filters, filter]
    this.state.exploreFilters.set(updatedFilters)
  }
  removeFilter(metricID: string) {
    const filters = this.state.exploreFilters.get() as Filter[]
    const updatedFilters = filters.filter((f) => f.metricID !== metricID)
    this.state.exploreFilters.set(updatedFilters)
  }
  updateFilterOption(metricID: string, option: FilterOption) {
    const filters = this.state.exploreFilters.get() as Filter[]
    const updatedFilters = filters.map((f) => {
      if (f.metricID === metricID) return option.getDefaultFilter()
      return f
    })
    this.state.exploreFilters.set(updatedFilters)
  }
  toggleFilterMetricOption(metric: Metric, label: string) {
    if (metric.isContinuous) {
      const option = (metric.options as ContinuousMetricOption[])?.find((o) => o.label === label)
      if (!option) throw new Error(`Can't find metric option with label: ${label}`)
      const { min, max } = option as ContinuousMetricOption
      const valueType = metric.isDate ? 'date' : 'number'
      this.updateFilterValues(
        metric.metricID,
        [new FilterRange({ min, max, valueType, label })],
        'toggle',
      )
    } else {
      const option = (metric.options as DiscreteMetricOption[])?.find((o) => o.label === label)
      const key = option ? (option as DiscreteMetricOption).key : label
      this.updateFilterValues(metric.metricID, [key], 'toggle')
    }
  }
  updateFilterValues(
    metricID: string,
    values: FilterValues,
    mode: 'set' | 'toggle' = 'set',
    invert?: boolean,
  ) {
    const filters = this.state.exploreFilters.get() as Filter[]
    const existingFilter = filters.find((f) => f.metricID === metricID)

    if (!existingFilter) {
      this.addFilter(metricID, values, invert)
    } else {
      let updatedFilter: Filter = existingFilter
      if (updatedFilter instanceof DiscreteFilter) {
        updatedFilter =
          mode === 'set'
            ? updatedFilter.setValues(values as DiscreteFilterValues)
            : updatedFilter.toggleValues(values as DiscreteFilterValues)
      }

      if (updatedFilter instanceof ContinuousFilter) {
        updatedFilter =
          mode === 'set'
            ? updatedFilter.setValues(values as ContinuousFilterValues)
            : updatedFilter.toggleValues(values as ContinuousFilterValues)
      }

      // If the filter is empty or contains all the possible values, remove it, otherwise update
      if (updatedFilter.isEmpty()) {
        const updatedFilters = filters.filter((f) => f.metricID !== metricID)
        this.state.exploreFilters.set(updatedFilters)
      } else {
        const updatedFilters = filters.map((f) => {
          if (f.metricID === metricID) return updatedFilter
          return f
        })
        this.state.exploreFilters.set(updatedFilters)
      }
    }
  }
  invertFilter(metricID: string) {
    const filters = this.state.exploreFilters.get() as Filter[]
    const updatedFilters = filters.map((f) => {
      if (f instanceof DiscreteFilter && f.metricID === metricID) return f.setInvert(!f.invert)
      return f
    })
    this.state.exploreFilters.set(updatedFilters)
  }
  clearFilters() {
    this.state.exploreFilters.set([])
  }
  setHighPropensityFilters() {
    this.state.exploreFilters.set([
      new ContinuousFilter({
        metricID: 'propensity',
        name: 'Propensity',
        ranges: [new FilterRange({ min: 80, max: 100 })],
      }),
    ])
  }
  setLevelFilters(levelID: string) {
    this.state.exploreFilters.set([
      new DiscreteFilter({
        metricID: 'levels',
        name: 'Levels',
        values: [levelID],
      }),
    ])
  }
  getFilterForMetric(metricID: string) {
    const filters = this.state.exploreFilters.get()
    return filters.find((filter) => filter.metricID === metricID) as Filter | undefined
  }
  getFilterOptionForFilter(metricID: string) {
    const filterOptions = this.state.exploreFilterOptions.get()
    return filterOptions.find((filterOption) => filterOption.metricID === metricID) as
      | FilterOption
      | undefined
  }

  // Opportunity
  async createOpportunity(name: string) {
    const propensityType = this.state.explorePropensityType.get() as PropensityType
    const productCategoryID = this.state.exploreProductCategoryID.get()
    if (!productCategoryID) throw new Error(`No product category selected`)
    const productCategories = this.state.productCategories.get().data as
      | ProductCategory[]
      | undefined
    const productCategory = productCategories?.find(
      (pc) => pc.productCategoryID === productCategoryID,
    )
    if (!productCategory)
      throw new Error(`Can't find product category with id: ${productCategoryID}`)
    const filters = this.state.exploreFilters.get() as Filter[]
    const engagementType = this.state.exploreEngagementType.get() as EngagementType

    const opportunity = Opportunity.create(
      name,
      productCategory,
      propensityType,
      engagementType,
      filters,
    )

    await opportunityManager.saveOpportunity(opportunity)

    return opportunity
  }
  selectOpportunity(
    opportunity: Opportunity,
    status: ExploreOpportunityStatus = ExploreOpportunityStatus.editing,
  ) {
    this.selectProductCategory(opportunity.propensityTarget.productCategory.productCategoryID)
    this.setPropensityPreset(opportunity.propensityTarget.propensityType)
    this.setEngagementPreset(opportunity.propensityTarget.engagementType)
    this.setFilters(opportunity.filters)
    this.state.exploreOpportunity.set(opportunity)
    this.state.exploreOpportunityStatus.set(status)
  }

  // Product category
  async fetchProductCategories() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)

    const fetchFunction = () => this.dataSource.fetchProductCategories(client.clientID)
    await load(this.state.productCategories, fetchFunction)
  }
  selectProductCategory(productCategoryID: string) {
    this.state.exploreProductCategoryID.set(productCategoryID)
  }
  deselectProductCategory() {
    this.state.exploreProductCategoryID.set(undefined)
    this.state.explorePropensityType.set(PropensityType.growth)
    this.state.exploreEngagementType.set(EngagementType.all)
  }

  // Export
  async exportMemberList(metrics: Metric[], propensityTarget?: PropensityTarget) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const filters = this.state.exploreFilters.get() as Filter[]
    const metricIDs = metrics.map((metric) => metric.metricID)
    return await this.dataSource.exportMemberList(
      client.clientID,
      filters,
      metricIDs,
      propensityTarget,
    )
  }

  // Other
  resetState() {
    this.clearFilters()
    this.state.exploreOpportunity.set(undefined)
    this.state.currentExploreConfig.set(undefined)
    this.state.exploreProductCategoryID.set(undefined)
    this.state.explorePropensityType.set(PropensityType.growth)
    this.state.exploreEngagementType.set(EngagementType.all)
    this.state.isEditingExplore.set(false)
  }
}
