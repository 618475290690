import { Stack, StackProps } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import isAfter from 'date-fns/isAfter' // Import isAfter function to compare dates
import isDate from 'date-fns/isDate'

export interface DateRangePickerProps extends StackProps {
  startDate: Date
  endDate: Date
  onStartDateChange: (date: Date | null) => void
  onEndDateChange: (date: Date | null) => void
  minStartDate?: Date
  maxEndDate?: Date
  disabled?: boolean
}

export default function DateRangePicker({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  minStartDate,
  maxEndDate,
  disabled,
  ...rest
}: DateRangePickerProps) {
  const handleStartDateChange = (date: Date | null) => {
    if (date && isDate(date) && endDate && isDate(endDate) && isAfter(date, endDate)) {
      onEndDateChange(null)
    }
    onStartDateChange(date)
  }

  const handleEndDateChange = (date: Date | null) => {
    onEndDateChange(date)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack direction='row' gap={1} {...rest}>
        <DatePicker
          label='Start date'
          value={startDate && isDate(startDate) ? startDate : null}
          minDate={minStartDate ? minStartDate : undefined}
          maxDate={maxEndDate ? maxEndDate : undefined}
          onChange={handleStartDateChange}
          disabled={disabled}
        />
        <DatePicker
          label='End date'
          minDate={startDate && isDate(startDate) ? startDate : undefined}
          maxDate={maxEndDate ? maxEndDate : undefined}
          value={endDate && isDate(endDate) ? endDate : null}
          onChange={handleEndDateChange}
          disabled={disabled}
        />
      </Stack>
    </LocalizationProvider>
  )
}
