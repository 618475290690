import { useHookstate } from '@hookstate/core'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../contexts/ModalContext'
import { opportunityManager } from '../../managers/_manager.config'
import ProductCategory from '../../model/explore/ProductCategory'
import Filter from '../../model/filter/Filter'
import Opportunity from '../../model/opportunity/Opportunity'
import { ScreenPath } from '../../navigation'
import globalState from '../../service/external/GlobalState'
import FilterChips from '../explore/filter/FilterChips'
import CustomTextFieldController from '../modal/CustomTextFieldController'
import ModalHeader from '../modal/ModalHeader'
import StatusView from '../util/StatusView'

export interface CreateOpportunityModalForm {
  name: string
}

export interface CreateOpportunityModalProps {
  productCategory: ProductCategory
  filters: Filter[]
  onCreate: (name: string) => Promise<string> // Creates the opportunity and returns the ID
  onClose: () => void
}

export default function CreateOpportunityModal({
  productCategory,
  filters,
  onCreate,
  onClose,
}: CreateOpportunityModalProps) {
  // Navigation
  const navigate = useNavigate()
  const { showSnackbar } = useModal()

  // Global state
  const opportunitiesState = useHookstate(globalState.opportunities).get()

  // -- Local state
  const [isLoading, setIsLoading] = useState(false)

  // Form
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CreateOpportunityModalForm>()

  // -- Lifecycle
  useEffect(() => {
    opportunityManager.fetchOpportunities()
  }, [])

  // -- Actions
  async function onSubmit({ name }: CreateOpportunityModalForm) {
    const opportunities = opportunitiesState.data
    if (!opportunities) return

    // Check if opportunity name is unique
    const error = Opportunity.validateName(name, opportunities as Opportunity[])
    if (error) {
      setError('name', { type: 'manual', message: error })
      return
    }

    try {
      setIsLoading(true)
      const opportunityID = await onCreate(name)
      setIsLoading(false)
      navigate(ScreenPath.opportunities)
      navigate(`${ScreenPath.opportunities}/${opportunityID}`)
    } catch (error) {
      showSnackbar('opportunity-create-error', 'Failed to create opportunity', 'error')
      setIsLoading(false)
      return
    }

    onClose()
  }

  // -- UI
  return (
    <Dialog open onClose={onClose} maxWidth='sm' fullWidth>
      <Stack gap={2}>
        <ModalHeader
          title='Create Opportunity'
          center
          description={`Create a marketing opportunity for ${productCategory.name} ${
            filters.length === 0
              ? 'targeting all members'
              : 'targeting members with these attributes:'
          }`}
        />
        {filters.length > 0 && (
          <FilterChips filters={filters as Filter[]} alignSelf='center' center />
        )}
      </Stack>

      <DialogContent>
        <Stack gap={2} p={2} textAlign='center'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <CustomTextFieldController
                name='name'
                control={control}
                label='Opportunity Name'
                rules={{ required: 'Name is required' }}
                error={errors.name?.message}
                autoFocus
              />
              <StatusView
                state={opportunitiesState}
                render={() => (
                  <LoadingButton
                    data-testid='opportunity-modal-create-opportunity-btn'
                    type='submit'
                    variant='contained'
                    loading={isLoading}
                  >
                    Create Opportunity
                  </LoadingButton>
                )}
              />
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
