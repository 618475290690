import { useEffect, useState } from 'react'
import ErrorView from './ErrorView'

interface Props {
  children: JSX.Element
}

function ErrorBoundary(props: Props): JSX.Element {
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const handleError = (event: ErrorEvent): void => {
      setError(event.error)
    }

    const handlePromiseRejection = (event: PromiseRejectionEvent): void => {
      setError(event.reason)
    }

    window.addEventListener('error', handleError)
    window.addEventListener('unhandledrejection', handlePromiseRejection)

    return () => {
      window.removeEventListener('error', handleError)
      window.removeEventListener('unhandledrejection', handlePromiseRejection)
    }
  }, [])

  useEffect(() => {
    if (error) {
      console.error('ErrorBoundary caught an error', error)
    }
  }, [error])

  if (error) {
    return <ErrorView error={error} />
  }

  return props.children
}

export default ErrorBoundary
