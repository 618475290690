import {
  Box,
  CardProps,
  Checkbox,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import palette from '../../theme/palette'
import DataTableHeader from './DataTableHeader'
import RowOptionsMenu, { RowActions } from './RowOptionsMenu'

export interface DataTableColumn<T> {
  name: string
  description?: string
  sortable?: boolean
  width?: number
  sx?: SxProps
  valueGetter: (item: T) => any
  sortComparator?: (v1: any, v2: any) => number
  render?: (item: T) => JSX.Element | string
}

export interface DataTableProps<T> extends CardProps {
  data: T[]
  columns: DataTableColumn<T>[]
  rowKey: (item: T) => string
  actions?: RowActions
  showPagination?: boolean
  disableColumnActions?: boolean
  showHeader?: boolean
  initialSortColumn?: DataTableColumn<T>
  initialSortDirection?: 'asc' | 'desc'
  initialRowsPerPage?: number
  headerVariant?: 'light' | 'neutral'
  showCheckbox?: boolean
  onMerge?: (items: T[]) => void
  useQueryParams?: boolean
}

export default function DataTable<T>({
  data,
  columns,
  rowKey,
  actions,
  showPagination = true,
  disableColumnActions = false,
  showHeader = true,
  initialSortColumn,
  initialSortDirection,
  initialRowsPerPage = 10,
  headerVariant = 'neutral',
  showCheckbox = false,
  onMerge,
  useQueryParams,
  ...rest
}: DataTableProps<T>) {
  // -- Theme
  const theme = useTheme()

  // -- Navigation
  const [searchParams, setSearchParams] = useSearchParams()
  const getInitialPageIndex = () => {
    if (!showPagination || !useQueryParams) return 0
    const page = searchParams.get('page')
    return page ? parseInt(page) : 0
  }

  // -- Local state
  const [actionsIsOpen, setActionsIsOpen] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null)
  const [actionsTop, setActionsTop] = useState(0)
  const [actionsLeft, setActionsLeft] = useState(0)
  const [pageIndex, setPageIndex] = useState(getInitialPageIndex())
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
  const [sortColumn, setSortColumn] = useState<DataTableColumn<T> | null>(initialSortColumn || null)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(initialSortDirection || 'asc')
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set())

  useEffect(() => {
    if (showPagination) {
      const page = useQueryParams ? parseInt(searchParams.get('page') || '0') : 0
      const rows = useQueryParams
        ? parseInt(searchParams.get('rows') || initialRowsPerPage.toString())
        : initialRowsPerPage
      setPageIndex(page)
      setRowsPerPage(rows)
    }
  }, [searchParams, showPagination])

  // -- Actions
  const handleChangePage = (_: unknown, newPage: number) => {
    if (!useQueryParams) {
      setPageIndex(newPage)
    } else {
      searchParams.set('page', newPage.toString())
      setSearchParams(searchParams)
    }
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rows = parseInt(event.target.value, 10)
    if (!useQueryParams) {
      setRowsPerPage(rows)
      setPageIndex(0)
    } else {
      searchParams.set('rows', rows.toString())
      searchParams.set('page', '0')
      setSearchParams(searchParams)
    }
  }

  const onRowClick = useCallback((id: string, event: React.MouseEvent<HTMLElement>) => {
    setActionsIsOpen(true)
    setSelectedRowId(id)
    setActionsTop(event.clientY)
    setActionsLeft(event.clientX)
  }, [])

  const handleSortRequest = (column: DataTableColumn<T>) => {
    if (sortColumn === column) {
      if (sortDirection === 'asc') {
        setSortDirection('desc')
      } else {
        setSortColumn(null)
        setSortDirection('asc')
      }
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  const toggleSelectAll = () => {
    if (selectedRows.size === data.length) {
      setSelectedRows(new Set())
    } else {
      setSelectedRows(new Set(data.map(rowKey)))
    }
  }

  const handleCheckboxChange = (id: string) => {
    setSelectedRows((prev) => {
      const newSelectedRows = new Set(prev)
      if (newSelectedRows.has(id)) {
        newSelectedRows.delete(id)
      } else {
        newSelectedRows.add(id)
      }
      return newSelectedRows
    })
  }

  const sortedData = useMemo(() => {
    if (!sortColumn) return data
    const sorted = [...data].sort((a, b) => {
      const valueA = sortColumn.valueGetter(a)
      const valueB = sortColumn.valueGetter(b)
      const comparator = sortColumn.sortComparator
      return comparator
        ? comparator(valueA, valueB)
        : valueA < valueB
        ? -1
        : valueA > valueB
        ? 1
        : 0
    })
    return sortDirection === 'asc' ? sorted : sorted.reverse()
  }, [data, sortColumn, sortDirection])

  const paginatedData = showPagination
    ? sortedData.slice(pageIndex * rowsPerPage, pageIndex * rowsPerPage + rowsPerPage)
    : sortedData

  // -- UI
  function getHeaderStyle() {
    switch (headerVariant) {
      case 'light':
        return {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${palette.light.grey[200]}`,
        }
      default:
        return {}
    }
  }

  return (
    <Box {...rest}>
      {actions && selectedRowId && actionsIsOpen && (
        <RowOptionsMenu
          rowIDs={selectedRows.size > 0 ? Array.from(selectedRows) : [selectedRowId]}
          actions={actions}
          onClose={() => setActionsIsOpen(false)}
          anchorPosition={{ top: actionsTop, left: actionsLeft }}
        />
      )}
      <TableContainer>
        <Table>
          {showHeader && (
            <TableHead>
              <TableRow>
                {showCheckbox && (
                  <TableCell padding='checkbox' sx={getHeaderStyle()}>
                    <Checkbox
                      checked={data.length > 0 && selectedRows.size === data.length}
                      onChange={toggleSelectAll}
                    />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell key={column.name} width={column.width} sx={getHeaderStyle()}>
                    <DataTableHeader
                      column={column}
                      sortDirection={sortColumn === column ? sortDirection : undefined}
                      active={sortColumn === column}
                      onSort={() => handleSortRequest(column)}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {paginatedData.map((item) => (
              <TableRow
                key={rowKey(item)}
                onClick={(event) => onRowClick(rowKey(item), event)}
                hover
                selected={selectedRows.has(rowKey(item))}
                sx={{
                  cursor: actions ? 'pointer' : 'default',
                  '&:hover': {
                    backgroundColor: selectedRows.has(rowKey(item))
                      ? `${theme.palette.action.focus} !important`
                      : theme.palette.action.hover,
                  },
                }}
                data-testid={`row-key-${rowKey(item)}`}
              >
                {showCheckbox && (
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedRows.has(rowKey(item))}
                      onChange={() => handleCheckboxChange(rowKey(item))}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </TableCell>
                )}
                {columns.map((col, colIndex) => (
                  <TableCell key={colIndex} width={col.width} sx={{ flex: 1, ...col.sx, py: 1 }}>
                    {col.render ? col.render(item) : col.valueGetter(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 50, 100]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={pageIndex}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  )
}
