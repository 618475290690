import { Icon } from '@iconify/react'
import { Box, Card, IconButton, Stack, Typography, useTheme } from '@mui/material'
import { memo } from 'react'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { exploreManager } from '../../managers/_manager.config'
import Metric from '../../model/admin/Metric'
import ChartType from '../../model/chart/ChartType'
import Filter, { FilterValues } from '../../model/filter/Filter'
import FilterOption from '../../model/filter/FilterOption'
import MetricData from '../../model/metric/MetricData'
import LoadableState from '../../util/LoadableState'
import StringUtil from '../../util/StringUtil'
import MetricChartDisplay from '../chart/MetricChartDisplay'
import FilterButton from '../explore/filter/FilterButton'
import DynamicMenu from '../util/DynamicMenu'
import StatusView from '../util/StatusView'
import FullMetricModal from './FullMetricModal'
import InfoTag from './InfoTag'
import MetricEmptyView from './MetricEmptyView'
import MetricErrorView from './MetricErrorView'

export interface MetricCardProps {
  metric: Metric
  chart: ChartType
  state: LoadableState<MetricData>
  isEditing?: boolean
  filter?: Filter
  filterOption?: FilterOption
  configState?: any
  onRemove?: () => void
  onSelectValue?: (value: string, metricID: string) => void
  onSelectChartType?: (type: ChartType) => void
  onConfigStateChange?: (state: any) => void
}

function MetricCard({
  metric,
  chart,
  state,
  isEditing,
  filter,
  filterOption,
  configState,
  onRemove,
  onSelectValue,
  onSelectChartType,
  onConfigStateChange,
}: MetricCardProps) {
  // Navigation
  const { openModal, closeModal } = useModal()

  // -- Theme
  const theme = useTheme()

  // Computed
  const selectedValues = filter?.getSelectedKeys(state.data?.getKeys() ?? [], metric.options)
  const showRemove = isEditing && !metric.isRequired

  function getPadding(chartType: string) {
    switch (chartType) {
      case 'timeframe' || 'summitRate':
        return 0
      default:
        return 2
    }
  }

  // -- Actions
  function onRemoveButtonClick() {
    onRemove?.()
  }

  function onFullscreenClick() {
    if (!state.data) return
    openModal(
      metric.metricID,
      <FullMetricModal
        chartType={chart}
        metric={metric}
        metricData={state.data}
        selectedValues={selectedValues}
        onClose={() => closeModal(metric.metricID)}
      />,
    )
  }

  // -- UI

  function Header() {
    return (
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        p={1}
        pl={0}
        pb={0}
        gap={1}
      >
        <Typography
          variant='subtitle1'
          overflow='ellipsis'
          sx={{
            marginLeft: 2,
            cursor: 'pointer',
            ':hover': { color: theme.palette.primary.main },
          }}
          onClick={onFullscreenClick}
        >
          {metric.name}
        </Typography>

        <Stack direction='row' justifyContent='flex-end' flex={1}>
          {/* Default Options */}
          {!isEditing && filterOption && (
            <FilterButton
              filter={filter}
              filterOption={filterOption}
              onSubmit={(values: FilterValues, invert?: boolean) => {
                exploreManager.updateFilterValues(metric.metricID, values, undefined, invert)
              }}
            />
          )}
          {!isEditing && metric.description && <InfoTag description={metric.description} />}

          {/* Editing Options */}
          {isEditing && metric.availableCharts.length > 1 && (
            <DynamicMenu
              actions={metric.availableCharts.map((chart) => ({
                label: StringUtil.camelCaseToTitleCase(chart),
                onClick: () => onSelectChartType?.(chart as ChartType),
                icon: <Icon icon={icons.charts[chart]} width={15} />,
              }))}
            >
              <IconButton sx={{ p: 0.7 }}>
                <Icon icon={icons.charts[chart]} width={17} />
              </IconButton>
            </DynamicMenu>
          )}
          {showRemove && <RemoveButton />}
        </Stack>
      </Stack>
    )
  }

  function RemoveButton() {
    return (
      <IconButton onClick={onRemoveButtonClick}>
        <Icon icon={icons.close} width={15} />
      </IconButton>
    )
  }

  return (
    <Card
      data-testid={'metric-card-' + metric.metricID}
      sx={{
        width: '100%',
        minHeight: '100%',
        overflow: 'visible',
        display: 'flex',
      }}
    >
      <Stack sx={{ flex: 1 }}>
        {metric.hasHeader ? (
          <Header />
        ) : (
          <Box sx={{ position: 'absolute', right: 5, top: 5, zIndex: 1 }}>
            {showRemove ? <RemoveButton /> : <InfoTag description={metric.description} />}
          </Box>
        )}
        <Box flex={3} p={2} pt={metric.hasHeader ? 1 : 2} pb={getPadding(metric.defaultChart)}>
          <StatusView
            state={state}
            errorRender={<MetricErrorView metricID={metric.metricID} />}
            render={(data: MetricData) => {
              return (data.chartData.isEmpty() ? (
                <MetricEmptyView name={metric.name} chart={chart} />
              ) : (
                <MetricChartDisplay
                  metricID={metric.metricID}
                  type={chart}
                  data={data.chartData}
                  state={configState}
                  onStateChange={onConfigStateChange}
                  selectedValues={selectedValues}
                  onSelectValue={metric.isFilter ? onSelectValue : undefined}
                />
              ))
            }}
          />
        </Box>
      </Stack>
    </Card>
  )
}

const filterIsEqual = (prev?: Filter, next?: Filter) => {
  if (!prev && next) return false
  if (prev && !next) return false
  if (prev && !prev.equals(next)) return false
  return true
}

const isEqual = (prev: MetricCardProps, next: MetricCardProps) => {
  if (prev.metric.metricID !== next.metric.metricID) return false
  if (prev.chart !== next.chart) return false
  if (prev.state !== next.state) return false
  if (prev.isEditing !== next.isEditing) return false
  if (!filterIsEqual(prev.filter, next.filter)) return false
  if (prev.filterOption !== next.filterOption) return false
  return true
}

export default memo(MetricCard, isEqual)

// export default MetricCard
