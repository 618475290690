import { Button, Card, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import DataTable, { DataTableColumn } from '../table/DataTable'
import EmptyTableView from '../table/EmptyTableView'
import { RowAction } from '../table/RowOptionsMenu'
import SearchBar from '../util/SearchBar'
import StandardCard from '../util/StandardCard'

export interface AdminTab<T> {
  label: string
  pluralLabel: string
  columns: DataTableColumn<T>[]
  searchFilter: (searchText: string, record: T) => boolean
  rowKey: (record: T) => string
  onCreate?: () => void
  onEdit: (id: string) => void
  onDuplicate?: (id: string) => void
  onDelete: (id: string) => void
  additionalActions?: RowAction[]
}

export default function AdminTabView<T>({
  data,
  tab: {
    label,
    pluralLabel,
    columns,
    searchFilter,
    rowKey,
    onCreate,
    onEdit,
    onDuplicate,
    onDelete,
    additionalActions,
  },
}: {
  data: T[]
  tab: AdminTab<T>
}) {
  // -- Local state
  const [searchText, setSearchText] = useState('')
  const [searchedData, setSearchedData] = useState(data)

  useEffect(() => {
    setSearchedData(data)
  }, [data])

  function handleSearch(searchText: string, data: T[]) {
    setSearchText(searchText)
    setSearchedData(data.filter((record) => searchFilter(searchText, record)))
  }

  return (
    <Card sx={{ p: 3, maxWidth: 'calc(100vw - 315px)' }}>
      <Stack gap={2}>
        <Stack direction='row' gap={5}>
          <SearchBar
            placeholder={`Search ${pluralLabel}`}
            value={searchText}
            onChange={(value) => handleSearch(value, data)}
          />
          {onCreate && (
            <Button
              variant='contained'
              color='primary'
              onClick={onCreate}
              style={{ whiteSpace: 'nowrap', padding: '0.5rem 2rem' }}
            >
              {`Create ${label}`}
            </Button>
          )}
        </Stack>
        {searchedData && searchedData.length === 0 ? (
          <EmptyTableView
            title={`No ${pluralLabel.toLowerCase()} found!`}
            subtitle={`Create a new ${label.toLowerCase()} above.`}
          />
        ) : (
          <StandardCard sx={{ p: 0 }} variant='outlined'>
            <DataTable
              data={searchedData}
              columns={columns}
              rowKey={rowKey}
              actions={{ onEdit, onDuplicate, onDelete, additionalActions }}
            />
          </StandardCard>
        )}
      </Stack>
    </Card>
  )
}
