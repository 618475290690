import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import BaseLayout from './components/layout/BaseLayout'
import EmptyLayout from './components/layout/EmptyLayout'
import Header from './components/navigation/Header'
import AuthRequiredView from './components/util/AuthRequiredView'
import ClientRequiredView from './components/util/ClientRequiredView'
import ErrorBoundary from './components/util/ErrorBoundary'
import PageViewTracking from './components/util/PageViewTracking'
import PermissionView from './components/util/PermissionView'
import { ModalProvider } from './contexts/ModalContext'
import { ScreenPath, navigationPages } from './navigation'
import AdminScreen from './screens/AdminScreen'
import AnnouncementsScreen from './screens/AnnouncementsScreen'
import DashboardScreen from './screens/DashboardScreen'
import ExploreSelectScreen from './screens/ExploreSelectScreen'
import MemberExploreScreen from './screens/MemberExploreScreen'
import MemberVoiceExploreScreen from './screens/MemberVoiceExploreScreen'
import OpportunitiesScreen from './screens/OpportunitiesScreen'
import OpportunityScreen from './screens/OpportunityScreen'
import ProductExploreScreen from './screens/ProductExploreScreen'
import ProfileScreen from './screens/ProfileScreen'
import ForgotPasswordScreen from './screens/auth/ForgotPasswordScreen'
import LoginScreen from './screens/auth/LoginScreen'
import ResetPasswordScreen from './screens/auth/ResetPasswordScreen'
import VerifyAccountScreen from './screens/auth/VerifyAccountScreen'
import ThemeProvider from './theme'

export default function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <PageViewTracking>
          <ThemeProvider>
            <ModalProvider>
              <Routes>
                <Route path='/' element={<Navigate to={ScreenPath.login} />} />
                <Route path='/' element={<EmptyLayout />}>
                  <Route path={ScreenPath.login} element={<LoginScreen />} />
                  <Route path={ScreenPath.forgotPassword} element={<ForgotPasswordScreen />} />
                  <Route path={ScreenPath.resetPassword} element={<ResetPasswordScreen />} />
                  <Route path={ScreenPath.verifyAccount} element={<VerifyAccountScreen />} />
                </Route>
                <Route
                  path='/'
                  element={
                    <AuthRequiredView>
                      <BaseLayout header={<Header pages={navigationPages} />} />
                    </AuthRequiredView>
                  }
                >
                  <Route element={<ClientRequiredView />}>
                    {/* Dashboard */}
                    <Route
                      path={ScreenPath.dashboard}
                      element={
                        <PermissionView requiredPermissions={['view-dashboard-screen']}>
                          <DashboardScreen />
                        </PermissionView>
                      }
                    />

                    {/* Explore */}
                    <Route
                      path={ScreenPath.explore}
                      element={
                        <PermissionView requiredPermissions={['view-explore-screen']}>
                          <ExploreSelectScreen />
                        </PermissionView>
                      }
                    />
                    <Route
                      path={`${ScreenPath.explore}/members`}
                      element={
                        <PermissionView requiredPermissions={['view-explore-screen']}>
                          <MemberExploreScreen />
                        </PermissionView>
                      }
                    />
                    <Route
                      path={`${ScreenPath.explore}/:productCategoryID`}
                      element={
                        <PermissionView requiredPermissions={['view-explore-screen']}>
                          <ProductExploreScreen />
                        </PermissionView>
                      }
                    />
                    <Route
                      path={`${ScreenPath.exploreMemberVoice}`}
                      element={
                        <PermissionView requiredPermissions={['view-explore-member-voice-screen']}>
                          <MemberVoiceExploreScreen />
                        </PermissionView>
                      }
                    />

                    {/* Opportunities */}
                    <Route
                      path={ScreenPath.opportunities}
                      element={
                        <PermissionView requiredPermissions={['view-opportunities-screen']}>
                          <OpportunitiesScreen />
                        </PermissionView>
                      }
                    />
                    <Route
                      path={`${ScreenPath.opportunities}/:opportunityID`}
                      element={
                        <PermissionView requiredPermissions={['view-opportunities-screen']}>
                          <OpportunityScreen />
                        </PermissionView>
                      }
                    />
                    <Route path={ScreenPath.announcements} element={<AnnouncementsScreen />} />
                  </Route>

                  {/* Admin */}
                  <Route
                    path={ScreenPath.admin}
                    element={
                      <PermissionView
                        requiredPermissions={['view-admin-screen']}
                        activationPermissions={['global-admin', 'client-admin']}
                      >
                        <AdminScreen />
                      </PermissionView>
                    }
                  />

                  {/* Profile */}
                  <Route
                    path={ScreenPath.profile}
                    element={
                      <PermissionView requiredPermissions={['view-profile']}>
                        <ProfileScreen />
                      </PermissionView>
                    }
                  />
                </Route>
              </Routes>
            </ModalProvider>
          </ThemeProvider>
        </PageViewTracking>
      </BrowserRouter>
    </ErrorBoundary>
  )
}
