import { Button, Card, Stack, Typography } from '@mui/material'
import { cloneElement } from 'react'
import palette from '../../theme/palette'

export interface ExplorePresetOptionProps {
  title: string
  description: string
  icon: JSX.Element
  selected?: boolean
  disabled?: boolean
  variant?: 'default' | 'button'
  testId?: string
  onClick?: () => void
}

export default function ExplorePresetOption({
  title,
  description,
  icon,
  selected = false,
  disabled = false,
  variant = 'default',
  testId = undefined,
  onClick,
}: ExplorePresetOptionProps) {
  if (variant === 'button') {
    return (
      <Button onClick={onClick} variant='contained' sx={{ flex: 1, textTransform: 'none' }} data-testid={testId}>
        <Stack gap={0.5} alignItems='center' textAlign='center'>
          {cloneElement(icon, {
            color: 'white',
          })}
          <Typography variant='subtitle1'>{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </Button>
    )
  }

  const DISABLED_COLOR = palette.light.grey[400]
  const enabledStyle = {
    color: selected ? palette.light.primary.main : '',
    borderColor: selected ? palette.light.primary.main : 'transparent',
    transform: selected ? 'scale(1.01)' : '',
    transition: 'all 0.1s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
      transform: 'scale(1.01)',
      borderColor: palette.light.primary.main,
    },
  }
  const disabledStyle = {
    borderColor: 'transparent',
    cursor: 'default',
    color: DISABLED_COLOR,
  }
  const style = disabled ? disabledStyle : enabledStyle

  const iconStyleOverride = disabled
    ? {
      style: {
        color: DISABLED_COLOR,
      },
    }
    : {}

  return (
    <Card
      data-testid={testId}
      sx={{
        padding: 3,
        flex: 1,
        borderRadius: 2,
        border: 1,
        ...style,
      }}
      onClick={disabled ? undefined : onClick}
    >
      <Stack gap={0.5} alignItems='center' textAlign='center'>
        {cloneElement(icon, iconStyleOverride)}
        <Typography variant='subtitle1'>{title}</Typography>
        <Typography>{description}</Typography>
      </Stack>
    </Card>
  )
}
