import RangeUtil from '../../util/RangeUtil'
import StringUtil from '../../util/StringUtil'
import { ContinuousMetricOption } from '../admin/Metric'
import Filter, { AFilter, FilterType } from './Filter'
import FilterRange, { FilterRangeValueType } from './FilterRange'

export type ContinuousFilterValues = FilterRange[]

export interface ContinuousFilterArgument {
  metricID: string
  valueType?: FilterRangeValueType
  name: string
  ranges: ContinuousFilterValues
  invert?: boolean
}

export default class ContinuousFilter implements AFilter {
  readonly metricID: string
  readonly type = FilterType.continuous
  readonly valueType: FilterRangeValueType
  readonly name: string
  readonly ranges: ContinuousFilterValues
  readonly invert?: boolean

  constructor(arg: ContinuousFilterArgument) {
    this.metricID = arg.metricID
    this.valueType = arg.valueType ?? 'number'
    this.name = arg.name
    this.ranges = arg.ranges.map((range) => new FilterRange(range))
    this.invert = arg.invert
  }

  getLabel() {
    return `${this.getNameLabel()}: ${this.getValueLabel()} ${this.invert ? ' (inverted)' : ''}`
  }

  getNameLabel() {
    return StringUtil.capitalize(this.name)
  }

  getValueLabel() {
    return this.ranges.map((range) => range.getLabel()).join(', ')
  }

  getSelectedKeys(keys: string[], options?: ContinuousMetricOption[]): string[] {
    return keys.filter((key) => {
      // Check if the key is a label from metric options
      const option = options?.find((option) => option.label === key)
      if (option) return this.ranges.some((range) => range.overlaps(new FilterRange({ ...option })))

      // Assume the key is a range string - parse it and check if it overlaps with any of the filter ranges
      try {
        // Check for overlap if custom range
        return this.ranges.some((range) => {
          const keyRange = RangeUtil.textToRanges(key)[0]
          return range.overlaps(keyRange)
        })
      } catch (e) {
        // Invalid range
        return false
      }
    })
  }

  equals(filter?: Filter) {
    if (!filter) return false
    if (!(filter instanceof ContinuousFilter)) return false
    if (this.metricID !== filter.metricID) return false
    if (this.name !== filter.name) return false
    if (this.ranges.length !== filter.ranges.length) return false
    if (!this.ranges.every((range, index) => range.equals(filter.ranges[index]))) return false
    return true
  }

  isEmpty() {
    return this.ranges.length === 0
  }

  setValues(values: ContinuousFilterValues) {
    return new ContinuousFilter({ ...this, ranges: RangeUtil.merge(values) })
  }

  setInvert(invert: boolean) {
    return new ContinuousFilter({ ...this, invert })
  }

  toggleValues(values: ContinuousFilterValues) {
    return new ContinuousFilter({
      ...this,
      ranges: RangeUtil.toggle(this.ranges, values),
    })
  }

  merge(filter: Filter) {
    if (!(filter instanceof ContinuousFilter)) return this
    return new ContinuousFilter({
      metricID: this.metricID,
      valueType: this.valueType,
      name: this.name,
      ranges: RangeUtil.merge([...this.ranges, ...filter.ranges]),
      invert: this.invert,
    })
  }
}
