import { Icon } from '@iconify/react'
import React from 'react'
import icons from './config/icons.config'
import { exploreManager } from './managers/_manager.config'
import User from './model/admin/User'

export enum ScreenPath {
  profile = '/profile',
  announcements = '/announcements',
  login = '/login',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  verifyAccount = '/verify-account',
  dashboard = '/dashboard',
  explore = '/explore',
  exploreMemberVoice = '/explore/member-voice',
  opportunities = '/opportunities',
  admin = '/admin',
}

export function getHomeScreenPath(user: User): ScreenPath {
  if (user.roleIDs.some((roleID) => roleID.includes('filene'))) {
    return ScreenPath.exploreMemberVoice
  }
  return ScreenPath.dashboard
}

export const authPages = [
  ScreenPath.login,
  ScreenPath.forgotPassword,
  ScreenPath.resetPassword,
  ScreenPath.verifyAccount,
]

export interface NavigationPage {
  label: string
  path: ScreenPath
  icon?: React.ReactNode
  action?: () => void
  requiredPermissions?: string[]
  activationPermissions?: string[]
}

export const navigationPages: NavigationPage[] = [
  {
    label: 'Dashboard',
    path: ScreenPath.dashboard,
    icon: <Icon icon={icons.dashboard} width={16} />,
    requiredPermissions: ['view-dashboard-screen'],
  },
  {
    label: 'Explore',
    path: ScreenPath.explore,
    icon: <Icon icon={icons.explore} width={16} />,
    action: () => {
      exploreManager.resetState()
    },
    requiredPermissions: ['view-explore-screen'],
  },
  {
    label: 'Opportunities',
    path: ScreenPath.opportunities,
    icon: <Icon icon={icons.opportunity} width={16} />,
    requiredPermissions: ['view-opportunities-screen'],
  },
  {
    label: 'Admin',
    path: ScreenPath.admin,
    icon: <Icon icon={icons.admin} width={16} />,
    requiredPermissions: ['view-admin-screen'],
    activationPermissions: ['global-admin', 'client-admin'],
  },
]
