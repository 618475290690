import { useHookstate } from '@hookstate/core'
import { Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import copy from '../../config/copy.config'
import AIButton from './AIButton'
import { opportunityViewState } from './OpportunityView'

export interface OpportunityMarketingPlanViewProps {
  onMarketingPlanChange: (marketingPlan: string) => void
  generateMarketingPlan: () => Promise<string>
}

export default function OpportunityMarketingPlanView({
  onMarketingPlanChange,
  generateMarketingPlan,
}: OpportunityMarketingPlanViewProps) {
  // Parent state
  const plan = useHookstate(opportunityViewState.marketingPlan).get()

  // -- Local state
  const [loading, setLoading] = useState<boolean>(false)

  // -- Actions
  function onGenerateClick() {
    generateAIPlan()

    // TODO: Figure out state issue here (generateAIPlan doesn't update loading or plan state when called from ConfirmModal)
    // if (plan) {
    //   const id = 'regenerate'
    //   openModal(
    //     id,
    //     <ConfirmModal
    //       title='Override?'
    //       message='If you generate a new plan, your current plan will be overridden. Are you sure you want to generate a new plan?'
    //       confirmation='Generate'
    //       color='warning'
    //       onClose={() => closeModal(id)}
    //       onConfirm={generateAIPlan}
    //     />,
    //   )
    // } else {
    //   generateAIPlan()
    // }
  }

  async function generateAIPlan() {
    setLoading(true)
    const generatedPlan = await generateMarketingPlan()
    onMarketingPlanChange(generatedPlan)
    setLoading(false)
  }

  // -- UI
  return (
    <Stack direction='column' gap={1}>
      <Stack direction='row' alignItems='flex-end' gap={1} justifyContent={'space-between'}>
        <Stack>
          <Typography variant='h5'>{copy.opportunity.modal.steps.marketingPlan.title}</Typography>
          <Typography color='text.secondary'>
            {copy.opportunity.modal.steps.marketingPlan.description}
          </Typography>
        </Stack>
        <AIButton onGenerateClick={onGenerateClick} loading={loading} />
      </Stack>
      <TextField
        multiline
        variant='outlined'
        rows={18}
        placeholder='Your plan here...'
        value={plan}
        onChange={(e) => onMarketingPlanChange(e.target.value)}
      />
    </Stack>
  )
}
