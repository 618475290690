import { Icon } from '@iconify/react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import Markdown from 'react-markdown'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import Opportunity from '../../model/opportunity/Opportunity'
import OpportunityResults from '../../model/opportunity/OpportunityResults'
import StandardCard from '../util/StandardCard'

export interface OpportunityAISummaryProps {
  opportunity: Opportunity
  results: OpportunityResults
}

export default function OpportunityAISummary({ opportunity, results }: OpportunityAISummaryProps) {
  // If there is no AI summary, return empty
  if (!results.AISummary) {
    return null
  }

  return (
    <StandardCard variant='outlined' padding={0}>
      <Accordion disableGutters>
        <AccordionSummary
          expandIcon={<Icon width={22} icon={icons.chevron.down} />} // Change the icon
          sx={{ flexDirection: 'row-reverse' }} // Change the icon side
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-end'
            paddingLeft={2}
          >
            <Stack>
              <Stack
                direction='row'
                sx={{
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Icon icon={icons.ai} width={20} />
                <Typography variant='h6'>
                  {copy.opportunity.view.results.aiSummary.title}
                </Typography>
              </Stack>
              <Typography variant='body2'>
                {copy.opportunity.view.results.aiSummary.description}
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3, pt: 0 }}>
          <Divider sx={{ mb: 2 }} />
          <Markdown>{results.AISummary}</Markdown>
        </AccordionDetails>
      </Accordion>
    </StandardCard>
  )
}
