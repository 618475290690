import ChartData from './ChartData'
import ChartDataType from './ChartDataType'
import StandardChartData, { StandardChartDataArgument } from './StandardChartData'
import StatisticData, { StatisticDataArgument } from './StatisticData'

export interface SummitRateChartDataArgument {
  avg: StatisticDataArgument
  line: StandardChartDataArgument
}

export default class SummitRateChartData extends ChartData {
  readonly avg: StatisticData
  readonly line: StandardChartData

  constructor(arg: SummitRateChartDataArgument) {
    super({ type: ChartDataType.summitRate })
    this.avg = new StatisticData(arg.avg)
    this.line = new StandardChartData(arg.line)
  }

  isEmpty() {
    return this.line.isEmpty()
  }
}
