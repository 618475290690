import { useHookstate } from '@hookstate/core'
import { exploreManager } from '../../managers/_manager.config'
import Metric from '../../model/admin/Metric'
import ChartType from '../../model/chart/ChartType'
import Filter from '../../model/filter/Filter'
import FilterOption from '../../model/filter/FilterOption'
import { DynamicGridItem } from '../../model/metric/GridItem'
import globalState from '../../service/external/GlobalState'
import DynamicGrid from '../layout/DynamicGrid'
import MetricCard from '../metric/MetricCard'
import MetricErrorView from '../metric/MetricErrorView'
import StatusView from '../util/StatusView'

export default function ExploreMetricsView() {
  // Global state
  const metricsState = useHookstate(globalState.exploreMetrics).get()
  const metricsDataState = useHookstate(globalState.exploreMetricsData).get()
  const currentConfig = useHookstate(globalState.currentExploreConfig).get()
  const isEditing = useHookstate(globalState.isEditingExplore).get()
  const filters = useHookstate(globalState.exploreFilters).get() as Filter[]
  const filterOptions = useHookstate(globalState.exploreFilterOptions).get() as FilterOption[]

  // -- Actions
  function onLayoutChange(items: DynamicGridItem[]) {
    exploreManager.updateConfigLayout(items)
  }

  function onSelectMetricValue(value: string, metricID: string) {
    const metric = metricsState.data?.find((m) => m.metricID === metricID)
    if (!metric) throw new Error(`Metric not found: ${metricID}`)
    exploreManager.toggleFilterMetricOption(metric as Metric, value)
  }

  // -- UI
  function renderCard(metricID: string, metrics: Metric[]) {
    const metric = metrics.find((metric) => metric.metricID === metricID)
    if (!metric) return <MetricErrorView metricID={metricID} />
    if (!currentConfig) return <MetricErrorView metricID={metricID} />
    const config = currentConfig.getMetricConfig(metric.metricID)
    if (!config) return <MetricErrorView metricID={metricID} />
    const state = metricsDataState.get(metric.metricID)
    if (!state) return <MetricErrorView metricID={metric.metricID} />
    const metricConfig = currentConfig.getMetricConfig(metric.metricID)
    if (!metricConfig) return <MetricErrorView metricID={metric.metricID} />

    const filter = filters.find((f) => f.metricID === metric.metricID)
    const filterOption = filterOptions.find((f) => f.metricID === metric.metricID)

    return (
      <MetricCard
        key={metricID}
        metric={metric}
        chart={config.chart}
        state={state}
        isEditing={isEditing}
        filter={filter}
        filterOption={filterOption}
        onRemove={() => exploreManager.removeMetric(metric.metricID)}
        onSelectValue={(value: string, metricID: string) => onSelectMetricValue(value, metricID)}
        onSelectChartType={(type: ChartType) =>
          exploreManager.updateMetricChart(metric.metricID, type)
        }
        configState={metricConfig.state}
        onConfigStateChange={(state: any) =>
          exploreManager.updateMetricConfigState(metric.metricID, state)
        }
      />
    )
  }

  if (!currentConfig) return null
  return (
    <StatusView
      defaultMargin
      state={metricsState}
      render={(metrics) => (
        <DynamicGrid
          items={currentConfig.getGridItems(metrics as Metric[])}
          renderItem={(id: string) => renderCard(id, metrics as Metric[])}
          isEditing={isEditing}
          onGridItemsChange={onLayoutChange}
        />
      )}
    />
  )
}
