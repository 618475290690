import { Dialog, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ModalHeader from '../modal/ModalHeader'

interface LogoutWarningModalProps {
  countdownSeconds: number
  onClose?: () => void
}

export default function LogoutWarningModal({ countdownSeconds, onClose }: LogoutWarningModalProps) {
  const [remainingTime, setRemainingTime] = useState(countdownSeconds)

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0))

      if (remainingTime === 0) {
        onClose?.()
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Dialog open onClose={onClose} aria-labelledby='warning-dialog-title'>
      <ModalHeader
        title='Inactivity Warning'
        description='You will be automatically logged out due to inactivity in 1 minute. Click your mouse or press a key to stay logged in.'
        center
      />
      <Typography align='center' m={2}>
        Time remaining
      </Typography>
      <Typography align='center' variant='h4' fontWeight='bold' mb={5}>
        {remainingTime}s
      </Typography>
    </Dialog>
  )
}
