import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Outlet } from 'react-router-dom'
import background from '../../assets/background.jpg'
import logo from '../../assets/logo-full.svg'
import { HEADER_HEIGHT } from '../navigation/Header'

export interface BaseLayoutProps {
  header: React.ReactNode
}

export default function BaseLayout({ header }: BaseLayoutProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {header}
      <Box
        component='main'
        sx={{
          flex: 1,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pt: HEADER_HEIGHT,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Outlet />
        </Box>
        <Stack alignItems='center' m={2} alignSelf='center'>
          <Typography variant='caption'>Powered by</Typography>
          <img src={logo} alt='Logo' style={{ width: 150 }} />
        </Stack>
      </Box>
    </Box>
  )
}
