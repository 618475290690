import ChartData from './ChartData'
import ChartDataType from './ChartDataType'

export interface StandardChartDataArgument {
  points: StandardDataPoint[]
}

export default class StandardChartData extends ChartData {
  readonly points: StandardDataPoint[]

  constructor(arg: StandardChartDataArgument) {
    super({ type: ChartDataType.standard })
    this.points = arg.points
  }

  getKeys() {
    return this.points.map((d) => d.key)
  }

  isEmpty() {
    return this.points.length === 0
  }
}

export interface StandardDataPoint {
  key: string
  value: number
}
