import { Box, Card, Typography } from '@mui/material'
import Logo from '../util/Logo'

export interface AuthFlowLayoutProps {
  title: string
  image?: string
  children: React.ReactNode | React.ReactNode[]
}

export default function AuthFlowLayout({ title, image, children }: AuthFlowLayoutProps) {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='100vh'>
      <Card sx={{ width: '100%', mx: 2, maxWidth: '30rem', p: 5 }}>
        <Box display='flex' flexDirection='column' gap={3} textAlign='center'>
          <Logo style={{ alignSelf: 'center' }} useClientLogo={false} />
          <Typography variant='h5'>{title}</Typography>
          {image && <img src={image} alt='' style={{ height: '12rem' }} />}
          {children}
        </Box>
      </Card>
    </Box>
  )
}
