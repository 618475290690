import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Card, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ExplorePresetOption from '../components/explore/ExplorePresetOption'
import ProductCategoriesDisplay from '../components/explore/ProductCategoriesDisplay'
import DynamicMenu from '../components/util/DynamicMenu'
import { default as copy } from '../config/copy.config'
import icons from '../config/icons.config'
import { adminManager, exploreManager } from '../managers/_manager.config'
import Level from '../model/admin/Level'
import ExplorePreset from '../model/explore/ExplorePreset'
import {
  ProductCategoryRatingBasicGoalType,
  ProductCategoryRatingGoals,
} from '../model/explore/ProductCategoryRating'
import PropensityType from '../model/propensity/PropensityType'
import { ScreenPath } from '../navigation'
import globalState from '../service/external/GlobalState'
import palette from '../theme/palette'

export default function ExploreSelectScreen() {
  // Navigation
  const navigate = useNavigate()

  // Global state
  const productCategoryRatingsState = useHookstate(globalState.productCategoryRatings).get()
  const levels = useHookstate(globalState.levels).get().data

  // -- Local state
  const [preset, setPreset] = useState<ExplorePreset>(ExplorePreset.growth)
  const [levelName, setLevelName] = useState<string>()

  // Effect
  useEffect(() => {
    exploreManager.fetchProductCategoryRatings(ProductCategoryRatingBasicGoalType.growth)
    adminManager.fetchLevels()
  }, [])

  // -- Actions
  function onSelectPreset(preset: ExplorePreset, level?: Level) {
    setPreset(preset)
    setLevelName(undefined)

    let goal: ProductCategoryRatingGoals | undefined
    switch (preset) {
      case ExplorePreset.growth:
        goal = ProductCategoryRatingBasicGoalType.growth
        exploreManager.setPropensityPreset(PropensityType.growth)
        exploreManager.clearFilters()
        break
      case ExplorePreset.churn:
        goal = ProductCategoryRatingBasicGoalType.churn
        exploreManager.setPropensityPreset(PropensityType.churn)
        exploreManager.clearFilters()
        break
      case ExplorePreset.lowHangingFruit:
        goal = ProductCategoryRatingBasicGoalType.lowHanging
        exploreManager.setPropensityPreset(PropensityType.growth)
        exploreManager.clearFilters()
        break
      case ExplorePreset.levelGrowth:
        if (!level) throw new Error('Level is required for level growth')
        goal = level.name
        setLevelName(level.name)
        exploreManager.setPropensityPreset(PropensityType.growth)
        exploreManager.setLevelFilters(level.levelID)
        break
      default:
        throw new Error('Unknown preset')
    }

    exploreManager.fetchProductCategoryRatings(goal)
  }

  function onSkipClick() {
    navigate(`${ScreenPath.explore}/members`)
  }

  return (
    <Stack justifyContent='center' alignItems='center' height='100%'>
      <Stack gap={2} p={4} maxWidth='120rem'>
        <Stack gap={2}>
          <Typography variant='h6' alignSelf='center'>
            {copy.explore.presetHeader}
          </Typography>
          <Stack direction='row' gap={1}>
            <ExplorePresetOption
              testId='explore-goal-growth'
              title={copy.explore.presets.growth.title}
              description={copy.explore.presets.growth.description}
              icon={<Icon icon={icons.growth} width={25} style={{ color: palette.light.growth }} />}
              selected={preset === ExplorePreset.growth}
              disabled={preset !== ExplorePreset.growth && productCategoryRatingsState.isLoading()}
              onClick={() => onSelectPreset(ExplorePreset.growth)}
            />
            <ExplorePresetOption
              testId='explore-goal-churn'
              title={copy.explore.presets.churn.title}
              description={copy.explore.presets.churn.description}
              icon={<Icon icon={icons.churn} width={25} style={{ color: palette.light.churn }} />}
              selected={preset === ExplorePreset.churn}
              disabled={preset !== ExplorePreset.churn && productCategoryRatingsState.isLoading()}
              onClick={() => onSelectPreset(ExplorePreset.churn)}
            />
            <ExplorePresetOption
              testId='explore-goal-low-hanging'
              title={copy.explore.presets.lowHangingFruit.title}
              description={copy.explore.presets.lowHangingFruit.description}
              icon={<Icon icon={icons.fruit} width={25} style={{ color: '#8800FF' }} />}
              selected={preset === ExplorePreset.lowHangingFruit}
              disabled={
                preset !== ExplorePreset.lowHangingFruit && productCategoryRatingsState.isLoading()
              }
              onClick={() => onSelectPreset(ExplorePreset.lowHangingFruit)}
            />
            <DynamicMenu
              sx={{ flex: 1.2 }}
              actions={
                levels?.map((level) => ({
                  label: `Level ${level.name}`,
                  onClick: () => onSelectPreset(ExplorePreset.levelGrowth, level),
                })) ?? []
              }
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <ExplorePresetOption
                testId='explore-goal-level-growth'
                title={`Level growth ${levelName ? `(${levelName})` : ''}`}
                description={copy.explore.presets.levelGrowth.description}
                icon={<Icon icon={icons.mountain} width={25} />}
                selected={preset === ExplorePreset.levelGrowth}
                disabled={
                  preset !== ExplorePreset.levelGrowth && productCategoryRatingsState.isLoading()
                }
              />
            </DynamicMenu>
            <Typography alignSelf='center' m={2} variant='h6'>
              OR
            </Typography>
            <ExplorePresetOption
              testId='explore-member-explore'
              title={copy.explore.presets.memberExplore.title}
              description={copy.explore.presets.memberExplore.description}
              icon={<Icon icon={icons.person} width={25} />}
              variant='button'
              onClick={onSkipClick}
            />
          </Stack>
        </Stack>
        {preset && (
          <Card sx={{ p: 5 }}>
            <Stack gap={2}>
              <Stack alignSelf='center' textAlign='center'>
                <Typography variant='h6' alignSelf='center'>
                  {copy.explore.recommendationsHeader.title}
                </Typography>
                <Typography color='text.secondary'>
                  {copy.explore.recommendationsHeader.description}
                </Typography>
              </Stack>
              <ProductCategoriesDisplay showRatings={true} />
            </Stack>
          </Card>
        )}
      </Stack>
    </Stack>
  )
}
