import { useHookstate } from '@hookstate/core'
import { Box, Button, Card, CardActions, CardContent, Grid, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useModal } from '../../../contexts/ModalContext'
import { adminManager } from '../../../managers/_manager.config'
import Client, { ClientArgument, ClientType } from '../../../model/admin/Client'
import globalState from '../../../service/external/GlobalState'
import CustomSelectController from '../../modal/CustomSelectController'
import CustomTextFieldController from '../../modal/CustomTextFieldController'

export default function ClientTab() {
  const { handleSubmit, control, reset } = useForm<ClientArgument>()
  const { showSnackbar } = useModal()

  // Global state
  const currentClient = useHookstate(globalState.currentClient).get()
  const clients = useHookstate(globalState.clients).get().data

  // -- Local state
  const [client, setClient] = useState(currentClient!)
  const [originalClient, setOriginalClient] = useState(currentClient!)

  // Effect
  useEffect(() => {
    if (currentClient && clients) {
      adminManager.selectClient(currentClient.clientID)
    }
  }, [currentClient, clients, adminManager])

  useEffect(() => {
    // If the client is changed, reset the form
    handleReset()
  }, [currentClient])

  // -- Actions
  const handleReset = () => {
    setClient(originalClient)

    reset({
      clientID: originalClient.clientID,
      name: originalClient.name,
      displayName: originalClient.displayName,
      type: originalClient.type,
      city: originalClient.city,
      state: originalClient.state,
      zip: originalClient.zip,
      phone: originalClient.phone,
      address: originalClient.address,
    })
  }

  const handleSave: SubmitHandler<ClientArgument> = async (data) => {
    const updatedClient = new Client({
      ...(client as Client),
      ...data,
    })

    await adminManager.saveClient(updatedClient)
    setClient(updatedClient)
    setOriginalClient(updatedClient)
    showSnackbar('client-updated', 'Changes saved')
  }

  return (
    <Card variant='outlined' sx={{ width: '100%' }}>
      <CardContent>
        <Typography variant='h5' component='h1' fontSize='lg'>
          Organization Details
        </Typography>
        <Typography fontSize='sm' mb={1} color='text.secondary'>
          Edit your organization details
        </Typography>
        <Box mt={4}>
          <form onSubmit={handleSubmit(handleSave)}>
            <Grid container spacing={2} columns={2}>
              <Grid item xs={2}>
                <CustomTextFieldController
                  name='clientID'
                  control={control}
                  defaultValue={client.clientID}
                  label='Organization ID'
                  disabled
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTextFieldController
                  name='name'
                  control={control}
                  defaultValue={client.name}
                  label='Name'
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTextFieldController
                  name='displayName'
                  control={control}
                  defaultValue={client.displayName}
                  label='Display Name'
                />
              </Grid>
              <Grid item xs={1}>
                <CustomSelectController
                  name='type'
                  control={control}
                  defaultValue={client.type}
                  label='Type'
                  options={Object.values(ClientType)}
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTextFieldController
                  name='city'
                  control={control}
                  defaultValue={client.city}
                  label='City'
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTextFieldController
                  name='state'
                  control={control}
                  defaultValue={client.state}
                  label='State'
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTextFieldController
                  name='zip'
                  control={control}
                  defaultValue={client.zip}
                  label='ZIP Code'
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTextFieldController
                  name='phone'
                  control={control}
                  defaultValue={client.phone}
                  label='Phone'
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTextFieldController
                  name='address'
                  control={control}
                  defaultValue={client.address}
                  label='Address'
                />
              </Grid>
            </Grid>
            <CardActions>
              <Stack direction='row' gap={1} mt={2}>
                <Button type='submit' variant='contained' color='primary'>
                  Save
                </Button>
                <Button onClick={handleReset}>Undo</Button>
              </Stack>
            </CardActions>
          </form>
        </Box>
      </CardContent>
    </Card>
  )
}
