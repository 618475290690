import ChartData from './ChartData'
import ChartDataType from './ChartDataType'

export interface StatisticDataArgument {
  title: string
  value: number | string
  description?: string
  valuePrefix?: string
  valueSuffix?: string
  size?: 'sm' | 'lg'
  precision?: number
}

export default class StatisticData extends ChartData {
  readonly title: string
  readonly value: number | string
  readonly description?: string
  readonly valuePrefix?: string
  readonly valueSuffix?: string
  readonly size?: 'sm' | 'lg'
  readonly precision?: number

  constructor(arg: StatisticDataArgument) {
    super({ type: ChartDataType.statistic })
    this.title = arg.title
    this.value = arg.value
    this.description = arg.description
    this.valuePrefix = arg.valuePrefix
    this.valueSuffix = arg.valueSuffix
    this.size = arg.size
    this.precision = arg.precision
  }

  isEmpty() {
    return false
  }
}
