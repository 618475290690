export enum LoadingState {
  unloaded = 'unloaded',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export interface LoadableStateArgument<T> {
  id?: string
  state: LoadingState
  error: Error | null
  data: T | null
}

export default class LoadableState<T> {
  readonly id: string
  readonly status: LoadingState
  readonly error: Error | null
  readonly data: T | null

  static create<T>(id?: string) {
    return new LoadableState<T>({ id, state: LoadingState.unloaded, error: null, data: null })
  }

  static set<T>(value: T, id?: string) {
    return new LoadableState<T>({ id, state: LoadingState.loaded, error: null, data: value })
  }

  static loading<T>(id?: string) {
    return new LoadableState<T>({ id, state: LoadingState.loading, error: null, data: null })
  }

  static error<T>(error: Error, id?: string) {
    return new LoadableState<T>({ id, state: LoadingState.error, error, data: null })
  }

  constructor(arg: LoadableStateArgument<T>) {
    this.id = arg.id || 'loadable-state'
    this.status = arg.state
    this.error = arg.error
    this.data = arg.data
  }

  isLoaded() {
    return this.status === LoadingState.loaded
  }

  isLoading() {
    return this.status === LoadingState.loading
  }

  isErrored() {
    return this.status === LoadingState.error
  }
}

export class LoadableArray<T> {
  readonly items: LoadableState<T>[]

  constructor(items: LoadableState<T>[]) {
    this.items = items
  }

  static create<T>(ids: string[]) {
    return new LoadableArray<T>(ids.map((id) => LoadableState.create<T>(id)))
  }

  get(id: string): LoadableState<T> {
    const item = this.items.find((item) => item.id === id)
    if (!item) return LoadableState.error<T>(new Error('Item not found'), id)
    return item
  }

  setItem<T>(value: T, id: string): LoadableArray<T> {
    const index = this.items.findIndex((item) => item.id === id)
    if (index === -1) throw new Error('Item not found')
    return new LoadableArray<T>(
      this.items.map(
        (item, i) => (i === index ? LoadableState.set<T>(value, id) : item) as LoadableState<T>,
      ),
    )
  }

  loadAll() {
    return new LoadableArray<T>(this.items.map((item) => LoadableState.loading<T>(item.id)))
  }

  errorItem<T>(id: string, error: Error): LoadableArray<T> {
    const index = this.items.findIndex((item) => item.id === id)
    if (index === -1) throw new Error('Item not found')
    return new LoadableArray<T>(
      this.items.map(
        (item, i) => (i === index ? LoadableState.error<T>(error, id) : item) as LoadableState<T>,
      ),
    )
  }
}
