import { useHookstate } from '@hookstate/core'
import { Button, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useModal } from '../../contexts/ModalContext'
import { adminManager } from '../../managers/_manager.config'
import Method from '../../model/admin/Method'
import OpportunityMethod from '../../model/opportunity/OpportunityMethod'
import globalState from '../../service/external/GlobalState'
import ListUtil from '../../util/ListUtil'
import MethodModal from '../admin/modals/MethodModal'
import DynamicMenu from '../util/DynamicMenu'
import StatusView from '../util/StatusView'
import OpportunityMethodInput from './OpportunityMethodInput'
import { opportunityViewState } from './OpportunityView'

export interface OpportunityMethodsInputProps {
  onMethodsChange: (methods: OpportunityMethod[]) => void
  generateMarketingMethodContent: (method: OpportunityMethod) => Promise<string>
  minStartDate?: Date
  maxEndDate?: Date
  disabled?: boolean
  defaultStartDate?: Date
  defaultEndDate?: Date
}

export default function OpportunityMethodsInput({
  onMethodsChange,
  generateMarketingMethodContent,
  minStartDate,
  maxEndDate,
  disabled,
  defaultStartDate,
  defaultEndDate,
}: OpportunityMethodsInputProps) {
  // Navigation
  const { openModal, closeModal } = useModal()

  // Global state
  const methodOptionsState = useHookstate(globalState.methods).get()

  // Parent state
  const methods = useHookstate(opportunityViewState.methods).get()

  // -- Lifecycle
  useEffect(() => {
    if (!methodOptionsState.data) adminManager.fetchMethods()
  }, [])

  // -- Actions
  function onAddMethod(method: Method) {
    let newMethod = OpportunityMethod.create(method)
      .updateStartDate(defaultStartDate ?? new Date())
      .updateEndDate(defaultEndDate ?? new Date())
    onMethodsChange(ListUtil.put(methods as OpportunityMethod[], newMethod))
  }

  function onRemoveMethod(opportunityMethodID: string) {
    onMethodsChange(
      ListUtil.remove(
        methods as OpportunityMethod[],
        (m) => m.opportunityMethodID === opportunityMethodID,
      ),
    )
  }

  function onMethodChange(method: OpportunityMethod) {
    onMethodsChange(
      ListUtil.put(
        methods as OpportunityMethod[],
        method,
        (m) => m.opportunityMethodID === method.opportunityMethodID,
      ),
    )
  }

  function handleAddOther() {
    openModal(
      'create-method',
      <MethodModal
        method={Method.create()}
        methods={methodOptionsState.data as Method[]}
        isCreating
        onClose={() => closeModal('create-method')}
        onSubmit={(updatedMethod) => adminManager.saveMethod(updatedMethod)}
      />,
    )
  }

  // -- UI
  return (
    <StatusView
      state={methodOptionsState}
      render={(methodOptions) => {
        return <Stack gap={2} >
          <Stack gap={2}>
            {methods.map((method) => (
              <OpportunityMethodInput
                key={method.opportunityMethodID}
                method={method}
                onChange={onMethodChange}
                onRemove={onRemoveMethod}
                methodOptions={methodOptions as Method[]}
                generateMarketingMethodContent={generateMarketingMethodContent}
                minStartDate={minStartDate}
                maxEndDate={maxEndDate}
                disabled={disabled}
              />
            ))}
          </Stack>
          <DynamicMenu
            actions={[
              ...methodOptions.map((methodOption) => ({
                label: methodOption.name,
                onClick: () => onAddMethod(methodOption),
              })),
              {
                label: 'Other...',
                onClick: handleAddOther,
                permissionID: 'create-method',
              },
            ]}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          >
            <Button>Add method</Button>
          </DynamicMenu>
        </Stack >
      }}
    />
  )
}
