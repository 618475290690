import { UserForm } from '../../components/admin/modals/UserModal'
import Client from './Client'
import Role from './Role'

export interface UserArgument {
  userID: string
  clientIDs: string[]
  username: string
  email: string
  phone: string
  firstName: string
  lastName: string
  roleIDs: string[]
}

export default class User {
  readonly userID: string
  readonly clientIDs: string[]
  readonly username: string
  readonly email: string
  readonly phone: string
  readonly firstName: string
  readonly lastName: string
  readonly roleIDs: string[]

  constructor(arg: UserArgument) {
    this.userID = arg.userID
    this.clientIDs = arg.clientIDs
    this.username = arg.username
    this.email = arg.email
    this.phone = arg.phone
    this.firstName = arg.firstName
    this.lastName = arg.lastName
    this.roleIDs = arg.roleIDs
  }
  static create(clientIDs: string[] = []) {
    return new User({
      userID: '',
      clientIDs: clientIDs,
      username: '',
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      roleIDs: [],
    })
  }
  getFullName() {
    return `${this.firstName} ${this.lastName}`
  }

  getAvailableClients(clients: Client[], roles: Role[]) {
    if (this.hasPermission('view-all-clients', roles)) return clients
    return clients.filter((client) => this.clientIDs.includes(client.clientID))
  }

  hasPermission(permissionID: string, roles?: Role[]) {
    if (this.roleIDs.includes('super-admin')) return true

    if (!roles) return false

    return roles
      .filter((role) => this.roleIDs.includes(role.roleID))
      .some((role) => role.hasPermission(permissionID))
  }

  duplicate() {
    return new User({
      ...this,
      userID: '',
      name: `${this.username} (copy)`,
    })
  }
  formUpdate(form: UserForm) {
    return new User({
      ...this,
      userID: form.email.toLowerCase(),
      username: form.username,
      email: form.email,
      phone: form.phone,
      firstName: form.firstName,
      lastName: form.lastName,
      roleIDs: form.roleIDs,
    })
  }
}
