import { Slider, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import FilterRange from '../../../model/filter/FilterRange'

export interface IntervalSliderProps {
  min: number
  max: number
  value?: FilterRange
  onChange: (range: FilterRange) => void
}

export default function IntervalSlider({ min, max, value, onChange }: IntervalSliderProps) {
  // -- Local state
  const [sliderValue, setSliderValue] = useState<[number, number]>(
    value ? [value?.min, value?.max ?? Infinity] : [min, max],
  )

  // Effect
  useEffect(() => {
    if (value) {
      setSliderValue([value.min, value.max ?? Infinity])
    } else {
      setSliderValue([min, max])
    }
  }, [value])

  // -- Actions
  function onSliderChange(_: Event, value: number | number[]) {
    if (!Array.isArray(value)) return
    const [min, max] = value
    setSliderValue([min, max])
  }

  function onSliderChangeCommitted(_: Event, value: number | number[]) {
    if (!Array.isArray(value)) return
    const [min, max] = value
    onChange(new FilterRange({ min, max }))
  }

  // -- UI
  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <Slider
        value={sliderValue}
        onChange={onSliderChange}
        onChangeCommitted={onSliderChangeCommitted as any}
        valueLabelDisplay='auto'
        min={min}
        max={max}
      />
    </Stack>
  )
}
