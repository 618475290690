import { Icon } from '@iconify/react'
import {
  Chip,
  IconButton,
  List,
  ListItem,
  Slide,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import { memo, useEffect, useRef, useState } from 'react'
import icons from '../../../config/icons.config'
import { opportunityManager } from '../../../managers/_manager.config'
import User from '../../../model/admin/User'
import Opportunity from '../../../model/opportunity/Opportunity'
import OpportunityLog from '../../../model/opportunity/OpportunityLog'
import LoadingIndicator from '../../util/LoadingIndicator'
import { ChatInput } from './ChatInput'

type ChatViewProps = {
  user: User | null
  opportunity: Opportunity
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  discussionLogs: OpportunityLog[]
  setDiscussionLogs: React.Dispatch<React.SetStateAction<OpportunityLog[]>>
}

const MessageElement = (log: OpportunityLog, isOutgoing: boolean) => {
  const theme = useTheme()

  return (
    <ListItem
      key={log.logID}
      sx={{
        width: '100%',
        flexDirection: 'column',
        alignItems: isOutgoing ? 'flex-end' : 'flex-start',
      }}
    >
      <Chip
        variant={isOutgoing ? 'filled' : 'outlined'}
        label={log.content}
        sx={{
          maxWidth: 325,
          width: 'fit-content',
          height: 'fit-content',
          marginBottom: 0.5,
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
            overflowWrap: 'break-word',
            padding: 0,
            fontSize: 14,
            color: isOutgoing ? theme.palette.primary.contrastText : theme.palette.text.primary,
          },
          paddingX: 1.75,
          paddingY: 1.5,
          background: isOutgoing ? theme.palette.primary.main : theme.palette.background.paper,
        }}
      />
      <Typography
        variant='body1'
        sx={{
          fontSize: 12,
          color: theme.palette.text.secondary,
          marginLeft: isOutgoing ? 0 : 0.5,
          marginRight: isOutgoing ? 0.5 : 0,
        }}
      >
        {log.userDisplayName}
      </Typography>
    </ListItem>
  )
}

export const ChatView = memo(function ChatView(props: ChatViewProps) {
  const listRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [listRef.current, props.discussionLogs, props.show])

  const ChatHeader = styled(Stack)(({ theme }) => ({
    background: theme.palette.background.default,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  }))

  const sendMessage = async (text: string) => {
    if (!text.trim()) return
    setLoading(true)
    const newLog = await opportunityManager.saveOpportunityLog(
      props.opportunity.opportunityID,
      text,
    )
    props.setDiscussionLogs([...props.discussionLogs, newLog])
    setLoading(false)
  }

  return (
    <Slide direction='left' in={props.show} mountOnEnter unmountOnExit>
      <Stack
        sx={{
          width: 425,
          height: 'calc(100vh - 52px)',
          background: theme.palette.grey[300],
          overflow: 'hidden',
          position: 'absolute',
          bottom: -0.5,
          right: 0,
          zIndex: 51,
          borderRadius: 1.5,
        }}
      >
        <ChatHeader direction='row' paddingY={2.5} paddingX={3}>
          <Stack height='fit-content'>
            <Typography variant='body1' fontWeight={600} fontSize={18} noWrap maxWidth={325}>
              {props.opportunity.name}
            </Typography>
            <Typography variant='body2' noWrap>
              Opportunity Discussion Board
            </Typography>
          </Stack>
          <IconButton onClick={() => props.setShow(false)}>
            <Icon icon={icons.closeSimple} width={20} height={20} />
          </IconButton>
        </ChatHeader>
        <Stack height='100%' overflow='auto'>
          <List>
            {props.discussionLogs.map((log) =>
              MessageElement(log, log.userID === props.user?.userID),
            )}
            {loading && (
              <ListItem
                sx={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  marginRight: 3,
                  marginBottom: 1.5,
                }}
              >
                <LoadingIndicator />
              </ListItem>
            )}
            <div ref={listRef} />
          </List>
        </Stack>
        <ChatInput sendMessage={sendMessage} loading={loading} />
      </Stack>
    </Slide>
  )
})
