import { Button, Stack, TextField } from '@mui/material'
import { useState } from 'react'
import ContinuousFilter, { ContinuousFilterValues } from '../../../model/filter/ContinuousFilter'
import ContinuousFilterOption from '../../../model/filter/ContinuousFilterOption'
import RangeUtil from '../../../util/RangeUtil'

export interface NumberFilterValueMenuProps {
  filter?: ContinuousFilter
  filterOption: ContinuousFilterOption
  onSubmit: (values: ContinuousFilterValues) => void
  onClose?: () => void
}

export default function NumberFilterValueMenu({
  filter,
  filterOption,
  onSubmit,
  onClose,
}: NumberFilterValueMenuProps) {
  // -- Local state
  const [rangeText, setRangeText] = useState(
    filter?.ranges.map((range) => range.getLabel()).join(', '),
  )
  const [isInputValid, setIsInputValid] = useState(true)

  // -- Actions
  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (isInputValid) {
        onDoneButtonClick()
      }
    }
  }

  function onDoneButtonClick() {
    try {
      const ranges = RangeUtil.textToRanges(rangeText ?? '234234')
      onSubmit(ranges)
      onClose?.()
    } catch (error) {
      setIsInputValid(false)
    }
  }

  function onRangeTextChange(text: string) {
    setRangeText(text)
    setIsInputValid(true)
  }

  return (
    <Stack p={2} gap={1} onKeyDown={handleKeyDown} data-testid='filter-value-menu-continuous'>
      <TextField
        value={rangeText}
        label='Range'
        placeholder='1-5, 10-15, 20+'
        error={!isInputValid}
        helperText={!isInputValid ? 'Please enter a valid range, \n e.g., "1-5, 10-15, 20+"' : ''}
        onChange={(e) => onRangeTextChange(e.target.value)}
        sx={{ minWidth: 300 }}
      />
      <Button
        variant='contained'
        sx={{ marginTop: 1 }}
        onClick={onDoneButtonClick}
        disabled={!isInputValid}
      >
        Done
      </Button>
    </Stack>
  )
}
