import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import { cloneElement } from 'react'
import { Control, Controller } from 'react-hook-form'

interface FormControllerProps {
  name: string
  control: Control<any>
  rules?: any
  placeholder?: string
  type?: 'text' | 'password' | 'checkbox' | 'radio' | 'select'
  icon?: JSX.Element
  label?: string
  options?: string[]
  required?: boolean
}

export default function FormController({
  name,
  control,
  rules,
  placeholder,
  type = 'text',
  icon,
  label,
  options,
  required,
}: FormControllerProps) {
  const commonProps = {
    name,
    control,
    rules: { required, ...rules },
  }

  if (type === 'checkbox') {
    return (
      <Controller
        {...commonProps}
        render={({ field }) => <FormControlLabel control={<Checkbox {...field} />} label={label} />}
      />
    )
  }

  if (type === 'radio') {
    return (
      <FormControl component='fieldset'>
        <FormLabel component='legend'>{label}</FormLabel>
        <Controller
          {...commonProps}
          render={({ field }) => (
            <RadioGroup {...field}>
              {options?.map((option, index) => (
                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
    )
  }

  if (type === 'select') {
    return (
      <Controller
        {...commonProps}
        render={({ field }) => (
          <Select {...field} variant='outlined' fullWidth placeholder={placeholder}>
            {options?.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    )
  }

  return (
    <Controller
      {...commonProps}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          fullWidth
          variant='outlined'
          placeholder={placeholder}
          type={type === 'password' ? 'password' : 'text'}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
          InputProps={
            icon
              ? {
                  startAdornment: cloneElement(icon, {
                    style: { marginRight: 5 },
                  }),
                }
              : {}
          }
        />
      )}
    />
  )
}
