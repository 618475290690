import { useHookstate } from '@hookstate/core'
import { dashboardManager } from '../../managers/_manager.config'
import Metric from '../../model/admin/Metric'
import { DynamicGridItem } from '../../model/metric/GridItem'
import globalState from '../../service/external/GlobalState'
import DynamicGrid from '../layout/DynamicGrid'
import MetricCard from '../metric/MetricCard'
import StatusView from '../util/StatusView'

export default function DashboardMetricsView() {
  // Global state
  const metricsState = useHookstate(globalState.dashboardMetrics).get()
  const metricsDataState = useHookstate(globalState.dashboardMetricsData).get()
  const currentConfig = useHookstate(globalState.currentDashboardConfig).get()
  const isEditing = useHookstate(globalState.isEditingDashboard).get()

  // -- Actions
  function onLayoutChange(items: DynamicGridItem[]) {
    dashboardManager.updateConfigLayout(items)
  }

  // -- UI
  function renderCard(id: string, metrics: Metric[]) {
    const metric = metrics.find((metric) => metric.metricID === id)
    if (!metric) return null
    const config = currentConfig?.getMetricConfig(metric.metricID)
    if (!config) return null
    const state = metricsDataState.get(metric.metricID)
    if (!state) return null

    return (
      <MetricCard
        key={id}
        metric={metric}
        chart={config.chart}
        state={state}
        isEditing={isEditing}
        onRemove={() => dashboardManager.removeMetric(metric.metricID)}
      />
    )
  }

  if (!currentConfig) return null
  return (
    <StatusView
      defaultMargin
      state={metricsState}
      render={(metrics) => (
        <DynamicGrid
          items={currentConfig.getGridItems(metrics as Metric[])}
          renderItem={(id: string) => renderCard(id, metrics as Metric[])}
          isEditing={isEditing}
          onGridItemsChange={onLayoutChange}
        />
      )}
    />
  )
}
