import { hookstate, useHookstate } from '@hookstate/core'
import { devtools } from '@hookstate/devtools'
import { Button, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import copy from '../../config/copy.config'
import { useModal } from '../../contexts/ModalContext'
import { opportunityManager } from '../../managers/_manager.config'
import User from '../../model/admin/User'
import Opportunity, { OpportunityStatus } from '../../model/opportunity/Opportunity'
import OpportunityLog from '../../model/opportunity/OpportunityLog'
import OpportunityMethod from '../../model/opportunity/OpportunityMethod'
import globalState from '../../service/external/GlobalState'
import ConfirmModal from '../util/ConfirmModal'
import StandardCard from '../util/StandardCard'
import { OpportunityDiscussion } from './discussion-view/OpportunityDiscussion'
import OpportunityHeader from './OpportunityHeader'
import OpportunityMarketingDetailsView from './OpportunityMarketingDetailsView'
import OpportunityMarketingPlanView from './OpportunityMarketingPlanView'
import OpportunityResultsView from './OpportunityResultsView'
import OpportunityTargetMembersView from './OpportunityTargetMembersView'

const initialState = {
  name: '',
  status: OpportunityStatus.draft,
  marketingPlan: '',
  activeStep: 0,
  startDate: new Date(),
  endDate: new Date(),
  resultsEndDate: new Date(),
  methods: [] as OpportunityMethod[],
  dateError: '',
}

export const opportunityViewState = hookstate(
  initialState,
  devtools({ key: 'opportunity-modal-state' }),
)

export interface OpportunityViewProps {
  opportunity: Opportunity
}

export default function OpportunityView({ opportunity }: OpportunityViewProps) {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()
  const navigate = useNavigate()

  // Global state
  const opportunities = useHookstate(globalState.opportunities).get().data as Opportunity[] | null
  const user = useHookstate(globalState.user).get().data as User | null

  // -- Local state
  const [hasChanges, setHasChanges] = useState(false)
  const [lastSavedState, setLastSavedState] = useState<string>()
  const [showDiscussion, setShowDiscussion] = useState(false)
  const [discussionLogs, setDiscussionLogs] = useState<OpportunityLog[]>([])

  // Opportunity State
  const nameState = useHookstate(opportunityViewState.name)
  const statusState = useHookstate(opportunityViewState.status)
  const marketingPlanState = useHookstate(opportunityViewState.marketingPlan)
  const startDateState = useHookstate(opportunityViewState.startDate)
  const endDateState = useHookstate(opportunityViewState.endDate)
  const resultsEndDateState = useHookstate(opportunityViewState.resultsEndDate)
  const methodsState = useHookstate(opportunityViewState.methods)
  const activeStepState = useHookstate(opportunityViewState.activeStep)

  // Constants + Computed
  const steps = ['Targeted Members', 'Marketing Plan', 'Marketing Details', 'Results']
  const isPublishing =
    activeStepState.get() === steps.length - 2 && statusState.get() === OpportunityStatus.pending

  // -- Lifecycle
  useEffect(() => {
    // Initialize state
    opportunityViewState.name.set(opportunity.name)
    opportunityViewState.status.set(opportunity.status)
    opportunityViewState.marketingPlan.set(opportunity.marketingPlan)
    opportunityViewState.startDate.set(opportunity.startDate)
    opportunityViewState.endDate.set(opportunity.endDate)
    opportunityViewState.resultsEndDate.set(opportunity.resultsEndDate)
    opportunityViewState.methods.set(opportunity.methods)
    opportunityViewState.activeStep.set(opportunity.getActiveStep())

    // Save initial state (for comparison so we can determine if there are changes)
    if (!lastSavedState) setLastSavedState(getCurrentStateString(opportunity))

    updateOpportunityLogs()
  }, [])

  useEffect(() => {
    // Check for changes
    const currentState = getCurrentStateString()
    setHasChanges(currentState !== lastSavedState)
  }, [
    nameState,
    statusState,
    marketingPlanState,
    startDateState,
    endDateState,
    resultsEndDateState,
    methodsState,
  ])

  // Functions
  async function updateOpportunityLogs() {
    const logs = await opportunityManager.fetchOpportunityLogs(opportunity.opportunityID)
    setDiscussionLogs(logs)
  }

  function getCurrentStateString(opportunity?: Opportunity) {
    return JSON.stringify({
      name: opportunity?.name ?? nameState.get(),
      status: opportunity?.status ?? statusState.get(),
      marketingPlan: opportunity?.marketingPlan ?? marketingPlanState.get(),
      startDate: opportunity?.startDate ?? startDateState.get(),
      endDate: opportunity?.endDate ?? endDateState.get(),
      resultsEndDateState: opportunity?.resultsEndDate ?? resultsEndDateState.get(),
      methods: opportunity?.methods ?? methodsState.get(),
    })
  }

  function save() {
    const updated = opportunity.update({
      name: nameState.get(),
      status: statusState.get(),
      marketingPlan: marketingPlanState.get(),
      startDate: startDateState.get(),
      endDate: endDateState.get(),
      resultsEndDate: resultsEndDateState.get(),
      methods: methodsState.get() as OpportunityMethod[],
    })
    opportunityManager.saveOpportunity(updated)
    setLastSavedState(getCurrentStateString())
    setHasChanges(false)
  }

  function getStatusForStep(step: number): OpportunityStatus {
    function getNewStatus(step: number) {
      switch (step) {
        case 1:
          return OpportunityStatus.pending
        case 3:
          return OpportunityStatus.published
        default:
          return OpportunityStatus.draft
      }
    }

    const currentStatusRank = Object.values(OpportunityStatus).indexOf(statusState.get())
    const newStatusRank = Object.values(OpportunityStatus).indexOf(getNewStatus(step))

    return currentStatusRank < newStatusRank ? getNewStatus(step) : statusState.get()
  }

  // -- Actions
  function onBackButtonClick() {
    if (hasChanges) {
      const id = 'confirm-back'
      openModal(
        id,
        <ConfirmModal
          title='Unsaved Changes'
          message='Are you sure you want to go back? Your changes will be lost'
          confirmation='Go Back'
          emphasized
          color='primary'
          onConfirm={() => navigate(-1)}
          onClose={() => closeModal(id)}
        />,
      )
    } else {
      navigate(-1)
    }
  }

  function onNext() {
    if (isPublishing) {
      const id = 'confirm-publish'
      openModal(
        id,
        <ConfirmModal
          title={copy.opportunity.view.publish.warning.title}
          message={copy.opportunity.view.publish.warning.description}
          confirmation='Publish'
          color='primary'
          emphasized
          onConfirm={goNext}
          onClose={() => closeModal(id)}
        />,
      )
    } else {
      goNext()
    }
  }

  function goNext() {
    const nextStep = activeStepState.get() + 1
    statusState.set(getStatusForStep(nextStep))
    onStepChange(nextStep)
    save()
  }

  function onStepChange(step: number) {
    opportunityViewState.activeStep.set(step)
  }

  // -- Actions
  function onNavigateAway() {}

  // Functions
  function validateName(name: string) {
    if (!opportunities) return 'Sorry. Something went wrong'
    return Opportunity.validateName(name, opportunities, opportunity.name)
  }

  // -- UI
  return (
    <Stack>
      <OpportunityHeader
        opportunity={opportunity}
        canSave={hasChanges}
        steps={steps}
        activeStep={activeStepState.get()}
        onSave={save}
        onStepChange={onStepChange}
        onBackButtonClick={onBackButtonClick}
        onNameChange={nameState.set}
        validateName={validateName}
      />
      <Stack p={2} gap={2} sx={{ mt: 16 }}>
        <StandardCard sx={{ pb: 5 }}>
          {activeStepState.get() === 0 && (
            <OpportunityTargetMembersView
              opportunity={opportunity}
              onNavigateAway={onNavigateAway}
            />
          )}
          {activeStepState.get() === 1 && (
            <OpportunityMarketingPlanView
              onMarketingPlanChange={marketingPlanState.set}
              generateMarketingPlan={() => opportunityManager.generateMarketingPlan(opportunity)}
            />
          )}
          {activeStepState.get() === 2 && (
            <OpportunityMarketingDetailsView
              onStartDateChange={startDateState.set}
              onEndDateChange={endDateState.set}
              onMethodsChange={methodsState.set}
              generateMarketingMethodContent={(method) =>
                opportunityManager.generateMarketingMethodContent(opportunity, method)
              }
            />
          )}
          {activeStepState.get() === 3 && <OpportunityResultsView opportunity={opportunity} />}
        </StandardCard>
        <Stack direction='row' justifyContent='flex-end'>
          {activeStepState.get() < steps.length - 1 ? (
            <Button onClick={onNext} variant='contained' sx={{ minWidth: 150 }}>
              {activeStepState.get() === steps.length - 2 ? 'Publish' : 'Next'}
            </Button>
          ) : (
            <div />
          )}
        </Stack>
      </Stack>
      <OpportunityDiscussion
        user={user}
        opportunity={opportunity}
        show={showDiscussion}
        setShow={setShowDiscussion}
        discussionLogs={discussionLogs}
        setDiscussionLogs={setDiscussionLogs}
      />
    </Stack>
  )
}
