import { useHookstate } from '@hookstate/core'
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import AnnouncementCard from '../components/announcements/AnnouncementCard'
import EmptyTableView from '../components/table/EmptyTableView'
import StatusView from '../components/util/StatusView'
import { announcementManager } from '../managers/_manager.config'
import { UserAnnouncementData } from '../model/announcement/UserAnnouncement'
import globalState from '../service/external/GlobalState'

type TagFilterType = 'Announcements' | 'Release Notes'

export default function AnnouncementsScreen() {
  const announcementState = useHookstate(globalState.userAnnouncementsData).get()
  const userState = useHookstate(globalState.user).get()
  const [selectedTag, setSelectedTag] = useState<TagFilterType>('Announcements')
  const [filteredAnnouncements, setFilteredAnnouncements] = useState<UserAnnouncementData[]>([])

  useEffect(() => {
    if (!announcementState.isLoaded() && !announcementState.isLoading() && userState.data) {
      announcementManager.fetchAnnouncements(userState.data.userID)
    } else if (announcementState.isLoaded()) {
      filterAnnouncementsByTags(selectedTag)
    }
  }, [announcementState, userState])

  function filterAnnouncementsByTags(newTag: TagFilterType) {
    const allAnnouncements = announcementState.data as UserAnnouncementData[]
    if (newTag === 'Announcements') {
      setFilteredAnnouncements(allAnnouncements)
    } else {
      setFilteredAnnouncements(
        allAnnouncements.filter(
          (announcementData) =>
            announcementData.announcement.tags &&
            announcementData.announcement.tags.includes('Release Note'),
        ),
      )
    }
  }

  function handleOnFilterChange(event: React.MouseEvent<HTMLElement>, newTag: string | null) {
    if (newTag) {
      setSelectedTag(newTag as TagFilterType)
      filterAnnouncementsByTags(newTag as TagFilterType)
    }
  }

  const toggleButtonStyle: any = {
    padding: '0.4rem 1rem',
  }

  return (
    <StatusView
      defaultMargin
      state={announcementState}
      render={(announcements) => (
        <Stack alignItems='center' height='100%' width='100%'>
          <Stack justifyContent='center' alignItems='center' height='100%' width='75%'>
            <Stack alignSelf='center' textAlign='center' marginTop={4}>
              <Typography variant='h5' alignSelf='center' style={{ marginBottom: '1rem' }}>
                Announcement Center
              </Typography>
              <ToggleButtonGroup
                value={selectedTag}
                onChange={handleOnFilterChange}
                exclusive
                color='primary'
              >
                <ToggleButton value='Announcements' style={toggleButtonStyle}>
                  All Announcements
                </ToggleButton>
                <ToggleButton value='Release Notes' style={toggleButtonStyle}>
                  Release Notes
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Stack gap={2} p={4} width={'100%'}>
              {filteredAnnouncements.length === 0 && (
                <EmptyTableView
                  title={`No ${selectedTag.toLowerCase()} found!`}
                  subtitle='Check back later for updates and announcements.'
                />
              )}
              {filteredAnnouncements.map((annoucement, index) => (
                <AnnouncementCard
                  key={index}
                  announcementData={annoucement as UserAnnouncementData}
                  userID={userState.data?.userID}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    />
  )
}
