import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Button, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { metricManager } from '../../managers/_manager.config'
import Metric, { MetricLocation } from '../../model/admin/Metric'
import Opportunity from '../../model/opportunity/Opportunity'
import OpportunityResults from '../../model/opportunity/OpportunityResults'
import globalState from '../../service/external/GlobalState'
import SelectMetricModal from '../metric/SelectMetricModal'
import StatusView from '../util/StatusView'
import AttributeResultsPanel from './AttributeResultsPanel'
import MetricAttributeResultsModal from './MetricAttributeResultsModal'

export interface OpportunitySegmentationProps {
  opportunity: Opportunity
  results: OpportunityResults
}

export default function OpportunitySegmentation({
  opportunity,
  results,
}: OpportunitySegmentationProps) {
  // Navigation
  const { openModal, closeModal } = useModal()

  // Global state
  const metricsState = useHookstate(globalState.metrics).get()

  // -- Lifecycle
  useEffect(() => {
    metricManager.fetchMetrics()
  }, [])

  // -- Actions
  function onFindMetricClick() {
    const metrics = metricsState.data
    if (!metrics) return

    const id = 'find-metric'
    openModal(
      id,
      <SelectMetricModal
        availableMetrics={
          metrics.filter(
            (metric) =>
              metric.isAvailable(MetricLocation.productExplore) ||
              metric.isAvailable(MetricLocation.memberExplore),
          ) as Metric[]
        }
        onMetricClick={openMetricAttributeResultsModal}
        onClose={() => closeModal(id)}
      />,
    )
  }

  function onSelectMetric(metricID: string) {
    const metric = metricsState.data?.find((m) => m.metricID === metricID) as Metric | undefined
    if (!metric) return
    openMetricAttributeResultsModal(metric)
  }

  function openMetricAttributeResultsModal(metric: Metric) {
    const id = `${metric.metricID}-attribute-results`
    openModal(
      id,
      <MetricAttributeResultsModal
        metric={metric}
        results={results}
        onClose={() => closeModal(id)}
      />,
    )
  }

  // -- UI
  return (
    <StatusView
      state={metricsState}
      defaultMargin
      render={() => (
        <Stack gap={2}>
          <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
            <Stack>
              <Typography variant='h6'>
                {copy.opportunity.view.results.segmentation.title}
              </Typography>
              <Typography color='text.secondary' maxWidth={800}>
                {copy.opportunity.view.results.segmentation.description}
              </Typography>
            </Stack>
            <Button
              variant='contained'
              color='inherit'
              startIcon={<Icon icon={icons.search} />}
              onClick={onFindMetricClick}
              sx={{ width: 300 }}
            >
              Find a metric
            </Button>
          </Stack>
          <Stack gap={2} direction='row'>
            <AttributeResultsPanel
              attributes={results.attributeResults}
              onSelectMetric={onSelectMetric}
              showPagination
              enableColumnActions
            />
          </Stack>
        </Stack>
      )}
    />
  )
}
