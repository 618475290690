import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { IconButton, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import { exploreManager } from '../../managers/_manager.config'
import StatisticData from '../../model/chart/StatisticData'
import Opportunity from '../../model/opportunity/Opportunity'
import { ScreenPath } from '../../navigation'
import StringUtil from '../../util/StringUtil'
import Statistic from '../chart/Statistic'
import FilterChips from '../explore/filter/FilterChips'
import { opportunityViewState } from './OpportunityView'

export interface OpportunityTargetMembersViewProps {
  opportunity: Opportunity
  onNavigateAway: () => void
}

export default function OpportunityTargetMembersView({
  opportunity,
  onNavigateAway,
}: OpportunityTargetMembersViewProps) {
  // Navigation
  const navigate = useNavigate()

  // Parent state
  const status = useHookstate(opportunityViewState.status).get()
  const isLocked = Opportunity.isLocked(status)

  // -- Actions
  function onEditFiltersClick() {
    exploreManager.selectOpportunity(opportunity)
    navigate(
      `${ScreenPath.explore}/${opportunity.propensityTarget.productCategory.productCategoryID}`,
    )
    onNavigateAway()
  }

  // -- UI
  return (
    <Stack gap={2} alignItems='center'>
      <Stack alignItems='center' mb={2}>
        <Typography variant='h5'>{copy.opportunity.modal.steps.targetedMembers.title}</Typography>
        <Typography color='text.secondary'>
          {copy.opportunity.modal.steps.targetedMembers.description}
        </Typography>
      </Stack>
      {opportunity.isManual ? (
        <Typography>Members from manual target list</Typography>
      ) : (
        <Stack direction='row' gap={1} alignItems='center'>
          {!isLocked && (
            <IconButton onClick={onEditFiltersClick}>
              <Icon icon={icons.edit} width={16} />
            </IconButton>
          )}
          <FilterChips filters={opportunity.filters} />
        </Stack>
      )}
      <Stack direction='row' alignItems='center' gap={3}>
        <Statistic
          data={
            new StatisticData({
              title: 'Targeted Members',
              value: opportunity.targetedMemberCount,
            })
          }
        />
        <Statistic
          data={
            new StatisticData({
              title: 'Average Propensity',
              value: opportunity.averagePropensity,
            })
          }
        />
        <Statistic
          data={
            new StatisticData({
              title: 'Estimated Value',
              value: StringUtil.dollarRangeFormat(
                opportunity.predictedGrowthLower,
                opportunity.predictedGrowthUpper,
              ),
            })
          }
        />
      </Stack>
    </Stack>
  )
}
