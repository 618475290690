import { Dialog, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import Client, { ClientType } from '../../../model/admin/Client'
import ColorPickerController from '../../modal/ColorPickerController'
import CustomSelectController from '../../modal/CustomSelectController'
import CustomTextFieldController from '../../modal/CustomTextFieldController'
import FileUploadController from '../../modal/FileUploadController'
import ModalContent from '../../modal/ModalContent'
import ModalFooter from '../../modal/ModalFooter'
import ModalHeader from '../../modal/ModalHeader'

export interface ClientModalProps {
  client: Client
  clients: Client[]
  isCreating?: boolean
  onClose?: () => void
  onSubmit?: (client: Client) => void
}

export interface ClientForm {
  clientID: string
  name: string
  displayName: string
  type: ClientType
  address: string
  city: string
  state: string
  zip: string
  phone: string
  color: string
  logo?: File
}

export default function ClientModal({
  client,
  clients,
  isCreating,
  onClose,
  onSubmit,
}: ClientModalProps) {
  // Form
  const { handleSubmit, control } = useForm<ClientForm>()

  // -- Actions
  const handleFormSubmit = (form: ClientForm) => {
    onSubmit?.(client.formUpdate(form))
    onClose?.()
  }

  // -- UI
  return (
    <Dialog open={true} onClose={onClose} maxWidth='md' fullWidth>
      <ModalHeader title={isCreating ? 'Create Client' : 'Edit Client'} />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack direction='row' gap={2}>
            <Stack gap={2} flex={1}>
              <CustomTextFieldController
                name='clientID'
                control={control}
                defaultValue={client.clientID}
                label='Client ID'
                disabled={!isCreating}
                rules={{
                  validate: (value: string) => {
                    if (!isCreating) return true
                    return !clients.find((client) => client.clientID === value)
                      ? // check that is all lowercase and has no spaces, permit '-' and '_'
                        /^[a-z0-9_-]*$/.test(value)
                        ? true
                        : 'Client ID must be all lowercase and contain no spaces'
                      : 'Client ID already exists'
                  },
                }}
              />
              <CustomTextFieldController
                name='name'
                control={control}
                defaultValue={client.name}
                label='Name'
              />
              <CustomTextFieldController
                name='displayName'
                control={control}
                defaultValue={client.displayName}
                label='Display Name'
              />
              <CustomSelectController
                name='type'
                control={control}
                defaultValue={client.type}
                label='Type'
                options={Object.values(ClientType)}
              />
              <ColorPickerController
                name='color'
                control={control}
                defaultValue={client.color ? client.color : '#17378C'}
                label='Color'
                optional={true}
              />
            </Stack>
            <Stack gap={2} flex={1}>
              <CustomTextFieldController
                name='address'
                control={control}
                defaultValue={client.address}
                label='Corporate Address'
              />
              <CustomTextFieldController
                name='city'
                control={control}
                defaultValue={client.city}
                label='City'
              />
              <CustomTextFieldController
                name='state'
                control={control}
                defaultValue={client.state}
                label='State (2-letter code)'
                rules={{
                  required: 'Please enter a valid State code (2 letters)',
                  pattern: {
                    value: /^[A-Za-z]{2}$/,
                    message: 'Please enter a valid State code (2 letters)',
                  },
                }}
              />

              <CustomTextFieldController
                name='zip'
                control={control}
                defaultValue={client.zip}
                label='ZIP Code'
                rules={{
                  required: 'Please enter a valid ZIP code (5 digits)',
                  pattern: {
                    value: /^[0-9]{5}$/,
                    message: 'Please enter a valid ZIP code (5 digits)',
                  },
                }}
              />
              <FileUploadController
                name='logo'
                control={control}
                label='Logo'
                placeholder={client.logoURL ? 'Replace current logo' : 'Upload logo'}
                optional={true}
              />
            </Stack>
          </Stack>
          <ModalFooter onClose={onClose} />
        </form>
      </ModalContent>
    </Dialog>
  )
}
