import { Icon } from '@iconify/react'
import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material'
import copy from '../../../config/copy.config'
import icons from '../../../config/icons.config'
import DiscreteFilter from '../../../model/filter/DiscreteFilter'
import Filter from '../../../model/filter/Filter'

export interface FilterChipProps {
  filter: Filter
  onInvert?: (metricID: string) => void
  onDelete?: (metricID: string) => void
}

export default function FilterChip({ filter, onInvert, onDelete }: FilterChipProps) {
  return (
    <Chip
      key={filter.metricID}
      label={
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 500,
          }}
        >
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography> {filter.getLabel()}</Typography>
            {onInvert && filter instanceof DiscreteFilter && (
              <Tooltip
                title={
                  !filter.invert ? copy.explore.filter.invertDescription : 'Turn off filter invert'
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    color: filter.invert ? 'primary.main' : 'grey.500',
                    '&:hover': {
                      color: filter.invert ? 'primary.dark' : 'grey.700',
                    },
                  }}
                  onClick={() => onInvert(filter.metricID)}
                >
                  <Icon icon={icons.invert} width={22} />
                </Box>
              </Tooltip>
            )}
          </Stack>
        </Box>
      }
      sx={{
        fontWeight: 'medium',
      }}
      deleteIcon={onDelete ? <Icon icon={icons.close} /> : undefined}
      onDelete={onDelete ? () => onDelete(filter.metricID) : undefined}
    />
  )
}
