import { Box, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

interface VerificationCodeInputProps {
  length: number
  value?: string
  onCompleted?: (value: string) => void
  onChange?: (value: string) => void
  autoFocus?: boolean
  error?: string
}

const VerificationCodeInput: React.FC<VerificationCodeInputProps> = ({
  length = 6,
  value,
  onCompleted,
  onChange,
  autoFocus,
  error,
}) => {
  const initialValues = value ? value.split('') : Array(length).fill('')
  const [values, setValues] = useState<string[]>(initialValues)
  const [prevValues, setPrevValues] = useState<string[]>([])
  const inputs = useRef<(HTMLInputElement | null)[]>([])

  const isFullyFilled = (vals: string[]): boolean => {
    return vals.every((val) => val !== '')
  }

  useEffect(() => {
    const fullValue = values.join('')
    const prevFullValue = prevValues.join('')

    if (fullValue === prevFullValue) return

    if (onChange) {
      onChange(fullValue)
    }
    if (isFullyFilled(values) && onCompleted) {
      onCompleted(fullValue)
    }
    setPrevValues(values)
  }, [values, onChange, onCompleted, length])

  const handleInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = [...values]
    newValues[index] = e.target.value.slice(-1) // Get the last character

    setValues(newValues)

    // If all characters are filled and it's not the last input, move to the next input
    if (e.target.value && index < length - 1) {
      inputs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && index > 0 && !values[index]) {
      // Move focus to the previous field if current is empty and backspace is pressed
      const newValues = [...values]
      newValues[index - 1] = '' // Clear the previous field
      setValues(newValues)
      inputs.current[index - 1]?.focus()
    }
  }

  const handleInputFocus = (index: number) => (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select()
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
      <Box display='flex' justifyContent='center' gap={1}>
        {Array.from({ length }).map((_, index) => (
          <TextField
            key={index}
            variant='standard'
            sx={{
              width: 50,
              '& input': {
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '2rem',
              },
            }}
            value={values[index]}
            onChange={handleInputChange(index)}
            onKeyDown={handleKeyDown(index)}
            onFocus={handleInputFocus(index)}
            inputRef={(ref) => (inputs.current[index] = ref)}
            autoFocus={autoFocus && index === 0}
            error={!!error}
          />
        ))}
      </Box>
      {error && (
        <Typography variant='caption' color='error'>
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default VerificationCodeInput
