import { Box, Stack } from '@mui/material'
import { memo } from 'react'
import ChartType from '../../model/chart/ChartType'
import SummitRateChartData from '../../model/chart/SummitRateChartData'
import StringUtil from '../../util/StringUtil'
import EChart, { Padding } from './Echart'
import Statistic from './Statistic'

export interface SummitRateChartProps {
  data: SummitRateChartData
}

function SummitRateChart({ data }: SummitRateChartProps) {
  return (
    <Stack direction='row' gap={2} height={'100%'}>
      <Statistic data={data.avg} />
      <Box sx={{ width: '100%', mr: 2 }}>
        <EChart
          data={data.line}
          type={ChartType.line}
          valueFormatter={(value: number) => StringUtil.numberFormat(value, 2) + '%'}
          padding={{ left: 40, top: 40 } as Padding}
        />
      </Box>
    </Stack>
  )
}

export default memo(SummitRateChart)
