import ChartData from './ChartData'
import ChartDataType from './ChartDataType'
import StandardChartData from './StandardChartData'

export enum Timeline {
  '1m' = '1m',
  '3m' = '3m',
  '6m' = '6m',
  '12m' = '12m',
  'ytd' = 'ytd',
}

export interface LevelMovement {
  timeline: Timeline
  growth: number
  churn: number
}

export interface LevelMemberCount {
  level: string
  count: number
}

export interface FullLevelData {
  name: string
  memberCount: number
  scoreStart: number
  scoreEnd: number
  avgProductCount: number
  memberCountHistoryData: StandardChartData
  movement?: LevelMovement[]
}

export interface FullLevelsChartDataArgument {
  levels: FullLevelData[]
}

export default class FullLevelsChartData extends ChartData {
  readonly levels: FullLevelData[]

  constructor(arg: FullLevelsChartDataArgument) {
    super({ type: ChartDataType.levelsFull })
    this.levels = arg.levels.map((level) => ({
      ...level,
      memberCountHistoryData: new StandardChartData(level.memberCountHistoryData),
    }))
  }

  isEmpty() {
    return this.levels.length === 0
  }
}
