import moment from 'moment'

export default class DateUtil {
  /**
   * Converts a date Object into a string in the format of 'YYYY-MM-DD HH:MM:SS'
   * for used in a Postgres query. The date is converted to UTC time.
   *
   * Note, if the Date object already have a timezone, it may return a different date due to
   * the conversion to UTC.
   *
   * Note, if this is used in a query, the timestamp column should be truncated to
   * the seconds, since javascript Date object does not have enough precision to
   * match the timestamp column, if the timestamp column has milliseconds.
   *
   * Warning, be careful of timezones. The Date object is in the local timezone, but
   * the Postgres timestamp column maybe in UTC. Verify the data type in the table.
   *
   * @param date A javascript Date object
   */
  static getUTCPostgresString(date: Date): string {
    const pad = (number: number) => (number < 10 ? '0' + number : number)

    let year = date.getUTCFullYear()
    let month = pad(date.getUTCMonth() + 1) // Months are zero-indexed
    let day = pad(date.getUTCDate())
    let hours = pad(date.getUTCHours())
    let minutes = pad(date.getUTCMinutes())
    let seconds = pad(date.getUTCSeconds())

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  // Get string in format of 'YYYY-MM-DD HH:MM:SS'
  static getDateTimeString(date: Date | string) {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  // Get string in format Aug 12, 2019
  static getDateString(date: Date | string, utc: boolean = false) {
    return utc ? moment(date).utc().format('MMM DD, YYYY') : moment(date).format('MMM DD, YYYY')
  }

  // Get string for date ranges
  static getDateRangeString(start: Date, end: Date, utc: boolean = false) {
    // Within the same month (e.g. Aug 12 - 15, 2019)
    if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
      return `${start.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        timeZone: utc ? 'UTC' : undefined,
      })} - ${end.toLocaleDateString('en-US', {
        day: 'numeric',
        timeZone: utc ? 'UTC' : undefined,
      })}, ${end.getFullYear()}`
    }

    // Within the same year (e.g. Aug 12 - Sep 15, 2019)
    if (start.getFullYear() === end.getFullYear()) {
      return `${start.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        timeZone: utc ? 'UTC' : undefined,
      })} - ${end.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: utc ? 'UTC' : undefined,
      })}`
    }

    // Different years (e.g. Aug 12, 2019 - Sep 15, 2020)
    return `${DateUtil.getDateString(start, utc)} - ${DateUtil.getDateString(end, utc)}`
  }

  // Get string in format of 'YYYY-MM-DD'
  static getDateStringISO(date: Date | string) {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }

  // Get the difference in days between two dates
  static getDifferenceInDays(date1: Date, date2: Date) {
    return moment(date2).diff(moment(date1), 'days')
  }

  // Add days to a date
  static addDays(date: Date, days: number) {
    return moment(date).add(days, 'days').toDate()
  }
}
