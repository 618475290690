import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import Markdown from 'react-markdown'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import '../../css/markdown.css'
import { announcementManager } from '../../managers/_manager.config'
import { UserAnnouncementData } from '../../model/announcement/UserAnnouncement'
import DateUtil from '../../util/DateUtil'

type AnnouncementCardProps = {
  announcementData: UserAnnouncementData
  userID: string | undefined
}

export default function AnnouncementCard(props: AnnouncementCardProps) {
  const { showSnackbar } = useModal()
  // -- Theme
  const theme = useTheme()

  // -- Local state
  const [expanded, setExpanded] = useState(false)

  // -- Actions
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleMarkAsRead = (announcementID: string) => {
    if (props.userID) {
      announcementManager.markAnnouncementAsRead(props.userID, announcementID)
      showSnackbar('announcement-marked', 'Announcement marked as read')
    }
  }

  // -- UI
  return (
    <Card
      sx={{
        width: '100%',
      }}
    >
      <Stack
        direction='row'
        height='100%'
        width='100%'
        bgcolor={
          !props.announcementData.isRead
            ? alpha(theme.palette.primary.main, 0.05)
            : theme.palette.background.default
        }
      >
        {!props.announcementData.isRead && <Box width={10} bgcolor={theme.palette.primary.main} />}
        <Stack p={4} pb={1} width='100%'>
          {!props.announcementData.isRead && (
            <Stack
              direction={'row'}
              mt={-1}
              mb={2}
              width='100%'
              justifyContent='space-between'
              alignItems='center'
              borderRadius={1}
            >
              <Stack direction='row' alignItems={'center'} justifyContent={'center'} gap={0.75}>
                <Icon icon={icons.announcement} color={theme.palette.primary.main} />
                <Typography variant='body1' fontWeight={700} color={theme.palette.primary.main}>
                  New Announcement!
                </Typography>
              </Stack>
              <Button
                variant='text'
                onClick={() => handleMarkAsRead(props.announcementData.announcement.announcementID)}
                style={{ marginRight: -10 }}
              >
                Mark as read
              </Button>
            </Stack>
          )}
          <Stack
            direction={'row'}
            mb={2}
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='h6' marginRight={4}>
              {props.announcementData.announcement.title}
            </Typography>
            <Typography variant='body2' alignSelf='flex-start' whiteSpace='nowrap'>
              {DateUtil.getDateString(props.announcementData.announcement.date)}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            mb={2}
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handleExpandClick}
              variant='text'
              style={{ marginLeft: -14, textTransform: 'none' }}
            >
              <Icon
                icon={icons.chevron.up}
                color={theme.palette.primary.main}
                fontSize={28}
                style={{
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                  marginRight: -2,
                }}
              />
              {expanded ? 'Show less' : 'Show more'}
            </Button>
            <Stack direction='row' justifyContent='flex-end' gap={1} alignItems='center' mr={-1}>
              {props.announcementData.announcement.tags.map((tag, index) => (
                <Chip key={index} label={tag} sx={{ m: 0.5 }} />
              ))}
            </Stack>
          </Stack>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <Stack mb={2}>
              <Markdown className='markdown-body'>
                {props.announcementData.announcement.content}
              </Markdown>
            </Stack>
          </Collapse>
        </Stack>
      </Stack>
    </Card>
  )
}
