import DataSource from '../service/domain/DataSource'
import StateService from '../service/domain/StateService'
import { load } from '../util/LoadableHookstateHelpers'

export default class MetricManager {
  readonly dataSource: DataSource
  readonly state: StateService

  constructor(dataSource: DataSource, state: StateService) {
    this.dataSource = dataSource
    this.state = state
  }

  fetchMetrics() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    // Don't fetch if already loaded
    if (this.state.metrics.get().isLoaded()) return

    // Fetch
    const fetchFunction = () => this.dataSource.fetchMetrics(client.clientID)
    return load(this.state.metrics, fetchFunction)
  }
}
