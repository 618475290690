import chroma from 'chroma-js'

export default class ColorUtil {
  static generateColorScheme(baseColor: string, numberOfItems: number): string[] {
    if (!/^#([0-9A-F]{3}){1,2}$/i.test(baseColor)) {
      throw new Error('Invalid hex color code')
    }
    const colorScale = chroma.scale([
      chroma(baseColor).darken(3),
      baseColor,
      chroma(baseColor).brighten(3),
    ])

    return colorScale.colors(numberOfItems)
  }
}
