export interface MetricAttributeResultArgument {
  metricID: string
  metricName: string
  attribute: string
  openCount: number
  targetedCount: number
  openPercent: number
  targetedPercent: number
  performance: number
}

export default class MetricAttributeResult {
  readonly metricID: string
  readonly metricName: string
  readonly attribute: string
  readonly openCount: number
  readonly openPercent: number
  readonly targetedCount: number
  readonly targetedPercent: number
  readonly performance: number

  constructor(arg: MetricAttributeResultArgument) {
    this.metricID = arg.metricID
    this.metricName = arg.metricName
    this.attribute = arg.attribute
    this.openCount = arg.openCount
    this.targetedCount = arg.targetedCount
    this.openPercent = arg.openPercent
    this.targetedPercent = arg.targetedPercent
    this.performance = arg.performance
  }

  getUniqueID() {
    return this.metricID + this.attribute + (Math.random() * 1000).toString()
  }

  getNonOpenCount() {
    return this.targetedCount - this.openCount
  }
}
