import { Icon } from '@iconify/react'
import { Chip, Dialog, IconButton, Stack } from '@mui/material'
import Markdown from 'react-markdown'
import icons from '../../config/icons.config'
import '../../css/markdown.css'
import { UserAnnouncementData } from '../../model/announcement/UserAnnouncement'
import DateUtil from '../../util/DateUtil'
import ModalContent from '../modal/ModalContent'
import ModalHeader from '../modal/ModalHeader'

interface PopUpAnnouncementModalProps {
  onClose?: () => void
  announcementData: UserAnnouncementData
}

export default function PopUpAnnouncementModal({
  onClose,
  announcementData,
}: PopUpAnnouncementModalProps) {
  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby='new-unread-announcement'
      fullWidth
      maxWidth='md'
    >
      <Stack
        direction={'column'}
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        mt={4}
      >
        <Chip label='New Announcement!' sx={{ p: 0.5 }} icon={<Icon icon={icons.announcement} />} />
        <ModalHeader
          title={announcementData.announcement.title}
          description={DateUtil.getDateString(announcementData.announcement.date)}
          center
        />
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 10, top: 10 }}>
          <Icon icon={icons.close} />
        </IconButton>
      </Stack>
      <ModalContent>
        <Stack justifyContent='flex-start' alignItems='flex-start' m={2}>
          <Markdown className='markdown-body'>{announcementData.announcement.content}</Markdown>
        </Stack>
      </ModalContent>
    </Dialog>
  )
}
