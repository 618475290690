import ChartData from './ChartData'
import ChartDataType from './ChartDataType'
import StatisticData, { StatisticDataArgument } from './StatisticData'

export interface MultiStatisticDataArgument {
  stats: StatisticDataArgument[]
}

export default class MultiStatisticData extends ChartData {
  readonly stats: StatisticData[]

  constructor(arg: MultiStatisticDataArgument) {
    super({ type: ChartDataType.multiStatistic })
    this.stats = arg.stats.map((stat) => new StatisticData(stat))
  }

  isEmpty() {
    return this.stats.length === 0
  }
}
