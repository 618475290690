import { Box, Stack, Typography } from '@mui/material'
import copy from '../../config/copy.config'
import Opportunity from '../../model/opportunity/Opportunity'
import OpportunityResults, { PerformanceMetric } from '../../model/opportunity/OpportunityResults'
import DensityMapInputView from '../chart/map/DensityMapInputView'

export interface OpportunityLocationResultsProps {
  opportunity: Opportunity
  results: OpportunityResults
}

export default function OpportunityLocationResults({
  opportunity,
  results,
}: OpportunityLocationResultsProps) {
  return (
    <Stack gap={2}>
      <Stack>
        <Typography variant='h6'>{copy.opportunity.view.results.location.title}</Typography>
        <Typography color='text.secondary' maxWidth={800}>
          {copy.opportunity.view.results.location.description}
        </Typography>
      </Stack>
      <Box sx={{ height: 500 }}>
        <DensityMapInputView
          metrics={[
            {
              id: PerformanceMetric.performance,
              name: PerformanceMetric.performance,
              data: results.locationPerformance.performanceData,
              suffix: '%',
            },
            {
              id: PerformanceMetric.opens,
              name: PerformanceMetric.opens,
              data: results.locationPerformance.openData,
              suffix: '%',
            },
            {
              id: PerformanceMetric.targeted,
              name: PerformanceMetric.targeted,
              data: results.locationPerformance.targetedData,
              suffix: '%',
            },
          ]}
          physicalLocations={results.locationPerformance.physicalLocations}
        />
      </Box>
    </Stack>
  )
}
