import AuthService from '../service/domain/AuthService'
import StateService from '../service/domain/StateService'
import TrackingService from '../service/domain/TrackingService'

export default class TrackingManager {
  tracking: TrackingService
  auth: AuthService
  state: StateService

  constructor(tracking: TrackingService, auth: AuthService, state: StateService) {
    this.tracking = tracking
    this.auth = auth
    this.state = state
  }

  private getBaseInfo(): { userID?: string; clientID?: string } {
    const userID = this.auth.getCurrentUserID()
    const clientID = this.state.currentClient.get()?.clientID
    return { userID, clientID }
  }

  trackPageView(path: string): void {
    const { userID, clientID } = this.getBaseInfo()
    if (!userID) return
    this.tracking.track(userID, 'Page View', { clientID, path })
  }

  trackLogin(): void {
    const { userID, clientID } = this.getBaseInfo()
    if (!userID) return
    this.tracking.track(userID, 'Login', { clientID })
  }

  trackError(error: Error): void {
    const { userID, clientID } = this.getBaseInfo()
    if (!userID) return
    this.tracking.track(userID, 'Error', {
      clientID,
      message: error.message,
      stack: error.stack,
    })
  }
}
