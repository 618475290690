import { RoleForm } from '../../components/admin/modals/RolesModal'
import User from './User'

export interface RoleArgument {
  roleID: string
  name: string
  isActive: boolean
  description: string
  permissionIDs: string[]
}

export default class Role {
  readonly roleID: string
  readonly name: string
  readonly isActive: boolean
  readonly description: string
  readonly permissionIDs: string[]

  constructor(arg: RoleArgument) {
    this.roleID = arg.roleID
    this.name = arg.name
    this.isActive = arg.isActive
    this.description = arg.description
    this.permissionIDs = arg.permissionIDs
  }

  static create() {
    return new Role({
      roleID: '',
      name: '',
      isActive: true,
      description: '',
      permissionIDs: [],
    })
  }
  duplicate() {
    return new Role({
      ...this,
      roleID: '',
      name: `${this.name} (copy)`,
    })
  }
  formUpdate(form: RoleForm) {
    return new Role({
      ...this,
      roleID: form.roleID,
      name: form.name,
      description: form.description,
      permissionIDs: form.permissionIDs,
    })
  }

  hasPermission(permissionID: string) {
    return this.permissionIDs.includes(permissionID)
  }

  canBeCreatedBy(user: User) {
    if (['super-admin', 'filene-user', 'filene-admin'].includes(this.roleID)) {
      if (user.hasPermission('create-super-admin-user')) return true
      return false
    }
    return true
  }
}
