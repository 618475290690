import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Divider, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import { opportunityManager } from '../../managers/_manager.config'
import Opportunity from '../../model/opportunity/Opportunity'
import OpportunityResults from '../../model/opportunity/OpportunityResults'
import globalState from '../../service/external/GlobalState'
import DateUtil from '../../util/DateUtil'
import StatusView from '../util/StatusView'
import OpportunityAISummary from './OpportunityAISummary'
import OpportunityLocationResults from './OpportunityLocationResults'
import OpportunityResultsOverview from './OpportunityResultsOverview'
import OpportunitySegmentation from './OpportunitySegmentationView'

export interface OpportunityResultsViewProps {
  opportunity: Opportunity
}

export default function OpportunityResultsView({ opportunity }: OpportunityResultsViewProps) {
  // -- Global state
  const resultsState = useHookstate(globalState.currentOpportunityResults).get()

  // -- Lifecycle
  useEffect(() => {
    // Refetch results if needed
    const results = resultsState.data
    if (!results || results.opportunityID !== opportunity.opportunityID) {
      opportunityManager.fetchOpportunityResults(opportunity.opportunityID)
    }
  }, [])

  // -- UI
  return (
    <StatusView
      state={resultsState}
      render={(results) => {
        if (!results)
          return (
            <Stack alignItems='center' gap={2} p={5} color='text.secondary'>
              <Icon icon={icons.clock} width={60} />
              <Typography maxWidth={400} textAlign='center'>
                This opportunity doesn't have results yet. Check back when the opportunity has
                started.
              </Typography>
              <Typography>Start Date: {DateUtil.getDateString(opportunity.startDate)}</Typography>
            </Stack>
          )
        return (
          <Stack gap={2} pb={2}>
            <Stack>
              <Typography variant='h5'>{copy.opportunity.view.results.title}</Typography>
              <Typography color='text.secondary' maxWidth={800}>
                {copy.opportunity.view.results.description}
              </Typography>
            </Stack>
            <OpportunityAISummary
              opportunity={opportunity}
              results={results as OpportunityResults} />
            <OpportunityResultsOverview
              opportunity={opportunity}
              results={results as OpportunityResults}
            />
            <Divider sx={{ my: 2 }} />
            <OpportunitySegmentation
              opportunity={opportunity}
              results={results as OpportunityResults}
            />
            <Divider sx={{ mb: 2 }} />
            <OpportunityLocationResults
              opportunity={opportunity}
              results={results as OpportunityResults}
            />
          </Stack>
        )
      }}
      nullRenderer={
        <Stack alignItems='center' gap={2} p={5} color='text.secondary'>
          <Icon icon={icons.clock} width={60} />
          <Typography maxWidth={400} textAlign='center'>
            This opportunity doesn't have results yet. Check back when the opportunity has
            started.
          </Typography>
          <Typography>Start Date: {DateUtil.getDateString(opportunity.startDate)}</Typography>
        </Stack>
      }
    />
  )
}
