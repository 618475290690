import { Icon } from '@iconify/react'
import { Card, IconButton, MenuItem, Select, Stack, TextField } from '@mui/material'
import { useState } from 'react'
import icons from '../../config/icons.config'
import Method from '../../model/admin/Method'
import OpportunityMethod from '../../model/opportunity/OpportunityMethod'
import DateRangePicker from '../util/DateRangePicker'
import AIButton from './AIButton'

export interface OpportunityMethodInputProps {
  method: OpportunityMethod
  methodOptions: Method[]
  onChange: (method: OpportunityMethod) => void
  onRemove: (methodID: string) => void
  generateMarketingMethodContent: (method: OpportunityMethod) => Promise<string>
  minStartDate?: Date
  maxEndDate?: Date
  disabled?: boolean
}

export default function OpportunityMethodInput({
  method,
  methodOptions,
  onChange,
  onRemove,
  minStartDate,
  maxEndDate,
  generateMarketingMethodContent,
  disabled,
}: OpportunityMethodInputProps) {
  // -- Local state
  const [loading, setLoading] = useState(false)

  // -- Actions
  function onMethodChange(methodID: string) {
    const methodOption = methodOptions.find((methodOption) => methodOption.methodID === methodID)
    if (!methodOption) return
    onChange(method.updateMethod(methodOption))
  }

  function onStartDateChange(date: Date | null) {
    if (!date) return
    onChange(method.updateStartDate(date))
  }

  function onEndDateChange(date: Date | null) {
    if (!date) return
    onChange(method.updateEndDate(date))
  }

  function onNotesChange(notes: string) {
    onChange(method.updateNotes(notes))
  }

  function onContentChange(content: string) {
    onChange(method.updateContent(content))
  }

  function onRemoveClick() {
    onRemove(method.opportunityMethodID)
  }

  async function handleGenerateClick() {
    generateAIContent()
    // if (content && content.trim()) {
    //   const id = 'regenerate'
    //   openModal(
    //     id,
    //     <ConfirmModal
    //       title='Override?'
    //       message='If you generate new content, your current content will be overridden. Are you sure you want to generate new content?'
    //       confirmation='Generate'
    //       color='warning'
    //       onClose={() => closeModal(id)}
    //       onConfirm={generateAIContent}
    //     />,
    //   )
    // } else {
    //   await generateAIContent()
    // }
  }

  async function generateAIContent() {
    try {
      setLoading(true)
      const aiResponse = await generateMarketingMethodContent(method)
      onContentChange(aiResponse)
    } catch (error) {
      throw new Error(`Failed to generate content: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  console.log(method)

  // -- UI
  return (
    <Card sx={{ p: 2, boxShadow: 'none' }} variant='outlined'>
      <Stack
        direction='column'
        gap={1}
        sx={{ backgroundColor: 'none', width: '100%', flex: 1, marginTop: 1 }}
      >
        <Stack direction='row' gap={1} alignItems='center' sx={{ width: '100%', display: 'flex' }}>
          <Select
            value={method.opportunityMethodID}
            onChange={(e) => onMethodChange(e.target.value)}
            sx={{ flex: 1 }}
            disabled={disabled}
          >
            <MenuItem value={method.opportunityMethodID}>{method.name}</MenuItem>
            {methodOptions
              .filter((methodOption) => methodOption.methodID !== method.methodID)
              .map((methodOption) => (
                <MenuItem key={methodOption.methodID} value={methodOption.methodID}>
                  {methodOption.name}
                </MenuItem>
              ))}
          </Select>
          <DateRangePicker
            startDate={method.startDate}
            endDate={method.endDate}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            sx={{ flex: 2 }}
            minStartDate={minStartDate}
            maxEndDate={maxEndDate}
            disabled={disabled}
          />
          <TextField
            label='Notes'
            value={method.notes}
            onChange={(e) => onNotesChange(e.target.value)}
            multiline
            sx={{ flex: 2 }}
          />
          <IconButton
            color='error'
            onClick={onRemoveClick}
            sx={{ flexGrow: 0, alignSelf: 'center' }}
          >
            <Icon icon={icons.close} width={25} />
          </IconButton>
        </Stack>

        <Stack direction='row' gap={1} alignItems='center' sx={{ flexGrow: 1 }}>
          <TextField
            label='Content'
            fullWidth
            value={method.content}
            onChange={(e) => onContentChange(e.target.value)}
            multiline
            sx={{ flexGrow: 1 }}
          />
          <AIButton onGenerateClick={handleGenerateClick} loading={loading} />
        </Stack>
      </Stack>
    </Card>
  )
}
