import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { ViewState } from 'react-map-gl'
import DensityMapData, {
  DensityData,
  PhysicalLocation,
  PhysicalLocationType,
} from '../../../model/chart/DensityMapData'
import LocationUtil from '../../../util/LocationUtil'
import DensityMap, { LocationGranularity } from './DensityMap'

export interface DensityMetric {
  id: string
  name: string
  data: DensityData[]
  suffix: string
}

export interface DensityMapInputViewState {
  metricID: string
  granularity: LocationGranularity
  layers: PhysicalLocationType[]
  viewState?: Partial<ViewState>
}

export interface DensityMapInputViewProps {
  metrics: DensityMetric[]
  physicalLocations: PhysicalLocation[]
  state?: DensityMapInputViewState
  onSelectValue?: (name: string, metricID: string) => void
  onStateChange?: (state: DensityMapInputViewState) => void
}

export default function DensityMapInputView({
  metrics,
  physicalLocations,
  state,
  onSelectValue,
  onStateChange,
}: DensityMapInputViewProps) {
  // -- Local state
  const [metricID, setMetricID] = useState<string>(metrics[0].id)
  const [granularity, setGranularity] = useState<LocationGranularity>(
    state?.granularity ?? LocationGranularity.state,
  )
  const [layers, setLayers] = useState<PhysicalLocationType[]>(state?.layers ?? [])
  const [viewState, setViewState] = useState<Partial<ViewState> | undefined>(state?.viewState)

  // -- Lifecycle
  // Update state when it changes
  useEffect(() => {
    onStateChange?.({ metricID, granularity, layers, viewState })
  }, [metricID, granularity, layers, viewState])

  // -- Logic
  const metric = metrics.find((m) => m.id === metricID)!

  // -- Actions
  function onSelectLocationName(name: string) {
    onSelectValue?.(name, granularity)
  }

  function onToggleLayer(layer: PhysicalLocationType) {
    setLayers((prev) => {
      const newLayers = [...prev]
      const index = newLayers.indexOf(layer)
      if (index === -1) {
        newLayers.push(layer)
      } else {
        newLayers.splice(index, 1)
      }

      return newLayers
    })
  }

  // -- UI
  function LocationTypeCheckbox({ type }: { type: PhysicalLocationType }) {
    const count = physicalLocations.filter((loc) => loc.locationType === type).length
    return (
      <FormControlLabel
        control={<Checkbox />}
        label={`${LocationUtil.formatLocationType(type)} (${count})`}
        checked={layers.includes(type)}
        onChange={() => onToggleLayer(type)}
      />
    )
  }

  return (
    <Stack sx={{ width: '100%', height: '100%', gap: 2 }} direction='row'>
      <FormControl>
        <Stack gap={1}>
          {metrics.length > 1 && (
            <Stack>
              <Typography sx={{ fontWeight: 'medium' }}>Metric</Typography>
              <Typography variant='body2'>Highlight regions based on this metric</Typography>
              <RadioGroup value={metricID} onChange={(e) => setMetricID(e.target.value)}>
                {metrics.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={<Radio />}
                    label={option.name}
                  />
                ))}
              </RadioGroup>
            </Stack>
          )}
          <Stack>
            <Typography sx={{ fontWeight: 'medium' }}>Granularity</Typography>
            <Typography variant='body2'>View data at a state, city, or zip code level</Typography>
            <RadioGroup
              value={granularity}
              onChange={(e) => setGranularity(e.target.value as LocationGranularity)}
            >
              <FormControlLabel
                value={LocationGranularity.state}
                control={<Radio />}
                label='State'
              />
              <FormControlLabel value={LocationGranularity.city} control={<Radio />} label='City' />
              <FormControlLabel value={LocationGranularity.zip} control={<Radio />} label='Zip' />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 'medium' }}>Locations</Typography>
            <Typography variant='body2'>Display physical locations on the map</Typography>
            <FormGroup>
              <LocationTypeCheckbox type={PhysicalLocationType.branch} />
              <LocationTypeCheckbox type={PhysicalLocationType.atm} />
            </FormGroup>
          </Stack>
        </Stack>
      </FormControl>
      <DensityMap
        granularity={granularity}
        layers={layers}
        initialViewState={state?.viewState}
        data={
          new DensityMapData({
            densityData: metric.data.filter((d) => d.granularity === granularity),
            physicalLocations: physicalLocations,
          })
        }
        suffix={metric.suffix}
        onSelectValue={onSelectLocationName}
        onViewStateChange={setViewState}
      />
    </Stack>
  )
}
