import { Box, BoxProps, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { cloneElement, useState } from 'react'
import PermissionView from './PermissionView'

export interface MenuAction {
  label: string
  onClick: () => void
  icon?: JSX.Element
  color?: string
  fontWeight?: string
  permissionID?: string
  hide?: boolean
  disabled?: boolean
}

interface MetricConfigOptionsMenuProps extends BoxProps {
  actions?: MenuAction[]
  menuComponent?: JSX.Element // Custom menu component
  children: JSX.Element
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
}

export default function DynamicMenu({
  menuComponent,
  actions,
  children,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  ...rest
}: MetricConfigOptionsMenuProps) {
  // -- Local state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // -- Actions
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box {...rest}>
      <Box onClick={onClick}>{children}</Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
      >
        {menuComponent && cloneElement(menuComponent, { onClose })}
        {actions?.map((option, index) => (
          <PermissionView
            key={index}
            requiredPermissions={option.permissionID ? [option.permissionID] : undefined}
          >
            <MenuItem
              key={index}
              onClick={() => {
                onClose()
                option.onClick()
              }}
              disabled={option.disabled}
              sx={{ minWidth: 150 }}
            >
              <Stack direction='row' gap={1} alignItems='center' color={option.color}>
                {option.icon}
                <Typography fontWeight={option.fontWeight}>{option.label}</Typography>
              </Stack>
            </MenuItem>
          </PermissionView>
        ))}
      </Menu>
    </Box>
  )
}
