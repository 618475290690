import StringUtil from '../../util/StringUtil'
import Filter, { AFilter, FilterType } from './Filter'

export type DiscreteFilterValues = string[]

export interface DiscreteFilterArgument {
  metricID: string
  name: string
  values: DiscreteFilterValues
  invert?: boolean
}

export default class DiscreteFilter implements AFilter {
  readonly metricID: string
  readonly type = FilterType.discrete
  readonly name: string
  readonly values: DiscreteFilterValues
  readonly invert?: boolean

  constructor(arg: DiscreteFilterArgument) {
    this.metricID = arg.metricID
    this.name = arg.name
    this.values = arg.values
    this.invert = arg.invert
  }

  getLabel() {
    return `${this.getNameLabel()}: ${this.invert ? 'Excluding ' : ''} ${this.getValueLabel()}`
  }
  getNameLabel() {
    return StringUtil.capitalize(this.name)
  }
  getValueLabel() {
    return this.values.join(', ')
  }

  getSelectedKeys(keys: string[]) {
    return this.invert
      ? keys.filter((key) => !this.values.includes(key))
      : keys.filter((key) => this.values.includes(key))
  }

  equals(filter?: Filter) {
    if (!filter) return false
    if (!(filter instanceof DiscreteFilter)) return false
    if (this.metricID !== filter.metricID) return false
    if (this.name !== filter.name) return false
    if (this.values.length !== filter.values.length) return false
    if (!this.values.every((value, index) => value === filter.values[index])) return false
    if (this.invert !== filter.invert) return false
    return true
  }

  isEmpty() {
    return this.values.length === 0
  }

  setValues(values: DiscreteFilterValues) {
    return new DiscreteFilter({ ...this, values })
  }

  setInvert(invert: boolean) {
    return new DiscreteFilter({ ...this, invert })
  }

  toggleValues(values: DiscreteFilterValues) {
    const newValues = new Set(this.values)

    for (const value of values) {
      if (newValues.has(value)) {
        newValues.delete(value)
      } else {
        newValues.add(value)
      }
    }

    return new DiscreteFilter({ ...this, values: Array.from(newValues) })
  }

  merge(filter: DiscreteFilter) {
    if (!(filter instanceof DiscreteFilter)) return this

    const newValues = new Set(this.values)
    for (const value of filter.values) {
      newValues.add(value)
    }

    return new DiscreteFilter({ ...this, values: Array.from(newValues) })
  }
}
