import { Stack, Typography } from '@mui/material'

interface ModalHeaderProps {
  icon?: JSX.Element
  title: string
  description?: string
  center?: boolean
}

export default function ModalHeader({ icon, title, description, center }: ModalHeaderProps) {
  return (
    <Stack
      alignItems={center ? 'center' : 'flex-start'}
      textAlign={center ? 'center' : 'left'}
      mx={3}
      mt={3}
    >
      {icon}
      <Typography variant='h5'>{title}</Typography>
      <Typography color='text.secondary' maxWidth={500}>
        {description}
      </Typography>
    </Stack>
  )
}
