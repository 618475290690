import { Stack, StackProps, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import TimeframeChartData from '../../model/chart/TimeframeChartData'
import StatisticNumber from './StatisticNumber'
import StatisticTitle from './StatisticTitle'

export interface TimeframeChartDataProps extends StackProps {
  data: TimeframeChartData
  description?: string
}

const toggleStyles = {
  buttonGroup: {
    flexDirection: 'row',
    my: 1,
  },
  button: {
    fontSize: '0.8rem',
    padding: '0.125rem 0.3125rem',
    margin: '0 0.0625rem',
  },
}

export default function TimeframeStatistic({
  data,
  description,
  ...props
}: TimeframeChartDataProps) {
  const [duration, setDuration] = React.useState('1m')

  const handleDurationChange = (event: any, newDuration: React.SetStateAction<string> | null) => {
    if (newDuration !== null) {
      setDuration(newDuration)
    }
  }

  const value = data.values.find((valueData) => valueData.timeline === duration)?.value

  return (
    <Stack {...props}>
      <StatisticTitle title={data.title} description={description} />
      <ToggleButtonGroup
        size='small'
        value={duration}
        exclusive
        onChange={handleDurationChange}
        aria-label='duration'
        sx={toggleStyles.buttonGroup}
      >
        <ToggleButton sx={toggleStyles.button} value='1m' aria-label='1 month'>
          1m
        </ToggleButton>
        <ToggleButton sx={toggleStyles.button} value='3m' aria-label='3 months'>
          3m
        </ToggleButton>
        <ToggleButton sx={toggleStyles.button} value='12m' aria-label='12 months'>
          12m
        </ToggleButton>
        <ToggleButton sx={toggleStyles.button} value='ytd' aria-label='year to date'>
          YTD
        </ToggleButton>
      </ToggleButtonGroup>
      <StatisticNumber {...data} value={value ?? '-'} />
    </Stack>
  )
}
