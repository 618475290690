import { Stack, StackProps } from '@mui/material'
import MultiStatisticData from '../../model/chart/MultiStatisticData'
import Statistic from './Statistic'

export interface MultiStatisticProps extends StackProps {
  data: MultiStatisticData
}

export default function MultiStatistic({ data, ...props }: MultiStatisticProps) {
  return (
    <Stack {...props}>
      {data.stats.map((stat, index) => (
        <Statistic key={index} data={stat} />
      ))}
    </Stack>
  )
}
