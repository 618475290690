import { useHookstate } from '@hookstate/core'
import { useEffect } from 'react'
import { adminManager } from '../../managers/_manager.config'
import Role from '../../model/admin/Role'
import globalState from '../../service/external/GlobalState'

export interface PermissionViewProps {
  requiredPermissions?: string[] // All of these permissions must be present for the children to be rendered
  activationPermissions?: string[] // If any of these permissions are present, the children will be rendered
  errorView?: JSX.Element
  children: JSX.Element
}

export default function PermissionView({
  requiredPermissions,
  activationPermissions,
  errorView,
  children,
}: PermissionViewProps) {
  // Global state
  const user = useHookstate(globalState.user).get().data
  const roles = useHookstate(globalState.roles).get().data as Role[] | undefined
  const currentClient = useHookstate(globalState.currentClient).get()

  // -- Lifecycle
  useEffect(() => {
    if (!roles && currentClient) adminManager.fetchRoles()
  }, [])

  // -- UI
  if (!user) return null
  const hasPermission =
    (!requiredPermissions ||
      requiredPermissions.every((permissionID) => user.hasPermission(permissionID, roles))) &&
    (!activationPermissions ||
      activationPermissions.some((permissionID) => user.hasPermission(permissionID, roles)))
  if (hasPermission) return children
  else return errorView ?? null
}
