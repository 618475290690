import { Alert, AlertProps, Snackbar, SnackbarOrigin } from '@mui/material'
import { Fragment, ReactNode, createContext, useContext, useState } from 'react'

interface ModalContextType {
  openModal: (id: string, render: ReactNode) => string
  closeModal: (id: string) => void
  showSnackbar: (
    id: string,
    message: string,
    severity?: AlertProps['severity'],
    origin?: SnackbarOrigin,
  ) => void
}

const ModalContext = createContext<ModalContextType>({
  openModal: () => '',
  closeModal: () => {},
  showSnackbar: () => {},
})

interface ModalProviderProps {
  children: ReactNode
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modals, setModals] = useState<{ id: string; render: ReactNode }[]>([])

  const openModal = (id: string, render: ReactNode) => {
    const newModal = { id, render }
    setModals((prevModals) => [...prevModals, newModal])
    return id
  }

  const closeModal = (id: string) => {
    setModals((prevModals) => prevModals.filter((m) => m.id !== id))
  }

  const showSnackbar = (
    id: string,
    message: string,
    severity: AlertProps['severity'] = 'success',
    origin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' },
  ) => {
    const handleClose = async () => {
      closeModal(id)
    }

    openModal(
      id,
      <Snackbar open autoHideDuration={5000} onClose={handleClose} anchorOrigin={origin}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>,
    )
  }

  return (
    <ModalContext.Provider value={{ openModal, closeModal, showSnackbar }}>
      {modals.map((modal) => (
        <Fragment key={modal.id}>{modal.render}</Fragment>
      ))}
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)
