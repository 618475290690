import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Tooltip } from '@mui/material'
import { useState } from 'react'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import Metric from '../../model/admin/Metric'
import globalState from '../../service/external/GlobalState'
import ExportMembersModal from '../opportunity/ExportMembersModal'
import LoadableIconButton from './LoadableExportButton'

export interface ExportMembersButtonProps {
  exportFunction: (metrics: Metric[]) => Promise<string>
  propensityRequired?: boolean
}

export default function ExportMembersButton({
  exportFunction,
  propensityRequired,
}: ExportMembersButtonProps) {
  const currentClient = useHookstate(globalState.currentClient).get()

  // Navigation
  const { showSnackbar, openModal, closeModal } = useModal()

  // -- Local state
  const [exporting, setExporting] = useState(false)

  // -- Actions
  async function onClick() {
    setExporting(true)
    openModal(
      'export-members',
      <ExportMembersModal
        onClose={() => closeModal('export-members')}
        onExport={(metrics) => exportMembers(metrics)}
        defaultMetricIDs={currentClient ? (currentClient.exportFormat as string[]) : []}
        propensityRequired={propensityRequired}
      />,
    )

    setExporting(false)
  }

  async function exportMembers(metrics: Metric[]) {
    setExporting(true)
    try {
      const path = await exportFunction(metrics)
      setExporting(false)
      showSnackbar('member-list-exported', `Member list exported to ${path}`)
    } catch (error) {
      setExporting(false)
      showSnackbar(
        'member-list-export-failed',
        'Failed to export member list. Let us know if this continues',
        'error',
      )
    }
  }

  return (
    <Tooltip title='Export member list to storage, where you can retrieve through SFTP'>
      <div>
        <LoadableIconButton onClick={onClick} loading={exporting} style={{ height: 'fit-content' }}>
          <Icon icon={icons.download} width={20} height={20} />
        </LoadableIconButton>
      </div>
    </Tooltip>
  )
}
