import { useHookstate } from '@hookstate/core'
import { Box, Dialog, DialogContent, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import Role from '../../../model/admin/Role'
import globalState from '../../../service/external/GlobalState'
import CustomChipController from '../../modal/CustomChipController'
import CustomTextFieldController from '../../modal/CustomTextFieldController'
import ModalFooter from '../../modal/ModalFooter'
import ModalHeader from '../../modal/ModalHeader'
import StatusView from '../../util/StatusView'

interface RolesModalProps {
  role: Role
  roles: Role[]
  isCreating?: boolean
  onClose?: () => void
  onSubmit?: (newRole: Role) => void
}

export interface RoleForm {
  roleID: string
  name: string
  description: string
  permissionIDs: string[]
}

export default function RolesModal({
  role,
  roles,
  isCreating,
  onClose,
  onSubmit,
}: RolesModalProps) {
  // Form
  const { handleSubmit, control } = useForm<RoleForm>()

  // Global state
  const permissionsState = useHookstate(globalState.permissions).get()

  // -- Local state
  const handleFormSubmit = (form: RoleForm) => {
    onSubmit?.(role.formUpdate(form))
    onClose?.()
  }

  // -- UI
  return (
    <Dialog open={true} onClose={onClose} maxWidth='sm' fullWidth>
      <ModalHeader title={isCreating ? 'Create Role' : 'Edit Role'} />
      <DialogContent>
        <Box mt={2}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack gap={2}>
              <CustomTextFieldController
                name='roleID'
                control={control}
                defaultValue={role.roleID}
                label='Role ID'
                disabled={!isCreating}
                rules={{
                  validate: (value: string) => {
                    if (!isCreating) return true
                    return !roles.find((role) => role.roleID === value) || 'Role ID already exists'
                  },
                }}
              />
              <CustomTextFieldController
                name='name'
                control={control}
                defaultValue={role.name}
                label='Name'
              />
              <CustomTextFieldController
                name='description'
                control={control}
                defaultValue={role.description}
                label='Description'
              />
              <StatusView
                state={permissionsState}
                render={(permissions) => {
                  return <CustomChipController
                    name='permissionIDs'
                    control={control}
                    defaultValue={role.permissionIDs}
                    label='Permissions'
                    options={permissions.map((permission) => permission.name)}
                  />
                }}
              />
            </Stack>
            <ModalFooter onClose={onClose} />
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
