import { Button, ButtonProps } from '@mui/material'

export interface SelectableButtonProps extends ButtonProps {
  isSelected: boolean
  selectedColor?: 'primary' | 'inherit'
  onClick?: () => void
}

export default function SelectableButton({
  isSelected,
  onClick,
  selectedColor = 'inherit',
  children,
  ...props
}: SelectableButtonProps) {
  return (
    <Button
      {...props}
      variant={isSelected ? 'contained' : 'text'}
      color={isSelected ? selectedColor : 'primary'}
      sx={{
        px: 2,
        ...(props.sx || {}),
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
