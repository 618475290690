import { Icon } from '@iconify/react'
import { Box, Dialog, DialogContent, TextField } from '@mui/material'
import { useState } from 'react'
import MetricsConfig from '../../model/metric/MetricsConfig'
import ModalFooter from '../modal/ModalFooter'
import ModalHeader from '../modal/ModalHeader'

export interface MetricConfigModalProps {
  config: MetricsConfig
  title: string
  isCreating?: boolean
  onSubmit: (config: MetricsConfig) => void
  onClose: () => void
  icon?: string
}

export default function MetricConfigModal({
  config,
  title,
  isCreating,
  onSubmit,
  onClose,
  icon,
}: MetricConfigModalProps) {
  // -- Local state
  const [name, setName] = useState(config.name)

  // -- Actions
  function onNameChange(name: string) {
    setName(name)
  }

  function onCreateButtonClick() {
    onSubmit(config.updateName(name))
    onClose()
  }

  return (
    <Dialog open onClose={onClose} maxWidth='xs' fullWidth>
      <ModalHeader title={title} center icon={icon ? <Icon icon={icon} width={30} /> : undefined} />
      <DialogContent>
        <Box>
          <TextField
            label='Name'
            value={name}
            onChange={(e) => onNameChange(e.target.value)}
            autoFocus
            fullWidth
            required
          />
        </Box>
      </DialogContent>
      <ModalFooter isCreating={isCreating} onConfirm={onCreateButtonClick} onClose={onClose} />
    </Dialog>
  )
}
