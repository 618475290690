import { Icon } from '@iconify/react';
import { useTheme } from '@mui/material';
import mime from 'mime';
import { MuiFileInput } from 'mui-file-input';
import React from 'react';
import { useDropzone } from "react-dropzone";
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import icons from '../../config/icons.config';

interface FileUploadControllerProps {
  name: string
  control: any
  defaultValue?: string | number
  label: string
  rules?: RegisterOptions<FieldValues, string>
  disabled?: boolean
  optional?: boolean
  placeholder?: string
  error?: string // Added error prop
  autoFocus?: boolean
  filetypes?: string[] 
  customAdornment?: (value: File | null) => string
}

const FileUploadController: React.FC<FileUploadControllerProps> = ({
  name,
  control,
  defaultValue,
  label,
  rules = {},
  disabled = false,
  optional = false,
  placeholder = 'Click to upload a file',
  error, // Destructure the error prop
  autoFocus,
  filetypes = ['.jpg', '.jpeg', '.png', '.svg'],
  customAdornment
}) => {
  const theme = useTheme()

  const accept: Record<string, string[]> = filetypes.reduce((acc: Record<string, string[]>, type: string) => {
    const mimeType = mime.getType(type);
    if (mimeType) {
      if (!acc[mimeType]) {
        acc[mimeType] = [];
      }
      acc[mimeType].push(type);
    }
    return acc;
  }, {});

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field , fieldState }) => (
        <FileDropzone
          onDrop={field.onChange}
          accept={accept}
          
        >
          <MuiFileInput
            {...field}
            inputProps={
              field.value ? 
              ({ accept: filetypes?.join(',')} ) : 
              { accept: filetypes?.join(','), text: placeholder}
            }
            InputProps={{
              startAdornment: <Icon icon={icons.upload} color={field.value ? theme.palette.text.primary : theme.palette.text.disabled} width={20} />,
            }}
            label={label}
            margin='none'
            style={{ width: '100%'}}
            sx={{
              "& .MuiFileInput-placeholder": {
                color: `${theme.palette.text.disabled} !important`,
              }
            }}
            clearIconButtonProps={{
              title: "Remove",
              children: <Icon icon={icons.close} fontSize="small" />
            }}
            error={fieldState.invalid || !!error} 
            helperText={error || fieldState.error?.message} 
            disabled={disabled}
            required={!optional}
            autoFocus={autoFocus}
            getSizeText={customAdornment ? customAdornment : undefined}
            />
          </FileDropzone>
      )}
    />
  )
}


function FileDropzone({ onDrop, children, accept, ...props }: { onDrop: (file: File) => void, children: React.ReactNode, accept: {[key: string]: string[]}}) {
  const onDropFiles = React.useCallback(
    (acceptedFiles: any) => {
      // Only call if there are files
      if (acceptedFiles.length > 0) {
        onDrop(acceptedFiles);
      }
    },
    [onDrop],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFiles,
    noClick: true,
    accept: accept,
    ...props,
  });

  return (
    <div style={{width: '100%'}} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}

export default FileUploadController
