import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface CustomSelectControllerProps {
  name: string
  control: any
  defaultValue?: string
  label: string
  options?: string[]
  children?: JSX.Element | JSX.Element[] // Can be used to override options
}

const CustomSelectController: React.FC<CustomSelectControllerProps> = ({
  name,
  control,
  defaultValue,
  label,
  options,
  children,
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field }) => (
      <FormControl fullWidth>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select {...field} labelId={`${name}-label`} label={label} size='medium'>
          {children
            ? children
            : options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    )}
  />
)

export default CustomSelectController
