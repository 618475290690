import { Dialog, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import Method from '../../../model/admin/Method'
import CustomTextFieldController from '../../modal/CustomTextFieldController'
import ModalContent from '../../modal/ModalContent'
import ModalFooter from '../../modal/ModalFooter'
import ModalHeader from '../../modal/ModalHeader'

export interface MethodModalProps {
  method: Method
  methods: Method[]
  isCreating?: boolean
  onClose?: () => void
  onSubmit?: (method: Method) => void
}

export interface MethodForm {
  methodID: string
  name: string
  description: string
  theme: string
}

export default function MethodModal({
  method,
  methods,
  isCreating,
  onClose,
  onSubmit,
}: MethodModalProps) {
  // Form
  const { handleSubmit, control } = useForm<MethodForm>()

  // -- Actions
  const handleFormSubmit = (form: MethodForm) => {
    onSubmit?.(method.formUpdate(form))
    onClose?.()
  }

  // -- UI
  return (
    <Dialog open={true} onClose={onClose} maxWidth='sm' fullWidth>
      <ModalHeader title={isCreating ? 'Create Method' : 'Edit Method'} />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack gap={2}>
            <CustomTextFieldController
              name='methodID'
              control={control}
              defaultValue={method.methodID}
              label='Method ID'
              disabled={!isCreating}
              rules={{
                validate: (value: string) => {
                  if (!isCreating) return true
                  return (
                    !methods.find((method) => method.methodID === value) ||
                    'Method ID already exists'
                  )
                },
              }}
            />
            <CustomTextFieldController
              name='name'
              control={control}
              defaultValue={method.name}
              label='Name'
            />

            <CustomTextFieldController
              name='description'
              control={control}
              defaultValue={method.description}
              label='Description'
              multiline
            />
          </Stack>
          <ModalFooter onClose={onClose} />
        </form>
      </ModalContent>
    </Dialog>
  )
}
