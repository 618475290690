import ChartData from './ChartData'
import ChartDataType from './ChartDataType'

export enum LocationGranularity {
  state = 'state',
  city = 'city',
  zip = 'zipCode',
}

export enum PhysicalLocationType {
  branch = 'branch',
  atm = 'atm',
}

export interface DensityData {
  location: string
  granularity: LocationGranularity
  value: number
}
export interface PhysicalLocation {
  locationName: string
  locationType: PhysicalLocationType
  latitude: number
  longitude: number
}

export interface DensityMapDataArgument {
  densityData: DensityData[]
  physicalLocations: PhysicalLocation[]
}

export default class DensityMapData extends ChartData {
  readonly densityData: DensityData[]
  readonly physicalLocations: PhysicalLocation[]

  constructor(data: DensityMapDataArgument) {
    super({ type: ChartDataType.densityMap })
    this.densityData = data.densityData
    this.physicalLocations = data.physicalLocations
  }

  isEmpty() {
    return this.densityData.length === 0
  }
}
