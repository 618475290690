import { Stack, Typography } from '@mui/material'

export interface EmptyTableViewProps {
  title: string
  subtitle: string
}

export default function EmptyTableView({ title, subtitle }: EmptyTableViewProps) {
  return (
    <Stack sx={{ gap: 0.5, m: 5, alignItems: 'center', textAlign: 'center' }}>
      <Typography variant='h6'>{title}</Typography>
      <Typography variant='body2'>{subtitle}</Typography>
    </Stack>
  )
}
