import { Paper, Typography, debounce, useTheme } from '@mui/material'
import chroma from 'chroma-js'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useEffect, useRef, useState } from 'react'
import Map, { Layer, MapLayerMouseEvent, Source, ViewState } from 'react-map-gl'
import DensityMapData, {
  DensityData,
  PhysicalLocation,
  PhysicalLocationType,
} from '../../../model/chart/DensityMapData'
import LocationUtil from '../../../util/LocationUtil'
import StringUtil from '../../../util/StringUtil'
import LocationPin from './LocationPin'

export enum LocationGranularity {
  state = 'state',
  city = 'city',
  zip = 'zipCode',
}

export type AreaData = {
  name: string
  value: number
}

export type HoverInfo = {
  content: JSX.Element | string
  x: number
  y: number
}

export interface DensityMapProps {
  granularity: LocationGranularity
  layers: PhysicalLocationType[]
  initialViewState?: Partial<ViewState>
  data: DensityMapData
  suffix: string
  onSelectValue: (name: string) => void
  onViewStateChange: (viewState: Partial<ViewState>) => void
}

const layerConfig = {
  [LocationGranularity.state]: {
    id: 'states',
    source: 'states',
    'source-layer': 'states-7utpo8',
    attribute: 'name',
    tileset: 'mapbox://luke20w.1v3aq9ij',
  },
  [LocationGranularity.city]: {
    id: 'cities',
    source: 'cities',
    'source-layer': 'cities-2qn8q5',
    attribute: 'city',
    tileset: 'mapbox://luke20w.6z9lvy2r',
  },
  [LocationGranularity.zip]: {
    id: 'zip-codes',
    source: 'zip-codes',
    'source-layer': 'zip-codes-bm1b26',
    attribute: 'name',
    tileset: 'mapbox://luke20w.cd3sstd3',
  },
}

export default function DensityMap({
  granularity,
  layers,
  initialViewState,
  data,
  suffix,
  onSelectValue,
  onViewStateChange,
}: DensityMapProps) {
  // -- Theme
  const theme = useTheme()

  // -- Local state
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null)
  const [hoveredArea, setHoveredArea] = useState<AreaData | null>(null)
  const [hoveredLocation, setHoveredLocation] = useState<PhysicalLocation | null>(null)
  const [mouseDown, setMouseDown] = useState<boolean>(false)
  const mapContainerRef = useRef<any>(null)
  const mapRef = useRef<any>(null)

  // -- Lifecycle
  // Handle container resize
  useEffect(() => {
    const handleResize = debounce(() => {
      if (mapRef.current) {
        mapRef.current.resize()
      }
    }, 10)

    const observer = new ResizeObserver(handleResize)
    if (mapContainerRef.current) {
      observer.observe(mapContainerRef.current)
    }

    return () => {
      if (mapContainerRef.current) {
        observer.unobserve(mapContainerRef.current)
      }
    }
  }, [])

  // -- Actions
  function onHover(e: MapLayerMouseEvent) {
    const { x, y } = e.point

    const area = getHoveredArea(e)
    setHoveredArea(area)
    const location = hoveredLocation

    if (location) {
      setHoverInfo({
        content: `${location.locationName} ${LocationUtil.formatLocationType(
          location.locationType,
        )}`,
        x,
        y,
      })
    } else if (area) {
      setHoverInfo({
        content: `${LocationUtil.formatAreaName(area.name)}: ${StringUtil.numberFormat(
          area.value,
        )} ${suffix}`,
        x,
        y,
      })
    } else {
      setHoverInfo(null)
    }

    function getHoveredArea(e: MapLayerMouseEvent) {
      const features = e.features
      const hoveredFeature = features && features[0]
      if (!hoveredFeature || !hoveredFeature.properties) return null
      const name = hoveredFeature.properties![layerConfig[granularity].attribute]
      if (!name) return null
      const value = data.densityData.find(
        (d) => d.location.toLowerCase() === name.toLowerCase(),
      )?.value
      return { name, value: value ?? 0 }
    }
  }

  function onClick() {
    const location = data.densityData.find(
      (d) => d.location.toLowerCase() === hoveredArea?.name.toLowerCase(),
    )?.location
    if (location) onSelectValue?.(location)
  }

  // -- UI
  function getLayer(type: LocationGranularity, data: DensityData[]) {
    const values = data.filter((d) => d.value > 0 && d.location !== 'Unknown').map((d) => d.value)
    const min = values.length > 0 ? Math.min(...values) : 0
    const max = values.length > 0 ? Math.max(...values) : 0

    const primary = theme.palette.primary.main
    const scale = chroma
      .scale([chroma(primary).brighten(2), primary, chroma(primary).darken(8)])
      .domain([min, max])

    const colors = data.reduce(
      (acc: any, { location, value }) => {
        if (value === 0) return acc
        acc[location.toLowerCase()] = scale(value).hex()
        acc[location.toUpperCase()] = scale(value).hex()
        return acc
      },
      { default: 'transparent' },
    )

    const layer = {
      id: 'fill-layer',
      type: 'fill',
      source: layerConfig[type].source,
      'source-layer': layerConfig[type]['source-layer'],
      paint: {
        'fill-color': [
          'match',
          ['get', layerConfig[type].attribute],
          ...Object.entries(colors).flat(),
          colors.default,
        ],
        'fill-opacity': 0.5,
        'fill-outline-color': 'rgba(0, 0, 0, 0.5)',
      },
    }

    return layer
  }

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
  if (!MAPBOX_TOKEN) throw new Error('Mapbox access token not found')

  return (
    <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }}>
      <Map
        ref={mapRef}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle='mapbox://styles/mapbox/light-v9'
        style={{ width: '100%', height: '100%', borderRadius: 12 }}
        interactiveLayerIds={['fill-layer']}
        onMouseMove={onHover}
        onClick={onClick}
        onMouseDown={() => {
          setMouseDown(true)
          setHoverInfo(null)
        }}
        onMouseUp={() => setMouseDown(false)}
        onMouseLeave={() => setHoverInfo(null)}
        onMouseOut={() => setHoverInfo(null)}
        initialViewState={
          initialViewState ?? {
            latitude: 39,
            longitude: -97,
            zoom: 3.25,
          }
        }
        onMove={(e) => onViewStateChange?.(e.viewState)}
      >
        <Source key={granularity} type='vector' url={layerConfig[granularity].tileset}>
          <Layer {...(getLayer(granularity, data.densityData) as any)} />
        </Source>
        {hoverInfo && !mouseDown && (
          <Paper
            sx={{
              position: 'absolute',
              top: hoverInfo.y - 10,
              left: hoverInfo.x,
              pointerEvents: 'none',
              p: 1,
              opacity: 0.75,
              transform: 'translate(-50%, -100%)',
            }}
          >
            <Typography>{hoverInfo.content}</Typography>
          </Paper>
        )}
        {data.physicalLocations
          .filter((location) => layers.includes(location.locationType))
          .map((location) => (
            <LocationPin
              key={location.locationName}
              location={location}
              setHoveredLocation={setHoveredLocation}
            />
          ))}
      </Map>
    </div>
  )
}
