import FilterRange, { FilterRangeValueType } from '../model/filter/FilterRange'

export default class RangeUtil {
  static textToRanges(text: string, valueType: FilterRangeValueType = 'number'): FilterRange[] {
    /*
    Transform text into ranges.

    Number examples:
    "1-5, 10-20, 20+" -> [{min: 1, max: 5}, {min: 10, max: 20}, {min: 20}]

    Date examples:
    "May 2024 - June 2025" -> [{min: Date(May 2024), max: Date(June 2025)}]
    "2022+" -> [{min: Date(2022), max: undefined}]
    "01/01/2022 - 01/01/2023" -> [{min: Date(01/01/2022), max: Date(01/01/2023)}]
    */

    const ranges: FilterRange[] = []

    const parts = text.split(',')
    for (let part of parts) {
      part = part.trim()

      // Min only (ex: "20+")
      if (part.includes('+')) {
        const value = part.replace('+', '').trim()
        if (valueType === 'date') {
          ranges.push(
            new FilterRange({ min: this.parseDateValidated(value).getTime(), valueType: 'date' }),
          )
        } else {
          ranges.push(new FilterRange({ min: this.parseFloatValidated(value) }))
        }
      }
      // Normal ranges (ex: "1-5")
      else if (part.includes('-')) {
        const [start, end] = part.split('-').map((val) => val.trim())
        if (valueType === 'date') {
          ranges.push(
            new FilterRange({
              min: this.parseDateValidated(start).getTime(),
              max: this.parseDateValidated(end).getTime(),
              valueType: 'date',
            }),
          )
        } else {
          ranges.push(
            new FilterRange({
              min: this.parseFloatValidated(start),
              max: this.parseFloatValidated(end),
            }),
          )
        }
      } else {
        throw new Error(`Invalid range: ${part}`)
      }
    }

    return ranges
  }

  private static parseFloatValidated(value: string): number {
    const number = parseFloat(value)
    if (isNaN(number)) {
      throw new Error(`Invalid number: ${value}`)
    }
    return number
  }

  private static parseDateValidated(value: string): Date {
    const date = new Date(value)
    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date: ${value}`)
    }
    return date
  }

  static merge(ranges: FilterRange[]): FilterRange[] {
    /*
    Merge the ranges (ex. [{min: 1, max: 5}, {min: 5, max: 7} {min: 10, max: 20}] -> [{min: 1, max: 7}, {min: 10, max: 20}])
    */

    if (ranges.length === 0) return []

    // Sort the ranges by their minimum value
    const sortedRanges = ranges.sort((a, b) => a.min - b.min)

    // Initialize the mergedRanges with the first range
    const mergedRanges: FilterRange[] = [sortedRanges[0]]

    for (const range of sortedRanges.slice(1)) {
      const lastMergedRange = mergedRanges[mergedRanges.length - 1]

      if (!range.label && (lastMergedRange.max ?? Infinity) >= range.min) {
        // If the current range overlaps with the last merged range, we merge them
        mergedRanges[mergedRanges.length - 1] = new FilterRange({
          min: lastMergedRange.min,
          max:
            !lastMergedRange.max || !range.max
              ? undefined
              : Math.max(lastMergedRange.max, range.max),
        })
      } else {
        // If the current range does not overlap with the last merged range, we add it to the list
        mergedRanges.push(range)
      }
    }

    return mergedRanges
  }

  static toggle(existingRanges: FilterRange[], rangesToToggle: FilterRange[]): FilterRange[] {
    let newRanges = [...existingRanges]

    for (const range of rangesToToggle) {
      const isWithin = newRanges.some((r) => range.isWithin(r))

      if (isWithin) {
        // If the range exists, we remove it
        newRanges = newRanges
          .filter((r) => !range.equals(r))
          .flatMap((r) => {
            // If the range to remove is within a larger range, we split the larger range
            if (range.isWithin(r)) {
              const before =
                range.min > r.min ? new FilterRange({ min: r.min, max: range.min }) : null
              const after =
                range.max !== undefined && r.max !== undefined && range.max < r.max
                  ? new FilterRange({ min: range.max, max: r.max })
                  : null
              return [before, after].filter((r): r is FilterRange => r !== null)
            }
            return [r]
          })
      } else {
        // If the range does not exist, we add it
        newRanges.push(range)
      }

      // After each toggle, we merge any overlapping or adjacent ranges
      newRanges = this.merge(newRanges)
    }

    return newRanges
  }
}
