import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import {
  Autocomplete,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { exploreManager, opportunityManager } from '../../managers/_manager.config'
import ExploreOpportunityStatus from '../../model/explore/ExploreOpportunityStatus'
import ProductCategory from '../../model/explore/ProductCategory'
import Opportunity from '../../model/opportunity/Opportunity'
import { ScreenPath } from '../../navigation'
import globalState from '../../service/external/GlobalState'
import ModalContent from '../modal/ModalContent'
import DataTable from '../table/DataTable'
import EmptyTableView from '../table/EmptyTableView'
import StandardCard from '../util/StandardCard'
import StatusView from '../util/StatusView'
import CreateOpportunityModal from './CreateOpportunityModal'
import { opportunityCols } from './OpportunitiesTable'

interface AIOpportunitiesModalProps {
  onClose: () => void
}

export default function AIOpportunitiesModal({ onClose }: AIOpportunitiesModalProps) {
  // -- Navigation
  const navigate = useNavigate()
  const { openModal, closeModal, showSnackbar } = useModal()

  // -- Global state
  const opportunitiesState = useHookstate(globalState.opportunities).get()
  const productCategoriesState = useHookstate(globalState.productCategories).get()

  // -- Local state
  const [selectedProductCategoryID, setSelectedProductCategoryID] = useState<string | null>(null)

  // -- Lifecycle
  useEffect(() => {
    opportunityManager.fetchOpportunities()
    if (!productCategoriesState.isLoaded()) exploreManager.fetchProductCategories()
  }, [])

  // -- Logic
  function getRelevantOpportunities(opportunities: Opportunity[]) {
    return opportunities.filter((opportunity) => {
      return opportunity.isPendingAI()
    })
  }

  function getFilteredOpportunities(opportunities: Opportunity[]) {
    return getRelevantOpportunities(opportunities).filter(
      (opportunity) =>
        selectedProductCategoryID === null ||
        opportunity.propensityTarget.productCategory.productCategoryID ===
          selectedProductCategoryID,
    )
  }

  // -- Actions
  function onAcceptClick(opportunity: Opportunity) {
    const id = 'accept-ai-opportunity'
    openModal(
      id,
      <CreateOpportunityModal
        productCategory={opportunity.propensityTarget.productCategory}
        filters={opportunity.filters}
        onCreate={async (name) => {
          await opportunityManager.acceptAIOpportunity(opportunity.setName(name))
          showSnackbar(
            'ai-opportunity-accepted',
            'AI opportunity accepted. It will now show up in your opportunities',
            'success',
          )
          closeModal(id)
          onClose()
          return opportunity.opportunityID
        }}
        onClose={() => closeModal(id)}
      />,
    )
  }

  async function onRejectClick(opportunity: Opportunity) {
    opportunityManager.rejectAIOpportunity(opportunity)
    showSnackbar('ai-opportunity-rejected', 'AI opportunity rejected')
  }

  function onExploreClick(opportunity: Opportunity) {
    exploreManager.selectOpportunity(opportunity, ExploreOpportunityStatus.viewing)
    navigate(
      `${ScreenPath.explore}/${opportunity.propensityTarget.productCategory.productCategoryID}`,
    )
    onClose()
  }

  // -- UI
  function Actions({ opportunity }: { opportunity: Opportunity }) {
    return (
      <Stack direction='row'>
        <Tooltip title='Accept'>
          <IconButton color='success' onClick={() => onAcceptClick(opportunity)}>
            <Icon icon={icons.check} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Reject'>
          <IconButton color='error' onClick={() => onRejectClick(opportunity)}>
            <Icon icon={icons.close} />
          </IconButton>
        </Tooltip>
      </Stack>
    )
  }

  function getProductCategoryOptions(
    productCategories: ProductCategory[],
  ): { productCategoryID: string | null; label: string }[] {
    return [
      ...productCategories.map((productCategory) => ({
        productCategoryID: productCategory.productCategoryID,
        label: productCategory.name,
      })),
    ]
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth='xl' fullWidth>
      <ModalContent>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 5, right: 5 }}>
          <Icon icon={icons.close} width={16} />
        </IconButton>
        <StatusView
          state={opportunitiesState}
          render={(opportunities) => {
            const relevantOpportunities = getRelevantOpportunities(opportunities as Opportunity[])
            const filteredOpportunities = getFilteredOpportunities(opportunities as Opportunity[])
            if (relevantOpportunities.length === 0) {
              return (
                <EmptyTableView
                  title={copy.opportunity.ai.modal.empty.title}
                  subtitle={copy.opportunity.ai.modal.empty.subtitle}
                />
              )
            }
            return (
              <Stack alignItems='center' gap={5}>
                <Stack alignItems='center'>
                  <Icon icon={icons.ai} width={50} />
                  <Typography variant='h5'>{copy.opportunity.ai.modal.title}</Typography>
                  <Typography color='text.secondary' maxWidth={500} textAlign='center'>
                    {copy.opportunity.ai.modal.description}
                  </Typography>
                </Stack>
                <Stack sx={{ gap: 2, alignItems: 'center' }}>
                  <StatusView
                    state={productCategoriesState}
                    render={(productCategories) => (
                      <Autocomplete
                        sx={{ width: '40%' }}
                        options={getProductCategoryOptions(productCategories as ProductCategory[])}
                        value={
                          getProductCategoryOptions(productCategories as ProductCategory[]).find(
                            (option) => option.productCategoryID === selectedProductCategoryID,
                          ) ?? null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Filter by Product Category'
                            size='small'
                          />
                        )}
                        renderOption={(params, option) => (
                          <Typography {...params}>{option.label}</Typography>
                        )}
                        onChange={(_, option) =>
                          setSelectedProductCategoryID(option?.productCategoryID ?? null)
                        }
                      />
                    )}
                  />
                  <StandardCard sx={{ p: 0 }} variant='outlined'>
                    <DataTable
                      data={filteredOpportunities}
                      columns={[
                        {
                          name: '',
                          width: 120,
                          valueGetter: () => null,
                          render: (o) => <Actions opportunity={o} />,
                        },
                        opportunityCols.productCategory,
                        opportunityCols.propensityType,
                        opportunityCols.filters,
                        opportunityCols.targetedMemberCount,
                        opportunityCols.averagePropensity,
                        opportunityCols.aiScore,
                        {
                          name: '',
                          width: 70,
                          valueGetter: () => null,
                          render: (o) => (
                            <Tooltip title='Explore Opportunity Members'>
                              <IconButton color='primary' onClick={() => onExploreClick(o)}>
                                <Icon icon={icons.explore} />
                              </IconButton>
                            </Tooltip>
                          ),
                        },
                      ]}
                      rowKey={(opportunity) => opportunity.opportunityID}
                      initialSortColumn={opportunityCols.aiScore}
                      initialSortDirection='desc'
                      initialRowsPerPage={50}
                    />
                  </StandardCard>
                </Stack>
              </Stack>
            )
          }}
        />
      </ModalContent>
    </Dialog>
  )
}
