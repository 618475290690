import { Icon } from '@iconify/react'
import { Box } from '@mui/material'
import { Marker } from 'react-map-gl'
import icons from '../../../config/icons.config'
import { PhysicalLocation, PhysicalLocationType } from '../../../model/chart/DensityMapData'

export interface LocationPinProps {
  location: PhysicalLocation
  setHoveredLocation: (location: PhysicalLocation | null) => void
}

function LocationPin({ location, setHoveredLocation }: LocationPinProps) {
  function getPinIcon(locationType: PhysicalLocationType) {
    const sharedProps = {
      style: {
        color: 'white',
      },
      pointerEvents: 'none',
      width: 16,
    }

    switch (locationType) {
      case PhysicalLocationType.branch:
        return <Icon icon={icons.bank} {...sharedProps} />
      case PhysicalLocationType.atm:
        return <Icon icon={icons.atm} {...sharedProps} />
      default:
        return null
    }
  }

  return (
    <Marker key={location.locationName} latitude={location.latitude} longitude={location.longitude}>
      <Box
        sx={{
          background: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          width: 30,
          height: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onMouseEnter={() => setHoveredLocation(location)}
        onMouseLeave={() => setHoveredLocation(null)}
      >
        {getPinIcon(location.locationType)}
      </Box>
    </Marker>
  )
}

export default LocationPin
