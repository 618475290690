import { CircularProgress, IconButton, IconButtonProps } from '@mui/material'

export interface LoadableIconButtonProps extends IconButtonProps {
  loading: boolean
}

export default function LoadableIconButton({ loading, ...props }: LoadableIconButtonProps) {
  return (
    <IconButton {...props} disabled={loading}>
      {loading ? <CircularProgress size={20} /> : props.children}
    </IconButton>
  )
}
