import { Icon } from '@iconify/react'
import { Button, Stack, Tooltip, useTheme } from '@mui/material'
import icons from '../../config/icons.config'
import ChartType from '../../model/chart/ChartType'
import MetricsConfig from '../../model/metric/MetricsConfig'
import MetricConfigButton from '../layout/MetricConfigButton'
import DynamicMenu from '../util/DynamicMenu'
import PermissionView from './PermissionView'

export interface ConfigToolbarProps {
  configs: MetricsConfig[]
  currentConfig: MetricsConfig
  isEditing: boolean
  onAddMetricClick: () => void
  onCreateConfigClick: () => void
  onRenameConfigClick: () => void
  onDeleteConfigClick: () => void
  onSelectConfig: (config: MetricsConfig) => void
  onEditClick: () => void
  editPermissionIDs?: string[]
}

export default function ConfigToolbar({
  configs,
  currentConfig,
  isEditing,
  onAddMetricClick,
  onCreateConfigClick,
  onRenameConfigClick,
  onDeleteConfigClick,
  onSelectConfig,
  onEditClick,
  editPermissionIDs,
}: ConfigToolbarProps) {
  // -- Theme
  const theme = useTheme()

  // -- UI
  return (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' gap={1}>
        <PermissionView requiredPermissions={editPermissionIDs}>
          <Stack
            direction='row'
            borderRadius={1}
            sx={{ background: 'white', border: 1, borderColor: theme.palette.grey[200] }}
            p={0.5}
          >
            <DynamicMenu
              actions={[
                {
                  label: 'Add metric',
                  onClick: onAddMetricClick,
                  icon: <Icon icon={icons.charts[ChartType.bar]} width={15} />,
                },
                {
                  label: 'Rename',
                  onClick: onRenameConfigClick,
                  icon: <Icon icon={icons.edit} width={15} />,
                },
                {
                  label: 'Delete',
                  onClick: onDeleteConfigClick,
                  icon: <Icon icon={icons.close} width={15} />,
                  color: 'error.main',
                  fontWeight: 'bold',
                },
              ]}
            >
              <Tooltip title='Options'>
                <Button>
                  <Icon icon={icons.dots} width={20} />
                </Button>
              </Tooltip>
            </DynamicMenu>
            <Tooltip title={isEditing ? 'Save Layout' : 'Edit Layout'}>
              <Button onClick={onEditClick}>
                {isEditing ? <Icon icon={icons.check} width={20} /> : <Icon icon={icons.edit} />}
              </Button>
            </Tooltip>
          </Stack>
        </PermissionView>
        <Stack direction='row' p={0.5} gap={1}>
          {configs.map((config) => (
            <MetricConfigButton
              key={config.metricsConfigID}
              config={config as MetricsConfig}
              isSelected={config.metricsConfigID === currentConfig.metricsConfigID}
              onSelect={(config) => onSelectConfig(config)}
            />
          ))}
          <PermissionView requiredPermissions={editPermissionIDs}>
            <Tooltip title='Create View'>
              <Button onClick={onCreateConfigClick}>
                <Icon icon={icons.plus} width={20} />
              </Button>
            </Tooltip>
          </PermissionView>
        </Stack>
      </Stack>
    </Stack>
  )
}
