import { Icon } from '@iconify/react'
import { Stack } from '@mui/material'
import icons from '../../config/icons.config'
import PropensityType from '../../model/propensity/PropensityType'
import palette from '../../theme/palette'

export interface PropensityTypeIndicatorProps {
  propensityType: PropensityType
}

export default function PropensityTypeIndicator({ propensityType }: PropensityTypeIndicatorProps) {
  return (
    <Stack direction='row' alignItems='center' color={palette.light[propensityType]} gap={1}>
      <Icon icon={icons[propensityType]} width={20} />
      {/* {StringUtil.capitalize(propensityType)} */}
    </Stack>
  )
}
