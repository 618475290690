import { useHookstate } from '@hookstate/core'
import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ExploreConfigToolbar from '../components/explore/ExploreConfigToolbar'
import ExploreMetricsView from '../components/explore/ExploreMetricsView'
import ProductCategoryHeader from '../components/explore/ProductCategoryHeader'
import StatusView from '../components/util/StatusView'
import { exploreManager } from '../managers/_manager.config'
import { MetricLocation } from '../model/admin/Metric'
import ExploreView from '../model/explore/ExploreView'
import ProductCategory from '../model/explore/ProductCategory'
import MetricsConfig from '../model/metric/MetricsConfig'
import globalState from '../service/external/GlobalState'

export default function ProductExploreScreen() {
  // Navigation
  const { productCategoryID } = useParams()

  // Global state
  const productCategoriesState = useHookstate(globalState.productCategories).get()
  const configsState = useHookstate(globalState.exploreConfigs).get()

  // -- Lifecycle
  useEffect(() => {
    fetch()
  }, [])

  async function fetch() {
    if (!productCategoryID) return
    await exploreManager.fetchConfigs(MetricLocation.productExplore)
    await exploreManager.fetchMetrics()
    await exploreManager.fetchProductCategories()
    exploreManager.selectProductCategory(productCategoryID)
    exploreManager.fetchMetricsData()
  }

  // -- UI
  return (
    <StatusView
      state={productCategoriesState}
      defaultMargin
      render={(productCategories) => {
        const productCategory = productCategories.find(
          (pc) => pc.productCategoryID === productCategoryID,
        ) as ProductCategory | undefined
        if (!productCategory) return null

        return (
          <Stack>
            <ProductCategoryHeader productCategory={productCategory} />
            <StatusView
              defaultMargin
              state={configsState}
              render={(configs) => {
                return <Stack p={2} gap={2}>
                  <ExploreConfigToolbar
                    location={MetricLocation.productExplore}
                    configs={
                      configs.filter((config) =>
                        config.isAvailable(ExploreView.productExplore as any),
                      ) as MetricsConfig[]
                    }
                  />
                  <ExploreMetricsView />
                </Stack>
              }}
            />
          </Stack>
        )
      }}
    />
  )
}
