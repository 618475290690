import { Icon } from '@iconify/react'
import { IconButton, Stack, TextField, useTheme } from '@mui/material'
import { memo, useState } from 'react'
import icons from '../../../config/icons.config'

type ChatInputProps = {
  sendMessage: (message: string) => void
  loading: boolean
}

export const ChatInput = memo(function ChatInput(props: ChatInputProps) {
  const theme = useTheme()
  const [text, setText] = useState('')

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!props.loading && e.key === 'Enter') {
      e.preventDefault()
      props.sendMessage(text)
      setText('')
    }
  }

  return (
    <Stack
      spacing={2}
      direction='row'
      sx={{
        padding: 1,
        paddingLeft: 2,
        paddingRight: 1,
        margin: 3,
        marginTop: 0,
        borderRadius: 1.5,
        alignItems: 'center',
        background: `${theme.palette.background.paper} !important`,
      }}
    >
      <TextField
        key='chat-input'
        variant='standard'
        fullWidth
        autoFocus
        placeholder='Type a message...'
        value={text}
        onChange={(e) => setText(e.target.value)}
        InputProps={{ disableUnderline: true }}
        onKeyDown={handleKeyDown}
      />
      <IconButton
        sx={{ height: 'fit-content', margin: '0 !important' }}
        onClick={() => {
          props.sendMessage(text)
          setText('')
        }}
        disabled={!text.trim() || props.loading}
      >
        <Icon icon={icons.send} width={20} height={20} transform='rotate(90)' />
      </IconButton>
    </Stack>
  )
})
