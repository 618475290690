import { useHookstate } from '@hookstate/core'
import { Box, Chip, Paper, Stack, Typography } from '@mui/material'
import { exploreManager } from '../../managers/_manager.config'
import ChartType from '../../model/chart/ChartType'
import ProductCategory from '../../model/explore/ProductCategory'
import ContinuousFilter from '../../model/filter/ContinuousFilter'
import Filter from '../../model/filter/Filter'
import FilterRange from '../../model/filter/FilterRange'
import PropensityType from '../../model/propensity/PropensityType'
import globalState from '../../service/external/GlobalState'
import palette from '../../theme/palette'
import StringUtil from '../../util/StringUtil'
import EChart from '../chart/Echart'
import StatusView from '../util/StatusView'
import ExploreHeaderStats from './ExploreHeaderStats'
import TargetProductCategoryBar from './TargetProductCategoryBar'
import ExploreFilterView from './filter/ExploreFilterView'
import IntervalSlider from './filter/IntervalSlider'

export interface ProductCategoryHeaderProps {
  productCategory: ProductCategory
}

export default function ProductCategoryHeader({ productCategory }: ProductCategoryHeaderProps) {
  // Global state
  const metricsDataState = useHookstate(globalState.exploreMetricsData).get()
  const propensityType = useHookstate(globalState.explorePropensityType).get() as PropensityType
  const filters = useHookstate(globalState.exploreFilters).get() as Filter[]
  const opportunity = useHookstate(globalState.exploreOpportunity).get()
  const opportunityStatus = useHookstate(globalState.exploreOpportunityStatus).get()

  const propensityFilter = filters.find((f) => f.metricID === 'propensity')
  if (propensityFilter && !(propensityFilter instanceof ContinuousFilter))
    throw new Error('Propensity filter must be a continuous filter')

  // -- Actions
  const handlePropensityIntervalChange = (range: FilterRange) => {
    exploreManager.updateFilterValues('propensity', [range])
  }

  // -- UI
  function OpportunityHeader() {
    if (!opportunity) return null
    return (
      <Chip
        sx={{ mx: 2 }}
        label={`${StringUtil.capitalize(opportunityStatus)} opportunity: ${opportunity.name}`}
      />
    )
  }

  return (
    <Paper sx={{ border: 'none', borderRadius: 0 }}>
      <Stack>
        <OpportunityHeader />
        <Stack direction='row' p={2} pb={3} gap={2}>
          <Stack textAlign='center' gap={1} width={500} height={220}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Box flexGrow={1} marginLeft={2}>
                <Typography variant='subtitle1' fontWeight='bold' textAlign='center'>
                  Propensity for {propensityType}
                </Typography>
              </Box>
            </Stack>
            <Box sx={{ flex: 1 }}>
              <StatusView
                state={metricsDataState.get('propensity')}
                ml={1.75}
                render={(propensityData) => {
                  return (
                    <Box sx={{ height: '100%', position: 'relative' }}>
                      <EChart
                        data={propensityData.chartData}
                        type={ChartType.bar}
                        selectedValues={propensityFilter?.getSelectedKeys(
                          propensityData.getKeys() ?? [],
                        )}
                        color={palette.light[propensityType]}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: 45,
                          left: 50,
                          right: 15,
                        }}
                      >
                        <IntervalSlider
                          min={0}
                          max={Math.max(
                            ...propensityData.getKeys()!.map((k) => parseInt(k.split('-')[1])),
                          )}
                          value={propensityFilter?.ranges[0]}
                          onChange={handlePropensityIntervalChange}
                        />
                      </Box>
                    </Box>
                  )
                }}
              />
            </Box>
          </Stack>
          <Stack width='100%' gap={2}>
            <TargetProductCategoryBar productCategory={productCategory} />
            <ExploreFilterView />
            <ExploreHeaderStats
              metricIDs={[
                'targetedMembers',
                'expectedValueGrowth',
                'avgPropensity',
                'totalPotential',
              ]}
            />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}
