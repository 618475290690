import APIDataSource from '../external/APIDataSource'
import CognitoService from '../external/CognitoService'
import MixPanelService from '../external/MixpanelService'
import MockDataSource from '../external/MockDataSource'
import AuthService from './AuthService'
import DataSource from './DataSource'
import TrackingService from './TrackingService'

export const authService: AuthService = new CognitoService()
await authService.initializeFromLocalStorage()

export const dataSource: DataSource = new APIDataSource()
export const apiDataSource: APIDataSource = new APIDataSource()
export const mockDataSource = new MockDataSource()
export const trackingService: TrackingService = new MixPanelService()
