import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Box, Input, Stack } from '@mui/material'
import { useState } from 'react'
import icons from '../../config/icons.config'
import ProductCategory from '../../model/explore/ProductCategory'
import ProductCategoryRating from '../../model/explore/ProductCategoryRating'
import PropensityType from '../../model/propensity/PropensityType'
import globalState from '../../service/external/GlobalState'
import StatusView from '../util/StatusView'
import ProductCategoryCard from './ProductCategoryCard'

export interface ProductCategoriesDisplayProps {
  showRatings?: boolean
}

export default function ProductCategoriesDisplay({ showRatings }: ProductCategoriesDisplayProps) {
  const productCategoriesState = useHookstate(globalState.productCategories).get()
  const productCategoryRatingsState = useHookstate(globalState.productCategoryRatings).get()

  // -- Local state
  const [searchText, setSearchText] = useState('')

  // -- Logic
  function getDisplayProductCategories(productCategories: ProductCategory[]) {
    const filtered = productCategories.filter((rating) =>
      rating.name.toLowerCase().includes(searchText.toLowerCase()),
    )
    const sorted = filtered.sort((a, b) => a.name.localeCompare(b.name))
    return sorted.map((pc) => ({
      productCategory: pc,
      rating: undefined,
      propensityType: undefined,
    }))
  }

  function getDisplayRatings(ratings: ProductCategoryRating[]) {
    const filtered = ratings.filter((rating) =>
      rating.productCategory.name.toLowerCase().includes(searchText.toLowerCase()),
    )
    const sorted = filtered.sort((a, b) => b.rating - a.rating)
    return sorted.map((r) => ({
      productCategory: r.productCategory,
      rating: r.rating,
      propensityType: r.propensityType,
    }))
  }

  // -- UI
  function renderItems(
    items: { productCategory: ProductCategory; rating?: number; propensityType?: PropensityType }[],
  ) {
    return (
      <Stack gap={2} alignItems='center'>
        <Input
          value={searchText}
          placeholder='Search for a product category'
          onChange={(event) => setSearchText(event.target.value)}
          sx={{
            fontSize: '1rem',
            paddingBottom: 0.5,
            flex: 1,
            width: '100% ',
          }}
          startAdornment={<Icon icon={icons.search} color='grey.300' style={{ marginRight: 10 }} />}
        />
        <Stack direction='row' flexWrap='wrap' gap={'10px'} width='100%'>
          {items.map((item) => (
            <Box sx={{ width: 'calc(20% - 10px)' }}>
              <ProductCategoryCard
                productCategory={item.productCategory}
                rating={item.rating}
                propensityType={item.propensityType}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
    )
  }

  if (showRatings) {
    return (
      <StatusView
        state={productCategoryRatingsState}
        defaultMargin
        render={(ratings) => renderItems(getDisplayRatings(ratings as ProductCategoryRating[]))}
      />
    )
  }

  return (
    <StatusView
      state={productCategoriesState}
      defaultMargin
      render={(productCategories) =>
        renderItems(getDisplayProductCategories(productCategories as ProductCategory[]))
      }
    />
  )
}
