import ChartData from './ChartData'
import ChartDataType from './ChartDataType'

export interface SankeyChartDataArgument {
  points: SankeyDataPoint[]
}

export default class SankeyChartData extends ChartData {
  readonly points: SankeyDataPoint[]

  constructor(arg: SankeyChartDataArgument) {
    super({ type: ChartDataType.sankey })
    this.points = arg.points
  }

  getKeys() {
    return this.points.map((point) => point.source)
  }

  isEmpty() {
    return this.points.length === 0
  }
}

export interface SankeyDataPoint {
  source: string
  target: string
  value: number
}
