import { PhysicalLocationType } from '../model/chart/DensityMapData'
import StringUtil from './StringUtil'

export default class LocationUtil {
  static getStateName(stateCode: string): string | undefined {
    const stateNameMap = {
      AL: 'Alabama',
      AK: 'Alaska',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      FL: 'Florida',
      GA: 'Georgia',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PA: 'Pennsylvania',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
      DC: 'District of Columbia',
      AS: 'American Samoa',
      GU: 'Guam',
      MP: 'Northern Mariana Islands',
      PR: 'Puerto Rico',
      UM: 'United States Minor Outlying Islands',
      VI: 'Virgin Islands',
    }
    if (stateCode in stateNameMap) {
      return stateNameMap[stateCode as keyof typeof stateNameMap]
    }
  }

  static formatAreaName(name: string): string {
    /*
    Format city, state, or zip code to title case

    Ex: 'new york, ny' -> 'New York, NY'
    Ex: 'new york' -> 'New York'
    */

    const capitalized = name
      .split(' ')
      .map((word) => StringUtil.capitalize(word))
      .join(' ')
    const split = capitalized.split(', ')
    if (split.length === 2) {
      return split[0] + ', ' + split[1].toUpperCase()
    }
    return capitalized
  }

  static formatLocationType(type: PhysicalLocationType): string {
    switch (type) {
      case PhysicalLocationType.branch:
        return 'Branch'
      case PhysicalLocationType.atm:
        return 'ATM'
    }
  }
}
