import Metric from '../admin/Metric'
import ChartType from '../chart/ChartType'
import GridItem, { DynamicGridItem } from './GridItem'

export interface MetricConfigArgument {
  metricID: string
  gridX: number
  gridY: number
  gridWidth: number | 'full'
  gridHeight: number
  chart: ChartType
  state?: any
}

export default class MetricConfig {
  metricID: string
  gridX: number
  gridY: number
  gridWidth: number | 'full'
  gridHeight: number
  chart: ChartType
  state?: any

  constructor(arg: MetricConfigArgument) {
    this.metricID = arg.metricID
    this.gridX = arg.gridX
    this.gridY = arg.gridY
    this.gridWidth = arg.gridWidth
    this.gridHeight = arg.gridHeight
    this.chart = arg.chart
    this.state = arg.state
  }

  getGridItem(metrics: Metric[]) {
    const metric = metrics.find((metric) => metric.metricID === this.metricID)
    if (!metric) throw new Error(`No metric found with id: ${this.metricID}`)
    return new GridItem({
      id: this.metricID,
      gridX: this.gridX,
      gridY: this.gridY,
      gridWidth: this.gridWidth,
      gridHeight: this.gridHeight,
      minGridWidth: metric.minGridWidth,
      minGridHeight: metric.minGridHeight,
      isResizable: metric.isResizable,
    })
  }

  updateLayout(item: DynamicGridItem) {
    return new MetricConfig({
      ...this,
      ...item,
    })
  }

  updateChart(chart: ChartType) {
    return new MetricConfig({
      ...this,
      chart,
    })
  }

  updateState(state: any) {
    return new MetricConfig({
      ...this,
      state,
    })
  }
}
