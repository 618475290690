enum ChartType {
  // Base
  bar = 'bar',
  horizontalBar = 'horizontalBar',
  groupedBar = 'groupedBar',
  stackedBar = 'stackedBar',
  donut = 'donut',
  line = 'line',
  multiLine = 'multiLine',
  pie = 'pie',
  sankey = 'sankey',
  treemap = 'treemap',

  // Custom
  levels = 'levels',
  levelsFull = 'levelsFull',
  summitRate = 'summitRate',
  statistic = 'statistic',
  multiStatistic = 'multiStatistic',
  timeframe = 'timeframe',
  densityMap = 'map',
  groupedDensityMap = 'groupedMap',
}

export default ChartType
