import { AdminAnnouncementForm } from '../../components/admin/modals/AdminAnnouncementModal'

export interface AdminAnnouncementArgument {
  readonly announcementID: string
  readonly title: string
  readonly date: Date
  readonly content: string
  readonly isPopup: boolean
  readonly description?: string
  readonly tags: string[]
  readonly recipients: {
    [clientID: string]: string[]
  }
}

export default class AdminAnnouncement {
  readonly announcementID: string
  readonly title: string
  readonly date: Date
  readonly content: string
  readonly isPopup: boolean
  readonly description?: string
  readonly tags: string[]
  readonly recipients: {
    [clientID: string]: string[]
  }

  constructor(arg: AdminAnnouncementArgument) {
    this.announcementID = arg.announcementID
    this.title = arg.title
    this.date = arg.date
    this.content = arg.content
    this.isPopup = arg.isPopup
    this.description = arg.description
    this.tags = arg.tags
    this.recipients = arg.recipients
  }

  static create() {
    return new AdminAnnouncement({
      announcementID: '',
      title: '',
      date: new Date(),
      content: '',
      isPopup: false,
      tags: [],
      recipients: {},
    })
  }

  duplicate() {
    return new AdminAnnouncement({
      ...this,
      announcementID: '',
      title: `${this.title} (copy)`,
    })
  }

  formUpdate(form: AdminAnnouncementForm) {
    return new AdminAnnouncement({
      ...this,
      announcementID: form.announcementID,
      title: form.title,
      content: form.content,
      isPopup: form.isPopup,
      description: form.description,
      tags: form.tags,
      recipients: form.recipients,
    })
  }
}
