import { Button, Dialog, Stack } from '@mui/material'
import ModalHeader from '../modal/ModalHeader'

interface ConfirmModalProps {
  title: string
  message: string
  confirmation: string
  color?: 'error' | 'warning' | 'primary'
  emphasized?: boolean
  onClose?: () => void
  onConfirm?: () => void
}

export default function ConfirmModal({
  title,
  message,
  confirmation,
  color = 'error',
  emphasized,
  onClose,
  onConfirm,
}: ConfirmModalProps) {
  const onConfirmButtonClick = () => {
    onClose?.()
    onConfirm?.()
  }

  return (
    <Dialog open onClose={onClose} maxWidth='xs'>
      <ModalHeader title={title} description={message} center />
      <Stack direction='row' justifyContent='flex-end' gap={2} m={3}>
        <Button
          onClick={onClose}
          data-testid='confirm-modal-cancel-button'
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirmButtonClick}
          color={color}
          variant={emphasized ? 'contained' : 'text'}
          data-testid='confirm-modal-confirm-button'
        >
          {confirmation}
        </Button>
      </Stack>
    </Dialog>
  )
}
