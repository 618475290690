import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

import background from '../../assets/background.jpg'

export default function EmptyLayout() {
  return (
    <Box
      component='main'
      width='100vw'
      minHeight='100vh'
      height='100%'
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Outlet />
    </Box>
  )
}
