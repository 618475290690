import React, { useEffect, useRef } from 'react'
import twemoji from 'twemoji'

export interface FlagEmojiProps {
  countryCode: string
  style?: React.CSSProperties
}

const FlagEmoji: React.FC<FlagEmojiProps> = ({ countryCode, style }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const images = ref.current.getElementsByTagName('img') as HTMLCollectionOf<HTMLImageElement>
      for (let img of images) {
        Object.assign(img.style, style)
      }
    }
  }, [style])

  const flagUnicode = countryCode
    .toUpperCase()
    .replace(/./g, (char: string) => String.fromCodePoint(127397 + char.charCodeAt(0)))

  return (
    <div ref={ref}>
      <span dangerouslySetInnerHTML={{ __html: twemoji.parse(flagUnicode) }} />
    </div>
  )
}

export default FlagEmoji
