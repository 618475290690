import { Dialog, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import Permission from '../../../model/admin/Permission'
import CustomTextFieldController from '../../modal/CustomTextFieldController'
import ModalContent from '../../modal/ModalContent'
import ModalFooter from '../../modal/ModalFooter'
import ModalHeader from '../../modal/ModalHeader'
import CustomSwitchController from '../../modal/SwitchController'

export interface PermissionModalProps {
  permission: Permission
  permissions: Permission[]
  isCreating?: boolean
  onClose?: () => void
  onSubmit?: (permission: Permission) => void
}

export interface PermissionForm {
  permissionID: string
  name: string
  description: string
  isActive: boolean
}

export default function PermissionsModal({
  permission,
  permissions,
  isCreating,
  onClose,
  onSubmit,
}: PermissionModalProps) {
  // Form
  const { handleSubmit, control } = useForm<PermissionForm>({
    defaultValues: permission,
  })

  // -- Actions
  const handleFormSubmit = (form: PermissionForm) => {
    onSubmit?.(permission.formUpdate(form))
    onClose?.()
  }

  // -- UI
  return (
    <Dialog open={true} onClose={onClose} maxWidth='sm' fullWidth>
      <ModalHeader title={isCreating ? 'Create Permission' : 'Edit Permission'} />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack gap={2}>
            <CustomTextFieldController
              name='permissionID'
              control={control}
              label='ID'
              disabled={!isCreating}
              rules={{
                validate: (value: string) => {
                  if (isCreating) {
                    return (
                      !permissions.find((permission) => permission.permissionID === value) ||
                      'Permission ID already exists'
                    )
                  }
                  // if isCreating is true, return true (no validation error)
                  return true
                },
              }}
            />

            <CustomTextFieldController name='name' control={control} label='Name' />
            <CustomTextFieldController name='description' control={control} label='Description' />
            <CustomSwitchController name='isActive' control={control} label='Active' />
          </Stack>
          <ModalFooter onClose={onClose} />
        </form>
      </ModalContent>
    </Dialog>
  )
}
