import { ClientForm } from '../../components/admin/modals/ClientModal'

export enum ClientType {
  creditUnion = 'Credit Union',
  communityBank = 'Community Bank',
}

export interface ClientArgument {
  clientID: string
  name: string
  displayName: string
  type: ClientType
  primaryContactUserID: string
  city: string
  state: string
  zip: string
  phone: string
  address: string
  logoURL?: string
  color?: string
  integrationIDs?: string[]
  updatedDate: Date
  logo?: File
  bucket?: string
  genAICustomMessages?: string
  exportFormat?: string[]
}

export default class Client {
  readonly clientID: string
  readonly name: string
  readonly displayName: string
  readonly type: ClientType
  readonly primaryContactUserID: string
  readonly city: string
  readonly state: string
  readonly zip: string
  readonly phone: string
  readonly address: string
  readonly logoURL?: string
  readonly color?: string
  readonly integrationIDs?: string[]
  readonly updatedDate: Date
  readonly logo?: File
  readonly bucket?: string
  readonly genAICustomMessages?: string
  readonly exportFormat?: string[]

  constructor(arg: ClientArgument) {
    this.clientID = arg.clientID
    this.name = arg.name
    this.displayName = arg.displayName
    this.type = arg.type
    this.primaryContactUserID = arg.primaryContactUserID
    this.city = arg.city
    this.state = arg.state
    this.zip = arg.zip
    this.phone = arg.phone
    this.address = arg.address
    this.logoURL = arg.logoURL
    this.color = arg.color
    this.integrationIDs = arg.integrationIDs
    this.updatedDate = arg.updatedDate
    this.logo = arg.logo
    this.bucket = arg.bucket
    this.genAICustomMessages = arg.genAICustomMessages
    this.exportFormat = arg.exportFormat
  }

  static create() {
    return new Client({
      clientID: '',
      name: '',
      displayName: '',
      type: ClientType.creditUnion,
      primaryContactUserID: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      address: '',
      updatedDate: new Date(),
    })
  }

  duplicate() {
    return new Client({
      ...this,
      clientID: '',
      name: `${this.name} (copy)`,
    })
  }

  formUpdate(form: ClientForm) {
    return new Client({
      ...this,
      ...form,
    })
  }
}
