export default class StringUtil {
  static capitalize(str: string): string {
    try {
      return str.charAt(0).toUpperCase() + str.slice(1)
    } catch (e) {
      return str
    }
  }

  static containsIgnoreCase(str: string, search: string): boolean {
    return str.toLowerCase().includes(search.toLowerCase())
  }

  static numberFormat(number?: number, decimals?: number, estimateLargeNumbers = true): string {
    /*
    Format the number with commas, abbreviate large numbers with k or M, and allow optional decimal places.
    */

    if (number === undefined || number === null) return '-'

    const absNumber = Math.abs(number)

    // In the millions
    if (estimateLargeNumbers && absNumber >= 1000000) {
      const formattedNumber = (number / 1000000).toFixed(decimals === undefined ? 1 : decimals)
      return `${formattedNumber.replace(/\.0+$/, '')}M`
    }

    // In the thousands
    if (estimateLargeNumbers && absNumber >= 1000) {
      const formattedNumber = (number / 1000).toFixed(decimals === undefined ? 1 : decimals)
      return `${formattedNumber.replace(/\.0+$/, '')}k`
    }

    // Less than 1
    if (absNumber < 1 && number !== 0) {
      return number.toLocaleString(undefined, { maximumFractionDigits: decimals ?? 2 })
    }

    // Less than 5
    if (absNumber < 5) {
      return number.toLocaleString(undefined, { maximumFractionDigits: decimals ?? 2 })
    }

    // All other numbers
    return number.toLocaleString(undefined, { maximumFractionDigits: decimals ?? 0 })
  }

  static dollarFormat(number?: number): string {
    if (number === undefined) return '-'
    return '$' + this.numberFormat(number)
  }

  static dollarRangeFormat(lower?: number, upper?: number): string {
    if (lower === undefined || upper === undefined) return '$- - $-'
    return `${this.dollarFormat(lower)} - ${this.dollarFormat(upper)}`
  }

  static camelCaseToTitleCase(str: string): string {
    /*
    Convert camelCase to Title Case
    */

    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
  }

  static shorten(str: string, maxLength: number): string {
    return str.length > maxLength ? str.substring(0, maxLength - 3) + '...' : str
  }
}
