import { ImmutableArray, useHookstate } from '@hookstate/core'
import { Box, Dialog, DialogContent, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { adminManager } from '../../../managers/_manager.config'
import AdminAnnouncement from '../../../model/admin/AdminAnnouncement'
import Client from '../../../model/admin/Client'
import User from '../../../model/admin/User'
import globalState from '../../../service/external/GlobalState'
import CustomChipController from '../../modal/CustomChipController'
import CustomTextFieldController from '../../modal/CustomTextFieldController'
import ModalFooter from '../../modal/ModalFooter'
import ModalHeader from '../../modal/ModalHeader'
import CustomSwitchController from '../../modal/SwitchController'
import StatusView from '../../util/StatusView'

interface AdminAnnouncementsModalProps {
  adminAnnouncement: AdminAnnouncement
  adminAnnouncements: AdminAnnouncement[]
  isCreating?: boolean
  onClose?: () => void
  onSubmit?: (newAnnouncement: AdminAnnouncement, recipientCount: number) => void
}

export interface AdminAnnouncementForm {
  announcementID: string
  title: string
  content: string
  isPopup: boolean
  description?: string
  tags: string[]
  recipients: {
    [clientID: string]: string[]
  }
}

export default function AdminAnnouncementsModal({
  adminAnnouncement,
  adminAnnouncements,
  isCreating,
  onClose,
  onSubmit,
}: AdminAnnouncementsModalProps) {
  // Form
  const { handleSubmit, control } = useForm<AdminAnnouncementForm>()

  // Global state
  const clientsState = useHookstate(globalState.clients).get()

  const [clientUsersMap, setClientUsersMap] = useState<{ [clientID: string]: User[] }>({})
  const [recipients, setRecipients] = useState<{
    [clientID: string]: string[]
  }>({})
  const [dropdownRecipients, setDropdownRecipients] = useState<{
    [clientID: string]: string[]
  }>({})
  const [recipientCount, setRecipientCount] = useState<number>(0)
  const [loading, setLoading] = useState(false)

  // Loads clients
  useEffect(() => {
    if (!clientsState.isLoaded() && !clientsState.isLoading()) {
      setLoading(true)
      adminManager.fetchClients()
      setLoading(false)
    }
  }, [clientsState])

  // Updates the selected recipients when editing an announcment
  useEffect(() => {
    updateDropdownRecipients(adminAnnouncement.recipients)
  }, [])

  // Handle create or edit form submit
  const handleFormSubmit = (form: AdminAnnouncementForm) => {
    form.recipients = recipients
    form.description = form.description || ''
    onSubmit?.(adminAnnouncement.formUpdate(form), recipientCount)
    onClose?.()
  }

  // Helper function to get client from clientID
  const getClient = (clientID: string) => {
    const clients = clientsState.data as ImmutableArray<Client>
    if (!clients) return null
    return clients.find((c) => c.clientID === clientID) as Client
  }

  // Helper function to generate user options and labels for dropdown
  const getUsersOptionsAndLabels = (
    clientID: string,
  ): {
    options: string[]
    displayLabels: string[]
  } => {
    const clientUsers = clientUsersMap[clientID] || []
    return {
      options: clientUsers.map((u) => u.userID),
      displayLabels: clientUsers.map((u) => u.getFullName()),
    }
  }

  const updateDropdownRecipients = async (recipients: Record<string, string[]>) => {
    setLoading(true)
    setDropdownRecipients(recipients)
    await updateRecipientData(recipients)
    setLoading(false)
  }

  const handleOnClientSelectChange = async (selectedClientIDs: string[]) => {
    setLoading(true)
    const tempRecipients = selectedClientIDs.reduce<Record<string, string[]>>((acc, clientID) => {
      acc[clientID] = dropdownRecipients[clientID] || []
      return acc
    }, {})
    await updateDropdownRecipients(tempRecipients)
    setLoading(false)
  }

  const handleOnUserSelectChange = async (userIDs: string[], clientID: string) => {
    updateDropdownRecipients({ ...dropdownRecipients, [clientID]: userIDs })
  }

  const updateClientUsersMap = async (
    clientIDs: string[],
  ): Promise<{
    [clientID: string]: User[]
  }> => {
    setLoading(true)
    const tempClientUsersMap = { ...clientUsersMap }
    for (const clientID of clientIDs) {
      if (!clientUsersMap[clientID] || clientUsersMap[clientID].length === 0) {
        tempClientUsersMap[clientID] = await adminManager.fetchClientUsersFromClientID(
          clientID,
          true,
        )
      }
    }
    setClientUsersMap(tempClientUsersMap)
    setLoading(false)
    return tempClientUsersMap
  }

  const updateRecipientData = async (dropdownRecipients: { [clientID: string]: string[] }) => {
    setLoading(true)
    let tempRecipients = { ...dropdownRecipients }
    if (Object.keys(dropdownRecipients).length === 0) {
      if (!clientsState.data) return
      tempRecipients = clientsState.data.reduce<Record<string, string[]>>((acc, client) => {
        acc[client.clientID] = []
        return acc
      }, {})
    }
    const newClientUserMap = await updateClientUsersMap(Object.keys(tempRecipients))
    for (const clientID in tempRecipients) {
      if (!tempRecipients[clientID] || tempRecipients[clientID].length === 0) {
        tempRecipients[clientID] = newClientUserMap[clientID].map((u) => u.userID)
      }
    }
    updateRecipientCount(tempRecipients)
    setRecipients(tempRecipients)
    setLoading(false)
  }

  const updateRecipientCount = async (recipients: { [clientID: string]: string[] }) => {
    let count = 0
    for (const clientID in recipients) {
      count += recipients[clientID].length
    }
    setRecipientCount(count)
  }

  // -- UI
  return (
    <Dialog open={true} onClose={onClose} maxWidth='md' fullWidth>
      <ModalHeader title={isCreating ? 'Create Announcement' : 'Edit Announcement'} />
      <DialogContent>
        <Box mt={2}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack gap={2}>
              <CustomTextFieldController
                name='announcementID'
                control={control}
                defaultValue={adminAnnouncement.announcementID}
                label='Announcement ID'
                disabled={!isCreating}
                rules={{
                  validate: (value: string) => {
                    if (!isCreating) return true
                    return !adminAnnouncements.find((a) => a.announcementID === value)
                      ? // check that is all lowercase and has no spaces, permit '-' and '_'
                      /^[a-z0-9_-]*$/.test(value)
                        ? true
                        : 'Client ID must be all lowercase and contain no spaces'
                      : 'Client ID already exists'
                  },
                }}
              />
              <Stack direction='row' gap={2}>
                <CustomTextFieldController
                  name='title'
                  control={control}
                  defaultValue={adminAnnouncement.title}
                  label='Title'
                />
                <CustomTextFieldController
                  name='description'
                  control={control}
                  defaultValue={adminAnnouncement.description}
                  label='Description'
                  optional
                />
              </Stack>
              <CustomTextFieldController
                name='content'
                control={control}
                defaultValue={adminAnnouncement.content}
                label='Content'
                multiline
                rows={8}
              />
              <CustomChipController
                name='tags'
                control={control}
                defaultValue={adminAnnouncement.tags}
                label='Tags'
                options={['Release Note']}
              />

              <StatusView
                defaultMargin
                state={clientsState}
                render={(clients) => {
                  return <CustomChipController
                    name='clientRecipients'
                    control={control}
                    defaultValue={Object.keys(adminAnnouncement.recipients)}
                    label='Client Recipients (leave empty for all)'
                    displayLabels={clients.map((c) => c.displayName)}
                    options={clients.map((c) => c.clientID)}
                    onChange={(e) => handleOnClientSelectChange(e.target.value as string[])}
                  />
                }}
              />

              <StatusView
                defaultMargin
                state={clientsState}
                render={(clients) => (
                  <>
                    {Object.keys(dropdownRecipients).map((clientID) => (
                      <CustomChipController
                        key={clientID}
                        name={`recipients.${clientID}`}
                        control={control}
                        defaultValue={adminAnnouncement.recipients[clientID] || []}
                        label={`${getClient(clientID)?.displayName
                          } User Recipients (leave empty for all)`}
                        displayLabels={getUsersOptionsAndLabels(clientID).displayLabels}
                        options={getUsersOptionsAndLabels(clientID).options}
                        onChange={(e) =>
                          handleOnUserSelectChange(e.target.value as string[], clientID)
                        }
                      />
                    ))}
                  </>
                )}
              />

              <CustomSwitchController
                name='isPopup'
                control={control}
                label='Display as popup'
                defaultValue={adminAnnouncement.isPopup}
              />
            </Stack>
            <ModalFooter onClose={onClose} isLoading={loading} />
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
