import { Icon } from '@iconify/react'
import { IconButton, Tooltip, useTheme } from '@mui/material'
import icons from '../../config/icons.config'

interface AIButtonProps {
  onGenerateClick: () => void
  loading: boolean
}

export default function AIButton({ onGenerateClick, loading }: AIButtonProps) {
  const theme = useTheme()
  return (
    <>
      {loading && (
        <style>
          {`
          @keyframes rotate {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
          `}
        </style>
      )}
      <Tooltip title='Generate with AI' placement='top'>
        <IconButton
          onClick={onGenerateClick}
          // style={{
          //   backgroundColor: theme.palette.primary.main,
          // }}
        >
          <Icon
            icon={icons.openai}
            color={theme.palette.primary.main}
            style={{
              animation: loading ? 'rotate 2s linear infinite' : 'none',
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  )
}
