import { Stack, Typography } from '@mui/material'
import { isNumber } from '@mui/x-data-grid/internals'
import StringUtil from '../../util/StringUtil'

export interface StatisticNumberProps {
  value: number | string
  precision?: number
  valuePrefix?: string
  valueSuffix?: string
  size?: 'sm' | 'lg'
}

export default function StatisticNumber({
  value,
  precision,
  valuePrefix,
  valueSuffix,
  size,
}: StatisticNumberProps) {
  function getFontSize() {
    switch (size) {
      case 'sm':
        return 15
      case 'lg':
        return 30
      default:
        return 20
    }
  }

  return (
    <Stack direction='row' alignItems='flex-end' gap={1}>
      <Typography
        fontWeight='bold'
        fontSize={getFontSize()}
        style={{ marginBottom: 0, lineHeight: 'normal' }}
      >
        {valuePrefix}
        {isNumber(value) ? StringUtil.numberFormat(value, precision, false) : value}
      </Typography>
      {valueSuffix && (
        <Typography fontSize={15} color='gray'>
          {valueSuffix}
        </Typography>
      )}
    </Stack>
  )
}
