import PropensityType from '../propensity/PropensityType'
import ProductCategory from './ProductCategory'

export enum EngagementType {
  all = 'All',
  focussed = 'Focussed',
}

export interface PropensityTargetArgument {
  productCategory: ProductCategory
  propensityType: PropensityType
  engagementType: EngagementType
}

export default class PropensityTarget {
  readonly productCategory: ProductCategory
  readonly propensityType: PropensityType
  readonly engagementType: EngagementType

  constructor(arg: PropensityTargetArgument) {
    this.productCategory = arg.productCategory
    this.propensityType = arg.propensityType
    this.engagementType = arg.engagementType
  }
}
