import Metric from '../admin/Metric'
import ChartData, { ChartDataArgument } from '../chart/ChartData'
import ChartDataType from '../chart/ChartDataType'
import DensityMapData, { DensityMapDataArgument } from '../chart/DensityMapData'
import FullLevelsChartData, { FullLevelsChartDataArgument } from '../chart/FullLevelsChartData'
import GroupedChartData, { GroupedChartDataArgument } from '../chart/GroupedChartData'
import GroupedDensityMapData, {
  GroupedDensityMapDataArgument,
} from '../chart/GroupedDensityMapData'
import MultiStatisticData, { MultiStatisticDataArgument } from '../chart/MultiStatisticData'
import SankeyChartData, { SankeyChartDataArgument } from '../chart/SankeyChartData'
import StandardChartData, { StandardChartDataArgument } from '../chart/StandardChartData'
import StatisticData, { StatisticDataArgument } from '../chart/StatisticData'
import SummitRateChartData, { SummitRateChartDataArgument } from '../chart/SummitRateChartData'
import TimeframeChartData, { TimeframeChartDataArguments } from '../chart/TimeframeChartData'

export interface MetricDataArgument {
  metricID: string
  chartData: ChartDataArgument
}

export default class MetricData {
  readonly metricID: string
  readonly chartData: ChartData

  constructor(arg: MetricDataArgument) {
    this.metricID = arg.metricID
    this.chartData = getChartData(arg.chartData.type, arg.chartData)
  }

  getFilterOption(metric: Metric) {
    return metric.getFilterOption(this.getKeys())
  }

  getKeys(): string[] | undefined {
    return (this.chartData as any).getKeys === undefined
      ? undefined
      : (this.chartData as any).getKeys()
  }
}

function getChartData(type: ChartDataType, arg: any) {
  switch (type) {
    case ChartDataType.standard:
      return new StandardChartData(arg as any as StandardChartDataArgument)
    case ChartDataType.grouped:
      return new GroupedChartData(arg as any as GroupedChartDataArgument)
    case ChartDataType.sankey:
      return new SankeyChartData(arg as any as SankeyChartDataArgument)
    case ChartDataType.levels:
      return new FullLevelsChartData(arg as any as FullLevelsChartDataArgument)
    case ChartDataType.levelsFull:
      return new FullLevelsChartData(arg as any as FullLevelsChartDataArgument)
    case ChartDataType.summitRate:
      return new SummitRateChartData(arg as any as SummitRateChartDataArgument)
    case ChartDataType.statistic:
      return new StatisticData(arg as any as StatisticDataArgument)
    case ChartDataType.multiStatistic:
      return new MultiStatisticData(arg as any as MultiStatisticDataArgument)
    case ChartDataType.timeframe:
      return new TimeframeChartData(arg as any as TimeframeChartDataArguments)
    case ChartDataType.densityMap:
      return new DensityMapData(arg as any as DensityMapDataArgument)
    case ChartDataType.groupedDensityMap:
      return new GroupedDensityMapData(arg as any as GroupedDensityMapDataArgument)
  }
}
