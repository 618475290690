import { useHookstate } from '@hookstate/core'
import logo from '../../assets/logo-full.svg'
import globalState from '../../service/external/GlobalState'

export interface LogoProps {
  style?: React.CSSProperties
  onClick?: () => void
  useClientLogo?: boolean
}

export default function Logo({ style, onClick, useClientLogo = true }: LogoProps) {
  const client = useHookstate(globalState.currentClient).get()

  return (
    <img
      src={useClientLogo && client ? `${client.logoURL}?${new Date().getTime()}` : logo}
      alt='Logo'
      style={{ height: 50, cursor: onClick ? 'pointer' : '', ...style }}
      onClick={onClick}
    />
  )
}
