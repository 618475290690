import ContinuousFilter from '../../../model/filter/ContinuousFilter'
import ContinuousFilterOption from '../../../model/filter/ContinuousFilterOption'
import DiscreteFilter from '../../../model/filter/DiscreteFilter'
import DiscreteFilterOption from '../../../model/filter/DiscreteFilterOption'
import Filter, { FilterValues } from '../../../model/filter/Filter'
import FilterOption from '../../../model/filter/FilterOption'
import DateFilterValueMenu from './DateFilterValueMenu'
import DiscreteFilterValueMenu from './DiscreteFilterValueMenu'
import NumberFilterValueMenu from './NumberFilterValueMenu'

export interface FilterValueMenuProps {
  filter?: Filter
  filterOption: FilterOption
  onSubmit: (values: FilterValues, invert?: boolean) => void
  onClose?: () => void
}

export default function FilterValueMenu({
  filter,
  filterOption,
  onSubmit,
  onClose,
}: FilterValueMenuProps) {
  if (filterOption instanceof DiscreteFilterOption) {
    return (
      <DiscreteFilterValueMenu
        filter={filter as DiscreteFilter | undefined}
        filterOption={filterOption}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    )
  }
  if (filterOption instanceof ContinuousFilterOption) {
    switch (filterOption.valueType) {
      case 'number':
        return (
          <NumberFilterValueMenu
            filter={filter as ContinuousFilter | undefined}
            filterOption={filterOption}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        )
      case 'date':
        return (
          <DateFilterValueMenu
            filter={filter as ContinuousFilter | undefined}
            filterOption={filterOption}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        )
    }
  }

  return null
}
