import { v4 as uuid } from 'uuid'

import DateUtil from '../../util/DateUtil'
import ProductCategory from '../explore/ProductCategory'
import PropensityTarget, {
  EngagementType,
  PropensityTargetArgument,
} from '../explore/PropensityTarget'
import Filter, { FilterArgument, FilterFactory } from '../filter/Filter'
import PropensityType from '../propensity/PropensityType'
import OpportunityMethod, { OpportunityMethodArgument } from './OpportunityMethod'

export enum OpportunityWorkflowStep {
  targetedMembers = 'Targeted Members',
  marketingPlan = 'Marketing Plan',
  analytics = 'Analytics',
}

export enum OpportunityStatus {
  draft = 'Draft',
  pending = 'Pending',
  published = 'Published',
  active = 'Active',
  completed = 'Completed',
}

export enum OpportunityAIStatus {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
}

export interface OpportunityArgument {
  opportunityID: string
  name: string
  marketingPlan: string
  status: OpportunityStatus
  propensityTarget: PropensityTargetArgument
  targetedMemberCount: number
  averagePropensity: number
  totalAssetValue?: number
  impactedMembers?: number
  predictedGrowthUpper: number
  predictedGrowthLower: number
  startDate: Date
  endDate: Date
  resultsEndDate: Date
  filters: FilterArgument[]
  methods: OpportunityMethodArgument[]
  AIStatus?: OpportunityAIStatus
  AIScore?: number
  isManual?: boolean
}

export default class Opportunity {
  readonly opportunityID: string
  readonly name: string
  readonly marketingPlan: string
  readonly status: OpportunityStatus
  readonly propensityTarget: PropensityTarget
  readonly targetedMemberCount: number
  readonly averagePropensity: number
  readonly totalAssetValue?: number
  readonly impactedMembers?: number
  readonly predictedGrowthUpper: number
  readonly predictedGrowthLower: number
  readonly startDate: Date
  readonly endDate: Date
  readonly resultsEndDate: Date
  readonly filters: Filter[]
  readonly methods: OpportunityMethod[]
  readonly AIStatus?: OpportunityAIStatus
  readonly AIScore?: number
  readonly isManual?: boolean

  // Creators
  constructor(arg: OpportunityArgument) {
    this.opportunityID = arg.opportunityID
    this.name = arg.name
    this.marketingPlan = arg.marketingPlan
    this.status = arg.status
    this.propensityTarget = new PropensityTarget(arg.propensityTarget)

    this.targetedMemberCount = arg.targetedMemberCount
    this.averagePropensity = arg.averagePropensity
    this.totalAssetValue = arg.totalAssetValue
    this.impactedMembers = arg.impactedMembers

    this.predictedGrowthUpper = arg.predictedGrowthUpper
    this.predictedGrowthLower = arg.predictedGrowthLower

    this.startDate = new Date(arg.startDate)
    this.endDate = new Date(arg.endDate)
    this.resultsEndDate = new Date(arg.resultsEndDate)

    this.filters = arg.filters.map((filter) => FilterFactory.create(filter))
    this.methods = arg.methods.map((method) => new OpportunityMethod(method))

    this.AIStatus = arg.AIStatus
    this.AIScore = arg.AIScore
    this.isManual = arg.isManual
  }

  static create(
    name: string,
    productCategory: ProductCategory,
    propensityType: PropensityType = PropensityType.growth,
    engagementType: EngagementType = EngagementType.all,
    filters: Filter[] = [],
    isManual: boolean = false,
  ) {
    return new Opportunity({
      opportunityID: uuid(),
      name: name,
      marketingPlan: '',
      status: OpportunityStatus.draft,
      propensityTarget: {
        propensityType: propensityType,
        productCategory: productCategory,
        engagementType: engagementType,
      },

      // These will be calculated and set on the backend when the opportunity is saved
      targetedMemberCount: 0,
      averagePropensity: 0,
      predictedGrowthUpper: 0,
      predictedGrowthLower: 0,

      startDate: new Date(),
      endDate: new Date(),
      resultsEndDate: DateUtil.addDays(new Date(), 10),

      filters: filters,
      methods: [],
      isManual: isManual,
    })
  }

  static validateName(name: string, opportunities: Opportunity[], originalName?: string) {
    if (!name) return 'Opportunity name is required'
    if (
      opportunities.some(
        (opportunity) => opportunity.name === name && opportunity.name !== originalName,
      )
    )
      return 'Opportunity name must be unique'
    return
  }

  static getActiveStep(status: OpportunityStatus) {
    switch (status) {
      case OpportunityStatus.draft:
        return 0
      case OpportunityStatus.pending:
        return 1
      case OpportunityStatus.published:
      case OpportunityStatus.active:
      case OpportunityStatus.completed:
        return 3
    }
  }

  static isLocked(status: OpportunityStatus) {
    return [
      OpportunityStatus.published,
      OpportunityStatus.active,
      OpportunityStatus.completed,
    ].includes(status)
  }

  isPendingAI() {
    return this.AIStatus === OpportunityAIStatus.pending
  }

  isUsable() {
    return !this.AIStatus || this.AIStatus === OpportunityAIStatus.accepted
  }

  getActiveStep() {
    return Opportunity.getActiveStep(this.status)
  }

  getDuration() {
    // Returns the duration in days
    return Math.round((this.endDate.getTime() - this.startDate.getTime()) / (1000 * 60 * 60 * 24))
  }

  setName(name: string) {
    return new Opportunity({
      ...this,
      name: name,
    })
  }

  acceptAI() {
    return new Opportunity({
      ...this,
      AIStatus: OpportunityAIStatus.accepted,
    })
  }

  rejectAI() {
    return new Opportunity({
      ...this,
      AIStatus: OpportunityAIStatus.rejected,
    })
  }

  update(update: Partial<Opportunity>) {
    return new Opportunity({
      ...this,
      ...update,
    })
  }

  duplicate() {
    return new Opportunity({
      ...this,
      opportunityID: uuid(),
      name: `${this.name} (copy)`,
    })
  }
}
