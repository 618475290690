import { MuiColorInput, matchIsValidColor } from 'mui-color-input'
import React from 'react'
import { Controller } from 'react-hook-form'

interface ColorPickerControllerProps {
  name: string
  control: any
  defaultValue?: string | number
  label: string
  disabled?: boolean
  optional?: boolean
  error?: string
  autoFocus?: boolean
  rows?: number
}

const CustomTextFieldController: React.FC<ColorPickerControllerProps> = ({
  name,
  control,
  defaultValue,
  label,
  disabled = false,
  optional = false,
  error,
  autoFocus,
  rows,
}) => {

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ validate: matchIsValidColor }}
      render={({ field , fieldState }) => (
        <MuiColorInput {...field} label={label} error={fieldState.invalid || !!error} format="hex"  />
      )}
    />
  )
}

export default CustomTextFieldController
