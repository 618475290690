import { Icon } from '@iconify/react'
import { Button, Stack, Step, StepLabel, Stepper, Typography, useTheme } from '@mui/material'
import icons from '../../config/icons.config'
import { opportunityManager } from '../../managers/_manager.config'
import Opportunity from '../../model/opportunity/Opportunity'
import palette from '../../theme/palette'
import StringUtil from '../../util/StringUtil'
import BackButton from '../util/BackButton'
import ExportMembersButton from '../util/ExportMembersButton'
import PermissionView from '../util/PermissionView'
import { opportunityViewState } from './OpportunityView'
import StatusChip from './StatusChip'

export interface OpportunityHeaderProps {
  opportunity: Opportunity
  canSave: boolean
  steps: string[]
  activeStep: number
  onSave: () => void
  onStepChange: (index: number) => void
  onNameChange: (name: string) => void
  validateName: (name: string) => string | undefined
  onBackButtonClick: () => void
}

export default function OpportunityHeader({
  opportunity,
  canSave,
  steps,
  activeStep,
  onSave,
  onStepChange,
  onBackButtonClick,
}: OpportunityHeaderProps) {
  // -- Theme
  const theme = useTheme()

  // -- UI
  function Steps() {
    return (
      <Stepper nonLinear activeStep={activeStep} sx={{ width: '100%', mb: 2 }}>
        {steps.map((label, index) => {
          const disabled = Opportunity.getActiveStep(opportunityViewState.status.get()) < index
          return (
            <Step
              key={label}
              completed={activeStep > index}
              disabled={disabled}
              onClick={() => !disabled && onStepChange(index)}
            >
              <StepLabel
                sx={{
                  cursor: 'pointer',
                  '& .MuiStepLabel-label': {
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    )
  }

  return (
    <Stack
      sx={{
        background: 'white',
        position: 'fixed',
        width: '100%',
        zIndex: 10,
        borderBottom: 1,
        borderBottomColor: theme.palette.grey[200],
      }}
      p={2}
    >
      <Stack direction='row' justifyContent='space-between' mb={2} gap={5}>
        <Stack direction='row' alignItems='center' gap={1} flex={1}>
          <BackButton onClick={onBackButtonClick} />
          <Typography variant='h5'>{opportunity.name}</Typography>
        </Stack>
        <Stack direction='row' gap={5} alignItems='center'>
          <Stack direction='row' gap={2} alignItems='center'>
            <Stack
              direction='row'
              gap={0.5}
              color={palette.light[opportunity.propensityTarget.propensityType]}
            >
              <Icon icon={icons[opportunity.propensityTarget.propensityType]} width={25} />
              <Typography fontSize={16}>
                {opportunity.propensityTarget.productCategory.name + ' '}
                {StringUtil.capitalize(opportunity.propensityTarget.propensityType)}
              </Typography>
            </Stack>
            <StatusChip status={opportunityViewState.status.get()} />
            <PermissionView requiredPermissions={['export-opportunity']}>
              <ExportMembersButton
                exportFunction={(metrics) => opportunityManager.exportMembers(opportunity, metrics)}
                propensityRequired={true}
              />
            </PermissionView>
            <Button variant='contained' disabled={!canSave} onClick={onSave}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Steps />
    </Stack>
  )
}
