import {
  AuthenticationDetails,
  ChallengeName,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import AuthService from '../domain/AuthService'

export default class CognitoService implements AuthService {
  private userPool: CognitoUserPool
  private user: CognitoUser | null
  private session: CognitoUserSession | null

  private loginMfaChallengeName: ChallengeName | null

  private EXPIRATION_TIME_BUFFER = 60 // refresh token 1 minute before it expires

  constructor() {
    const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID

    if (!userPoolId) throw new Error('Missing user pool id from env')
    if (!clientId) throw new Error('Missing client id from env')

    this.userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    })

    this.user = null
    this.session = null
    this.loginMfaChallengeName = null

    this.initializeFromLocalStorage()
  }

  getAuthToken(): string | null {
    return this.session?.getIdToken().getJwtToken() ?? null
  }

  getAccessTokenExpiryTime(): number | null {
    return this.session?.getAccessToken().getExpiration() ?? null
  }

  isAuthenticated(): boolean {
    return !!this.session
  }

  getCurrentUserID() {
    return this.user?.getUsername()
  }

  async getAuthTokenAutoRefresh(): Promise<string | null> {
    // If the user was never logged in, return null
    if (!this.session) {
      console.log('No session found')
      return null
    }

    if (
      this.session.getAccessToken().getExpiration() - Math.floor(new Date().getTime() / 1000) >
      this.EXPIRATION_TIME_BUFFER
    ) {
      console.log('Access token still valid, returning it')
      return this.session.getIdToken().getJwtToken()
    }

    // Attempt to refresh the session, if it fails, logout the user and return null
    try {
      console.log('Refreshing session')
      await this.refreshSession()
    } catch (err) {
      console.log('Error refreshing session. Logging out user', err)
      await this.logout()
      return null
    }

    // Return the new access token
    if (!this.session) {
      console.log('No session found after refreshing')
      await this.logout()
      return null
    }
    console.log('Session refreshed, returning new access token')
    return this.session.getIdToken().getJwtToken()
  }

  async refreshSession(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.session) {
        return reject(new Error(`Can't refresh session. No current session available`))
      }

      const token = this.session.getRefreshToken().getToken()
      if (!token) {
        return reject(
          new Error(
            `Can't refresh session. Not able to get refresh token from the current session`,
          ),
        )
      }

      if (!this.user) {
        return reject(new Error(`Can't refresh session. No current user`))
      }

      this.user.refreshSession(new CognitoRefreshToken({ RefreshToken: token }), (err, session) => {
        if (err) {
          return reject(err)
        } else {
          this.session = session // Update the session with the new one
          return resolve()
        }
      })
    })

    // return new Promise((resolve, reject) => {
    //   const token = this.session?.getRefreshToken().getToken()
    //   if (!token)
    //     throw new Error(
    //       `Can't refresh session. Not able to get refresh token from the current session`,
    //     )
    //   if (!this.user) throw new Error(`Can't refresh session. No current user`)
    //   this.user.refreshSession(new CognitoRefreshToken({ RefreshToken: token }), (err) => {
    //     if (err) reject(err)
    //     else resolve()
    //   })
    // })
  }

  async login(email: string, password: string): Promise<void> {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          this.user = cognitoUser
          resolve()
        },
        onFailure: (err) => {
          reject(err)
        },
        totpRequired: (challengeName, challengeParameters) => {
          this.loginMfaChallengeName = challengeName
          this.user = cognitoUser
          resolve()
        },
        mfaRequired: (challengeName, challengeParameters) => {
          this.loginMfaChallengeName = challengeName
          this.user = cognitoUser
          resolve()
        },
        newPasswordRequired: () => {
          this.user = cognitoUser
          reject('InitialPasswordRequired')
        },
      })
    })
  }

  async logout(): Promise<void> {
    if (this.user) {
      this.user.signOut()
      this.user = null
      this.session = null
    }
  }

  async checkPassword(email: string, password: string): Promise<boolean> {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })

    return new Promise((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => resolve(true),
        onFailure: () => resolve(false),
      })
    })
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.user) {
        reject(new Error('No user logged in'))
        return
      }
      this.user.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result as void)
        }
      })
    })
  }

  async sendResetPasswordCode(email: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => resolve(),
        onFailure: (err) => {
          console.log(err)
          reject(err)
        },
      })
    })
  }

  async resetPassword(email: string, code: string, newPassword: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: () => resolve(),
        onFailure: (err) => reject(err),
      })
    })
  }

  async setInitialPassword(password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.user) throw new Error('No user available to set new password for')

      this.user.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: (session) => {
            resolve()
          },
          mfaRequired: () => {
            resolve()
          },
          onFailure: (err) => {
            console.log(err)
            reject(err)
          },
        },
      )
    })
  }

  async verifyMFACode(code: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.user) {
        reject(new Error('No user logged in'))
        return
      }

      this.user.sendMFACode(
        code,
        {
          onSuccess: (session, useConfirmationNecessary) => {
            // Save refresh token in local storage
            localStorage.setItem('refreshToken', session.getRefreshToken().getToken())
            this.session = session
            resolve()
          },
          onFailure: (err) => {
            reject(err)
          },
        },
        this.loginMfaChallengeName as ChallengeName,
      )
    })
  }

  public async restoreSessionFromRefreshToken(
    username: string,
    refreshToken: string,
  ): Promise<void> {
    const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken })
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(cognitoRefreshToken, (err, session) => {
        if (err) {
          reject(err)
        } else {
          console.log('Session restored from refresh token')
          this.user = cognitoUser
          this.session = session
          resolve()
        }
      })
    })
  }

  async initializeFromLocalStorage() {
    // Check if test refresh token is stored in local storage
    const refreshToken = localStorage.getItem('refreshToken')
    const username = localStorage.getItem('username')
    if (refreshToken && username) {
      await this.restoreSessionFromRefreshToken(username, refreshToken)
      return
    }

    // Check if user is already logged in
    const user = this.userPool.getCurrentUser()
    user?.getSession((err: Error, session: CognitoUserSession | null) => {
      if (err) return
      if (session && session.isValid()) {
        this.user = user
        this.session = session
      }
    })
  }
}
