import { Icon } from '@iconify/react'
import { Stack, Typography } from '@mui/material'
import icons from '../../config/icons.config'
import ChartType from '../../model/chart/ChartType'

export interface MetricEmptyViewProps {
  name: string
  chart: ChartType
}

export default function MetricEmptyView({ name, chart }: MetricEmptyViewProps) {
  return (
    <Stack
      sx={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        p: 2,
        color: 'text.secondary',
        gap: 1,
      }}
    >
      <Icon icon={icons.charts[chart]} width={40} />
      <Stack sx={{ maxWidth: 300 }}>
        <Typography variant='body2'>
          There are no members that match the filters and have {name.toLowerCase()} data
        </Typography>
      </Stack>
    </Stack>
  )
}
