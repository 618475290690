import { useEffect, useState } from 'react'
import { useModal } from '../../contexts/ModalContext'
import { authManager } from '../../managers/_manager.config'
import LogoutWarningModal from './LogoutWarningModal'

const AUTO_LOGOUT_TIME = 10 * 60 * 1000 // 10 minutes in milliseconds
const WARNING_TIME = 1 * 60 * 1000 // 1 minute in milliseconds
const MODAL_ID = 'auto-logout-warning'

export default function AutoLogout() {
  // Navigation
  const { openModal, closeModal } = useModal()

  // -- Local state
  const [warningOpen, setWarningOpen] = useState(false)

  // -- Lifecycle
  useEffect(() => {
    const showWarning = () => {
      openModal(
        MODAL_ID,
        <LogoutWarningModal
          countdownSeconds={WARNING_TIME / 1000}
          onClose={() => closeModal(MODAL_ID)}
        />,
      )
      setWarningOpen(true)
    }

    const closeWarning = () => {
      if (warningOpen) {
        closeModal(MODAL_ID)
        setWarningOpen(false)
      }
    }

    let logoutTimer = setTimeout(handleLogout, AUTO_LOGOUT_TIME)
    let warningTimer = setTimeout(showWarning, AUTO_LOGOUT_TIME - WARNING_TIME)

    const resetTimer = async () => {
      clearTimeout(logoutTimer)
      clearTimeout(warningTimer)
      logoutTimer = setTimeout(handleLogout, AUTO_LOGOUT_TIME)
      warningTimer = setTimeout(showWarning, AUTO_LOGOUT_TIME - WARNING_TIME)

      closeWarning()
    }

    // Event listeners for user activity
    window.addEventListener('mousedown', resetTimer)
    window.addEventListener('keydown', resetTimer)

    return () => {
      clearTimeout(logoutTimer)
      clearTimeout(warningTimer)
      window.removeEventListener('mousedown', resetTimer)
      window.removeEventListener('keydown', resetTimer)
    }
  }, [])

  const handleLogout = async () => {
    window.location.href = '/'
    await authManager.logout()
  }

  return null
}
