import { Alert } from '@mui/material'
import { useEffect } from 'react'
import { trackingManager } from '../../managers/_manager.config'

export interface ErrorViewProps {
  error: Error | null
}

export default function ErrorView({ error }: ErrorViewProps) {
  useEffect(() => {
    if (!error) return
    trackingManager.trackError(error)
  }, [])

  return (
    <Alert severity='error'>
      Sorry, something went wrong. Let us know if this persists and we'll get on fixing it
    </Alert>
  )
}
