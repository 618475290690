import { Box, BoxProps } from '@mui/material'
import LoadableState, { LoadingState } from '../../util/LoadableState'
import ErrorView from './ErrorView'
import LoadingIndicator from './LoadingIndicator'

export interface StatusViewProps<T> extends BoxProps {
  state: LoadableState<T>
  render?: (data: T) => JSX.Element | null
  nullRenderer?: JSX.Element | null // Used when render depends on loadable data succeed, but the data is null
  loadingRender?: JSX.Element | null // Used when render depends on loadable data that is loading
  unloadedRender?: JSX.Element | null // Used when render does not depend on loadable data or when data is null
  errorRender?: JSX.Element | null // Used when render depends on loadable data that has errored
  defaultMargin?: boolean | number // Set to true to use default margin, or a number to set custom margin
  showLoadingIndicator?: boolean
  loading?: boolean
  align?: 'flex-start' | 'center' | 'flex-end'
}

export default function StatusView<T>({
  state,
  render,
  loadingRender,
  unloadedRender,
  errorRender,
  nullRenderer,
  defaultMargin,
  showLoadingIndicator = true,
  loading = false,
  align = 'center',
  ...rest
}: StatusViewProps<T>) {
  function getMargin() {
    if (defaultMargin === undefined) return 0
    if (defaultMargin === true) return 10
    if (defaultMargin === false) return 0
    return defaultMargin
  }

  if (loading) return loadingRender ?? null

  switch (state.status) {
    case LoadingState.loading:
      if (loadingRender !== undefined) return loadingRender
      return (
        <Box
          height='100%'
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent={align}
          p={getMargin()}
          {...rest}
        >
          {showLoadingIndicator && <LoadingIndicator />}
        </Box>
      )
    case LoadingState.error:
      return errorRender ?? <ErrorView error={state.error} />
    case LoadingState.loaded:
      if (!state.data) {
        if (nullRenderer) return nullRenderer
        return null
      }
      if (!render) return null
      return render(state.data)
    case LoadingState.unloaded:
      return unloadedRender ? unloadedRender : null
    default:
      return null
  }
}
