import ChartData from './ChartData'
import ChartDataType from './ChartDataType'

export interface GroupedChartDataArgument {
  points: GroupedDataPoint[]
}

export default class GroupedChartData extends ChartData {
  readonly points: GroupedDataPoint[]

  constructor(arg: GroupedChartDataArgument) {
    super({ type: ChartDataType.grouped })
    this.points = arg.points
  }

  getKeys() {
    return Array.from(new Set(this.points.map((d) => d.key)))
  }

  isEmpty() {
    return this.points.length === 0
  }
}

export interface GroupedDataPoint {
  key: string
  value: number
  group: string
}
