enum ChartDataType {
  // Base
  standard = 'standard',
  grouped = 'grouped',
  sankey = 'sankey',

  // Custom
  levels = 'levels',
  levelsFull = 'levelsFull',
  summitRate = 'summitRate',
  statistic = 'statistic',
  multiStatistic = 'multiStatistic',
  timeframe = 'timeframe',
  densityMap = 'densityMap',
  groupedDensityMap = 'groupedDensityMap',
}

export default ChartDataType
