import mixpanel from 'mixpanel-browser';
import TrackingService from '../domain/TrackingService';

export default class MixPanelService implements TrackingService {
  token: string

  constructor() {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN
    if (!token) throw new Error('Missing mixpanel token from env (REACT_APP_MIXPANEL_TOKEN)')
    this.token = token

    mixpanel.init(token)
  }

  track(userID: string, event: string, data: any): void {
    mixpanel.identify(userID)
    mixpanel.track(event, data)
  }
}
