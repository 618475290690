import ContinuousFilter, { ContinuousFilterValues } from './ContinuousFilter'
import { FilterType } from './Filter'
import FilterOption from './FilterOption'
import { FilterRangeValueType } from './FilterRange'

export interface ContinuousFilterOptionArgument {
  metricID: string
  valueType?: FilterRangeValueType
  name: string
}

export default class ContinuousFilterOption implements FilterOption {
  readonly metricID: string
  readonly type = FilterType.continuous
  readonly valueType: FilterRangeValueType
  readonly name: string

  constructor(arg: ContinuousFilterOptionArgument) {
    this.metricID = arg.metricID
    this.valueType = arg.valueType ?? 'date'
    this.name = arg.name
  }

  getDefaultFilter(values?: ContinuousFilterValues) {
    return new ContinuousFilter({
      metricID: this.metricID,
      valueType: this.valueType,
      name: this.name,
      ranges: values ?? [],
    })
  }

  isFull(ranges: ContinuousFilterValues) {
    // Continuous filter is never full
    return false
  }
}
