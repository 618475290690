import { Icon } from '@iconify/react'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import icons from '../../config/icons.config'
import PropensityType from '../../model/propensity/PropensityType'
import palette from '../../theme/palette'

export interface PropensityTypeSelectorProps {
  value: PropensityType
  onChange: (value: PropensityType) => void
  size?: 'small' | 'medium' | 'large'
}

export default function PropensityTypeSelector({
  value,
  onChange,
  size = 'medium',
}: PropensityTypeSelectorProps) {
  return (
    <ToggleButtonGroup
      size='small'
      value={value}
      onChange={(_, value) => onChange(value)}
      exclusive
    >
      <ToggleButton
        value={PropensityType.growth}
        key={PropensityType.growth}
        color='success'
        size={size}
      >
        <Tooltip title='Growth opportunity'>
          <Icon icon={icons.growth} color={palette.light.growth} width={18} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        value={PropensityType.churn}
        key={PropensityType.churn}
        color='error'
        size={size}
      >
        <Tooltip title='Churn opportunity'>
          <Icon icon={icons.churn} color={palette.light.churn} width={18} />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
