import { useHookstate } from '@hookstate/core'
import { Dialog, Stack, TextField } from '@mui/material'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { adminManager } from '../../../managers/_manager.config'
import User from '../../../model/admin/User'
import globalState from '../../../service/external/GlobalState'
import CustomChipController from '../../modal/CustomChipController'
import CustomTextFieldController from '../../modal/CustomTextFieldController'
import ModalContent from '../../modal/ModalContent'
import ModalFooter from '../../modal/ModalFooter'
import ModalHeader from '../../modal/ModalHeader'
import PhoneInput from '../../modal/PhoneInput'
import StatusView from '../../util/StatusView'

export interface UserModalProps {
  user: User
  users: User[]
  isCreating?: boolean
  onClose?: () => void
  onSubmit?: (newUser: User) => void
}

export interface UserForm {
  username: string
  email: string
  phone: string
  firstName: string
  lastName: string
  roleIDs: string[]
}

export default function UserModal({ user, users, isCreating, onClose, onSubmit }: UserModalProps) {
  // Form
  const { handleSubmit, control, getValues, setValue } = useForm<UserForm>({
    defaultValues: isCreating
      ? {}
      : {
        username: user.username,
        email: user.email,
        phone: user.phone,
        firstName: user.firstName,
        lastName: user.lastName,
        roleIDs: user.roleIDs,
      },
  })

  // Global state
  const rolesState = useHookstate(globalState.roles).get()
  const currentUserState = useHookstate(globalState.user).get()

  // Effect
  useEffect(() => {
    adminManager.fetchRoles()
  }, [])

  // -- Actions
  const handleFormSubmit = (form: UserForm) => {
    onSubmit?.(user.formUpdate(form))
    onClose?.()
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth='sm' fullWidth>
      <ModalHeader title={isCreating ? 'Create User' : 'Edit User'} />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack gap={2}>
            <Controller
              control={control}
              name='email'
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Email'
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value.toLowerCase())}
                  disabled={!isCreating}
                />
              )}
              rules={{
                validate: (value: string) => {
                  if (!isCreating) return true
                  return (
                    !users.find((user) => user.email === value) || 'User with email already exists'
                  )
                },
              }}
            />
            <CustomTextFieldController
              name='username'
              control={control}
              defaultValue={user.username}
              label='Display Name'
            />
            <CustomTextFieldController
              name='firstName'
              control={control}
              defaultValue={user.firstName}
              label='First Name'
            />
            <CustomTextFieldController
              name='lastName'
              control={control}
              defaultValue={user.lastName}
              label='Last Name'
            />
            <PhoneInput
              name='phone'
              label='Phone'
              value={getValues().phone}
              onChange={(phone) => setValue('phone', phone)}
            />
            <StatusView
              state={currentUserState}
              render={(currentUser) => {
                return <StatusView
                  state={rolesState}
                  render={(roles) => {
                    return <CustomChipController
                      name='roleIDs'
                      control={control}
                      defaultValue={user.roleIDs}
                      label='Roles'
                      options={roles
                        .filter((role) => role.canBeCreatedBy(currentUser as User))
                        .map((role) => role.roleID)}
                    />
                  }}
                />
              }}
            />
          </Stack>
          <ModalFooter onClose={onClose} />
        </form>
      </ModalContent>
    </Dialog>
  )
}
