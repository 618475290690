import { Icon } from '@iconify/react'
import { Box, BoxProps, Button, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import icons from '../../../config/icons.config'
import Filter, { FilterValues } from '../../../model/filter/Filter'
import FilterOption from '../../../model/filter/FilterOption'
import FilterChips from './FilterChips'
import FilterInput from './FilterInput'

export interface FilterViewProps extends BoxProps {
  filters: Filter[]
  filterOptions: FilterOption[]
  onAddFilter: (metricID: string) => void
  onRemoveFilter: (metricID: string) => void
  onInvertFilter: (metricID: string) => void
  onFilterOptionChange: (metricID: string, filterOption: FilterOption) => void
  onFilterValueChange: (metricID: string, values: FilterValues, invert?: boolean) => void
  onApplyFilters: () => void
  onClearFilters: () => void
}

export default function FilterView({
  filters,
  filterOptions,
  onAddFilter,
  onInvertFilter,
  onRemoveFilter,
  onFilterOptionChange,
  onFilterValueChange,
  onApplyFilters,
  onClearFilters,
  ...rest
}: FilterViewProps) {
  // -- Local state
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [prevFilters, setPrevFilters] = useState<Filter[]>([])

  // Effect
  useEffect(() => {
    if (filtersHaveChanged(filters, prevFilters)) {
      setPrevFilters(filters)
      setFiltersChanged(true)
    }
  }, [filters])

  // Computed
  const availableFilterOptions = filterOptions.filter(
    (filterOption) => !filters.find((filter) => filter.metricID === filterOption.metricID),
  )

  // Functions
  function filtersHaveChanged(filters: Filter[], prevFilters: Filter[]) {
    if (filters.length !== prevFilters.length) return true
    if (!filters.every((filter, index) => filter.equals(prevFilters[index]))) return true
    return false
  }

  function getFilterOption(metricID: string) {
    const filterOption = filterOptions.find((filterOption) => filterOption.metricID === metricID)
    if (!filterOption) throw new Error(`Filter option with metricID ${metricID} not found`)
    return filterOption
  }

  // -- Actions
  function onToggleCollapsed() {
    setIsCollapsed((prev) => !prev)
  }

  function addFilter() {
    onAddFilter(availableFilterOptions[0].metricID)
  }

  function onApplyFilterButtonClick() {
    setFiltersChanged(false)
    onApplyFilters()
  }

  function clearFilters() {
    onClearFilters()
  }

  // -- UI
  function ActionButtons() {
    return (
      <Stack direction='row' gap={2}>
        <Button data-testid='explore-members-clear-filter-btn' onClick={clearFilters}>
          Clear All
        </Button>
        <Button
          data-testid='explore-members-apply-filter-btn'
          variant='contained'
          disabled={!filtersChanged}
          onClick={onApplyFilterButtonClick}
        >
          Apply Filter
        </Button>
      </Stack>
    )
  }

  return (
    <Box {...rest}>
      <Stack direction='row' justifyContent='space-between' gap={2} mb={1}>
        {!isCollapsed ? (
          <Stack flex={1}>
            <Typography fontWeight='medium' fontSize='0.9rem'>
              All Eligible Members
            </Typography>
            {filters.map((filter, index) => (
              <FilterInput
                key={index}
                filter={filter}
                filterOption={getFilterOption(filter.metricID)}
                availableFilterOptions={availableFilterOptions}
                index={index}
                onRemoveFilter={onRemoveFilter}
                onFilterOptionChange={onFilterOptionChange}
                onFilterValueChange={onFilterValueChange}
              />
            ))}
          </Stack>
        ) : (
          <Box flex={1}>
            <FilterChips
              filters={filters}
              onInvert={(metricID) => onInvertFilter(metricID)}
              onDelete={(metricID) => onRemoveFilter(metricID)}
            />
          </Box>
        )}
        <Stack direction='row' gap={1} alignItems='flex-end' alignSelf='flex-start'>
          {isCollapsed && <ActionButtons />}
          {/* <IconButton onClick={onToggleCollapsed}>
            {isCollapsed ? <Icon icon={icons.chevron.down} /> : <Icon icon={icons.chevron.up} />}
          </IconButton> */}
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        {!isCollapsed ? (
          availableFilterOptions.length > 0 ? (
            <Button onClick={addFilter} variant='text' startIcon={<Icon icon={icons.plus} />}>
              Filter
            </Button>
          ) : (
            <div />
          )
        ) : (
          <div />
        )}
        {!isCollapsed && <ActionButtons />}
      </Stack>
    </Box>
  )
}
