import { State } from '@hookstate/core'
import ListUtil from './ListUtil'
import LoadableState from './LoadableState'

export async function load<T>(state: State<LoadableState<T>>, fetchFunction: () => Promise<T>) {
  state.set(LoadableState.loading<T>())
  try {
    const data = await fetchFunction()
    state.set(LoadableState.set(data))
  } catch (error: any) {
    state.set(LoadableState.error<T>(error))
  }
}

export async function save<T>(
  state: State<LoadableState<T>>,
  item: T,
  saveFunction: (item: T) => Promise<void>,
) {
  try {
    state.set(LoadableState.loading<T>())
    await saveFunction(item)
    state.set(LoadableState.set(item))
  } catch (error: any) {
    state.set(LoadableState.error<T>(error))
  }
}

export async function saveOneInList<T>(
  state: State<LoadableState<T[]>>,
  item: T,
  saveFunction: (item: T) => Promise<T | void>,
  matchFunction?: (item: T) => boolean,
  useSaveResult = false,
) {
  try {
    if (useSaveResult) {
      const updatedItem = (await saveFunction(item)) as T
      const items = (state.get()?.data ?? []) as T[]
      const updatedItems = ListUtil.put(items, updatedItem, matchFunction)
      state.set(LoadableState.set(updatedItems))
      return updatedItem
    } else {
      const items = (state.get()?.data ?? []) as T[]
      const updatedItems = ListUtil.put(items, item, matchFunction)
      state.set(LoadableState.set(updatedItems))
      await saveFunction(item)
      return item
    }
  } catch (error: any) {
    state.set(LoadableState.error<T[]>(error))
    return item
  }
}

export async function saveOneInListLocal<T>(
  state: State<LoadableState<T[]>>,
  item: T,
  matchFunction: (item: T) => boolean,
) {
  try {
    const items = (state.get()?.data ?? []) as T[]
    const updatedItems = ListUtil.put(items, item, matchFunction)
    state.set(LoadableState.set(updatedItems))
  } catch (error: any) {
    state.set(LoadableState.error<T[]>(error))
  }
}

export async function deleteOneInList<T>(
  state: State<LoadableState<T[]>>,
  matchFunction: (item: T) => boolean,
  deleteFunction: () => Promise<void>,
) {
  try {
    const items = (state.get()?.data ?? []) as T[]
    state.set(LoadableState.set(items.filter((item) => !matchFunction(item))))
    await deleteFunction()
  } catch (error: any) {
    state.set(LoadableState.error<T[]>(error))
  }
}

export async function deleteOneInListLocal<T>(
  state: State<LoadableState<T[]>>,
  matchFunction: (item: T) => boolean,
) {
  try {
    const items = (state.get()?.data ?? []) as T[]
    state.set(LoadableState.set(items.filter((item) => !matchFunction(item))))
  } catch (error: any) {
    state.set(LoadableState.error<T[]>(error))
  }
}
