import { useHookstate } from '@hookstate/core'
import { Badge, BadgeProps } from '@mui/material'
import globalState from '../../service/external/GlobalState'

export interface AIOpportunitiesBadgeProps extends BadgeProps {
  children?: JSX.Element
}

export default function AIOpportunitiesBadge({ children, ...rest }: AIOpportunitiesBadgeProps) {
  const opportunities = useHookstate(globalState.opportunities).get().data
  if (!opportunities) return null

  const count = opportunities.filter((opportunity) => opportunity.isPendingAI()).length

  return (
    <Badge badgeContent={count} color='error' {...rest}>
      {children}
    </Badge>
  )
}
