import { Icon } from '@iconify/react'
import { Box, Card, Skeleton, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import icons from '../../config/icons.config'
import { exploreManager } from '../../managers/_manager.config'
import ProductCategory from '../../model/explore/ProductCategory'
import PropensityType from '../../model/propensity/PropensityType'
import { ScreenPath } from '../../navigation'
import palette from '../../theme/palette'

export interface ProductCategoryCardProps {
  productCategory: ProductCategory
  rating?: number
  propensityType?: PropensityType
  loading?: boolean
}

export default function ProductCategoryCard({
  productCategory,
  rating,
  propensityType,
  loading = false,
}: ProductCategoryCardProps) {
  // Navigation
  const navigate = useNavigate()

  // -- Actions
  function onClick() {
    if (!productCategory) return
    exploreManager.selectProductCategory(productCategory?.productCategoryID)
    navigate(`${ScreenPath.explore}/${productCategory?.productCategoryID}`)
  }

  function Rating({ rating, type }: { rating: number; type: PropensityType }) {
    return (
      <Stack direction='row' flex={1} gap={0.5}>
        <Icon icon={icons[type]} color={palette.light[type]} />
        {Array.from(Array(5).keys()).map((index) => (
          <Box
            sx={{
              flex: 1,
              height: 10,
              borderRadius: 5,
              background: index < rating ? palette.light[type] : palette.light.grey[400],
            }}
          />
        ))}
      </Stack>
    )
  }

  // -- UI
  const height = rating ? 80 : 60

  if (loading) return <Skeleton variant='rounded' width='100%' height={height} />
  if (!productCategory) return null
  return (
    <Card
      sx={{
        flex: 1,
        padding: 2,
        cursor: 'pointer',
        border: 1,
        height: height,
        borderColor: 'transparent',
        '&:hover': {
          boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
          transform: 'scale(1.02)',
          transition: 'all 0.1s ease-in-out',
          border: 1,
          borderColor: palette.light.primary.main,
        },
      }}
      onClick={onClick}
      data-testid={'product-category-card-' + productCategory.productCategoryID}
    >
      <Stack gap={1}>
        <Typography>{productCategory.name}</Typography>
        {rating && propensityType && <Rating rating={rating} type={propensityType} />}
      </Stack>
    </Card>
  )
}
