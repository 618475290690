import FullLevelsChartData, { Timeline } from '../../model/chart/FullLevelsChartData'
import StandardChartData from '../../model/chart/StandardChartData'
import StatisticData from '../../model/chart/StatisticData'
import SummitRateChartData from '../../model/chart/SummitRateChartData'
import TimeframeChartData from '../../model/chart/TimeframeChartData'
import MemberType from '../../model/dashboard/MemberType'
import ProductCategoryRating, {
  ProductCategoryRatingBasicGoalType,
  ProductCategoryRatingGoals,
} from '../../model/explore/ProductCategoryRating'
import MetricData from '../../model/metric/MetricData'
import PropensityType from '../../model/propensity/PropensityType'
import { dataSource } from '../domain/_service.config'

const federalMockDataAverageMemberAge = {
  [MemberType.all]: new MetricData({
    metricID: 'averageMemberAge',
    chartData: new StatisticData({
      title: 'Average Member Age',
      value: '48.25',
      valueSuffix: `(Years)`,
      size: 'lg',
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'averageMemberAge',
    chartData: new StatisticData({
      title: 'Average Member Age',
      value: '47.83',
      valueSuffix: `(Years)`,
      size: 'lg',
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'averageMemberAge',
    chartData: new StatisticData({
      title: 'Average Member Age',
      value: '49.33',
      valueSuffix: `(Years)`,
      size: 'lg',
    }),
  }),
}

const federalMockDataAverageProductCount = {
  [MemberType.all]: new MetricData({
    metricID: 'averageProductCount',
    chartData: new TimeframeChartData({
      title: 'Average Number of Products',
      values: [
        {
          value: 2.32,
          timeline: Timeline['1m'],
        },
        {
          value: 2.1,
          timeline: Timeline['3m'],
        },
        {
          value: 2.0,
          timeline: Timeline['12m'],
        },
        {
          value: 1.7,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 2,
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'averageProductCount',
    chartData: new TimeframeChartData({
      title: 'Average Number of Products',
      values: [
        {
          value: 2.32,
          timeline: Timeline['1m'],
        },
        {
          value: 2.1,
          timeline: Timeline['3m'],
        },
        {
          value: 2.0,
          timeline: Timeline['12m'],
        },
        {
          value: 1.7,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 2,
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'averageProductCount',
    chartData: new TimeframeChartData({
      title: 'Average Number of Products',
      values: [
        {
          value: 2.32,
          timeline: Timeline['1m'],
        },
        {
          value: 2.1,
          timeline: Timeline['3m'],
        },
        {
          value: 2.0,
          timeline: Timeline['12m'],
        },
        {
          value: 1.7,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 2,
    }),
  }),
}

const federalMockDataNewDepositCount = {
  [MemberType.all]: new MetricData({
    metricID: 'newDepositCount',
    chartData: new TimeframeChartData({
      title: 'New Deposit Count',
      values: [
        {
          value: 2106,
          timeline: Timeline['1m'],
        },
        {
          value: 6911,
          timeline: Timeline['3m'],
        },
        {
          value: 26919,
          timeline: Timeline['12m'],
        },
        {
          value: 13800,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'newDepositCount',
    chartData: new TimeframeChartData({
      title: 'New Deposit Count',
      values: [
        {
          value: 1758,
          timeline: Timeline['1m'],
        },
        {
          value: 6087,
          timeline: Timeline['3m'],
        },
        {
          value: 23698,
          timeline: Timeline['12m'],
        },
        {
          value: 12461,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'newDepositCount',
    chartData: new TimeframeChartData({
      title: 'New Deposit Count',
      values: [
        {
          value: 257,
          timeline: Timeline['1m'],
        },
        {
          value: 727,
          timeline: Timeline['3m'],
        },
        {
          value: 3112,
          timeline: Timeline['12m'],
        },
        {
          value: 1339,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
}

const federalMockDataNewDepositAmount = {
  [MemberType.all]: new MetricData({
    metricID: 'newDepositCount',
    chartData: new TimeframeChartData({
      title: 'New Deposit Count',
      values: [
        {
          value: 41715945,
          timeline: Timeline['1m'],
        },
        {
          value: 134769425,
          timeline: Timeline['3m'],
        },
        {
          value: 518549550,
          timeline: Timeline['12m'],
        },
        {
          value: 299228645,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'newDepositAmount',
    chartData: new TimeframeChartData({
      title: 'New Deposit Amount',
      values: [
        {
          value: 39989565,
          timeline: Timeline['1m'],
        },
        {
          value: 132691731,
          timeline: Timeline['3m'],
        },
        {
          value: 516715225,
          timeline: Timeline['12m'],
        },
        {
          value: 299202706,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'newDepositAmount',
    chartData: new TimeframeChartData({
      title: 'New Deposit Amount',
      values: [
        {
          value: 2259,
          timeline: Timeline['1m'],
        },
        {
          value: 14044,
          timeline: Timeline['3m'],
        },
        {
          value: 90801,
          timeline: Timeline['12m'],
        },
        {
          value: 25938,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
}

const federalMockDataNewLoansAmount = {
  [MemberType.all]: new MetricData({
    metricID: 'newLoansAmount',
    chartData: new TimeframeChartData({
      title: 'New Loans Amount',
      values: [
        {
          value: 30070484,
          timeline: Timeline['1m'],
        },
        {
          value: 82462609,
          timeline: Timeline['3m'],
        },
        {
          value: 350074247,
          timeline: Timeline['12m'],
        },
        {
          value: 140779897,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'newLoansAmount',
    chartData: new TimeframeChartData({
      title: 'New Loans Amount',
      values: [
        {
          value: 18653245.67,
          timeline: Timeline['1m'],
        },
        {
          value: 54762880.67,
          timeline: Timeline['3m'],
        },
        {
          value: 241999406,
          timeline: Timeline['12m'],
        },
        {
          value: 92022544,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'newLoansAmount',
    chartData: new TimeframeChartData({
      title: 'New Loans Amount',
      values: [
        {
          value: 9854093,
          timeline: Timeline['1m'],
        },
        {
          value: 26805986,
          timeline: Timeline['3m'],
        },
        {
          value: 106921544,
          timeline: Timeline['12m'],
        },
        {
          value: 48757353,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
}

const federalMockDataNewLoansCount = {
  [MemberType.all]: new MetricData({
    metricID: 'newLoansCount',
    chartData: new TimeframeChartData({
      title: 'New Loans Count',
      values: [
        {
          value: 308,
          timeline: Timeline['1m'],
        },
        {
          value: 880,
          timeline: Timeline['3m'],
        },
        {
          value: 3620,
          timeline: Timeline['12m'],
        },
        {
          value: 1617,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'newLoansCount',
    chartData: new TimeframeChartData({
      title: 'New Loans Count',
      values: [
        {
          value: 412,
          timeline: Timeline['1m'],
        },
        {
          value: 1382,
          timeline: Timeline['3m'],
        },
        {
          value: 5748,
          timeline: Timeline['12m'],
        },
        {
          value: 2457,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'newLoansCount',
    chartData: new TimeframeChartData({
      title: 'New Loans Count',
      values: [
        {
          value: 308,
          timeline: Timeline['1m'],
        },
        {
          value: 880,
          timeline: Timeline['3m'],
        },
        {
          value: 3620,
          timeline: Timeline['12m'],
        },
        {
          value: 1617,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
}

const federalMockDataNewMembersCount = {
  [MemberType.all]: new MetricData({
    metricID: 'newMembersCount',
    chartData: new TimeframeChartData({
      title: 'New Members Count',
      values: [
        {
          value: 597,
          timeline: Timeline['1m'],
        },
        {
          value: 1835,
          timeline: Timeline['3m'],
        },
        {
          value: 7898,
          timeline: Timeline['12m'],
        },
        {
          value: 3311,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'newMembersCount',
    chartData: new TimeframeChartData({
      title: 'New Members Count',
      values: [
        {
          value: 254,
          timeline: Timeline['1m'],
        },
        {
          value: 1060,
          timeline: Timeline['3m'],
        },
        {
          value: 4718,
          timeline: Timeline['12m'],
        },
        {
          value: 1974,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'newMembersCount',
    chartData: new TimeframeChartData({
      title: 'New Members Count',
      values: [
        {
          value: 215,
          timeline: Timeline['1m'],
        },
        {
          value: 714,
          timeline: Timeline['3m'],
        },
        {
          value: 3082,
          timeline: Timeline['12m'],
        },
        {
          value: 1336,
          timeline: Timeline.ytd,
        },
      ],
      size: 'lg',
      precision: 0,
    }),
  }),
}

const federalMockDataActiveOpportunities = {
  [MemberType.all]: new MetricData({
    metricID: 'activeOpportunities',
    chartData: new StatisticData({
      title: 'Active Opportunities',
      value: '22',
      size: 'lg',
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'activeOpportunities',
    chartData: new StatisticData({
      title: 'Active Opportunities',
      value: '12',
      size: 'lg',
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'activeOpportunities',
    chartData: new StatisticData({
      title: 'Active Opportunities',
      value: '10',
      size: 'lg',
    }),
  }),
}

const federalMockDataPendingOpportunities = {
  [MemberType.all]: new MetricData({
    metricID: 'pendingOpportunities',
    chartData: new StatisticData({
      title: 'Pending Opportunities',
      value: '10',
      size: 'lg',
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'pendingOpportunities',
    chartData: new StatisticData({
      title: 'Pending Opportunities',
      value: '6',
      size: 'lg',
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'pendingOpportunities',
    chartData: new StatisticData({
      title: 'Pending Opportunities',
      value: '4',
      size: 'lg',
    }),
  }),
}

const federalMockDataCompletedOpportunities = {
  [MemberType.all]: new MetricData({
    metricID: 'completedOpportunities',
    chartData: new StatisticData({
      title: 'Completed Opportunities',
      value: '25',
      size: 'lg',
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'completedOpportunities',
    chartData: new StatisticData({
      title: 'Completed Opportunities',
      value: '15',
      size: 'lg',
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'completedOpportunities',
    chartData: new StatisticData({
      title: 'Completed Opportunities',
      value: '10',
      size: 'lg',
    }),
  }),
}

const federalMockDataPublishedOpportunities = {
  [MemberType.all]: new MetricData({
    metricID: 'publishedOpportunities',
    chartData: new StatisticData({
      title: 'Published Opportunities',
      value: '12',
      size: 'lg',
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'publishedOpportunities',
    chartData: new StatisticData({
      title: 'Published Opportunities',
      value: '9',
      size: 'lg',
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'publishedOpportunities',
    chartData: new StatisticData({
      title: 'Published Opportunities',
      value: '3',
      size: 'lg',
    }),
  }),
}

const federalMockDataDraftOpportunities = {
  [MemberType.all]: new MetricData({
    metricID: 'draftOpportunities',
    chartData: new StatisticData({
      title: 'Draft Opportunities',
      value: '35',
      size: 'lg',
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'draftOpportunities',
    chartData: new StatisticData({
      title: 'Draft Opportunities',
      value: '21',
      size: 'lg',
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'draftOpportunities',
    chartData: new StatisticData({
      title: 'Draft Opportunities',
      value: '14',
      size: 'lg',
    }),
  }),
}

const federalMockDataUnreviewedAiOpportunities = {
  [MemberType.all]: new MetricData({
    metricID: 'unreviewedAiOpportunities',
    chartData: new StatisticData({
      title: 'Unreviewed Ai Opportunities',
      value: '15',
      size: 'lg',
    }),
  }),
  [MemberType.direct]: new MetricData({
    metricID: 'unreviewedAiOpportunities',
    chartData: new StatisticData({
      title: 'Unreviewed Ai Opportunities',
      value: '9',
      size: 'lg',
    }),
  }),
  [MemberType.indirect]: new MetricData({
    metricID: 'unreviewedAiOpportunities',
    chartData: new StatisticData({
      title: 'Unreviewed Ai Opportunities',
      value: '6',
      size: 'lg',
    }),
  }),
}

const federalMockData: { [key: string]: any } = {
  averageMemberAge: federalMockDataAverageMemberAge,
  averageProductCount: federalMockDataAverageProductCount,
  newDepositCount: federalMockDataNewDepositCount,
  newDepositAmount: federalMockDataNewDepositAmount,
  newLoansAmount: federalMockDataNewLoansAmount,
  newLoansCount: federalMockDataNewLoansCount,
  newMembersCount: federalMockDataNewMembersCount,
  activeOpportunities: federalMockDataActiveOpportunities,
  pendingOpportunities: federalMockDataPendingOpportunities,
  publishedOpportunities: federalMockDataPublishedOpportunities,
  completedOpportunities: federalMockDataCompletedOpportunities,
  draftOpportunities: federalMockDataDraftOpportunities,
  unreviewedAiOpportunities: federalMockDataUnreviewedAiOpportunities,
}

export default class MockDataSource {
  getDashboardMetricData(clientID: string, memberType: MemberType, metricID: string) {
    if (clientID === 'federal') {
      switch (metricID) {
        case 'currentMembers':
          switch (memberType) {
            case MemberType.all:
              return new MetricData({
                metricID: 'currentMembers',
                chartData: new StatisticData({
                  title: 'Current Members',
                  value: 104958,
                  size: 'lg',
                }),
              })
            case MemberType.direct:
              return new MetricData({
                metricID: 'currentMembers',
                chartData: new StatisticData({
                  title: 'Current Members',
                  value: 87324,
                  size: 'lg',
                }),
              })
            case MemberType.indirect:
              return new MetricData({
                metricID: 'currentMembers',
                chartData: new StatisticData({
                  title: 'Current Members',
                  value: 17634,
                  size: 'lg',
                }),
              })
          }
        case 'dashboardTargetedMembers':
          switch (memberType) {
            case MemberType.all:
              return new MetricData({
                metricID: 'dashboardTargetedMembers',
                chartData: new StatisticData({
                  title: 'Targeted Members',
                  value: 36234,
                  valueSuffix: ` (${((36234 / 104938) * 100).toFixed(2)}%)`,
                  size: 'lg',
                }),
              })
            case MemberType.direct:
              return new MetricData({
                metricID: 'dashboardTargetedMembers',
                chartData: new StatisticData({
                  title: 'Targeted Members',
                  value: 27176, // 75% of 36234
                  valueSuffix: ` (${((27176 / 104958) * 100).toFixed(2)}%)`,
                  size: 'lg',
                }),
              })
            case MemberType.indirect:
              return new MetricData({
                metricID: 'dashboardTargetedMembers',
                chartData: new StatisticData({
                  title: 'Targeted Members',
                  value: 9058, // 25% of 36234
                  valueSuffix: ` (${((9058 / 104958) * 100).toFixed(2)}%)`,
                  size: 'lg',
                }),
              })
          }
        case 'summitRate':
          switch (memberType) {
            case MemberType.all:
              return new MetricData({
                metricID: 'summitRate',
                chartData: new SummitRateChartData({
                  avg: new StatisticData({
                    title: 'Summit Rate',
                    value: 0.84,
                    valueSuffix: '%',
                    precision: 2,
                    size: 'lg',
                  }),
                  line: new StandardChartData({
                    points: [
                      { key: 'Sep', value: -1.2 },
                      { key: 'Oct', value: 0.5 },
                      { key: 'Nov', value: 1.2 },
                      { key: 'Dec', value: 0.8 },
                      { key: 'Jan', value: 0.9 },
                      { key: 'Feb', value: 0.94 },
                    ],
                  }),
                }),
              })
            case MemberType.direct:
              return new MetricData({
                metricID: 'summitRate',
                chartData: new SummitRateChartData({
                  avg: new StatisticData({
                    title: 'Summit Rate',
                    value: 0.93,
                    valueSuffix: '%',
                    precision: 2,
                    size: 'lg',
                  }),
                  line: new StandardChartData({
                    points: [
                      { key: 'Sep', value: -0.6 },
                      { key: 'Oct', value: 0.3 },
                      { key: 'Nov', value: 1.1 },
                      { key: 'Dec', value: 0.7 },
                      { key: 'Jan', value: 0.8 },
                      { key: 'Feb', value: 0.92 },
                    ],
                  }),
                }),
              })
            case MemberType.indirect:
              return new MetricData({
                metricID: 'summitRate',
                chartData: new SummitRateChartData({
                  avg: new StatisticData({
                    title: 'Summit Rate',
                    value: 0.72,
                    valueSuffix: '%',
                    precision: 2,
                    size: 'lg',
                  }),
                  line: new StandardChartData({
                    points: [
                      { key: 'Sep', value: -1.8 },
                      { key: 'Oct', value: 0.7 },
                      { key: 'Nov', value: 1.3 },
                      { key: 'Dec', value: 0.9 },
                      { key: 'Jan', value: 1.0 },
                      { key: 'Feb', value: 0.96 },
                    ],
                  }),
                }),
              })
          }
        case 'memberValueGrowth':
          switch (memberType) {
            case MemberType.all:
              return new MetricData({
                metricID: 'memberValueGrowth',
                chartData: new StatisticData({
                  title: 'Member Value Growth',
                  value: 12490200,
                  description: '(Past 12 Months)',
                  valuePrefix: '$',
                  size: 'lg',
                }),
              })
            case MemberType.direct:
              return new MetricData({
                metricID: 'memberValueGrowth',
                chartData: new StatisticData({
                  title: 'Member Value Growth',
                  value: 12490200 * 0.75, // 75% of total growth
                  description: '(Past 12 Months)',
                  valuePrefix: '$',
                  size: 'lg',
                }),
              })
            case MemberType.indirect:
              return new MetricData({
                metricID: 'memberValueGrowth',
                chartData: new StatisticData({
                  title: 'Member Value Growth',
                  value: 12490200 * 0.25, // 25% of total growth
                  description: '(Past 12 Months)',
                  valuePrefix: '$',
                  size: 'lg',
                }),
              })
          }
        case 'levelsFull':
          switch (memberType) {
            case MemberType.all:
              return new MetricData({
                metricID: 'levelsFull',
                chartData: new FullLevelsChartData({
                  levels: [
                    {
                      name: 'A',
                      memberCount: 1907,
                      scoreStart: 30,
                      scoreEnd: 100,
                      avgProductCount: 11.262716308337703,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: 1899 },
                          { key: 'Current Month - 4', value: 1907 },
                          { key: 'Current Month - 3', value: 1907 },
                          { key: 'Current Month - 2', value: 1907 },
                          { key: 'Current Month - 1', value: 1907 },
                          { key: 'Current', value: 1907 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: 65, churn: 0 },
                        { timeline: Timeline['3m'], growth: 70, churn: 0 },
                        { timeline: Timeline['6m'], growth: 72, churn: 0 },
                        { timeline: Timeline['12m'], growth: 392, churn: 0 },
                        { timeline: Timeline['ytd'], growth: 463, churn: 0 },
                      ],
                    },
                    {
                      name: 'B',
                      memberCount: 7053,
                      scoreStart: 19,
                      scoreEnd: 30,
                      avgProductCount: 6.649652630086488,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: 7015 },
                          { key: 'Current Month - 4', value: 7053 },
                          { key: 'Current Month - 3', value: 7053 },
                          { key: 'Current Month - 2', value: 7053 },
                          { key: 'Current Month - 1', value: 7053 },
                          { key: 'Current', value: 7053 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: 140, churn: 64 },
                        { timeline: Timeline['3m'], growth: 182, churn: 128 },
                        { timeline: Timeline['6m'], growth: 209, churn: 178 },
                        { timeline: Timeline['12m'], growth: 1121, churn: 216 },
                        { timeline: Timeline['ytd'], growth: 1279, churn: 221 },
                      ],
                    },
                    {
                      name: 'C',
                      memberCount: 17181,
                      scoreStart: 12,
                      scoreEnd: 19,
                      avgProductCount: 4.353122635469414,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: 17185 },
                          { key: 'Current Month - 4', value: 17181 },
                          { key: 'Current Month - 3', value: 17181 },
                          { key: 'Current Month - 2', value: 17181 },
                          { key: 'Current Month - 1', value: 17181 },
                          { key: 'Current', value: 17181 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: 181, churn: 92 },
                        { timeline: Timeline['3m'], growth: 126, churn: 101 },
                        { timeline: Timeline['6m'], growth: 319, churn: 519 },
                        { timeline: Timeline['12m'], growth: 1638, churn: 741 },
                        { timeline: Timeline['ytd'], growth: 1899, churn: 749 },
                      ],
                    },
                    {
                      name: 'D',
                      memberCount: 18047,
                      scoreStart: 8,
                      scoreEnd: 12,
                      avgProductCount: 3.000055410871613,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: 18095 },
                          { key: 'Current Month - 4', value: 18047 },
                          { key: 'Current Month - 3', value: 18047 },
                          { key: 'Current Month - 2', value: 18047 },
                          { key: 'Current Month - 1', value: 18047 },
                          { key: 'Current', value: 18047 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: 235, churn: 181 },
                        { timeline: Timeline['3m'], growth: 175, churn: 300 },
                        { timeline: Timeline['6m'], growth: 288, churn: 640 },
                        { timeline: Timeline['12m'], growth: 1346, churn: 1140 },
                        { timeline: Timeline['ytd'], growth: 1493, churn: 1206 },
                      ],
                    },
                    {
                      name: 'E',
                      memberCount: 47205,
                      scoreStart: 4,
                      scoreEnd: 8,
                      avgProductCount: 2,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: 47427 },
                          { key: 'Current Month - 4', value: 47205 },
                          { key: 'Current Month - 3', value: 47205 },
                          { key: 'Current Month - 2', value: 47205 },
                          { key: 'Current Month - 1', value: 47205 },
                          { key: 'Current', value: 47205 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: 42, churn: 210 },
                        { timeline: Timeline['3m'], growth: 25, churn: 233 },
                        { timeline: Timeline['6m'], growth: 79, churn: 536 },
                        { timeline: Timeline['12m'], growth: 437, churn: 1303 },
                        { timeline: Timeline['ytd'], growth: 504, churn: 1454 },
                      ],
                    },
                    {
                      name: 'F',
                      memberCount: 13545,
                      scoreStart: 0,
                      scoreEnd: 4,
                      avgProductCount: 1.0000769941484446,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: 12844 },
                          { key: 'Current Month - 4', value: 12988 },
                          { key: 'Current Month - 3', value: 12988 },
                          { key: 'Current Month - 2', value: 12988 },
                          { key: 'Current Month - 1', value: 12988 },
                          { key: 'Current', value: 13545 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: 0, churn: 243 },
                        { timeline: Timeline['3m'], growth: 0, churn: 421 },
                        { timeline: Timeline['6m'], growth: 0, churn: 612 },
                        { timeline: Timeline['12m'], growth: 0, churn: 2403 },
                        { timeline: Timeline['ytd'], growth: 0, churn: 2806 },
                      ],
                    },
                  ],
                }),
              })
            case MemberType.direct:
              return new MetricData({
                metricID: 'levelsDirect',
                chartData: new FullLevelsChartData({
                  levels: [
                    {
                      name: 'A',
                      memberCount: 1200,
                      scoreStart: 30,
                      scoreEnd: 100,
                      avgProductCount: 11.262716308337703,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (1200 * 1899) / 1907 },
                          { key: 'Current Month - 4', value: (1200 * 1907) / 1907 },
                          { key: 'Current Month - 3', value: (1200 * 1907) / 1907 },
                          { key: 'Current Month - 2', value: (1200 * 1907) / 1907 },
                          { key: 'Current Month - 1', value: (1200 * 1907) / 1907 },
                          { key: 'Current', value: 1200 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: (65 * 1200) / 1907, churn: 0 },
                        { timeline: Timeline['3m'], growth: (70 * 1200) / 1907, churn: 0 },
                        { timeline: Timeline['6m'], growth: (72 * 1200) / 1907, churn: 0 },
                        { timeline: Timeline['12m'], growth: (392 * 1200) / 1907, churn: 0 },
                        { timeline: Timeline['ytd'], growth: (463 * 1200) / 1907, churn: 0 },
                      ],
                    },
                    {
                      name: 'B',
                      memberCount: 3500,
                      scoreStart: 19,
                      scoreEnd: 30,
                      avgProductCount: 6.649652630086488,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (3500 * 7015) / 7053 },
                          { key: 'Current Month - 4', value: (3500 * 7053) / 7053 },
                          { key: 'Current Month - 3', value: (3500 * 7053) / 7053 },
                          { key: 'Current Month - 2', value: (3500 * 7053) / 7053 },
                          { key: 'Current Month - 1', value: (3500 * 7053) / 7053 },
                          { key: 'Current', value: 3500 },
                        ],
                      }),
                      movement: [
                        {
                          timeline: Timeline['1m'],
                          growth: (140 * 3500) / 7053,
                          churn: (64 * 3500) / 7053,
                        },
                        {
                          timeline: Timeline['3m'],
                          growth: (182 * 3500) / 7053,
                          churn: (128 * 3500) / 7053,
                        },
                        {
                          timeline: Timeline['6m'],
                          growth: (209 * 3500) / 7053,
                          churn: (178 * 3500) / 7053,
                        },
                        {
                          timeline: Timeline['12m'],
                          growth: (1121 * 3500) / 7053,
                          churn: (216 * 3500) / 7053,
                        },
                        {
                          timeline: Timeline['ytd'],
                          growth: (1279 * 3500) / 7053,
                          churn: (221 * 3500) / 7053,
                        },
                      ],
                    },
                    {
                      name: 'C',
                      memberCount: 8000,
                      scoreStart: 12,
                      scoreEnd: 19,
                      avgProductCount: 4.353122635469414,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (8000 * 17185) / 17181 },
                          { key: 'Current Month - 4', value: (8000 * 17181) / 17181 },
                          { key: 'Current Month - 3', value: (8000 * 17181) / 17181 },
                          { key: 'Current Month - 2', value: (8000 * 17181) / 17181 },
                          { key: 'Current Month - 1', value: (8000 * 17181) / 17181 },
                          { key: 'Current', value: 8000 },
                        ],
                      }),
                      movement: [
                        {
                          timeline: Timeline['1m'],
                          growth: (181 * 8000) / 17181,
                          churn: (92 * 8000) / 17181,
                        },
                        {
                          timeline: Timeline['3m'],
                          growth: (126 * 8000) / 17181,
                          churn: (101 * 8000) / 17181,
                        },
                        {
                          timeline: Timeline['6m'],
                          growth: (319 * 8000) / 17181,
                          churn: (519 * 8000) / 17181,
                        },
                        {
                          timeline: Timeline['12m'],
                          growth: (1638 * 8000) / 17181,
                          churn: (741 * 8000) / 17181,
                        },
                        {
                          timeline: Timeline['ytd'],
                          growth: (1899 * 8000) / 17181,
                          churn: (749 * 8000) / 17181,
                        },
                      ],
                    },
                    {
                      name: 'D',
                      memberCount: 8000,
                      scoreStart: 8,
                      scoreEnd: 12,
                      avgProductCount: 3.000055410871613,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (8000 * 18095) / 18047 },
                          { key: 'Current Month - 4', value: (8000 * 18047) / 18047 },
                          { key: 'Current Month - 3', value: (8000 * 18047) / 18047 },
                          { key: 'Current Month - 2', value: (8000 * 18047) / 18047 },
                          { key: 'Current Month - 1', value: (8000 * 18047) / 18047 },
                          { key: 'Current', value: 8000 },
                        ],
                      }),
                      movement: [
                        {
                          timeline: Timeline['1m'],
                          growth: (235 * 8000) / 18047,
                          churn: (181 * 8000) / 18047,
                        },
                        {
                          timeline: Timeline['3m'],
                          growth: (175 * 8000) / 18047,
                          churn: (300 * 8000) / 18047,
                        },
                        {
                          timeline: Timeline['6m'],
                          growth: (288 * 8000) / 18047,
                          churn: (640 * 8000) / 18047,
                        },
                        {
                          timeline: Timeline['12m'],
                          growth: (1346 * 8000) / 18047,
                          churn: (1140 * 8000) / 18047,
                        },
                        {
                          timeline: Timeline['ytd'],
                          growth: (1493 * 8000) / 18047,
                          churn: (1206 * 8000) / 18047,
                        },
                      ],
                    },
                    {
                      name: 'E',
                      memberCount: 15000,
                      scoreStart: 4,
                      scoreEnd: 8,
                      avgProductCount: 2,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (15000 * 47427) / 47205 },
                          { key: 'Current Month - 4', value: (15000 * 47205) / 47205 },
                          { key: 'Current Month - 3', value: (15000 * 47205) / 47205 },
                          { key: 'Current Month - 2', value: (15000 * 47205) / 47205 },
                          { key: 'Current Month - 1', value: (15000 * 47205) / 47205 },
                          { key: 'Current', value: 15000 },
                        ],
                      }),
                      movement: [
                        {
                          timeline: Timeline['1m'],
                          growth: (42 * 15000) / 47205,
                          churn: (210 * 15000) / 47205,
                        },
                        {
                          timeline: Timeline['3m'],
                          growth: (25 * 15000) / 47205,
                          churn: (233 * 15000) / 47205,
                        },
                        {
                          timeline: Timeline['6m'],
                          growth: (79 * 15000) / 47205,
                          churn: (536 * 15000) / 47205,
                        },
                        {
                          timeline: Timeline['12m'],
                          growth: (437 * 15000) / 47205,
                          churn: (1303 * 15000) / 47205,
                        },
                        {
                          timeline: Timeline['ytd'],
                          growth: (504 * 15000) / 47205,
                          churn: (1454 * 15000) / 47205,
                        },
                      ],
                    },
                    {
                      name: 'F',
                      memberCount: 5000,
                      scoreStart: 0,
                      scoreEnd: 4,
                      avgProductCount: 1.0000769941484446,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (5000 * 12844) / 13545 },
                          { key: 'Current Month - 4', value: (5000 * 12988) / 13545 },
                          { key: 'Current Month - 3', value: (5000 * 12988) / 13545 },
                          { key: 'Current Month - 2', value: (5000 * 12988) / 13545 },
                          { key: 'Current Month - 1', value: (5000 * 12988) / 13545 },
                          { key: 'Current', value: 5000 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: 0, churn: (243 * 5000) / 13545 },
                        { timeline: Timeline['3m'], growth: 0, churn: (421 * 5000) / 13545 },
                        { timeline: Timeline['6m'], growth: 0, churn: (612 * 5000) / 13545 },
                        { timeline: Timeline['12m'], growth: 0, churn: (2403 * 5000) / 13545 },
                        { timeline: Timeline['ytd'], growth: 0, churn: (2806 * 5000) / 13545 },
                      ],
                    },
                  ],
                }),
              })
            case MemberType.indirect:
              return new MetricData({
                metricID: 'levelsIndirect',
                chartData: new FullLevelsChartData({
                  levels: [
                    {
                      name: 'A',
                      memberCount: 707,
                      scoreStart: 30,
                      scoreEnd: 100,
                      avgProductCount: 11.262716308337703,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (707 * 1899) / 1907 },
                          { key: 'Current Month - 4', value: (707 * 1907) / 1907 },
                          { key: 'Current Month - 3', value: (707 * 1907) / 1907 },
                          { key: 'Current Month - 2', value: (707 * 1907) / 1907 },
                          { key: 'Current Month - 1', value: (707 * 1907) / 1907 },
                          { key: 'Current', value: 707 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: (65 * 707) / 1907, churn: 0 },
                        { timeline: Timeline['3m'], growth: (70 * 707) / 1907, churn: 0 },
                        { timeline: Timeline['6m'], growth: (72 * 707) / 1907, churn: 0 },
                        { timeline: Timeline['12m'], growth: (392 * 707) / 1907, churn: 0 },
                        { timeline: Timeline['ytd'], growth: (463 * 707) / 1907, churn: 0 },
                      ],
                    },
                    {
                      name: 'B',
                      memberCount: 3553,
                      scoreStart: 19,
                      scoreEnd: 30,
                      avgProductCount: 6.649652630086488,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (3553 * 7015) / 7053 },
                          { key: 'Current Month - 4', value: (3553 * 7053) / 7053 },
                          { key: 'Current Month - 3', value: (3553 * 7053) / 7053 },
                          { key: 'Current Month - 2', value: (3553 * 7053) / 7053 },
                          { key: 'Current Month - 1', value: (3553 * 7053) / 7053 },
                          { key: 'Current', value: 3553 },
                        ],
                      }),
                      movement: [
                        {
                          timeline: Timeline['1m'],
                          growth: (140 * 3553) / 7053,
                          churn: (64 * 3553) / 7053,
                        },
                        {
                          timeline: Timeline['3m'],
                          growth: (182 * 3553) / 7053,
                          churn: (128 * 3553) / 7053,
                        },
                        {
                          timeline: Timeline['6m'],
                          growth: (209 * 3553) / 7053,
                          churn: (178 * 3553) / 7053,
                        },
                        {
                          timeline: Timeline['12m'],
                          growth: (1121 * 3553) / 7053,
                          churn: (216 * 3553) / 7053,
                        },
                        {
                          timeline: Timeline['ytd'],
                          growth: (1279 * 3553) / 7053,
                          churn: (221 * 3553) / 7053,
                        },
                      ],
                    },
                    {
                      name: 'C',
                      memberCount: 9181,
                      scoreStart: 12,
                      scoreEnd: 19,
                      avgProductCount: 4.353122635469414,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (9181 * 17185) / 17181 },
                          { key: 'Current Month - 4', value: (9181 * 17181) / 17181 },
                          { key: 'Current Month - 3', value: (9181 * 17181) / 17181 },
                          { key: 'Current Month - 2', value: (9181 * 17181) / 17181 },
                          { key: 'Current Month - 1', value: (9181 * 17181) / 17181 },
                          { key: 'Current', value: 9181 },
                        ],
                      }),
                      movement: [
                        {
                          timeline: Timeline['1m'],
                          growth: (181 * 9181) / 17181,
                          churn: (92 * 9181) / 17181,
                        },
                        {
                          timeline: Timeline['3m'],
                          growth: (126 * 9181) / 17181,
                          churn: (101 * 9181) / 17181,
                        },
                        {
                          timeline: Timeline['6m'],
                          growth: (319 * 9181) / 17181,
                          churn: (519 * 9181) / 17181,
                        },
                        {
                          timeline: Timeline['12m'],
                          growth: (1638 * 9181) / 17181,
                          churn: (741 * 9181) / 17181,
                        },
                        {
                          timeline: Timeline['ytd'],
                          growth: (1899 * 9181) / 17181,
                          churn: (749 * 9181) / 17181,
                        },
                      ],
                    },
                    {
                      name: 'D',
                      memberCount: 10047,
                      scoreStart: 8,
                      scoreEnd: 12,
                      avgProductCount: 3.000055410871613,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (10047 * 18095) / 18047 },
                          { key: 'Current Month - 4', value: (10047 * 18047) / 18047 },
                          { key: 'Current Month - 3', value: (10047 * 18047) / 18047 },
                          { key: 'Current Month - 2', value: (10047 * 18047) / 18047 },
                          { key: 'Current Month - 1', value: (10047 * 18047) / 18047 },
                          { key: 'Current', value: 10047 },
                        ],
                      }),
                      movement: [
                        {
                          timeline: Timeline['1m'],
                          growth: (235 * 10047) / 18047,
                          churn: (181 * 10047) / 18047,
                        },
                        {
                          timeline: Timeline['3m'],
                          growth: (175 * 10047) / 18047,
                          churn: (300 * 10047) / 18047,
                        },
                        {
                          timeline: Timeline['6m'],
                          growth: (288 * 10047) / 18047,
                          churn: (640 * 10047) / 18047,
                        },
                        {
                          timeline: Timeline['12m'],
                          growth: (1346 * 10047) / 18047,
                          churn: (1140 * 10047) / 18047,
                        },
                        {
                          timeline: Timeline['ytd'],
                          growth: (1493 * 10047) / 18047,
                          churn: (1206 * 10047) / 18047,
                        },
                      ],
                    },
                    {
                      name: 'E',
                      memberCount: 32205,
                      scoreStart: 4,
                      scoreEnd: 8,
                      avgProductCount: 2,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (32205 * 47427) / 47205 },
                          { key: 'Current Month - 4', value: (32205 * 47205) / 47205 },
                          { key: 'Current Month - 3', value: (32205 * 47205) / 47205 },
                          { key: 'Current Month - 2', value: (32205 * 47205) / 47205 },
                          { key: 'Current Month - 1', value: (32205 * 47205) / 47205 },
                          { key: 'Current', value: 32205 },
                        ],
                      }),
                      movement: [
                        {
                          timeline: Timeline['1m'],
                          growth: (42 * 32205) / 47205,
                          churn: (210 * 32205) / 47205,
                        },
                        {
                          timeline: Timeline['3m'],
                          growth: (25 * 32205) / 47205,
                          churn: (233 * 32205) / 47205,
                        },
                        {
                          timeline: Timeline['6m'],
                          growth: (79 * 32205) / 47205,
                          churn: (536 * 32205) / 47205,
                        },
                        {
                          timeline: Timeline['12m'],
                          growth: (437 * 32205) / 47205,
                          churn: (1303 * 32205) / 47205,
                        },
                        {
                          timeline: Timeline['ytd'],
                          growth: (504 * 32205) / 47205,
                          churn: (1454 * 32205) / 47205,
                        },
                      ],
                    },
                    {
                      name: 'F',
                      memberCount: 8545,
                      scoreStart: 0,
                      scoreEnd: 4,
                      avgProductCount: 1.0000769941484446,
                      memberCountHistoryData: new StandardChartData({
                        points: [
                          { key: 'Current Month - 5', value: (8545 * 12844) / 13545 },
                          { key: 'Current Month - 4', value: (8545 * 12988) / 13545 },
                          { key: 'Current Month - 3', value: (8545 * 12988) / 13545 },
                          { key: 'Current Month - 2', value: (8545 * 12988) / 13545 },
                          { key: 'Current Month - 1', value: (8545 * 12988) / 13545 },
                          { key: 'Current', value: 8545 },
                        ],
                      }),
                      movement: [
                        { timeline: Timeline['1m'], growth: 0, churn: (243 * 8545) / 13545 },
                        { timeline: Timeline['3m'], growth: 0, churn: (421 * 8545) / 13545 },
                        { timeline: Timeline['6m'], growth: 0, churn: (612 * 8545) / 13545 },
                        { timeline: Timeline['12m'], growth: 0, churn: (2403 * 8545) / 13545 },
                        { timeline: Timeline['ytd'], growth: 0, churn: (2806 * 8545) / 13545 },
                      ],
                    },
                  ],
                }),
              })
          }
      }

      const result = federalMockData?.[metricID]?.[memberType]
      if (result) {
        return result
      }
    }
  }

  async getProductCategoryRatings(clientID: string, goalType: ProductCategoryRatingGoals) {
    if (clientID === 'federal') {
      const propensityType: PropensityType =
        goalType === ProductCategoryRatingBasicGoalType.churn
          ? PropensityType.churn
          : PropensityType.growth

      const productCategories = await dataSource.fetchProductCategories(clientID)
      return productCategories.map((productCategory) => {
        const rating = Math.random() * 5
        return new ProductCategoryRating({
          productCategory,
          propensityType,
          rating,
        })
      })
    }

    return
  }
}
