import { ReactNode, useMemo } from 'react'
// @mui
import { CssBaseline } from '@mui/material'
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'

import { useHookstate } from '@hookstate/core'
import chroma from 'chroma-js'
import { useLocation } from 'react-router-dom'
import { authPages, ScreenPath } from '../navigation'
import globalState from '../service/external/GlobalState'
import breakpoints from './breakpoints'
import componentsOverride from './overrides'
import palette from './palette'
import shadows, { customShadows } from './shadows'
import typography from './typography'

interface ThemeProviderProps {
  children: ReactNode
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  // Navigation
  const location = useLocation()

  // Global state
  const client = useHookstate(globalState.currentClient).get()

  // Calculations
  const isAuthPage = authPages.includes(location.pathname as ScreenPath)
  const primary = isAuthPage || !client?.color ? palette.light.primary.main : client.color

  // -- Theme
  const isLight = true

  const themeOptions = useMemo(
    () => ({
      palette: {
        ...(isLight ? palette.light : palette.dark),
        primary: {
          lighter: chroma(primary).brighten(0.3).hex(),
          light: chroma(primary).brighten(0.1).hex(),
          main: primary,
          dark: chroma(primary).darken(0.1).hex(),
          darker: chroma(primary).darken(0.3).hex(),
        },
      },
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, primary],
  )

  const theme = createTheme(themeOptions as any)
  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
