import ChartDataType from './ChartDataType'

export interface ChartDataArgument {
  type: ChartDataType
}

export default abstract class ChartData {
  readonly type: ChartDataType

  constructor(arg: ChartDataArgument) {
    this.type = arg.type
  }

  abstract isEmpty(): boolean
}
