import { Icon } from '@iconify/react'
import { IconButton, Popover } from '@mui/material'
import { useState } from 'react'
import icons from '../../../config/icons.config'
import Filter, { FilterValues } from '../../../model/filter/Filter'
import FilterOption from '../../../model/filter/FilterOption'
import FilterValueMenu from './FilterValueMenu'

interface FilterButtonProps {
  filter?: Filter
  filterOption: FilterOption
  onSubmit: (values: FilterValues) => void
}

export default function FilterButton({ filter, filterOption, onSubmit }: FilterButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick} size='small'>
        <Icon icon={icons.filter} />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <FilterValueMenu
          filter={filter}
          filterOption={filterOption}
          onSubmit={onSubmit}
          onClose={handleClose}
        />
      </Popover>
    </>
  )
}
