import { Navigate } from 'react-router-dom'
import { authManager } from '../../managers/_manager.config'
import { ScreenPath } from '../../navigation'
import AutoLogout from './AutoLogout'

export interface AuthRequiredViewProps {
  children: JSX.Element
}

export default function AuthRequiredView({ children }: AuthRequiredViewProps) {
  if (!authManager.isAuthenticated()) {
    return <Navigate to={ScreenPath.login} />
  }

  return (
    <>
      {children}
      <AutoLogout />
    </>
  )
}
