import { useHookstate } from '@hookstate/core'
import { MenuItem, Select, Stack, Typography } from '@mui/material'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { dashboardManager } from '../../managers/_manager.config'
import Metric, { MetricLocation } from '../../model/admin/Metric'
import MemberType from '../../model/dashboard/MemberType'
import MetricsConfig from '../../model/metric/MetricsConfig'
import globalState from '../../service/external/GlobalState'
import StringUtil from '../../util/StringUtil'
import MetricConfigModal from '../metric/MetricConfigModal'
import SelectMetricModal from '../metric/SelectMetricModal'
import ConfigToolbar from '../util/ConfigToolbar'
import ConfirmModal from '../util/ConfirmModal'

export interface DashboardConfigToolbarProps {
  configs: MetricsConfig[]
}

export default function DashboardConfigToolbar({ configs }: DashboardConfigToolbarProps) {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()

  // Global state
  const currentConfig = useHookstate(globalState.currentDashboardConfig).get()
  const dashboardMetrics = useHookstate(globalState.dashboardMetrics).get().data
  const isEditing = useHookstate(globalState.isEditingDashboard).get()
  const memberType = useHookstate(globalState.dashboardMemberType).get()

  // -- Actions
  function onEdit() {
    if (dashboardManager.toggleEditing()) {
      showSnackbar('dashboard-updated', 'Changes saved')
    }
  }

  function onAddMetric() {
    if (!dashboardMetrics || !currentConfig) return

    const id = 'addDashboardMetric'
    openModal(
      id,
      <SelectMetricModal
        availableMetrics={
          dashboardMetrics.filter(
            (metric) =>
              !currentConfig.hasMetric(metric.metricID) &&
              metric.isAvailable(MetricLocation.dashboard),
          ) as Metric[]
        }
        onMetricClick={(metric) => {
          dashboardManager.addMetric(metric)
        }}
        onClose={() => closeModal(id)}
      />,
    )
  }

  function onCreateConfig() {
    const config = MetricsConfig.create([MetricLocation.dashboard])
    openModal(
      config.metricsConfigID,
      <MetricConfigModal
        config={config}
        title='Create Dashboard'
        isCreating
        onSubmit={(config) => dashboardManager.saveConfig(config)}
        onClose={() => closeModal(config.metricsConfigID)}
        icon={icons.dashboard}
      />,
    )
  }

  function onRenameConfig() {
    if (!currentConfig) return
    openModal(
      currentConfig!.metricsConfigID,
      <MetricConfigModal
        config={currentConfig! as MetricsConfig}
        title='Rename Dashboard'
        onSubmit={(config) => dashboardManager.saveConfig(config)}
        onClose={() => closeModal(currentConfig.metricsConfigID)}
      />,
    )
  }

  function onDeleteConfig() {
    if (!currentConfig) return
    const id = 'delete-config'
    openModal(
      id,
      <ConfirmModal
        title='Delete Dashboard?'
        message='Are you sure that you want to delete this dashboard? Deleting this dashboard will remove it from your entire organization'
        confirmation='Delete'
        onConfirm={() => {
          dashboardManager.deleteConfig(currentConfig.metricsConfigID)
          showSnackbar('dashboard-updated', 'Deleted dashboard')
        }}
        onClose={() => closeModal(id)}
      />,
    )
  }

  function onSelectConfig(config: MetricsConfig) {
    dashboardManager.selectConfig(config)
  }

  function onMemberTypeChange(memberType: MemberType) {
    dashboardManager.selectMemberType(memberType)
  }

  // -- UI
  if (!currentConfig) return null
  return (
    <Stack direction='row' justifyContent='space-between'>
      <ConfigToolbar
        configs={configs}
        currentConfig={currentConfig as MetricsConfig}
        isEditing={isEditing}
        onAddMetricClick={onAddMetric}
        onEditClick={onEdit}
        onCreateConfigClick={onCreateConfig}
        onRenameConfigClick={onRenameConfig}
        onDeleteConfigClick={onDeleteConfig}
        onSelectConfig={onSelectConfig}
      />
      <Select
        data-testid='dashboard-select-membertype'
        size='small'
        value={memberType}
        onChange={(e) => onMemberTypeChange(e.target.value as MemberType)}
        sx={{
          background: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {Object.values(MemberType).map((type) => (
          <MenuItem key={type} value={type}>
            <Typography fontWeight={type === memberType ? 'medium' : 'normal'}>
              {StringUtil.capitalize(type)} Members
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}
