import { Button, Stack } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { Icon } from '@iconify/react'
import resetPasswordImage from '../../assets/reset-password.svg'
import AuthFlowLayout from '../../components/layout/AuthFlowLayout'
import FormController from '../../components/util/FormController'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { authManager } from '../../managers/_manager.config'
import { ScreenPath } from '../../navigation'

export enum ResetPasswordState {
  initial = 'initial',
  reset = 'reset',
}

export interface ResetPasswordFormData {
  password: string
  confirmPassword: string
}

export interface ResetPasswordPassedData {
  resetPasswordState: ResetPasswordState.reset
  resetPasswordCode: string
  resetPasswordEmail: string
}

export interface NewPasswordPassedData {
  resetPasswordState: ResetPasswordState.initial
}

function ResetPasswordScreen() {
  // Navigation
  const { showSnackbar } = useModal()
  const navigate = useNavigate()
  const location = useLocation()
  const passedData: ResetPasswordPassedData | NewPasswordPassedData | undefined = location.state

  const [isLoading, setIsLoading] = useState(false)
  const { handleSubmit, control, setError } = useForm<ResetPasswordFormData>()

  async function handleReset({ password, confirmPassword }: ResetPasswordFormData) {
    if (password !== confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: 'Passwords do not match',
      })
      return
    }
    try {
      console.log('HERE')
      if (!passedData) throw new Error('Missing data provided in navigation state')

      console.log('HERE')
      switch (passedData.resetPasswordState) {
        case ResetPasswordState.initial:
          setIsLoading(true)
          console.log('HERE')
          await authManager.setInitialPassword(password)
          setIsLoading(false)
          break
        case ResetPasswordState.reset:
          console.log('HERE')
          const { resetPasswordCode, resetPasswordEmail } = passedData
          setIsLoading(true)
          console.log('HERE')
          await authManager.resetPassword(resetPasswordCode, resetPasswordEmail, password)
          setIsLoading(false)
          break
        default:
          throw new Error('Invalid reset password state')
      }

      showSnackbar(
        'resetPasswordSuccess',
        'Your password has been reset successfully. You can now login with your new password',
        'success',
      )

      navigate(ScreenPath.login)
    } catch (error) {
      setIsLoading(false)
      setError('confirmPassword', {
        type: 'manual',
        message:
          'Something went wrong resetting your password. Try going back to login and resetting your password again',
      })
    }
  }

  return (
    <AuthFlowLayout title='Reset your password' image={resetPasswordImage}>
      <form onSubmit={handleSubmit(handleReset)}>
        <Stack gap={5}>
          <Stack gap={1}>
            <FormController
              name='password'
              control={control}
              rules={{ required: 'Please input your new password' }}
              placeholder='New password'
              type='password'
              icon={<Icon icon={icons.lock} />}
            />
            <FormController
              name='confirmPassword'
              control={control}
              rules={{ required: 'Please confirm your new password' }}
              placeholder='Confirm password'
              type='password'
              icon={<Icon icon={icons.lock} />}
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            <Button onClick={() => navigate(ScreenPath.login)} fullWidth>
              Cancel
            </Button>
            <Button type='submit' variant='contained' fullWidth disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Reset'}
            </Button>
          </Stack>
        </Stack>
      </form>
    </AuthFlowLayout>
  )
}

export default ResetPasswordScreen
