import { Box, Dialog, Stack } from '@mui/material'

import Metric from '../../model/admin/Metric'
import ChartType from '../../model/chart/ChartType'
import OpportunityResults from '../../model/opportunity/OpportunityResults'
import StringUtil from '../../util/StringUtil'
import EChart from '../chart/Echart'
import MetricErrorView from '../metric/MetricErrorView'
import ModalCloseButton from '../modal/ModalCloseButton'
import ModalContent from '../modal/ModalContent'
import ModalHeader from '../modal/ModalHeader'
import AttributeResultsPanel from './AttributeResultsPanel'

interface MetricAttributeResultsModalProps {
  metric: Metric
  results: OpportunityResults
  onClose: () => void
}

export default function MetricAttributeResultsModal({
  metric,
  results,
  onClose,
}: MetricAttributeResultsModalProps) {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='lg'>
      <ModalCloseButton onClose={onClose} />
      <ModalHeader title={metric.name} description={metric.description} center />
      <ModalContent>
        {results.hasAttributesForMetric(metric.metricID) ? (
          <Stack gap={4} alignItems='center'>
            <Box sx={{ width: '100%', maxWidth: 700, minHeight: '100%', height: 400 }}>
              <EChart
                type={ChartType.groupedBar}
                data={results.getMetricData(metric).chartData}
                valueFormatter={(value) => `${StringUtil.numberFormat(value, 2)}%`}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <AttributeResultsPanel
                attributes={results.getAttributeResultsForMetric(metric.metricID)}
                hideMetricCol
                enableColumnActions
              />
            </Box>
          </Stack>
        ) : (
          <Stack>
            <MetricErrorView metricID={metric.metricID} />
          </Stack>
        )}
      </ModalContent>
    </Dialog>
  )
}
