import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface CustomChipControllerProps {
  name: string
  control: any
  defaultValue: string[]
  label: string
  options: string[]
  displayLabels?: string[]
  onChange?: (event: SelectChangeEvent<any>) => void
  disabled?: boolean // add disabled property here
}

const CustomChipController: React.FC<CustomChipControllerProps> = ({
  name,
  control,
  defaultValue,
  label,
  options,
  displayLabels,
  onChange,
  disabled = false, // extract disabled from props here and default to false
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field }) => (
      <FormControl fullWidth>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          {...field}
          labelId={`${name}-label`}
          label={label}
          size='medium'
          multiple
          disabled={disabled} // add disabled property here
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  label={displayLabels ? displayLabels[options.indexOf(value)] : value}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          )}
          onChange={(e: SelectChangeEvent<any>) => {
            field.onChange(e.target.value)
            if (onChange) {
              onChange(e)
            }
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={option} value={option}>
              {displayLabels ? displayLabels[index] : option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
  />
)
export default CustomChipController
