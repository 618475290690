import { Icon } from '@iconify/react'
import {
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import mountain from '../../assets/mountain.svg'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import { exploreManager } from '../../managers/_manager.config'
import ChartType from '../../model/chart/ChartType'
import FullLevelsChartData, {
  FullLevelData,
  LevelMovement,
} from '../../model/chart/FullLevelsChartData'
import StandardChartData from '../../model/chart/StandardChartData'
import { ScreenPath } from '../../navigation'
import palette from '../../theme/palette'
import ColorUtil from '../../util/ColorUtil'
import StringUtil from '../../util/StringUtil'
import InfoTag from '../metric/InfoTag'
import EChart from './Echart'
import Pyramid from './Pyramid'

const styles = {
  rowFlex: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'stretch',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
  },
  columnFlex: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredFlex: { justifyContent: 'center', alignItems: 'center' },
  cellStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderBottom: '0.0625rem solid #e0e0e0',
    padding: '0.125rem 0.25rem',
  },
  levelName: { fontSize: '2.2rem', marginRight: '0.625rem', fontWeight: 700 },
  circle: (size: number, color: string) => {
    const bubbleSize = size
    const iconSize = bubbleSize * 0.4 // Assuming the icon is 60% of the bubble size

    return {
      bubble: {
        width: `${size}px`,
        marginRight: bubbleSize * 0.25,
        height: `${size}px`,
        ...styles.centeredFlex,
        backgroundColor: color,
        borderRadius: '50%',
        boxShadow: '0.3rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1)',
        transition: 'ease-out 0.3s',
      },
      icon: {
        fontSize: `${iconSize}px`, // Convert size to pixels
        color: 'white',
      },
    }
  },
}

const toggleStyles = {
  buttonGroup: {
    flexDirection: 'row',
    my: 1,
  },
  button: {
    fontSize: '0.8rem',
    padding: '0.125rem 0.3125rem',
    margin: '0 0.0625rem',
  },
}

const MAX_WIDTH = 50

export interface LevelsTableProps {
  data: FullLevelsChartData
}
interface TableRowsProps {
  data: FullLevelsChartData
  normalizedValues: number[]
  currentDuration: string
}
interface TitleRowProps {
  onDurationChange: (event: any, newDuration: React.SetStateAction<string> | null) => void
  currentDuration: string
}

export default function LevelsCard({ data }: LevelsTableProps) {
  // Navigation
  const navigate = useNavigate()

  function onButtonClick() {
    navigate('/explore')
  }

  const values = data.levels.map((level) => level.memberCount)
  const maxValue = Math.max(...values)
  const normalizedValues = values.map((value) => value / maxValue)

  const [duration, setDuration] = React.useState('1m')

  const handleDurationChange = (event: any, newDuration: React.SetStateAction<string> | null) => {
    if (newDuration !== null) {
      setDuration(newDuration)
    }
  }

  return (
    <div>
      <TitleRow onDurationChange={handleDurationChange} currentDuration={duration} />
      <TableRows data={data} normalizedValues={normalizedValues} currentDuration={duration} />
    </div>
  )
}

const TitleRow: React.FC<TitleRowProps> = ({ onDurationChange, currentDuration }) => {
  return (
    <Grid container>
      <Grid item xs={3.75} sx={{ ...styles.cellStyles, alignItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', marginLeft: 12 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Title title='Members Per Level' />
            <InfoTag description={copy.dashboard.membersPerLevel.description} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} sx={styles.cellStyles} flexDirection={'column'}>
        <Stack direction='row' justifyContent='center' alignItems='center' width='100%'>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', marginLeft: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Title title='Level Movement' />
              <InfoTag description={copy.dashboard.levelsFull.description} />
            </Box>
          </Box>
        </Stack>

        <ToggleButtonGroup
          size='small'
          value={currentDuration}
          exclusive
          onChange={onDurationChange}
          aria-label='duration'
          sx={toggleStyles.buttonGroup}
        >
          <ToggleButton sx={toggleStyles.button} value='1m' aria-label='1 month'>
            1m
          </ToggleButton>
          <ToggleButton sx={toggleStyles.button} value='3m' aria-label='3 months'>
            3m
          </ToggleButton>
          <ToggleButton sx={toggleStyles.button} value='12m' aria-label='12 months'>
            12m
          </ToggleButton>
          <ToggleButton sx={toggleStyles.button} value='ytd' aria-label='year to date'>
            YTD
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={2} sx={{ ...styles.cellStyles, alignItems: 'flex-start' }}>
        <Title title='Avg. Products and Services' />
      </Grid>
      <Grid item xs={3.25} sx={{ ...styles.cellStyles, alignItems: 'flex-start' }}>
        <Stack direction='column' justifyContent='space-between' width={150} alignItems='center'>
          <Title title='# of Members' />
          <Typography color='text.secondary' variant='body2' fontSize={12}>
            Past 6 Months
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}
const TableRows = ({ data, normalizedValues, currentDuration }: TableRowsProps) => {
  // -- Theme
  const theme = useTheme()
  const colorScheme = ColorUtil.generateColorScheme(theme.palette.primary.main, data.levels.length)

  // Compute the maxWidth among all movementData
  const maxWidth = Math.max(
    ...data.levels.map((level) => {
      const movementData = level.movement?.find((m) => m.timeline === currentDuration)
      return movementData ? Math.max(movementData.growth, movementData.churn) : 0
    }),
  )

  return (
    <Grid container>
      {/* First column of the "Members Per Level" section */}
      <Grid
        item
        xs={2.25}
        sx={{
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundImage: `url(${mountain})`,
            backgroundSize: 'cover',
            opacity: 0.3,
          },
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        {normalizedValues.map((normalizedValue, index) => (
          <Grid
            sx={{
              ...FIXED_HEIGHT,
              ...styles.cellStyles,
              borderBottom: '0.0625rem solid #e0e0e0',
              padding: '0.125rem 0.0rem',
            }}
            direction='row'
          >
            <Pyramid key={`pyramid-${index}`} value={normalizedValue} color={colorScheme[index]} />
          </Grid>
        ))}
      </Grid>

      {/* Second column of the "Members Per Level" section */}
      <Grid item xs={1.5}>
        {data.levels.map((levelData, index) => (
          <MemberLevel key={index} data={levelData} />
        ))}
      </Grid>

      {/* "Level Movement" column */}
      <Grid item xs={3}>
        {data.levels.map((levelData, index) => {
          const movementData = levelData.movement?.find((m) => m.timeline === currentDuration)

          if (!movementData) return null
          return <MovementBubbles key={`growth-${index}`} data={movementData} maxWidth={maxWidth} />
        })}
      </Grid>

      {/* "Avg. Products" column */}
      <Grid item xs={2}>
        {data.levels.map((levelData, index) => (
          <AverageProduct key={index} data={levelData} />
        ))}
      </Grid>

      {/* "Member History" column */}
      <Grid item xs={3}>
        {data.levels.map((levelData, index) => (
          <LineChart key={`growth-${index}`} data={levelData.memberCountHistoryData} />
        ))}
      </Grid>

      {/* "Explore Members Button" column */}
      <Grid item xs={0.25}>
        {data.levels.map((levelData, index) => (
          <ExploreButton data={levelData} key={`growth-${index}`} />
        ))}
      </Grid>
    </Grid>
  )
}

const Title: React.FC<{ title: string }> = ({ title }) => (
  <Typography fontWeight='bold'>{title}</Typography>
)
const FIXED_HEIGHT = { height: '60px' }

const MemberLevel: React.FC<{ data: FullLevelData }> = ({ data }) => {
  const { name, scoreStart, scoreEnd, memberCount } = data
  const mppRange = `${scoreStart}-${scoreEnd}`

  return (
    <Grid sx={{ ...FIXED_HEIGHT, ...styles.cellStyles }} direction='row'>
      <Typography style={styles.levelName}>{name}</Typography>
      <Grid item container direction='column' spacing={0}>
        <Typography variant='subtitle2'>{StringUtil.numberFormat(memberCount)} Members</Typography>
        <Typography color='text.secondary' variant='body2'>
          {mppRange} MPP
        </Typography>
      </Grid>
    </Grid>
  )
}

const AverageProduct: React.FC<{ data: FullLevelData }> = ({ data }) => {
  return (
    <Typography
      sx={{ ...styles.cellStyles, ...FIXED_HEIGHT }}
      fontSize={'20px'}
      color='grey'
      fontWeight={700}
    >
      {StringUtil.numberFormat(data.avgProductCount, 1)}
    </Typography>
  )
}

const ExploreButton: React.FC<{ data: FullLevelData }> = ({ data }) => {
  const navigate = useNavigate()

  const onButtonClick = () => {
    exploreManager.setLevelFilters(data.name)
    navigate(`${ScreenPath.explore}/members`)
  }

  return (
    <Grid
      sx={{
        ...styles.centeredFlex,
        ...styles.cellStyles,
        ...FIXED_HEIGHT,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip
        title='Explore members'
        placement='top'
        PopperProps={{
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <IconButton onClick={onButtonClick}>
          <Icon icon={icons.caret} />
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

const MovementBubbles: React.FC<{ data: LevelMovement; maxWidth: number }> = ({
  data: { growth: up, churn: down },
  maxWidth,
}) => {
  const MIN_WIDTH = 10
  const MAX_WIDTH = 55

  const normalizeValue = (value: number) => {
    const normalizedRatio = value / maxWidth // value is between 0 and 1
    return normalizedRatio * (MAX_WIDTH - MIN_WIDTH) + MIN_WIDTH
  }

  const normalizedUp = up === 0 ? 0 : normalizeValue(up)
  const normalizedDown = down === 0 ? 0 : normalizeValue(down)

  return (
    <Grid
      sx={{
        ...styles.centeredFlex,
        ...styles.cellStyles,
        ...FIXED_HEIGHT,
        justifyContent: 'center',
      }}
    >
      <Stack direction='row' justifyContent='space-between' width={150} alignItems='center'>
        <Bubble
          originalWidth={up}
          width={normalizedUp}
          icon={icons.growth}
          color={palette.light.growth}
        />
        <Bubble
          originalWidth={down}
          width={normalizedDown}
          icon={icons.churn}
          color={palette.light.churn}
        />
      </Stack>
    </Grid>
  )
}

const Bubble: React.FC<{ width: number; originalWidth: number; icon: string; color: string }> = ({
  width,
  originalWidth,
  icon,
  color,
}) => {
  const circleStyles = styles.circle(width, color)

  return (
    <Tooltip
      title={`${originalWidth.toFixed(0)}`}
      placement='top'
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      {width === 0 ? (
        <div />
      ) : (
        <ButtonBase
          style={{
            ...circleStyles.bubble,
            marginRight: (MAX_WIDTH - width) / 2,
            marginLeft: (MAX_WIDTH - width) / 2,
          }}
        >
          <Icon icon={icon} style={circleStyles.icon} />
        </ButtonBase>
      )}
    </Tooltip>
  )
}

const LineChart: React.FC<{ data: StandardChartData }> = memo(({ data }) => {
  return (
    <Grid
      style={
        {
          ...styles.columnFlex,
          ...FIXED_HEIGHT,
          borderBottom: '0.0625rem solid #e0e0e0',
          width: '100%',
        } as React.CSSProperties
      }
    >
      <EChart data={data} type={ChartType.levelsFull} constrainedValues />
    </Grid>
  )
})
