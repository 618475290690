import { useHookstate } from '@hookstate/core'
import { useModal } from '../../contexts/ModalContext'
import { exploreManager } from '../../managers/_manager.config'
import Metric, { MetricLocation } from '../../model/admin/Metric'
import MetricsConfig from '../../model/metric/MetricsConfig'
import globalState from '../../service/external/GlobalState'
import MetricConfigModal from '../metric/MetricConfigModal'
import SelectMetricModal from '../metric/SelectMetricModal'
import ConfigToolbar from '../util/ConfigToolbar'
import ConfirmModal from '../util/ConfirmModal'

export interface ExploreConfigToolbarProps {
  location: MetricLocation
  configs: MetricsConfig[]
}

export default function ExploreConfigToolbar({ location, configs }: ExploreConfigToolbarProps) {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()

  // Global state
  const currentConfig = useHookstate(globalState.currentExploreConfig).get()
  const exploreMetrics = useHookstate(globalState.exploreMetrics).get().data
  const isEditing = useHookstate(globalState.isEditingExplore).get()

  // -- Actions
  function onEdit() {
    if (exploreManager.toggleEditing()) {
      showSnackbar('explore-view-updated', 'Changes saved')
    }
  }

  function onAddMetric() {
    if (!exploreMetrics || !currentConfig) return

    const id = 'addExploreMetric'
    openModal(
      id,
      <SelectMetricModal
        availableMetrics={
          exploreMetrics.filter(
            (metric) => !currentConfig.hasMetric(metric.metricID) && metric.isAvailable(location),
          ) as Metric[]
        }
        onMetricClick={(metric) => {
          exploreManager.addMetric(metric)
        }}
        onClose={() => closeModal(id)}
      />,
    )
  }

  function onCreateConfig() {
    const config = MetricsConfig.create([
      MetricLocation.productExplore,
      MetricLocation.memberExplore,
    ])
    openModal(
      config.metricsConfigID,
      <MetricConfigModal
        config={config}
        title='Create View'
        isCreating
        onSubmit={(config) => exploreManager.saveConfig(config)}
        onClose={() => closeModal(config.metricsConfigID)}
      />,
    )
  }

  function onRenameConfig() {
    if (!currentConfig) return
    openModal(
      currentConfig!.metricsConfigID,
      <MetricConfigModal
        config={currentConfig! as MetricsConfig}
        title='Rename View'
        onSubmit={(config) => exploreManager.saveConfig(config)}
        onClose={() => closeModal(currentConfig.metricsConfigID)}
      />,
    )
  }

  function onDeleteConfig() {
    if (!currentConfig) return
    const id = 'delete-config'
    openModal(
      id,
      <ConfirmModal
        title='Delete View?'
        message='Are you sure that you want to delete this view? Deleting this view will remove it from your entire organization'
        confirmation='Delete'
        onConfirm={() => {
          exploreManager.deleteConfig(currentConfig.metricsConfigID)
          showSnackbar('explore-view-deleted', 'View deleted')
        }}
        onClose={() => closeModal(id)}
      />,
    )
  }

  function onSelectConfig(config: MetricsConfig) {
    exploreManager.selectConfig(config)
  }

  // -- UI
  if (!currentConfig) return null
  return (
    <ConfigToolbar
      configs={configs}
      currentConfig={currentConfig as MetricsConfig}
      isEditing={isEditing}
      onAddMetricClick={onAddMetric}
      onEditClick={onEdit}
      onCreateConfigClick={onCreateConfig}
      onRenameConfigClick={onRenameConfig}
      onDeleteConfigClick={onDeleteConfig}
      onSelectConfig={onSelectConfig}
      editPermissionIDs={['edit-explore-config']}
    />
  )
}
