import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { trackingManager } from '../../managers/_manager.config'

export interface PageViewTrackingProps {
  children: JSX.Element
}

export default function PageViewTracking({ children }: PageViewTrackingProps) {
  const location = useLocation()

  useEffect(() => {
    trackingManager.trackPageView(location.pathname)
  }, [location.pathname])

  return children
}
