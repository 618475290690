import { useHookstate } from '@hookstate/core'
import { Skeleton, Stack } from '@mui/material'
import Metric from '../../model/admin/Metric'
import StatisticData from '../../model/chart/StatisticData'
import globalState from '../../service/external/GlobalState'
import Statistic from '../chart/Statistic'
import InfoTag from '../metric/InfoTag'
import StatusView from '../util/StatusView'

export interface ExploreHeaderStatsProps {
  metricIDs: string[]
}

export default function ExploreHeaderStats({ metricIDs }: ExploreHeaderStatsProps) {
  // Global state
  const metricsState = useHookstate(globalState.exploreMetrics).get()
  const metricsDataState = useHookstate(globalState.exploreMetricsData).get()

  // -- UI
  function Stats({ metrics }: { metrics: Metric[] }) {
    // Check that all metrics have data
    let allHaveData = true
    for (const metricID of metricIDs) {
      const metricDataState = metricsDataState.get(metricID)
      const metricData = metricDataState.data
      if (!metricData || !(metricData.chartData instanceof StatisticData)) {
        allHaveData = false
        break
      }
    }
    if (!allHaveData) return <LoadingIndicator />

    // Create stat components
    const statComponents = []
    for (const metricID of metricIDs) {
      const metricDataState = metricsDataState.get(metricID)
      const metricData = metricDataState.data
      const metric = metrics.find((m) => m.metricID === metricID)
      if (!metric || !metricData || !(metricData.chartData instanceof StatisticData)) continue

      statComponents.push(
        <Stack key={metricID} direction='row' gap={1} alignItems='flex-start'>
          <Statistic data={metricData.chartData} />
          <InfoTag description={metric.description} />
        </Stack>,
      )
    }

    return (
      <Stack direction='row' gap={5} justifyContent='flex-start'>
        {statComponents}
      </Stack>
    )
  }

  function LoadingIndicator() {
    return (
      <Stack direction='row' gap={1}>
        {metricIDs.map((metricID) => (
          <Stack key={metricID}>
            <Skeleton variant='text' width={150} />
            <Skeleton variant='rounded' width={170} height={30} />
          </Stack>
        ))}
      </Stack>
    )
  }

  return (
    <StatusView
      state={metricsState}
      loadingRender={<LoadingIndicator />}
      render={(metrics) => <Stats metrics={metrics as Metric[]} />}
    />
  )
}
