export interface DynamicGridItem {
  readonly id: string
  readonly gridX: number
  readonly gridY: number
  readonly gridWidth: number | 'full'
  readonly gridHeight: number
  readonly minGridWidth: number
  readonly minGridHeight: number
  readonly isResizable: boolean
}

export default class GridItem implements DynamicGridItem {
  readonly id: string
  readonly gridX: number
  readonly gridY: number
  readonly gridWidth: number | 'full'
  readonly gridHeight: number
  readonly minGridWidth: number
  readonly minGridHeight: number
  readonly isResizable: boolean

  constructor(arg: GridItemArgument) {
    this.id = arg.id
    this.gridX = arg.gridX
    this.gridY = arg.gridY
    this.gridWidth = arg.gridWidth
    this.gridHeight = arg.gridHeight
    this.minGridWidth = arg.minGridWidth
    this.minGridHeight = arg.minGridHeight
    this.isResizable = arg.isResizable ?? true
  }
}

export interface GridItemArgument {
  id: string
  gridX: number
  gridY: number
  gridWidth: number | 'full'
  gridHeight: number
  minGridWidth: number
  minGridHeight: number
  isResizable?: boolean
}
