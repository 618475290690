import Metric from '../model/admin/Metric'
import MemberType from '../model/dashboard/MemberType'
import { DynamicGridItem } from '../model/metric/GridItem'
import MetricData from '../model/metric/MetricData'
import MetricsConfig from '../model/metric/MetricsConfig'
import DataSource from '../service/domain/DataSource'
import StateService from '../service/domain/StateService'
import {
  deleteOneInList,
  load,
  saveOneInList,
  saveOneInListLocal,
} from '../util/LoadableHookstateHelpers'
import { LoadableArray } from '../util/LoadableState'

export default class DashboardManager {
  readonly dataSource: DataSource
  readonly state: StateService

  constructor(dataSource: DataSource, state: StateService) {
    this.dataSource = dataSource
    this.state = state
  }

  // Config
  async fetchConfigs() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)

    const fetchFunction = () => this.dataSource.fetchDashboardConfigs(client.clientID)
    await load(this.state.dashboardConfigs, fetchFunction)

    if (this.state.dashboardConfigs.get()?.data?.length === 0)
      throw new Error(`Must have at least 1 dashboard config`)
    this.state.currentDashboardConfig.set(this.state.dashboardConfigs.get()?.data?.[0])
  }
  async saveConfig(config: MetricsConfig) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const saveFunction = () => this.dataSource.saveDashboardConfig(client.clientID, config)
    const matchFunction = (c: MetricsConfig) => c.metricsConfigID === config.metricsConfigID
    saveOneInList(this.state.dashboardConfigs, config, saveFunction, matchFunction)
    this.state.currentDashboardConfig.set(config)
  }
  async deleteConfig(configID: string) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const deleteFunction = () => this.dataSource.deleteDashboardConfig(client.clientID, configID)
    const matchFunction = (c: MetricsConfig) => c.metricsConfigID === configID
    deleteOneInList(this.state.dashboardConfigs, matchFunction, deleteFunction)

    // Set the view back to the first config if possible
    const configs = this.state.dashboardConfigs.get()?.data
    if (configs && configs.length > 0) {
      this.state.currentDashboardConfig.set(configs[0])
      this.fetchMetricsData()
    } else {
      this.state.currentDashboardConfig.set(undefined)
    }

    this.state.isEditingDashboard.set(false)
  }
  selectConfig(config: MetricsConfig) {
    this.state.currentDashboardConfig.set(config)
    this.state.isEditingDashboard.set(false)
    this.fetchMetricsData()
  }
  updateConfigLayout(items: DynamicGridItem[]) {
    const config = this.state.currentDashboardConfig.get()
    if (!config) throw new Error(`No current dashboard configuration`)
    this.updateConfigLocal(config.updateLayout(items))
  }
  private updateConfigLocal(config: MetricsConfig) {
    this.state.currentDashboardConfig.set(config)
    saveOneInListLocal(
      this.state.dashboardConfigs,
      config,
      (c) => c.metricsConfigID === config.metricsConfigID,
    )
  }

  // Metrics
  async fetchMetrics() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)

    const fetchFunction = () => this.dataSource.fetchDashboardMetrics(client.clientID)
    await load(this.state.dashboardMetrics, fetchFunction)
  }
  async fetchMetricsData() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const config = this.state.currentDashboardConfig.get()
    if (!config) throw new Error(`No dashboard configuration selected`)
    const memberType = this.state.dashboardMemberType.get()

    const metricIds = config.metrics.map((m) => m.metricID)

    this.state.dashboardMetricsData.set(LoadableArray.create<MetricData>(metricIds).loadAll())

    for (const metricID of metricIds) {
      this.fetchMetricData(client.clientID, metricID, memberType)
    }
  }
  async fetchMetricData(clientID: string, metricID: string, memberType: MemberType) {
    try {
      const data = await this.dataSource.fetchDashboardMetricData(clientID, memberType, metricID)
      this.state.dashboardMetricsData.set(
        this.state.dashboardMetricsData.get().setItem(data, metricID),
      )
    } catch (error: any) {
      this.state.dashboardMetricsData.set(
        this.state.dashboardMetricsData
          .get()
          .errorItem(
            metricID,
            new Error(`[${metricID}] Could not fetch metric data: ${error.message}`),
          ),
      )
    }
  }
  async addMetric(metric: Metric) {
    const config = this.state.currentDashboardConfig.get()
    if (!config) throw new Error(`No current dashboard configuration`)
    const updatedConfig = config.add(metric)
    this.saveConfig(updatedConfig)
    this.fetchMetricsData()
  }
  removeMetric(metricID: string) {
    const config = this.state.currentDashboardConfig.get()
    if (!config) throw new Error(`No current dashboard configuration`)
    const updatedConfig = config.remove(metricID)
    this.saveConfig(updatedConfig)
  }

  // Editing
  toggleEditing() {
    const isEditing = this.state.isEditingDashboard.get()
    this.state.isEditingDashboard.set(!isEditing)

    if (isEditing) {
      const config = this.state.currentDashboardConfig.get()
      if (!config) throw new Error(`No current dashboard configuration`)
      this.saveConfig(config as MetricsConfig)
      return true
    }
    return false
  }

  // Member Type
  selectMemberType(memberType: MemberType) {
    this.state.dashboardMemberType.set(memberType)
    this.fetchMetricsData()
  }
}
