import { CircularProgress } from '@mui/material'

export interface LoadingIndicatorProps {
  size?: number
}

export function LoadingIndicator({ size = 36 }: LoadingIndicatorProps) {
  return (
    <CircularProgress
      sx={{
        color: 'primary',
        width: '64px',
        height: '64px',
      }}
      size={size}
    />
  )
}

export default LoadingIndicator
