import { useHookstate } from '@hookstate/core'
import { Box, BoxProps, Card } from '@mui/material'
import { exploreManager } from '../../../managers/_manager.config'
import Filter, { FilterValues } from '../../../model/filter/Filter'
import FilterOption from '../../../model/filter/FilterOption'
import globalState from '../../../service/external/GlobalState'
import FilterView from './FilterView'

export interface ExploreFilterViewProps extends BoxProps {
  onApplyFilters?: () => void
}

export default function ExploreFilterView({
  onApplyFilters: applyFiltersExtraAction,
  ...props
}: ExploreFilterViewProps) {
  // Global state
  const filters = useHookstate(globalState.exploreFilters).map((filter) => filter.get())
  const filterOptions = useHookstate(globalState.exploreFilterOptions).map((filterOption) =>
    filterOption.get(),
  )

  // -- Actions
  function onAddFilter(metricID: string) {
    exploreManager.addFilter(metricID)
  }

  function onInvertFilter(metricID: string) {
    exploreManager.invertFilter(metricID)
  }

  function onRemoveFilter(metricID: string) {
    exploreManager.removeFilter(metricID)
  }

  function onFilterOptionChange(metricID: string, filterOption: FilterOption) {
    exploreManager.updateFilterOption(metricID, filterOption)
  }

  function onFilterValueChange(metricID: string, values: FilterValues, invert?: boolean) {
    exploreManager.updateFilterValues(metricID, values, undefined, invert)
  }

  function onApplyFilters() {
    exploreManager.fetchMetricsData()
    applyFiltersExtraAction?.()
  }

  function onClearFilters() {
    exploreManager.clearFilters()
  }

  // -- UI
  return (
    <Box {...props}>
      <Card variant='outlined' sx={{ p: 2, boxShadow: 'none' }}>
        <FilterView
          filters={filters as Filter[]}
          filterOptions={filterOptions as FilterOption[]}
          onAddFilter={onAddFilter}
          onInvertFilter={onInvertFilter}
          onRemoveFilter={onRemoveFilter}
          onFilterOptionChange={onFilterOptionChange}
          onFilterValueChange={onFilterValueChange}
          onApplyFilters={onApplyFilters}
          onClearFilters={onClearFilters}
        />
      </Card>
    </Box>
  )
}
