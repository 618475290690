import { Icon } from '@iconify/react'
import { Button, Checkbox, Divider, Stack, TextField, Tooltip } from '@mui/material'
import { useState } from 'react'
import copy from '../../../config/copy.config'
import icons from '../../../config/icons.config'
import DiscreteFilter, { DiscreteFilterValues } from '../../../model/filter/DiscreteFilter'
import DiscreteFilterOption from '../../../model/filter/DiscreteFilterOption'
import palette from '../../../theme/palette'
import StringUtil from '../../../util/StringUtil'

export interface DiscreteFilterValueMenuProps {
  filter?: DiscreteFilter
  filterOption: DiscreteFilterOption
  onSubmit: (values: DiscreteFilterValues, invert: boolean) => void
  onClose?: () => void
}

export default function DiscreteFilterValueMenu({
  filter,
  filterOption,
  onSubmit,
  onClose,
}: DiscreteFilterValueMenuProps) {
  // -- Local state
  const [searchValue, setSearchValue] = useState('')
  const [selectedValues, setSelectedValues] = useState(filter?.values ?? [])
  const [invert, setInvert] = useState(false)

  // Computed
  const isValueSelected = (value: string) => selectedValues.includes(value)
  const isFull = filterOption.isFull(selectedValues)

  // -- Actions
  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault()
      onDoneButtonClick()
    }
  }

  function onSearchValueChange(value: string) {
    setSearchValue(value)
  }

  function onDoneButtonClick() {
    onSubmit(selectedValues, invert)
    onClose?.()
  }

  function onSelectValue(value: string) {
    setSelectedValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value)
      } else {
        return filterOption.isMultiSelect ? [...prev, value] : [value]
      }
    })
  }

  function toggleSelectAll() {
    if (isFull) {
      setSelectedValues([])
    } else {
      setSelectedValues(filterOption.options)
    }
  }

  return (
    <Stack p={2} gap={1} onKeyDown={handleKeyDown} data-testid='filter-value-menu-discrete'>
      {/* Search bar */}
      <Stack direction='row' data-testid='filter-value-menu-discrete-search'>
        <TextField
          value={searchValue}
          onChange={(e) => onSearchValueChange(e.target.value)}
          size='small'
          placeholder='Search'
          InputProps={{
            startAdornment: (
              <Icon
                icon={icons.search}
                width={16}
                color={palette.dark.text.secondary}
                style={{ marginRight: 5 }}
              />
            ),
          }}
        />
        <Tooltip title={copy.explore.filter.invertDescription}>
          <Checkbox
            checked={invert}
            onChange={(_, checked) => setInvert(checked)}
            checkedIcon={<Icon icon={icons.invert} />}
          />
        </Tooltip>
      </Stack>

      {/* Select all and values */}
      <Stack data-testid='filter-value-menu-discrete-value-select'>
        {filterOption.isMultiSelect && (
          <Stack>
            <ValueOption value='Select all' onSelectOption={toggleSelectAll} isSelected={isFull} />
            <Divider sx={{ marginY: 0.2 }} />
          </Stack>
        )}

        {filterOption.options
          .filter((value) => value.toLowerCase().includes(searchValue.toLowerCase()))
          .map((value, index) => (
            <ValueOption
              key={index}
              value={value}
              onSelectOption={onSelectValue}
              isSelected={isValueSelected(value)}
            />
          ))}
      </Stack>
      <Button variant='contained' sx={{ marginTop: 1 }} onClick={onDoneButtonClick}>
        Done
      </Button>
    </Stack>
  )
}

export interface ValueOptionProps {
  value: string
  onSelectOption: (value: string) => void
  isSelected: boolean
}

export function ValueOption({ value, onSelectOption, isSelected }: ValueOptionProps) {
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        cursor: 'pointer',
        borderRadius: 1,
        '&:hover': {
          backgroundColor: 'grey.200',
        },
      }}
      onClick={() => onSelectOption(value)}
    >
      <Checkbox size='small' checked={isSelected} />
      {StringUtil.capitalize(value)}
    </Stack>
  )
}
