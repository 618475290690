import { Stack, StackProps } from '@mui/material'
import StatisticData from '../../model/chart/StatisticData'
import StatisticNumber from './StatisticNumber'
import StatisticTitle from './StatisticTitle'

export interface StatisticProps extends StackProps {
  data: StatisticData
  description?: string
}

export default function Statistic({ data, description, ...props }: StatisticProps) {
  return (
    <Stack {...props}>
      <StatisticTitle title={data.title} description={description} />
      <StatisticNumber {...data} />
    </Stack>
  )
}
