import { Icon } from '@iconify/react'
import { IconButton } from '@mui/material'
import icons from '../../config/icons.config'

export interface ModalCloseButtonProps {
  onClose: () => void
}

export default function ModalCloseButton({ onClose }: ModalCloseButtonProps) {
  return (
    <IconButton onClick={onClose} sx={{ position: 'absolute', top: 5, right: 5, zIndex: 1 }}>
      <Icon icon={icons.close} width={16} />
    </IconButton>
  )
}
