import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import {
  CountryIso2,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone'
import FlagEmoji from './FlagEmoji'

export interface PhoneInputProps extends BaseTextFieldProps {
  value: string
  onChange: (phone: string) => void
}

export default function PhoneInput({ value, onChange, ...restProps }: PhoneInputProps) {
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'us',
    value,
    countries: defaultCountries,
    onChange: (data) => {
      // Prevent onChange if the country code is being erased
      if (data.phone.startsWith('+' + country.dialCode)) {
        onChange(data.phone)
      }
    },
  })

  // Effect to prepend country code if it's missing
  useEffect(() => {
    if (!phone.startsWith('+' + country.dialCode)) {
      onChange('+' + country.dialCode)
    }
  }, [country.dialCode, phone, onChange])

  return (
    <TextField
      variant='outlined'
      label='Phone number'
      color='primary'
      placeholder='Phone number'
      value={phone}
      onChange={(e) => {
        // Only update if the new value starts with the country code
        if (e.target.value.startsWith('+' + country.dialCode)) {
          handlePhoneValueChange(e)
        }
      }}
      type='tel'
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' style={{ marginRight: '2px', marginLeft: '-8px' }}>
            <Select
              MenuProps={{
                style: {
                  height: '300px',
                  width: '360px',
                  top: '10px',
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',

                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                '&.Mui-focused': {
                  fieldset: {
                    display: 'none',
                  },
                },
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(value) => (
                <FlagEmoji countryCode={country.iso2} style={{ width: '24px', height: '24px' }} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c)
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagEmoji
                      countryCode={country.iso2}
                      style={{ width: '24px', height: '24px' }}
                    />

                    <Typography marginLeft={1} marginRight='8px'>
                      {country.name}
                    </Typography>
                    <Typography color='gray'>+{country.dialCode}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  )
}
