import { Icon } from '@iconify/react'
import { Button, Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import image from '../../assets/forgot-password.svg'
import AuthFlowLayout from '../../components/layout/AuthFlowLayout'
import FormController from '../../components/util/FormController'
import icons from '../../config/icons.config'
import { authManager } from '../../managers/_manager.config'
import { ScreenPath } from '../../navigation'
import { VerificationType } from './VerifyAccountScreen'

export interface ForgotPasswordFormData {
  email: string
}

function ForgotPasswordScreen() {
  const navigate = useNavigate()
  const { handleSubmit, control } = useForm<ForgotPasswordFormData>()

  function handleResetPassword({ email }: ForgotPasswordFormData) {
    authManager.sendResetPasswordCode(email)
    navigate(ScreenPath.verifyAccount, {
      state: {
        verificationType: VerificationType.resetPassword,
        email,
      },
    })
  }

  function handleCancel() {
    navigate(ScreenPath.login)
  }

  return (
    <AuthFlowLayout title='Reset your password' image={image}>
      <Typography>
        Enter your email address. We'll send you a link to reset your password
      </Typography>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <Stack spacing={3}>
          <FormController
            name='email'
            control={control}
            rules={{
              required: 'Please input your email',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Please input a valid email',
              },
            }}
            placeholder='Email'
            icon={<Icon icon={icons.email} />}
          />
          <Stack direction='row' spacing={2} mt={2}>
            <Button onClick={handleCancel} fullWidth>
              Cancel
            </Button>
            <Button type='submit' variant='contained' color='primary' fullWidth>
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    </AuthFlowLayout>
  )
}

export default ForgotPasswordScreen
