import { useHookstate } from '@hookstate/core'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import OpportunityView from '../components/opportunity/OpportunityView'
import StatusView from '../components/util/StatusView'
import { opportunityManager } from '../managers/_manager.config'
import Opportunity from '../model/opportunity/Opportunity'
import globalState from '../service/external/GlobalState'

export default function OpportunityScreen() {
  // Navigation
  const { opportunityID } = useParams()

  // Global state
  const opportunitiesState = useHookstate(globalState.opportunities).get()

  // -- Lifecycle
  useEffect(() => {
    opportunityManager.fetchOpportunities()
  }, [])

  return (
    <StatusView
      state={opportunitiesState}
      defaultMargin
      render={(opportunities) => {
        const opportunity = opportunities.find((o) => o.opportunityID === opportunityID) as
          | Opportunity
          | undefined
        if (!opportunity) return null
        return <OpportunityView opportunity={opportunity} />
      }}
    />
  )
}
