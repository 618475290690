import { useHookstate } from '@hookstate/core'
import { useEffect } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import { adminManager } from '../../../managers/_manager.config'
import User from '../../../model/admin/User'
import globalState from '../../../service/external/GlobalState'
import StatusView from '../../util/StatusView'
import AdminTabView, { AdminTab } from '../AdminTabView'
import DeleteModal from '../modals/DeleteModal'
import UserModal from '../modals/UserModal'

export default function UsersTabView() {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()

  // Global state

  const usersState = useHookstate(globalState.users).get()
  const users = usersState.data

  // Effect
  useEffect(() => {
    adminManager.fetchUsers()
  }, [])

  // -- Actions
  const handleCreate = () => {
    const user = User.create()

    openModal(
      'create-role',
      <UserModal
        user={user}
        users={users as User[]}
        isCreating
        onClose={() => closeModal('create-role')}
        onSubmit={(updatedUser) => {
          adminManager.saveUser(updatedUser)
          showSnackbar('user-created', 'User created')
        }}
      />,
    )
  }

  const handleEdit = (userID: string) => {
    const user = users?.find((user) => user.userID === userID)
    if (!user) return
    openModal(
      userID,
      <UserModal
        users={users as User[]}
        user={user as User}
        onSubmit={(updatedUser) => {
          adminManager.saveUser(updatedUser)
          showSnackbar('user-updated', 'User updated')
        }}
        onClose={() => closeModal(user.userID)}
      />,
    )
  }

  const handleDuplicate = (userID: string) => {
    const user = users?.find((user) => user.userID === userID)
    if (!user) return
    openModal(
      userID,
      <UserModal
        users={users as User[]}
        isCreating
        user={user.duplicate()}
        onSubmit={(updatedUser) => {
          adminManager.saveUser(updatedUser)
          showSnackbar('user-created', 'User created')
        }}
        onClose={() => closeModal(user.userID)}
      />,
    )
  }

  const handleDelete = (userID: string) => {
    openModal(
      userID,
      <DeleteModal
        onConfirm={() => {
          adminManager.deleteUser(userID)
          showSnackbar('user-deleted', 'User deleted')
        }}
        label='user'
        onClose={() => closeModal(userID)}
      />,
    )
  }

  // -- UI
  const UsersTab: AdminTab<User> = {
    label: 'User',
    pluralLabel: 'Users',
    columns: [
      { name: 'Name', valueGetter: (user) => user.getFullName() },
      { name: 'Email', valueGetter: (user) => user.email },
      { name: 'Phone', valueGetter: (user) => user.phone },
    ],
    searchFilter: (searchText, user) =>
      user.getFullName().toLowerCase().includes(searchText.toLowerCase()),
    rowKey: (user) => user.userID,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDuplicate: handleDuplicate,
    onDelete: handleDelete,
  }

  return (
    <StatusView
      state={usersState}
      render={(users) => <AdminTabView data={users as User[]} tab={UsersTab} />}
    />
  )
}
