import { Stack, Typography } from '@mui/material'
import InfoTag from '../metric/InfoTag'

export interface StatisticTitleProps {
  title: string
  description?: string
}

export default function StatisticTitle({ title, description }: StatisticTitleProps) {
  return (
    <Stack direction='row' alignItems='center' gap={0.5}>
      <Typography fontSize={15} color='text.secondary'>
        {title}
      </Typography>
      {description && <InfoTag description={description} size={15} />}
    </Stack>
  )
}
