import { PermissionForm } from '../../components/admin/modals/PermissionsModal'

export interface PermissionArgument {
  permissionID: string
  name: string
  description: string
  isActive: boolean
}

export default class Permission {
  readonly permissionID: string
  readonly name: string
  readonly description: string
  readonly isActive: boolean

  constructor(arg: PermissionArgument) {
    this.permissionID = arg.permissionID
    this.name = arg.name
    this.description = arg.description
    this.isActive = arg.isActive
  }

  static create() {
    return new Permission({
      permissionID: '',
      name: '',
      description: '',
      isActive: true,
    })
  }

  duplicate() {
    return new Permission({
      ...this,
      name: `${this.name} (copy)`,
      permissionID: '',
    })
  }

  formUpdate(form: PermissionForm) {
    return new Permission({
      ...this,
      permissionID: form.permissionID,
      name: form.name,
      description: form.description,
      isActive: form.isActive,
    })
  }
}
