import { Icon } from '@iconify/react'
import { Chip } from '@mui/material'
import chroma from 'chroma-js'
import icons from '../../config/icons.config'
import { MetricCategory } from '../../model/admin/Metric'

export interface MetricCategoryChipProps {
  category: MetricCategory
  onSelectCategory: (category: MetricCategory) => void
  selectedCategory: MetricCategory
}

export const categoryColors = {
  [MetricCategory.all]: '#636363', //gray
  [MetricCategory.financialBehaviors]: '#3ca83c', //green
  [MetricCategory.demographics]: '#f08c23', //orange
  [MetricCategory.location]: '#3E5EFE', //blue
  [MetricCategory.memberParticipation]: '#c80303', //red
  [MetricCategory.surveys]: '#625685', //purple
  // [MetricCategory.transactions]: '#16A565', // teal
  [MetricCategory.productBalances]: '#16A565', // gold
}

export const categoryIcons = {
  [MetricCategory.all]: icons.global,
  [MetricCategory.financialBehaviors]: icons.dollar,
  [MetricCategory.demographics]: icons.person,
  [MetricCategory.location]: icons.distance,
  [MetricCategory.memberParticipation]: icons.check,
  [MetricCategory.surveys]: icons.survey,
  // [MetricCategory.transactions]: icons.transactions,
  [MetricCategory.productBalances]: icons.transactions,
}

export default function MetricCategoryChip({
  category,
  onSelectCategory,
  selectedCategory,
}: MetricCategoryChipProps) {
  return (
    <Chip
      key={category}
      label={category}
      size='medium'
      onClick={() => {
        onSelectCategory(category)
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: categoryColors[category],
        cursor: 'pointer',
        borderColor: categoryColors[category],
        fontWeight: selectedCategory === category ? 'bold' : 'normal',
        backgroundColor:
          selectedCategory === category
            ? chroma(categoryColors[category]).brighten(2.7).hex()
            : 'transparent',
        '& .MuiChip-icon': {
          color: categoryColors[category],
          marginRight: 'auto',
          marginLeft: 2,
        },
        '& .MuiChip-label': {
          flexGrow: 1,
        },
        '&:hover': {
          backgroundColor: chroma(categoryColors[category]).brighten(2.7).hex(),
          '& .MuiChip-icon': {
            color: 'black',
          },
        },
      }}
      icon={<Icon icon={categoryIcons[category]} color={categoryColors[category]} fontSize={15} />}
    />
  )
}
