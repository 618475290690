import { Box, Paper, Stack } from '@mui/material'
import { exploreManager } from '../../managers/_manager.config'
import ExportMembersButton from '../util/ExportMembersButton'
import ExploreHeaderStats from './ExploreHeaderStats'
import ExploreFilterView from './filter/ExploreFilterView'

export interface MemberVoiceExploreHeaderProps {}

export default function MemberVoiceExploreHeader({}: MemberVoiceExploreHeaderProps) {
  // -- UI
  return (
    <Paper sx={{ border: 'none', borderRadius: 0, padding: 2 }}>
      <Stack>
        <Stack direction='row' gap={2} alignItems='flex-start'>
          <Box width={170}>
            <ExploreHeaderStats metricIDs={['targetedMembers']} />
          </Box>
          <ExploreFilterView
            flex={1}
            onApplyFilters={() => exploreManager.fetchProductCategories()}
          />
          <ExportMembersButton
            exportFunction={(metrics) => exploreManager.exportMemberList(metrics)}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}
