import { Icon } from '@iconify/react'
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import icons from '../../../config/icons.config'
import Filter, { FilterValues } from '../../../model/filter/Filter'
import FilterOption from '../../../model/filter/FilterOption'
import DynamicMenu from '../../util/DynamicMenu'
import FilterValueMenu from './FilterValueMenu'

export interface FilterInputProps {
  filter: Filter
  filterOption: FilterOption
  availableFilterOptions: FilterOption[]
  index: number
  onRemoveFilter: (metricID: string) => void
  onFilterOptionChange: (metricID: string, filterOption: FilterOption) => void
  onFilterValueChange: (metricID: string, values: FilterValues, invert?: boolean) => void
}

export default function FilterInput({
  filter,
  filterOption,
  availableFilterOptions,
  index,
  onRemoveFilter,
  onFilterOptionChange,
  onFilterValueChange,
}: FilterInputProps) {
  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Typography variant='subtitle2'>{index === 0 ? 'where' : 'and'}</Typography>
      <Stack direction='row' alignItems='center'>
        <DynamicMenu
          actions={availableFilterOptions.map((filterOption) => ({
            label: filterOption.name,
            onClick: () => onFilterOptionChange(filter.metricID, filterOption),
          }))}
        >
          <Button>{filter.name}</Button>
        </DynamicMenu>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2' mx={1}>
            is
          </Typography>
          <DynamicMenu
            menuComponent={
              <FilterValueMenu
                filter={filter}
                filterOption={filterOption}
                onSubmit={(values, invert) => onFilterValueChange(filter.metricID, values, invert)}
              />
            }
          >
            <Button>{filter.getValueLabel()}</Button>
          </DynamicMenu>
        </Stack>
      </Stack>
      <Tooltip title='Remove'>
        <IconButton onClick={() => onRemoveFilter(filter.metricID)}>
          <Icon icon={icons.close} width={16} />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
