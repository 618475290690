import { useHookstate } from '@hookstate/core'
import { useEffect } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import { adminManager } from '../../../managers/_manager.config'
import Method from '../../../model/admin/Method'
import globalState from '../../../service/external/GlobalState'
import StatusView from '../../util/StatusView'
import AdminTabView, { AdminTab } from '../AdminTabView'
import DeleteModal from '../modals/DeleteModal'
import MethodModal from '../modals/MethodModal'

export default function DefaultMethodsTabView() {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()

  // Global state
  const methodsState = useHookstate(globalState.defaultMethods).get()
  const methods = methodsState.data

  // Effect
  useEffect(() => {
    adminManager.fetchDefaultMethods()
  }, [])

  // -- Actions
  const handleCreate = () => {
    const method = Method.create()
    openModal(
      'create-role',
      <MethodModal
        method={method}
        methods={methods as Method[]}
        isCreating
        onClose={() => closeModal('create-role')}
        onSubmit={async (updatedMethod) => {
          await adminManager.saveDefaultMethod(updatedMethod)
          closeModal('create-role')
          showSnackbar('method-created', 'Method created')
        }}
      />,
    )
  }

  const handleEdit = (methodID: string) => {
    const methods = methodsState.data
    const method = methods?.find((method) => method.methodID === methodID)
    if (!method) return
    openModal(
      methodID,
      <MethodModal
        methods={methods as Method[]}
        method={method as Method}
        onSubmit={async (updatedMethod) => {
          await adminManager.saveDefaultMethod(updatedMethod)
          closeModal(method.methodID)
          showSnackbar('method-updated', 'Method updated')
        }}
        onClose={() => closeModal(method.methodID)}
      />,
    )
  }

  const handleDuplicate = (methodID: string) => {
    const methods = methodsState.data
    const method = methods?.find((method) => method.methodID === methodID)
    if (!method) return
    openModal(
      methodID,
      <MethodModal
        methods={methods as Method[]}
        method={method.duplicate()}
        isCreating
        onSubmit={async (updatedMethod) => {
          await adminManager.saveDefaultMethod(updatedMethod)
          closeModal(method.methodID)
          showSnackbar('method-created', 'Method created')
        }}
        onClose={() => closeModal(method.methodID)}
      />,
    )
  }

  const handleDelete = (methodID: string) => {
    openModal(
      methodID,
      <DeleteModal
        onClose={() => closeModal(methodID)}
        onConfirm={() => {
          adminManager.deleteDefaultMethod(methodID)
          showSnackbar('method-deleted', 'Method deleted')
        }}
        label='method'
      />,
    )
  }

  // -- UI
  const DefaultMethodsTab: AdminTab<Method> = {
    label: 'Method',
    pluralLabel: 'Methods',
    columns: [
      { name: 'ID', valueGetter: (m) => m.methodID },
      { name: 'Name', valueGetter: (m) => m.name },
      { name: 'Description', valueGetter: (m) => m.description },
    ],
    searchFilter: (searchText, record) =>
      record.name.toLowerCase().includes(searchText.toLowerCase()),
    rowKey: (record) => record.methodID,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDuplicate: handleDuplicate,
    onDelete: handleDelete,
  }

  return (
    <StatusView
      state={methodsState}
      render={(methods) => <AdminTabView data={methods as Method[]} tab={DefaultMethodsTab} />}
    />
  )
}
