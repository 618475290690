export interface IntegrationArgument {
  integrationID: string
  name: string
  description: string
  logoURL?: string
  isAvailable: boolean
}

export default class Integration {
  readonly integrationID: string
  readonly name: string
  readonly description: string
  readonly logoURL?: string
  readonly isAvailable: boolean

  constructor(arg: IntegrationArgument) {
    this.integrationID = arg.integrationID
    this.name = arg.name
    this.description = arg.description
    this.logoURL = arg.logoURL
    this.isAvailable = arg.isAvailable
  }
}
