import { TextField } from '@mui/material'
import React from 'react'
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form'

interface CustomTextFieldControllerProps {
  name: string
  control: any
  defaultValue?: string | number
  label: string
  rules?: RegisterOptions<FieldValues, string>
  disabled?: boolean
  optional?: boolean
  multiline?: boolean
  error?: string // Added error prop
  autoFocus?: boolean
  rows?: number
}

const CustomTextFieldController: React.FC<CustomTextFieldControllerProps> = ({
  name,
  control,
  defaultValue,
  label,
  rules = {},
  disabled = false,
  optional = false,
  multiline = false,
  error, // Destructure the error prop
  autoFocus,
  rows,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          rows={rows}
          size='medium'
          label={label}
          fullWidth
          error={fieldState.invalid || !!error} // Consider external error
          helperText={error || fieldState.error?.message} // Display external error first, then fieldState error
          disabled={disabled}
          required={!optional}
          multiline={multiline}
          autoFocus={autoFocus}
        />
      )}
    />
  )
}

export default CustomTextFieldController
