import { v4 as uuid } from 'uuid'
import Method from '../admin/Method'

export interface OpportunityMethodArgument {
  opportunityMethodID?: string
  methodID: string
  name: string
  startDate: Date
  endDate: Date
  notes: string
  content: string
}

export default class OpportunityMethod {
  readonly opportunityMethodID: string
  readonly methodID: string
  readonly name: string
  readonly startDate: Date
  readonly endDate: Date
  readonly notes: string
  readonly content: string

  constructor(arg: OpportunityMethodArgument) {
    this.opportunityMethodID = arg.opportunityMethodID ?? uuid()
    this.methodID = arg.methodID
    this.name = arg.name
    this.startDate = new Date(arg.startDate)
    this.endDate = new Date(arg.endDate)
    this.notes = arg.notes
    this.content = arg.content
  }

  static create(method: Method) {
    return new OpportunityMethod({
      opportunityMethodID: uuid(),
      methodID: method.methodID,
      name: method.name,
      startDate: new Date(),
      endDate: new Date(),
      notes: '',
      content: '',
    })
  }

  updateMethod(method: Method) {
    return new OpportunityMethod({
      ...this,
      methodID: method.methodID,
      name: method.name,
    })
  }

  updateStartDate(date: Date) {
    return new OpportunityMethod({
      ...this,
      startDate: date,
    })
  }

  updateEndDate(date: Date) {
    return new OpportunityMethod({
      ...this,
      endDate: date,
    })
  }

  updateNotes(notes: string) {
    return new OpportunityMethod({
      ...this,
      notes: notes,
    })
  }
  updateContent(content: string) {
    return new OpportunityMethod({
      ...this,
      content: content,
    })
  }
}
