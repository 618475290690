import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom' // import useNavigate
import icons from '../../config/icons.config'
import { adminManager, authManager } from '../../managers/_manager.config'
import Client from '../../model/admin/Client'
import Role from '../../model/admin/Role'
import User from '../../model/admin/User'
import { ScreenPath, getHomeScreenPath } from '../../navigation' // import the Path
import globalState from '../../service/external/GlobalState'
import DateUtil from '../../util/DateUtil'
import InfoTag from '../metric/InfoTag'
import DynamicMenu from '../util/DynamicMenu'
import StatusView from '../util/StatusView'

interface UserMenuProps {
  user: User
}

export default function UserMenu({ user }: UserMenuProps) {
  // Navigation
  const navigate = useNavigate()

  // Global state
  const clientsState = useHookstate(globalState.clients).get()
  const currentClientState = useHookstate(globalState.currentClient)
  const currentClient = currentClientState.get()
  const rolesState = useHookstate(globalState.roles).get()

  // -- UI
  const profileOption = {
    label: 'Profile',
    onClick: () => navigate(ScreenPath.profile),
    icon: <Icon icon={icons.person} />,
  }
  const selectAccountOption = {
    label: 'Select organization',
    onClick: () => {
      adminManager.clearClient()
      navigate(getHomeScreenPath(user))
    },
    icon: <Icon icon={icons.bank} />,
  }
  const logoutOption = {
    label: 'Logout',
    onClick: () => {
      authManager.logout()
      navigate(ScreenPath.login)
    },
    color: 'error.main',
    fontWeight: 'bold',
    icon: <Icon icon={icons.close} />,
  }

  return (
    <StatusView
      state={clientsState}
      render={(clients) => (
        <StatusView
          state={rolesState}
          render={(roles) => (
            <DynamicMenu
              actions={
                user.getAvailableClients(clients as Client[], roles as Role[]).length > 1
                  ? [profileOption, selectAccountOption, logoutOption]
                  : [profileOption, logoutOption]
              }
            >
              <Stack
                direction='column'
                marginTop={1}
                alignItems={'flex-end'}
                sx={{ cursor: 'pointer' }}
              >
                <Typography>
                  Welcome, {user.firstName} {user.lastName}
                </Typography>
                {currentClient && (
                  <Stack direction='row' alignItems='center' mt={-0.5} mb={1}>
                    <Typography fontSize={11}>
                      {currentClient.name || 'No organization selected'}
                    </Typography>
                    <InfoTag
                      description={`Data last updated: ${DateUtil.getDateString(
                        currentClient.updatedDate,
                      )}`}
                      size={15}
                    />
                  </Stack>
                )}
              </Stack>
            </DynamicMenu>
          )}
        />
      )}
    />
  )
}
