import { FormControl, FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface CustomSwitchControllerProps {
  name: string
  control: any
  defaultValue?: boolean
  label: string
}

const CustomSwitchController: React.FC<CustomSwitchControllerProps> = ({
  name,
  control,
  defaultValue,
  label,
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field }) => (
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
          }
          label={label}
          labelPlacement='end'
        />
      </FormControl>
    )}
  />
)

export default CustomSwitchController
