import { LoadingButton } from '@mui/lab'
import { DialogActions } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

export interface ModalFooterProps {
  isCreating?: boolean
  onClose?: () => void
  onConfirm?: () => void
  center?: boolean
  isLoading?: boolean
}

export default function ModalFooter({
  isCreating,
  onClose,
  onConfirm,
  center,
  isLoading,
}: ModalFooterProps) {
  return (
    <DialogActions sx={{ justifyContent: center ? 'center' : '' }}>
      <Stack direction={center ? 'column-reverse' : 'row'} spacing={1}>
        <Button variant='text' fullWidth onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type='submit'
          variant='contained'
          color='primary'
          fullWidth
          onClick={onConfirm}
          loading={isLoading}
        >
          {isCreating ? 'Create' : 'Done'}
        </LoadingButton>
      </Stack>
    </DialogActions>
  )
}
