import { Icon } from '@iconify/react'
import { Box, BoxProps, IconButton, Tooltip } from '@mui/material'
import icons from '../../config/icons.config'

export interface InfoTagProps extends BoxProps {
  description?: string
  size?: number
}

export default function InfoTag({ description, size = 20, ...rest }: InfoTagProps) {
  if (!description) return null

  return (
    <Box
      {...rest}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...rest.sx,
      }}
    >
      <Tooltip title={description}>
        <IconButton sx={{ p: 0.5, cursor: 'default' }}>
          <Icon icon={icons.info} color='text.primary' width={size} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
