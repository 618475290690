import { Icon } from '@iconify/react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import StatisticData from '../../model/chart/StatisticData'
import Opportunity from '../../model/opportunity/Opportunity'
import OpportunityResults from '../../model/opportunity/OpportunityResults'
import PropensityType from '../../model/propensity/PropensityType'
import DateUtil from '../../util/DateUtil'
import Statistic from '../chart/Statistic'
import StandardCard from '../util/StandardCard'
import ExtendedObservationPeriod from './ExtendedObservationPeriod'
import OpportunityAdoptionChart from './OpportunityAdoptionChart'
import { opportunityViewState } from './OpportunityView'

export interface OpportunityResultsOverviewProps {
  opportunity: Opportunity
  results: OpportunityResults
}

export default function OpportunityResultsOverview({
  opportunity,
  results,
}: OpportunityResultsOverviewProps) {
  function Timeline({ opportunity }: { opportunity: Opportunity }) {
    return (
      <StandardCard variant='outlined' sx={{ minWidth: 500 }}>
        <Stack gap={1}>
          <Typography variant='h6'>Timeline</Typography>
          <Stack color='text.secondary'>
            <Stack direction='row' gap={1} alignItems='center'>
              <Icon icon={icons.calendar} />
              <Typography>
                {DateUtil.getDateString(opportunity.startDate)}
                {' - '}
                {DateUtil.getDateString(opportunity.endDate)} ({opportunity.getDuration()} days)
              </Typography>
            </Stack>
            <ExtendedObservationPeriod
              endDate={opportunityViewState.endDate.get()}
              resultsEndDate={opportunityViewState.resultsEndDate.get()}
            />
          </Stack>
        </Stack>
      </StandardCard>
    )
  }

  function ConversionMetrics({
    opportunity,
    results,
  }: {
    opportunity: Opportunity
    results: OpportunityResults
  }) {
    if (opportunity.propensityTarget.propensityType === PropensityType.growth) {
      if (!results.growthStats) throw new Error('Growth opportunity must have results.growthStats')
      return (
        <StandardCard variant='outlined'>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.targetedMembers.title,
                    value: opportunity.targetedMemberCount,
                  })
                }
                description={copy.opportunity.view.results.stats.targetedMembers.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.conversionRate.title,
                    value: results.growthStats.conversionRate,
                    valueSuffix: '%',
                    precision: 2,
                  })
                }
                description={copy.opportunity.view.results.stats.conversionRate.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.uniqueMembersOpened.title,
                    value: results.growthStats.adoptedMemberCount,
                  })
                }
                description={copy.opportunity.view.results.stats.uniqueMembersOpened.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.productsOpened.title,
                    value: results.growthStats.adoptedProductCount,
                  })
                }
                description={copy.opportunity.view.results.stats.productsOpened.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.avgBalanceAdded.title,
                    value: results.growthStats.averageBalanceAdded,
                    valuePrefix: '$',
                  })
                }
                description={copy.opportunity.view.results.stats.avgBalanceAdded.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.totalBalanceAdded.title,
                    value: results.growthStats.totalBalanceAdded,
                    valuePrefix: '$',
                    precision: 0,
                  })
                }
                description={copy.opportunity.view.results.stats.totalBalanceAdded.description}
              />
            </Grid>

            {
              (() => {
                if (!results.growthStats.applicationsCount) return null
                return <Grid item xs={6}>
                  <Statistic
                    data={
                      new StatisticData({
                        title: copy.opportunity.view.results.stats.applicationsCount.title,
                        value: results.growthStats.applicationsCount,
                        valuePrefix: '',
                        precision: 0,
                      })
                    }
                    description={copy.opportunity.view.results.stats.applicationsCount.description}
                  />
                </Grid>
              })()
            }
            {
              (() => {
                if (!results.growthStats.pctDiffThreeMonth) return null
                return <Grid item xs={6}>
                  <Statistic
                    data={
                      new StatisticData({
                        title: copy.opportunity.view.results.stats.pctDiffThreeMonthAvg.title,
                        value: Math.abs(results.growthStats.pctDiffThreeMonth),
                        valueSuffix: '%',
                        valuePrefix: results.growthStats.pctDiffThreeMonth > 0 ? '+ ' : '- ',
                        precision: 0,
                      })
                    }
                    description={copy.opportunity.view.results.stats.pctDiffThreeMonthAvg.description}
                  />
                </Grid>
              })()
            }
            {
              (() => {
                if (!results.growthStats.pctDiffLastYear) return null

                return <Grid item xs={6}>
                  <Statistic
                    data={
                      new StatisticData({
                        title: copy.opportunity.view.results.stats.pctDiffLastYear.title,
                        value: Math.abs(results.growthStats.pctDiffLastYear),
                        valueSuffix: '%',
                        // Use + or - sign prefix 
                        valuePrefix: results.growthStats.pctDiffLastYear > 0 ? '+ ' : '- ',
                        precision: 0,
                      })
                    }
                    description={copy.opportunity.view.results.stats.pctDiffLastYear.description}
                  />
                </Grid>
              })()
            }
            {
              (() => {
                if (!results.growthStats.pctDiffNonTargeted) return null

                return (<Grid item xs={6}>
                  <Statistic
                    data={
                      new StatisticData({
                        title: copy.opportunity.view.results.stats.pctDiffNonTargeted.title,
                        value: Math.abs(results.growthStats.pctDiffNonTargeted),
                        valueSuffix: '%',
                        valuePrefix: results.growthStats.pctDiffNonTargeted > 0 ? '+ ' : '- ',
                        precision: 0,
                      })
                    }
                    description={copy.opportunity.view.results.stats.pctDiffNonTargeted.description}
                  />
                </Grid>)
              })()
            }
          </Grid>
        </StandardCard>
      )
    }
    if (opportunity.propensityTarget.propensityType === PropensityType.churn) {
      if (!results.churnStats) throw new Error('Churn opportunity must have results.churnStats')
      return (
        <StandardCard variant='outlined'>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.targetedMembers.title,
                    value: opportunity.targetedMemberCount,
                  })
                }
                description={copy.opportunity.view.results.stats.targetedMembers.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.churnRate.title,
                    value: results.churnStats.churnRate,
                    valueSuffix: '%',
                    precision: 2,
                  })
                }
                description={copy.opportunity.view.results.stats.churnRate.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.uniqueMembersChurned.title,
                    value: results.churnStats.churnedMemberCount,
                  })
                }
                description={copy.opportunity.view.results.stats.uniqueMembersChurned.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.productsChurned.title,
                    value: results.churnStats.churnedProductCount,
                  })
                }
                description={copy.opportunity.view.results.stats.productsChurned.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.avgBalanceLost.title,
                    value: results.churnStats.averageBalanceLost,
                    valuePrefix: '$',
                  })
                }
                description={copy.opportunity.view.results.stats.avgBalanceLost.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Statistic
                data={
                  new StatisticData({
                    title: copy.opportunity.view.results.stats.totalBalanceLost.title,
                    value: results.churnStats.totalBalanceLost,
                    valuePrefix: '$',
                    precision: 0,
                  })
                }
                description={copy.opportunity.view.results.stats.totalBalanceLost.description}
              />
            </Grid>
          </Grid>
        </StandardCard>
      )
    }

    return null
  }

  return (
    <Stack direction='row' gap={1}>
      <Stack gap={1} width={500}>
        <Timeline opportunity={opportunity} />
        <ConversionMetrics opportunity={opportunity} results={results} />
      </Stack>
      <Box flex={1}>
        <OpportunityAdoptionChart
          propensityType={opportunity.propensityTarget.propensityType}
          methods={opportunity.methods}
          results={results}
        />
      </Box>
    </Stack>
  )
}
