import { v4 as uuidv4 } from 'uuid'

import Metric, { MetricLocation } from '../admin/Metric'
import ChartType from '../chart/ChartType'
import { DynamicGridItem } from './GridItem'
import MetricConfig, { MetricConfigArgument } from './MetricConfig'

export interface MetricsConfigArgument {
  metricsConfigID: string
  name: string
  locations: MetricLocation[]
  metrics: MetricConfigArgument[]
}

export default class MetricsConfig {
  readonly metricsConfigID: string
  readonly name: string
  readonly locations: MetricLocation[]
  readonly metrics: MetricConfig[]

  constructor(arg: MetricsConfigArgument) {
    this.metricsConfigID = arg.metricsConfigID
    this.name = arg.name
    this.locations = arg.locations
    this.metrics = arg.metrics.map((metric) => new MetricConfig(metric))
  }

  static create(locations: MetricLocation[]) {
    return new MetricsConfig({
      metricsConfigID: uuidv4(),
      name: '',
      locations: locations,
      metrics: [],
    })
  }

  getMetricIDs() {
    return this.metrics.map((metric) => metric.metricID)
  }

  getMetricConfig(metricID: string) {
    return this.metrics.find((metric) => metric.metricID === metricID)
  }

  getGridItems(metrics: Metric[]) {
    return this.metrics.map((metric) => metric.getGridItem(metrics))
  }

  isAvailable(location: MetricLocation) {
    return this.locations.includes(location)
  }

  hasMetric(metricID: string) {
    return this.metrics.find((metric) => metric.metricID === metricID)
  }

  updateName(name: string) {
    return new MetricsConfig({
      ...this,
      name,
    })
  }

  updateLayout(items: DynamicGridItem[]) {
    return new MetricsConfig({
      ...this,
      metrics: this.metrics.map((card) => {
        const item = items.find((item) => item.id === card.metricID)
        if (!item) throw new Error(`No grid item found for card ${card.metricID}`)
        return card.updateLayout(item)
      }),
    })
  }

  updateMetricChart(metricID: string, chart: ChartType) {
    return new MetricsConfig({
      ...this,
      metrics: this.metrics.map((metric) => {
        if (metric.metricID === metricID) return metric.updateChart(chart)
        return metric
      }),
    })
  }

  updateMetricState(metricID: string, state: any) {
    return new MetricsConfig({
      ...this,
      metrics: this.metrics.map((metric) => {
        if (metric.metricID === metricID) return metric.updateState(state)
        return metric
      }),
    })
  }

  add(metric: Metric) {
    const metricConfig = new MetricConfig({
      metricID: metric.metricID,
      gridX: 0,
      gridY: 0,
      gridWidth: metric.defaultGridWidth,
      gridHeight: metric.defaultGridHeight,
      chart: metric.defaultChart,
    })
    return new MetricsConfig({
      ...this,
      metrics: [...this.metrics, metricConfig],
    })
  }

  remove(metricID: string) {
    return new MetricsConfig({
      ...this,
      metrics: this.metrics.filter((metric) => metric.metricID !== metricID),
    })
  }
}
