import User from '../model/admin/User'
import AuthService from '../service/domain/AuthService'
import DataSource from '../service/domain/DataSource'
import StateService from '../service/domain/StateService'
import TrackingService from '../service/domain/TrackingService'
import { load } from '../util/LoadableHookstateHelpers'
import LoadableState from '../util/LoadableState'
import { adminManager, trackingManager } from './_manager.config'

export default class AuthManager {
  readonly auth: AuthService
  readonly dataSource: DataSource
  readonly state: StateService
  readonly tracking: TrackingService

  constructor(
    authService: AuthService,
    dataSource: DataSource,
    state: StateService,
    tracking: TrackingService,
  ) {
    this.auth = authService
    this.dataSource = dataSource
    this.state = state
    this.tracking = tracking
  }

  isAuthenticated() {
    // If we don't have the user yet, load it
    if (!this.state.user.data.get()) {
      const userID = this.auth.getCurrentUserID()
      if (!userID) return false
      load(this.state.user, () => this.dataSource.fetchUser(userID))
    }
    return this.auth.isAuthenticated()
  }

  async refreshSession() {
    await this.auth.refreshSession()
  }

  async restoreSessionFromRefreshToken(username: string, refreshToken: string) {
    await this.auth.restoreSessionFromRefreshToken(username, refreshToken)
  }

  getAccessTokenExpiryTime() {
    return this.auth.getAccessTokenExpiryTime()
  }

  async login(username: string, password: string) {
    return await this.auth.login(username, password)
  }
  async finishLogin() {
    const authToken = this.auth.getAuthToken()
    if (!authToken)
      throw new Error(`Can't assign auth token to data source. No auth token set in auth service`)

    const userID = this.auth.getCurrentUserID()
    if (!userID) throw new Error(`No user ID set in auth service`)
    const user = await this.dataSource.fetchUser(userID)
    this.state.user.set(LoadableState.set(user))

    adminManager.fetchClientFromLocalStorage()
    trackingManager.trackLogin()
  }

  async logout() {
    this.state.user.set(LoadableState.create<User>())
    this.state.currentClient.set(undefined)
    await this.auth.logout()
  }

  async checkPassword(password: string) {
    const user = this.state.user.data.get()
    if (!user) throw new Error(`No user set`)
    return await this.auth.checkPassword(user.email, password)
  }
  async changePassword(oldPassword: string, newPassword: string) {
    await this.auth.changePassword(oldPassword, newPassword)
  }

  async verifyMFACode(code: string) {
    await this.auth.verifyMFACode(code)
    await this.finishLogin()
  }

  async sendResetPasswordCode(email: string) {
    await this.auth.sendResetPasswordCode(email)
  }

  async resetPassword(code: string, email: string, password: string) {
    await this.auth.resetPassword(email, code, password)
  }

  async setInitialPassword(password: string) {
    await this.auth.setInitialPassword(password)
  }
}
