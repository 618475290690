import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Button, Chip, Dialog, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { exploreManager } from '../../managers/_manager.config'
import Metric from '../../model/admin/Metric'
import globalState from '../../service/external/GlobalState'
import InfoTag from '../metric/InfoTag'
import SelectMetricModal from '../metric/SelectMetricModal'
import ModalHeader from '../modal/ModalHeader'

interface ExportMembersModalProps {
  onClose?: () => void
  onExport?: (columns: Metric[]) => void
  defaultMetricIDs?: string[]
  propensityRequired?: boolean
}

interface ExportMembersModalContentProps {
  openModal: (id: string, content: JSX.Element) => void
  closeModal: (id: string) => void
  exploreMetrics: Metric[]
  selectedMetrics: Metric[]
  setSelectedMetrics: (metrics: Metric[]) => void
  propensityRequired?: boolean
}

export function ExportMembersModalContent({
  openModal,
  closeModal,
  exploreMetrics,
  selectedMetrics,
  setSelectedMetrics,
  propensityRequired,
}: ExportMembersModalContentProps) {
  const onMetricSelectModalButtonClick = () => {
    const id = 'select-metric'
    const availableMetrics: Metric[] = []
    const selectedMetricIDs = new Set(
      selectedMetrics.map((selectedMetric) => selectedMetric.metricID),
    )

    for (const metric of exploreMetrics) {
      if (!selectedMetricIDs.has(metric.metricID)) {
        availableMetrics.push(metric)
      }
    }

    openModal(
      id,
      <SelectMetricModal
        availableMetrics={availableMetrics}
        onMetricClick={(metric) => {
          setSelectedMetrics([...selectedMetrics, metric])
        }}
        onClose={() => closeModal(id)}
        description='Select a metric to add to your CSV export'
      />,
    )
  }

  const onDeleteAttributeColumn = (metricID: string) => {
    setSelectedMetrics(selectedMetrics.filter((metric) => metric.metricID !== metricID))
  }

  const renderChipList = () => {
    return selectedMetrics.map((metric) => {
      return (
        <Chip
          variant='outlined'
          label={metric.name}
          onDelete={() => onDeleteAttributeColumn(metric.metricID)}
          key={metric.metricID}
        />
      )
    })
  }

  const Subtitle = ({ title, description }: { title: string; description: string }) => (
    <Stack direction='row' spacing={0.25} alignItems='center'>
      <Typography variant='subtitle1'>{title}</Typography>
      <Typography color='error' variant='subtitle1'>
        *
      </Typography>
      <InfoTag description={description} size={15} />
    </Stack>
  )

  return (
    <>
      <Stack spacing={1} marginBottom={2}>
        <Stack direction={'row'}>
          <Subtitle
            title='CSV Columns'
            description='Pick columns of interest you want to see in your downloaded file.'
          />
        </Stack>

        <Stack direction={'row'} gap={1} flexWrap='wrap'>
          <Chip label='Member ID (Required)' />
          {propensityRequired && <Chip label='Propensity Score (Required)' />}
          {renderChipList()}
        </Stack>
      </Stack>

      <Button
        onClick={onMetricSelectModalButtonClick}
        color='inherit'
        variant='contained'
        sx={{ alignSelf: 'flex-start' }}
        startIcon={<Icon icon={icons.plus} width={20} />}
      >
        Add Column
      </Button>
    </>
  )
}

export default function ExportMembersModal({
  onClose,
  onExport,
  defaultMetricIDs,
  propensityRequired,
}: ExportMembersModalProps) {
  // Global state
  const exploreMetrics = useHookstate(globalState.exploreMetrics).get().data

  // -- Local state
  const [selectedMetrics, setSelectedMetrics] = useState<Metric[]>([])

  const { openModal, closeModal } = useModal()

  // -- Lifecycle
  useEffect(() => {
    exploreManager.fetchMetrics().then(() => {
      if (defaultMetricIDs && exploreMetrics) {
        const defaultMetrics = exploreMetrics.filter((metric) =>
          defaultMetricIDs.includes(metric.metricID),
        ) as Metric[]
        setSelectedMetrics(defaultMetrics)
      }
    })
  }, [])

  // -- Actions
  const onExportButtonClick = () => {
    onClose?.()
    onExport?.(selectedMetrics)
  }

  return (
    <Dialog open onClose={onClose} fullWidth>
      <ModalHeader title='Export Members' />
      <Stack spacing={2} m={3}>
        <ExportMembersModalContent
          openModal={openModal}
          closeModal={closeModal}
          exploreMetrics={exploreMetrics as Metric[]}
          selectedMetrics={selectedMetrics}
          setSelectedMetrics={setSelectedMetrics}
          propensityRequired={propensityRequired}
        />
        <Stack direction='row' justifyContent='right' gap={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onExportButtonClick} color='primary' variant='contained'>
            Export
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
