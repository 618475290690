import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Stack,
  Typography,
  alpha,
} from '@mui/material'
import { useEffect } from 'react'
import copy from '../../../config/copy.config'
import icons from '../../../config/icons.config'
import { adminManager } from '../../../managers/_manager.config'
import Integration from '../../../model/admin/Integration'
import { OpportunityStatus } from '../../../model/opportunity/Opportunity'
import globalState from '../../../service/external/GlobalState'
import { statusColors } from '../../opportunity/StatusChip'
import StatusView from '../../util/StatusView'

export default function IntegrationsTab() {
  // Global state
  const integrationsState = useHookstate(globalState.integrations).get()

  // Effect
  useEffect(() => {
    adminManager.fetchIntegrations()
  }, [])

  return (
    <StatusView
      state={integrationsState}
      render={(integrations) => {
        return <Stack spacing={2}>
          <Stack>
            <Typography variant='h3' fontSize={30}>
              {copy.admin.integrations.title}
            </Typography>
            <Typography variant='body1' color='text.secondary'>
              {copy.admin.integrations.description}
            </Typography>
          </Stack>
          {integrations.length > 0 ? (
            integrations.map((integration) => (
              <IntegrationCard key={integration.integrationID} integration={integration} />
            ))
          ) : (
            <Card sx={{ p: 2 }}>
              <Typography variant='body1' color='text.primary'>
                {copy.admin.integrations.none}
              </Typography>
            </Card>
          )}
        </Stack>
      }}
    />
  )
}

function IntegrationCard({ integration }: { integration: Integration }) {
  return (
    <Card sx={{ display: 'flex', margin: 0.5, width: '100%' }}>
      {integration.logoURL && (
        <CardMedia
          component='img'
          sx={{ width: 100, margin: 3, objectFit: 'contain' }}
          image={integration.logoURL}
          alt={integration.name}
        />
      )}
      <Divider orientation='vertical' flexItem />
      <Stack
        sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}
        justifyContent='space-between'
        alignItems='center'
      >
        <CardContent>
          <Typography component='div' variant='h5'>
            {integration.name}
          </Typography>
          <Typography variant='body1' color='text.secondary' component='div'>
            {integration.description}
          </Typography>
        </CardContent>

        <Chip
          label={integration.isAvailable ? 'Available' : 'Disabled'}
          sx={{
            color: integration.isAvailable
              ? statusColors[OpportunityStatus.completed]
              : statusColors[OpportunityStatus.draft],
            backgroundColor: alpha(
              integration.isAvailable
                ? statusColors[OpportunityStatus.completed]
                : statusColors[OpportunityStatus.draft],
              0.1,
            ),
            marginRight: 3,
            fontSize: 15,
            fontWeight: 'bold',
          }}
          icon={
            <Icon
              icon={integration.isAvailable ? icons.check : icons.close}
              color={
                integration.isAvailable
                  ? statusColors[OpportunityStatus.completed]
                  : statusColors[OpportunityStatus.draft]
              }
            />
          }
        />
      </Stack>
    </Card>
  )
}
